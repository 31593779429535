import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';
import { theme } from '../../../../styles/themes';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const VGSLogo: FC<IProps> = ({
  className = '',
  id = '',
  fill = theme.colors.secondary,
}) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }vgs-logo` }
    id={ id }
    width={ 102 }
    height={ 41 }
    fill={ fill }
  >
    <g clipPath='url(#clip0_7544_9738)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M18.3171 40.8347L18.3476 40.8173L18.3782 40.8347L27.0333 35.8592L35.7534 30.8487V10.8039L31.8324 13.0572L29.5437 14.4955L24.5437 17.4005V21.9856L31.8324 17.7708V25.0674L31.8319 25.0676L31.7727 28.5171L18.3476 36.2326L4.92265 28.5171L4.8632 25.0676L4.86303 13.0572L4.89334 13.0399L4.92265 13.0572L18.3476 5.34233L27.8135 10.7821L31.8324 8.47251L18.3775 0.740417L18.3476 0.757619L18.3179 0.740417L0.941895 10.726V30.8487L9.66202 35.8592L18.3171 40.8347Z' fill={ fill } />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.04639 15.0598L12.283 17.5862V24.2194L18.3477 27.8287L24.4126 24.2194V23.0398L28.649 20.5182V26.828L18.3477 32.9589L8.04639 26.828V15.0598Z' fill={ fill } />
      <path d='M58.2955 32.1185H54.1291L46.0959 11.5484H50.1472L56.2237 27.9308L62.1854 11.5484H66.2134L58.2955 32.1185Z' fill={ fill } />
      <path d='M77.6097 29.1862C78.9885 29.1862 79.9697 28.9087 80.9745 28.4464V21.1871H84.5967V30.7814C82.6103 32.0066 80.3904 32.8158 77.5629 32.8158C71.8846 32.8158 66.9072 28.6082 66.9072 22.0194C66.9072 15.4305 71.8377 11.1998 77.5865 11.1998C80.0165 11.1998 82.1899 11.8009 83.8722 12.7487V16.6558C82.0498 15.4074 79.9697 14.8756 78.0069 14.8756C73.8942 14.8756 70.9266 17.9042 70.9266 22.0656C70.9266 26.227 73.8474 29.1862 77.633 29.1862H77.6097Z' fill={ fill } />
      <path d='M100.039 12.5704V16.1797C98.6469 15.1974 96.6747 14.6263 94.9807 14.6263C93.0549 14.6263 91.5933 15.4487 91.5933 16.9564C91.5933 18.3498 92.8461 19.0123 95.236 19.9946C98.2522 21.2281 101.246 22.5074 101.246 26.2309C101.246 29.9544 98.1828 32.4672 93.8669 32.4672C91.338 32.4672 89.0873 31.5991 87.7183 30.594V26.7334C89.157 28.0812 91.3841 29.0406 93.5423 29.0406C95.7 29.0406 97.2546 28.0355 97.2546 26.3451C97.2546 24.8146 95.7698 24.0607 93.3335 23.0327C90.5954 21.9134 87.7415 20.5885 87.7415 17.0934C87.7415 13.5983 90.6187 11.1998 94.7951 11.1998C96.8603 11.1998 98.7163 11.7709 100.062 12.5704H100.039Z' fill={ fill } />
    </g>
    <defs>
      <clipPath id='clip0_7544_9738'>
        <rect width='100.587' height='40.0943' fill='white' transform='translate(0.658203 0.740417)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
