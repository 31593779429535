import styled from 'styled-components';
import { FlexRow, FlexCol } from '../../../styles/styles';

export const NoLinkedCardsContainer = styled.div`
  ${FlexCol}
  align-items: flex-start;
  text-align: left;
  min-height: 300px;

  .no-cards-text {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const LinkingOptions = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-between;
    min-height: 384px;
  }

  .apply-section, .plaid-section {
    width: 100%;
    padding: 24px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: calc(50% - 16px);
    }
  }

  .full-width {
    width: 100%;
  }

  .plaid-section {
    ${FlexCol}
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    justify-content: space-between;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      margin-top: 20px;
    }
  
    button {
      margin: 0 auto;
    }
  }
`;

export const TopContent = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-between;
  }
`;

export const NoCardsLinkedInfoWrapper = styled.div`
  ${FlexCol}
  width: 100%;
`;
