import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { CardModel, CardStatus } from '../../models/cards';
import { BankCard } from '../BankCard';
import { Button, ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { PlaidButton } from '../PlaidButton';
import { AccountCardsContainer, BankCardsContainer, ErrorLoadingCards, LoadingSpinnerContainer, RelinkTooltip } from './styles';
import { WarningNegativeIcon } from '../svgs/icons/WarningNegativeIcon';
import { theme } from '../../styles/themes';
import { TinyPopover } from '../TinyPopover';

interface IProps {
  className?: string;
}

const DashboardBankCardsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const cards = user.cards;
  const [errorLoadingCards, setErrorLoadingCards] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    user.loadCards()
      .catch(() => {
        analytics.fireEvent('Account_Cards_LoadError');
        setErrorLoadingCards(true);
      });
  }, [user.isLoggedIn, user.cards, user.groupedCards]);
   
  const onPlaidClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_Plaid_LinkCard_Click');
  }, []);

  const onPlaidSuccess = useCallback(() => {
    analytics.fireEvent('AccountDashBoard_linkCard_Success');
  }, []);

  const onRelinkClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_RelinkCard_Click');
  }, []);

  const toggleWarningTooltip = useCallback((key: string) => {
    analytics.fireEvent('AccountHub_WarningTooltip_Click');
    setIsTooltipOpen(prevStates => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  }, [setIsTooltipOpen]);

  const renderTooltipAnchor = useCallback((key: string) => (
    <Button
      className='warning'
      onClick={ () => toggleWarningTooltip(key) }
      kind={ ButtonKind.Blank }
    >
      <WarningNegativeIcon fill={ theme.colors.red } />
    </Button>
  ), []);
  
  const renderCards = useCallback(() => {
    if (errorLoadingCards) {
      return (
        <ErrorLoadingCards>
          Error Loading Cards
        </ErrorLoadingCards>
      );
    }

    if (user.loadingCards) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );
    }

    if (user.cardsLoaded && !user.cards.length) return null;

    const groupedInstitutions = user.groupedCards;
    const groupedElements = [];

    const needsRelinking = (institution: CardModel[]) => {
      const needsRelinking = institution.filter(bank => bank.status === CardStatus.Unlinked);
      return needsRelinking;
    };

    for (const key in groupedInstitutions) {
      if (groupedInstitutions[key]) {
        groupedElements.push( 
          <AccountCardsContainer key={ key } accountCards={ key === 'Karma Wallet' ? 1 : groupedInstitutions[key].length } >
            {
              !!needsRelinking(groupedInstitutions[key])[0]
                ? (
                  <TinyPopover
                    align='end'
                    dismissOnOutsideAction
                    anchor={ renderTooltipAnchor(key) }
                    isOpen={ isTooltipOpen[key] }
                    onRequestClose={ () => toggleWarningTooltip(key) }
                    placement={ ['right'] }
                  >
                    <RelinkTooltip>
                      <p className='attention'>Attention:</p>
                      <p> Your sustainability impact is no longer being tracked. { key } needs relinking.</p>
                      <ButtonLink
                        href='/account/linked-cards?relink=true'
                        onClick={ onRelinkClick }
                      >
                        <p className='re-link'>Re-Link Accounts</p>
                      </ButtonLink>
                    </RelinkTooltip>
                  </TinyPopover>
                )
                : null
            }
            {
              groupedInstitutions[key].map( (card, index) => (
                key === 'Karma Wallet' && index !== 0
                  ? null
                  : (
                    <BankCard
                      key={ card._id }
                      card={ card }
                      dashboardCard
                      className={ `card-${index} ${ !!needsRelinking(groupedInstitutions[key])[0] ? 'relink-card' : '' }` }
                    />
                  )
              ))
            }
          </AccountCardsContainer>,
        );
      }
    }

    return groupedElements;
    
  }, [user.groupedCards, user.cards, cards, isTooltipOpen]);

  const renderLinkCardButton = useCallback(() => (
    <PlaidButton
      className='plaid-button'
      kind={ ButtonKind.Primary }
      onClick={ onPlaidClick }
      onPlaidSuccess={ onPlaidSuccess }
    >
      <BankCard dashboardAddCard />
    </PlaidButton>
  ), []);

  return (
    <BankCardsContainer className={ className }>
      { renderCards() }
      { user.cardsLoaded && renderLinkCardButton() }
    </BankCardsContainer>
  );
};

export const DashboardBankCards = observer(DashboardBankCardsBase);
