import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ApplyProgressBarContainer } from './styles';
import { ApplyForCardModel, CurrentPage } from '../../../../models/karmaCard';

interface IProps {
  className?: string;
  applyForCardModel: ApplyForCardModel;
}

export const ApplyProgressBarBase: React.FC<IProps> = ({
  className = '',
  applyForCardModel,
}) => {
  const pillItems = useMemo(() => [
    {
      complete: applyForCardModel.pageOneComplete,
      active: applyForCardModel.currentPage === CurrentPage.PageOne,
      label: 'Enter email',
    },
    {
      complete: applyForCardModel.pageTwoComplete,
      active: applyForCardModel.currentPage === CurrentPage.PageTwo_CollectDocs,
      label: 'Verify identity',
    },
    // figure out when we can mark this as complete
    {
      complete: applyForCardModel.pendingPageComplete,
      active: applyForCardModel.currentPage === CurrentPage.Payment,
      label: 'Membership fee',
    },
    {
      complete: false,
      active: applyForCardModel.currentPage === CurrentPage.Pending || applyForCardModel.currentPage === CurrentPage.Success,
      label: 'Download app',
    },
  ], [applyForCardModel.currentPage, applyForCardModel.pageOneComplete, applyForCardModel.pageTwoComplete, applyForCardModel.pendingPageComplete]);

  if (applyForCardModel.currentPage === CurrentPage.Pending || applyForCardModel.currentPage === CurrentPage.Declined) return null;

  return (
    <ApplyProgressBarContainer
      className={ className }
      items={ pillItems }
    />
  );
};

export const ApplyProgressBar = observer(ApplyProgressBarBase);
