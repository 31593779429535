import React from 'react';
import { AppLandingPageContainer, DisclaimerContainer } from './styles';
import { AppLandingPageHero } from './Hero';
import { YouShopWeDonateSection } from '../../components/YouShopWeDonateSection';
import { SafetyAndSecuritySection } from '../../components/SafetyAndSecuritySection';
import { NotReadySection } from '../../components/NotReadySection';
import { DisclaimerSection } from '../../components/DisclaimerSection';
import { AppLandingBenefitsSection } from '../../components/AppLandingBenefitsSection';
import { VideosAndArticlesSection } from '../../components/VideosAndArticlesSection';
import { WhatIsTheKarmaWalletCardSection } from '../../components/WhatIsTheKarmaWalletCardSection';
import { TopBrandsOffersSection } from '../../components/TopBrandsOffersSection';

interface IProps {
  className?: string;
}

const disclaimerText = [
  {
    text: '*This optional offer is not a Pathward product or service nor does Pathward endorse this offer.',
  },
  {
    text: '**Standard text message and data rates, fees, and charges may apply.',
  },
  {
    text: '***Visa\'s Zero Liability policy does not apply to certain commercial card and anonymous prepaid card transactions or transactions not processed by Visa. Cardholders must use care in protecting their card and notify their issuing financial institution immediately of any unauthorized use. Contact your issuer for more detail.',
  },
  {
    text: 'The Karma Wallet Visa® Prepaid Card is issued by Pathward®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Karma Wallet Visa Prepaid Card is powered by Marqeta. This card can be used everywhere Visa debit cards are accepted.',
    paddingTop: true,
  },
  { 
    text: 'The Karma Wallet Card is currently only available for US residents. It will work for purchases globally wherever Visa is accepted.',
    paddingTop: true,
  },
];

export const AppLanding: React.FC<IProps> = ({
  className = '',
}) => (
  <AppLandingPageContainer title='Karma Wallet Mobile App' className={ className }>
    <AppLandingPageHero />
    <TopBrandsOffersSection />
    <AppLandingBenefitsSection />
    <YouShopWeDonateSection />
    <VideosAndArticlesSection />
    <WhatIsTheKarmaWalletCardSection />
    <SafetyAndSecuritySection />
    <NotReadySection />
    <DisclaimerContainer>
      <DisclaimerSection textItems={ disclaimerText } />
    </DisclaimerContainer>
  </AppLandingPageContainer>
);
