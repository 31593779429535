import styled from 'styled-components';
import { AccountHubBasicContainer, FlexCol, FlexRow } from '../../../../styles/styles';

export const YourCashbackContainer = styled.div`
  ${AccountHubBasicContainer}
  width: 100%;
  margin-bottom: 40px;

 .no-info-past-payout {
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 16px;
 }

  h5 {
    font-size: 18px;
    text-align: left;
  }
`;

export const YourBalanceContainer = styled.div`
  ${FlexCol}
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  justify-content: center;
  padding: 20px 10px; 

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 30px 25px;
  }

  .left-content {
    ${FlexCol}

    .pending-tag {
      font-weight: 500;
      margin: unset;
    }
  }
  
  .right-content {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;

    h3 {
      font-weight: 700;
    }
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexRow}
      align-items: center;
      gap: 20px;
      height: unset;
    }
  }
`;

export const AccrualsContainer = styled.div`
  margin-top: 40px;

  .accruals-header-container {
    ${FlexRow}
    align-items: center;
    margin-bottom: 20px;

    .cashback-accruals-info-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .accruals-header {
    margin-bottom: 0;
  }

  .accruals-wrapper {
    ${FlexCol}

    .view-all-accruals-wrapper {
      ${FlexRow}
      align-self: flex-end;
      margin-top: 10px;

      a {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        padding: 5px 10px;
      }
    }
  }
`;

export const LifetimeCashbackContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;
  margin-top: 40px;
`;

export const NextPayoutContainer = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  .next-payout-text {
    ${FlexRow}
    align-items: center;
  }

  .next-payout-info-icon {
    margin-left: 5px;
  }
`;

export const CashbackChip = styled.div`
  align-self: flex-end;
  border-radius: 56px;
  background: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: 700;
  padding: 2px 12px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    align-self: unset;
  }
`;

export const PayoutsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PayoutItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  a {
    ${FlexRow}
    justify-content: space-between;
    padding: 15px 0;
    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const LeftContent = styled.div`
  ${FlexRow}
  align-items: center;
  flex: 2;
  font-size: 16px;
  font-weight: 600;
  text-align: left;

  .dollar-icon-container {
    ${FlexRow}
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 4px;
    justify-content: center;
    margin-right: 16px;
    min-width: 41px;
    min-height: 41px;

    svg {
      height: auto;
      width: 10px;
    }
  }
`;

export const CenterContent = styled.div`
  align-items: center;
  display: none;
  font-size: 13px;
  font-weight: 500;
  flex: 1;
  justify-content: center;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    ${FlexRow}
  }
`;

export const RightContent = styled.div`
  ${FlexRow}
  align-items: center;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-end;
  text-align: right;
`;
