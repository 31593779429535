import React from 'react';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { ApplyNowButtonContainer } from './styles';

interface IProps {
  className?: string;
  locationOfButton: string;
  kind?: ButtonKind;
}

export const ApplyButton: React.FC<IProps> = ({
  className = '',
  locationOfButton,
  kind,
}) => {
  const analytics = useAnalytics();

  const onApplyClick = () => {
    analytics.fireEvent('Apply_Now_Clicked', locationOfButton);
  };

  return (
    <ApplyNowButtonContainer
      className={ `${className} join-waitlist` }
      kind={ kind ? kind : ButtonKind.Secondary }
      href={ '/apply' }
      onClick={ onApplyClick }
    >
      Apply Now
    </ApplyNowButtonContainer>
  );
};
