import styled from 'styled-components';
import { AccountHubBasicContainer, FlexCol, FlexRow } from '../../styles/styles';
import { Button } from '../Button';

export const AccountUserInformationSectionContainer = styled.div`
${AccountHubBasicContainer}
  width: 100%;
  
  #user-password,
  #user-email,
  #user-name,
  #user-zip,
  #user-state,
  #user-city,
  #user-address,
  #user-address-continued {
    border-color: ${({ theme }) => theme.colors.teal1};
    background-color: ${({ theme }) => theme.colors.grey1};
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

export const AccountInformationFieldsWrapper = styled.div`
  width: 100%;
  max-width: 692px;
  margin: 0 auto;
  padding: 50px 0;

  h6 {
    font-weight: 500;
    margin-bottom: 30px;
  }

  .pill-label {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 14px;
  }
`;

export const FieldAndButtonContainer = styled.div`
  ${FlexRow}
  width: 100%;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    width: 100%;
  }
`;

export const AddressInformationFieldsContainer = styled.div`
  .address-change,
  .address-change, b {
    color: ${({ theme }) => theme.colors.grey3};
    text-align: center;
  }
`;

export const ZipAndStateContainer = styled.div`
  ${FlexCol}
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  
  .user-info-text-field {
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    align-items: flex-start;
    gap: 20px;
    margin: unset;

    .user-info-text-field {
      width: 100%;
    }
  }
`;

export const AddressAndEditButtonContainer = styled.div`
  ${FlexRow}
  align-items: center;
  gap: 20px;
  width: 100%;

  div {
    display: flex;
    width: 100%;
  }
`;

export const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey4};
  height: 44.8px;
`;
