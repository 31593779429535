import React from 'react';
import { ButtonKind } from '../Button/styles';
import { ApplyNowButton } from '../ApplyNowButton';
import { ApplyOrJoinWaitlistButtonContainer } from './styles';

interface IProps {
  className?: string;
  kind?: ButtonKind;
  locationOfButton: string;
}

export const ApplyOrJoinWaitlist: React.FC<IProps> = ({
  kind,
  locationOfButton,
  className = '',
}) => (
  <ApplyOrJoinWaitlistButtonContainer className={ `${className} apply-or-join-waitlist-button-wrapper` }>
    <ApplyNowButton
      kind={ kind }
      locationOfButton={ locationOfButton }
      className={ className }
    />
  </ApplyOrJoinWaitlistButtonContainer>
);
