import React from 'react';
import { BenefitCardContainer, BenefitCardVariant, InnerWrapper } from './styles';

export interface IBenefitCard {
  title: string;
  description?: string;
  firstLine?: string;
  learnMore?: JSX.Element;
  icon?: JSX.Element;
  variant?: BenefitCardVariant;
}

interface IProps extends IBenefitCard {
  className?: string;
}

export const BenefitCard: React.FC<IProps> = ({
  className,
  title,
  description,
  firstLine,
  learnMore,
  variant,
  icon,
}) => (
  <BenefitCardContainer className={ className } variant={ variant }>
    <InnerWrapper>
      { !!firstLine ? <p className='description'>{ firstLine }</p> : null }
      <p className='title'>{ title }</p>
      <span className='description-container'>
        { !!icon ? icon : null }
        { !!description ? <p className='description'>{ description }</p> : null }
      </span>
      { !!learnMore ? learnMore : null }
    </InnerWrapper>
  </BenefitCardContainer>
);
