import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';
import { TextSMMedium, TextXSBoldLight } from '../../styles/components/text';

export const MembershipBenefitsContainer = styled.div`
  ${FlexCol}
  gap: 24px;
  justify-content: space-between;
  width: 100%;

  p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: unset;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;

export const BenefitSectionContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 12px;
  width: 100%;

  .header {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const BenefitCardsContainer = styled.div`
  ${FlexCol}
  gap: 10px;
  width: 100%;

  .tooltip-text {
    ${TextXSBoldLight}
    color: ${({ theme }) => theme.colors.white};
    max-width: 230px;

    a {
      ${TextXSBoldLight}
      color: ${({ theme }) => theme.colors.secondary};
      display: inline-block;
      text-decoration: none;
    }
  }

  .tooltip {
    display: block;

    :hover {
      cursor: pointer;
    }
  }

  .learn-more {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey3};
    text-decoration: underline;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    gap: 24px;
  }
`;
