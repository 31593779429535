import styled from 'styled-components';

export const CommissionsListContainer = styled.div`
  margin-top: 30px;

  .total {
    text-align: right;
    margin-top: 20px;

    span {
      font-weight: bold;
    }
  }
`;

export const CommissionRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};

  .date {
    width: 140px;
  }

  .merchant {
    flex-grow: 1;
  }

  .amount {
    width: 100px;
    text-align: right;
  }
`;
