import { observer } from 'mobx-react-lite';
import React from 'react';
import { HeaderContainer, KarmaCollectiveOffersContainer } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { ChevronDirection, ChevronIcon } from '../../../components/svgs/icons/ChevronIcon';
import { CardSize } from '../../../components/CashbackCard/styles';
import { KarmaCollectiveOffersWidgetLocation, KarmaCollectiveWidget } from '../../../components/KarmaCollectiveWidget';

interface IProps extends IThemeProps {
  className?: string;
}

const KarmaCollectiveOffersPageBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  
  const onBackToDashboardClick = () => {
    analytics.fireEvent('KarmaCollectiveOffers_BackToDashboard_Click');
  };

  return (
    <KarmaCollectiveOffersContainer className={ className }>
      <HeaderContainer>
        <ButtonLink
          className='back-to-dashboard-button'
          kind={ ButtonKind.Blank }
          href='/account/dashboard'
          label='Back to Dashboard'
          onClick={ onBackToDashboardClick }
        >
          <ChevronIcon direction={ ChevronDirection.Left } stroke={ theme.colors.dark } />
        </ButtonLink>
      </HeaderContainer>
      <KarmaCollectiveWidget location={ KarmaCollectiveOffersWidgetLocation.KarmaCollectiveOffersPage } cardsSize={ CardSize.Regular } />
    </KarmaCollectiveOffersContainer>
  );
};

const KarmaCollectiveOffersPageObserver = observer(KarmaCollectiveOffersPageBase);
export const KarmaCollectiveOffersPage = withTheme(KarmaCollectiveOffersPageObserver);
