import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const ReceiptIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }receipt-icon` }
    id={ id }
    width={ 73 }
    height={ 73 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M15.5 9.5L25.25 12.5L35 9.5L44.75 12.5L54.5 9.5V63.5L44.75 60.5L35 63.5L25.25 60.5L15.5 63.5V9.5Z' stroke='#001C20' strokeWidth='2.25' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M24.5 39.5V38H21.8507L23.2138 40.2717L24.5 39.5ZM44 41C44.8284 41 45.5 40.3284 45.5 39.5C45.5 38.6716 44.8284 38 44 38V41ZM23.2138 40.2717L27.7138 47.7717L30.2862 46.2283L25.7862 38.7283L23.2138 40.2717ZM24.5 41H44V38H24.5V41Z' fill='#001C20' />
    <path d='M47 35V36.5H49.6493L48.2862 34.2283L47 35ZM43.7862 26.7283C43.36 26.0179 42.4386 25.7875 41.7283 26.2138C41.0179 26.64 40.7875 27.5614 41.2138 28.2717L43.7862 26.7283ZM48.2862 34.2283L43.7862 26.7283L41.2138 28.2717L45.7138 35.7717L48.2862 34.2283ZM47 33.5H27.5V36.5H47V33.5Z' fill='#001C20' />
  </SvgIcon>
);
