import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CompanyModel } from './companies';
import { ICarbonImpact } from './impact';

dayjs.extend(utc);

type UserMonthlyImpactReportsModelPrivateFields = '_loadingUserImpactReport' |
'_loadingUserImpactReportsSummaryMonthData' |
'_selectedReportId' |
'_userImpactReports' |
'_userImpactReportsSummary';

type UserMonthlyImpactReportModelPrivateFields = '_userImpactReport' ;

type UserImpactReportsSummaryModelPrivateFields = '_annualData' |
'_hasImpactData' |
'_mostRecentReport' |
'_oldestReport';

interface IUserImpactMonthData {
  date: Date;
  negative: number;
  neutral: number;
  positive: number;
  score: number;
  transactionCount: number;
}

export interface IUserImpactTransaction {
  _id: string;
  user: string;
  company: CompanyModel;
  card: string;
  sector: string;
  amount: number;
  date: Date;
  createOn: Date;
}

interface IUserMonthlyImpactReportResponse {
  transactions: IUserImpactTransaction[];
  impact: IUserImpactMonthData;
  carbon: ICarbonImpact;
  date: Date;
  createdOn: Date;
  _id: string;
}

interface IUserImpactReportsSummaryMonthBase {
  reportId?: string;
  withinDataRange: boolean;
  score: number;
}

interface IUserImpactReportsSummaryResponseMonth extends IUserImpactReportsSummaryMonthBase {
  date: Date;
}

export interface IUserImpactReportsSummaryMonth extends IUserImpactReportsSummaryMonthBase {
  date: dayjs.Dayjs;
}

interface IUserImpactReportsSummaryYearBase {
  year: number;
}
interface IUserImpactReportsSummaryReponseYear extends IUserImpactReportsSummaryYearBase {
  data: IUserImpactReportsSummaryResponseMonth[];
}

interface IUserImpactReportsSummaryYear extends IUserImpactReportsSummaryYearBase {
  data: IUserImpactReportsSummaryMonth[];
}

interface IUserImpactReportsSummaryReponse {
  annualData: IUserImpactReportsSummaryReponseYear[];
}

export class UserMonthlyImpactReportModel extends BaseModel {
  private _userImpactReport: IUserMonthlyImpactReportResponse = null;

  constructor (reportData: IUserMonthlyImpactReportResponse) {
    super();

    makeObservable<UserMonthlyImpactReportModel, UserMonthlyImpactReportModelPrivateFields>(this, {
      _userImpactReport: observable,
      carbon: computed,
      date: computed, 
      transactions: computed,
      createdOn: computed,
      impact: computed,
      _id: computed,
    });

    this._userImpactReport = reportData;
  }

  get carbon() { return(this._userImpactReport.carbon); }
  get date() { return dayjs(this._userImpactReport.date); }
  get transactions() { return this._userImpactReport.transactions; }
  get createdOn() { return dayjs(this._userImpactReport.createdOn); }
  get impact() { return this._userImpactReport.impact; }
  get _id() { return this._userImpactReport._id; }
}

export class UserImpactReportsSummaryModel extends BaseModel {
  private _annualData: IUserImpactReportsSummaryYear[] = [];
  private _annualDataFlattened: IUserImpactReportsSummaryMonth[] = [];
  private _hasImpactData = false;
  private _mostRecentReport = '';
  private _oldestReport = '';

  constructor (summary: IUserImpactReportsSummaryReponse) {
    super();
      
    makeObservable<UserImpactReportsSummaryModel, UserImpactReportsSummaryModelPrivateFields>(this, {
      _annualData: observable,
      _hasImpactData: observable,
      _mostRecentReport: observable,
      _oldestReport: observable,
      annualData: computed,
      hasImpactData: computed,
      getAnnualData: action.bound,
    });

    this._init(summary);
  }

  get annualData() { return this._annualData; }
  get annualDataFlattened() { return this._annualDataFlattened; }
  get hasImpactData() { return this._hasImpactData; }
  get mostRecentReport() { return this._mostRecentReport; }
  get oldestReport() { return this._oldestReport; }

  public getAnnualData = (year: number) => this._annualData.find(data => data.year === year);

  public getPreviousAndNextReportsWithData = (reportId: string) => {
    const reportIndex = this._annualDataFlattened.findIndex(data => data.reportId === reportId);
    let previousReport: IUserImpactReportsSummaryMonth = null;
    let nextReport: IUserImpactReportsSummaryMonth = null;
    for (let i = reportIndex - 1; i >= 0; i -= 1) {
      if (this._annualDataFlattened[i].reportId) {
        previousReport = this._annualDataFlattened[i];
        break;
      }
    }
    for (let i = reportIndex + 1; i < this._annualDataFlattened.length; i += 1) {
      if (this._annualDataFlattened[i].reportId) {
        nextReport = this._annualDataFlattened[i];
        break;
      }
    }
    return [previousReport, nextReport];
  };

  private _init = (summary: IUserImpactReportsSummaryReponse) => {
    for (const year of summary.annualData) {
      this._annualData.push({ 
        ...year, 
        data: year.data.map(m => {
          // set information needed from the summary
          if (m.reportId) {
            this._hasImpactData = true;
            this._mostRecentReport = m.reportId;
            if (!this._oldestReport) this._oldestReport = m.reportId;
          }

          // convert date to dayjs and return as _annualData
          const monthSummary = { ...m, date: dayjs(m.date) };
          this._annualDataFlattened.push(monthSummary);
          return monthSummary;
        }),
      });
    }
  };
}

export class UserMonthlyImpactReportsModel extends BaseModel {
  private _loadingUserImpactReportsSummaryMonthData = false;
  private _loadingUserImpactReport = false;
  private _selectedReportId = '';
  private _userImpactReports: { [key: string]: UserMonthlyImpactReportModel } = {};
  private _userImpactReportsSummary: UserImpactReportsSummaryModel = null;

  constructor () {
    super();
    makeObservable<UserMonthlyImpactReportsModel, UserMonthlyImpactReportsModelPrivateFields>(this, {
      _loadingUserImpactReport: observable,
      _loadingUserImpactReportsSummaryMonthData: observable,
      _selectedReportId: observable,
      _userImpactReports: observable,
      _userImpactReportsSummary: observable,
      loadingUserImpactReport: computed,
      loadingUserImpactReportsSummaryMonthData: computed,
      selectedReportId: computed, 
      userImpactReportsSummary: computed,
      userImpactReports: computed,
      loadUserImpactReport: action.bound,
      loadUserImpactReportsSummaryMonthData: action.bound,
    });
  }

  get loadingUserImpactReportsSummaryMonthData() { return this._loadingUserImpactReportsSummaryMonthData; }
  get loadingUserImpactReport() { return this._loadingUserImpactReport; }
  get selectedReportId() { return this._selectedReportId; }
  get userImpactReportsSummary() { return this._userImpactReportsSummary; }
  get userImpactReports() { return this._userImpactReports; }

  public loadUserImpactReport = async (reportId: string) => {
    if (this._loadingUserImpactReport || !this._userImpactReportsSummary.hasImpactData) return false;
    if (this._userImpactReports[reportId]) {
      this._selectedReportId = reportId;
      return this._userImpactReports[reportId];
    }
    this._loadingUserImpactReport = true;

    const result = await this.webServiceHelper.sendRequest<IUserMonthlyImpactReportResponse>({
      path: `/user-impact-reports/${reportId}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._userImpactReports[reportId] = new UserMonthlyImpactReportModel(result.value);
        this._selectedReportId = reportId;
        this._loadingUserImpactReport = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingUserImpactReport = false;
      });

      throw new Error(result.error);
    }
  };

  public loadUserImpactReportsSummaryMonthData = async () => {
    if (this._loadingUserImpactReportsSummaryMonthData) return false;
    this._loadingUserImpactReportsSummaryMonthData = true;

    const result = await this.webServiceHelper.sendRequest<IUserImpactReportsSummaryReponse>({
      path: '/user-impact-reports/summary',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._userImpactReportsSummary = new UserImpactReportsSummaryModel(result.value);
        this._loadingUserImpactReportsSummaryMonthData = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingUserImpactReportsSummaryMonthData = false;
      });

      throw new Error(result.error);
    }
  };
}
