import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { withTheme } from 'styled-components';
import { useUserSession } from '../../contexts/user';
import { H3 } from '../../styles/components/header';
import { IThemeProps } from '../../styles/themes';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { PencilIcon } from '../svgs/icons/PencilIcon';
import { UserInfoContainer, UserInfoItemContainer, UserInfoWrapper } from './styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { UpdateProfileModal } from '../modals/UpdateProfileModal';
import { noop } from '../../lib/misc';
import { SectionHeader } from '../SectionHeader';

interface IProps extends IThemeProps {
  className?: string;
}

export enum UpdateOptions {
  Name = 'name',
  Email = 'email',
  Password = 'password',
  Zipcode = 'zipcode',
  Address = 'address'
}

const UserInfoBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const [itemToUpdate, setItemToUpdate] = useState('');

  const onEditClick = useCallback((itemName: string) => () => {
    analytics.fireEvent(`EditUserInfoClick_${itemName[0].toUpperCase() + itemName.substring(1)}`);
    setItemToUpdate(itemName);
  }, []);

  if (!user.initialized) return null;

  const renderEditWrapper = (itemName: string, label: string, text: string) => (
    <UserInfoItemContainer className={ itemName }>
      <div className='label-wrapper'>
        <H3>{ label }</H3>
        <Button
          kind={ ButtonKind.Blank }
          onClick={ onEditClick(itemName) }
        >
          <PencilIcon stroke={ theme.colors.darkGray1 } />
        </Button>
      </div>
      <div className='field-text'>{ text }</div>
    </UserInfoItemContainer>
  );

  return (
    <UserInfoContainer className={ className }>
      <SectionHeader headerText='Account Info' />
      <UserInfoWrapper>
        <div className='info-row'>
          { renderEditWrapper(UpdateOptions.Name, user.name, `Member Since ${dayjs(user.dateJoined).format('MMMM YYYY')}`) }
        </div>
        <div className='info-row middle-row'>
          { renderEditWrapper(UpdateOptions.Email, 'Email', user?.primaryEmail?.email) }
          { renderEditWrapper(UpdateOptions.Password, 'Password', '••••••••') }
          { renderEditWrapper(UpdateOptions.Address, 'Address', user?.zipcode) }
        </div>
      </UserInfoWrapper>
      <UpdateProfileModal
        itemToUpdate={ itemToUpdate }
        setItemToUpdate={ setItemToUpdate }
        onClose={ noop }
      />
    </UserInfoContainer>
  );
};

const UserInfoObserver = observer(UserInfoBase);
export const UserInfo = withTheme(UserInfoObserver);
