import React from 'react';
import { ITopValueItem } from '../OurImpact';
import { TopValueCardContainer } from './styles';

interface IProps {
  className?: string;
  valueItem: ITopValueItem | any, // eslint-disable-line no-use-before-define
}

const TopValueCard: React.FC<IProps> = ( {
  className,
  valueItem,
}) => (
  <TopValueCardContainer className={ className }>
    <img src={ valueItem.icon } alt={ valueItem.value } />
    <p className='value-name'>{ valueItem.value }</p>
    <p className='value-dollar-amount'>{ valueItem.dollarValue }</p>
    <p className='value-purchases'>Purchases: { valueItem.purchases }</p>
  </TopValueCardContainer>
);

export default TopValueCard;
