import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateSelectorChartContainer, ImpactReportsInnerWrapper, ImpactReportsPageContainer, VisualizationSectionContainer } from './styles';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { IUserImpactReportsSummaryMonth, UserMonthlyImpactReportModel, UserMonthlyImpactReportsModel } from '../../../models/userMonthlyImpactReport';
import { useAnalytics } from '../../../contexts/analytics-store';
import { IRatings, IUserScoreBreakdown, ImpactModel } from '../../../models/impact';
import { KarmaScorePieChart } from './UserImpactDataVisualizationChart/KarmaScorePieChart';
import { DateSelector } from './UserImpactDataVisualizationChart/DateSelector';
import { UserImpactDataVisualizationChart } from './UserImpactDataVisualizationChart';
import { useUserSession } from '../../../contexts/user';
import { EmptyState } from './EmptyState';
import { ImpactReportTransactions } from '../../../components/ImpactReportTransactions';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { CarbonSection } from './CarbonSection';
import { ApplyForCardWidget } from '../../../components/ApplyForCardWidget';
import { AccountHubH2 } from '../../../styles/components/header';
import { DisclaimerText } from '../../../styles/components/paragraph';

interface IProps extends IThemeProps {
  className?: string;
}

const getMaxScore = (score: number) => {
  if (score > 100) return 100;
  if (score < 0) return 0;
  
  return score;
};

const ImpactReportsPageBase: React.FC<IProps> = ({
  className,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const impact = useRef(new ImpactModel()).current;
  const userImpactReportsModel = useRef(new UserMonthlyImpactReportsModel()).current;
  const [errorLoading, setErrorLoading] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [noReportData, setNoReportData] = useState(false);
  const [impactRanges, setImpactRanges] = useState<IRatings>(null);
  const [activeMonthReport, setActiveMonthReport] = useState<UserMonthlyImpactReportModel>(null);
  const [maxScore, setMaxScore] = useState(0);
  const [activeMonth, setActiveMonth] = useState(null);
  const [yearlyData, setYearlyData] = useState<IUserImpactReportsSummaryMonth[]>(null);

  useEffect(() => {
    if (!userImpactReportsModel?.userImpactReportsSummary?.hasImpactData && !userImpactReportsModel.loadingUserImpactReportsSummaryMonthData) {
      setNoReportData(true);
    } else {
      setNoReportData(false);
    }
  }, [userImpactReportsModel?.userImpactReportsSummary, userImpactReportsModel.loadingUserImpactReportsSummaryMonthData]);

  const onDotClick = useCallback(async(payload: any) => {
    try {
      await userImpactReportsModel.loadUserImpactReport(payload.payload.reportId);
      setActiveMonth(impact.monthlyBreakdown[payload.index]);
      analytics.fireEvent(`Account_ImpactReports_LoadReportClick_${payload.payload.reportId}`);
    } catch (error) {
      analytics.fireEvent(`Account_ImpactReports_LoadReportClick_${payload.payload.reportId}_Error`);
    }
  }, []);

  const onApplyClick = useCallback(() => {
    analytics.fireEvent('Account_ImpactReports_ApplyForCard_Click');
  }, []);

  useEffect(() => {
    if (!!impact.monthlyBreakdown) {
      const allScores = impact.monthlyBreakdown.map((month: IUserScoreBreakdown) => month.score);
      const max = Math.max(...allScores);
      setActiveMonth(impact.monthlyBreakdown[impact.monthlyBreakdown.length - 1]);
      setMaxScore(getMaxScore(max));
    }
  }, [impact.monthlyBreakdown]);

  useEffect(() => {
    impact.loadUserImpact() 
      .catch(() => {
        setErrorLoading(true);
        analytics.fireEvent('Account_UserImpact_Error_Loading');
      });
  }, []);

  useEffect(() => {
    setActiveMonthReport(userImpactReportsModel.userImpactReports[userImpactReportsModel.selectedReportId]);
  }, [userImpactReportsModel?.selectedReportId]);

  useEffect(() => {
    if (!userImpactReportsModel.loadingUserImpactReportsSummaryMonthData) {
      setIsLoading(true);
      userImpactReportsModel.loadUserImpactReportsSummaryMonthData()
        .then(() => {
          setYearlyData(userImpactReportsModel.userImpactReportsSummary.annualDataFlattened);
          userImpactReportsModel.loadUserImpactReport(userImpactReportsModel.userImpactReportsSummary.mostRecentReport)
            .catch(() => {
              analytics.fireEvent('Account_ImpactReport_ErrorLoadingReport');
              setErrorLoading(true);
            });
        })
        .catch(() => {
          analytics.fireEvent('Account_ImpactReport_ErrorLoadingSummary');
          setErrorLoading(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    ImpactModel.getRatings()
      .then((res) => setImpactRanges(res))
      .catch(() => {
        analytics.fireEvent('Account_ImpactReport_ErrorLoadingRanges');
      });
  }, []);

  const renderVisualizationSection = useCallback(() => {
    if (!isLoading && noReportData) return <EmptyState hasCards={ user.hasCards } />;

    if (errorLoading) return (
      <div className='error-loading-container'>
        We are unable to load your impact reports at this time. Please try again later.
      </div>
    );
    
    if (!yearlyData) return null;

    return (
      <VisualizationSectionContainer>
        <KarmaScorePieChart impactRanges={ impactRanges } activeMonthReport={ activeMonthReport } />
        <DateSelectorChartContainer>
          <DateSelector
            className='date-selector'
            userImpactReportsModel={ userImpactReportsModel }
            userImpactReportsSummary={ userImpactReportsModel.userImpactReportsSummary }
            activeMonthReport={ activeMonthReport }
          />
          <UserImpactDataVisualizationChart
            data={ yearlyData }
            onDotClick={ onDotClick }
            maxScore={ maxScore }
            activeMonthReport={ activeMonthReport }
          />
        </DateSelectorChartContainer>
      </VisualizationSectionContainer>
    );
  }, [activeMonth, impact.ratings, userImpactReportsModel, yearlyData, activeMonthReport]);

  const renderTransactions = useCallback(() => {
    if (!activeMonthReport) return null;
    
    return (
      <ImpactReportTransactions activeMonthReport={ activeMonthReport } className='full-width' />
    );
  }, [activeMonthReport]);

  if (!impact || isLoading) return (
    <LoadingSpinner />
  );

  return (
    <ImpactReportsPageContainer className={ className }>
      { !user.hasKarmaWalletCard ? <ApplyForCardWidget className='apply-for-card full-width' onApplyClick={ onApplyClick } /> : null }
      <ImpactReportsInnerWrapper className='full-width'>
        <div>
          <AccountHubH2>My Impact Reports</AccountHubH2>
          <DisclaimerText className='sub-text'>*Impact Reports are generated at the beginning of each month for transactions in the previous month.</DisclaimerText>
        </div>
        { renderVisualizationSection() }
        { !!activeMonthReport ? <CarbonSection activeMonthReport={ activeMonthReport } /> : null }
        { renderTransactions() }
      </ImpactReportsInnerWrapper>
    </ImpactReportsPageContainer>
  );
};

const ImpactReportsPageObserver = observer(ImpactReportsPageBase);
export const ImpactReportsPage = withTheme(ImpactReportsPageObserver);
