import React from 'react';
import { SvgIcon } from '../../SvgIcon';

export const CreditCards = ({ 
  className = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}credit-cards graphic` }
    id={ id }
    height={ 206 }
    width={ 286 }
    fill='none'
  >
    <g opacity='0.5'>
      <rect x='36.1536' y='38.7883' width='229.548' height='135.731' rx='4' transform='rotate(-9.43711 36.1536 38.7883)' fill='white' stroke='#323232' strokeWidth='2' />
      <rect x='40.0217' y='68.1589' width='231.548' height='28.6939' transform='rotate(-9.43711 40.0217 68.1589)' fill='#323232' />
      <line x1='78.2172' y1='121.881' x2='150.267' y2='109.905' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
      <line x1='80.5695' y1='136.032' x2='125.427' y2='128.576' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    </g>
    <rect x='1' y='70' width='230' height='135' rx='4' fill='white' stroke='#323232' strokeWidth='2' />
    <rect y='99.4448' width='232' height='28.5417' fill='#323232' />
    <line x1='28.9246' y1='158.382' x2='102.11' y2='158.382' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <line x1='28.9246' y1='172.653' x2='74.4907' y2='172.653' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
  </SvgIcon>
);
