import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { AutomaticRewardsEnrollModalContainer } from './styles';
import { ButtonKind } from '../../Button/styles';
import { Button } from '../../Button';
import { H4 } from '../../../styles/components/header';
import { ModalBankCardItem } from './ModalBankCardItem';
import { useUserSession } from '../../../contexts/user';
import { ensureCorrectCardFormat, formatCardInput } from '../../../utils/input-validation';
import { useAnalytics } from '../../../contexts/analytics-store';
import { CardModel } from '../../../models/cards';
import { useToaster } from '../../../contexts/toaster-store';
import { LoadingSpinner } from '../../loading/LoadingSpinner';

interface IAutomaticRewardsEnrollModalProps {
  className?: string;
  onClose?: () => void;
  isOpen: boolean;
  userCards: CardModel[];
}

const AutomaticRewardsEnrollModalBase: React.FC<IAutomaticRewardsEnrollModalProps> = ({
  className = '',
  onClose,
  isOpen,
  userCards,
}) => {
  const user = useUserSession();
  const toaster = useToaster();
  const analytics = useAnalytics();
  const cards = userCards.filter(card => (card?.subtype === 'checking' || card?.subtype === 'credit card') && !card?.isEnrolledInAutomaticRewards);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [cardNumberIsValid, setCardNumberIsValid] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardVerifying, setCardVerifying] = useState(false);
  const [cardVerifyingError, setCardVerifyingError] = useState(false);

  const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex =/^[0-9-]*$/;
    if (!regex.test(e.target.value)) return;
    const value = formatCardInput(e.target.value);
    if (ensureCorrectCardFormat(value)) {
      setCardNumberIsValid(true);
      setCardNumber(value); 
    } else {
      setCardNumberIsValid(false);
      if (value === undefined) setCardNumber('');
      else setCardNumber(value); 
    }
  };

  const onCardToggleButtonClick = (index: number) => {
    setCardNumber('');
    setCurrentCardIndex(index);
  };

  const onNextCardClick = useCallback(() => {
    analytics.fireEvent('AutomaticRewardsModal_NextCard_Click');
    if (currentCardIndex === cards.length - 1) return;
    setCardNumber('');
    setCurrentCardIndex(prev => prev+1);
  }, [currentCardIndex, cards]);

  const onVerifyCardClick = useCallback(async () => {
    setCardVerifying(true);
    setCardVerifyingError(false);
    const bin = cardNumber.split('-')[0];
    const lastFour = cardNumber.split('-')[1];
    analytics.fireEvent('AutomaticRewardsModal_VerifyCard_Click');
    try {
      await user.enrollInKardRewards({ bin, lastFour }, cards[currentCardIndex]._id);
      if (cards.length > 1) {
        setCurrentCardIndex(0);
        setCardNumber('');
        await user.loadCards(true);
        setCardVerifying(false);
      } else {
        await user.loadCards(true);
        setCardVerifying(false);
        onClose();
      }
      toaster.push({ message: 'Card enrolled in automatic rewards successfully.' });
    } catch {
      analytics.fireEvent('AutomaticRewardsModal_VerifyCard_Error');
      setCardVerifyingError(true);
      setCardVerifying(false);
    }
  }, [cardNumber, currentCardIndex, cards, onClose, user]);

  const ctas = [
    {
      id: 'not_interested',
      label: 'Not Interested',
      onClick: onClose,
    },
    {
      id: 'next_card',
      label: 'Next Card',
      onClick: onNextCardClick,
      disabled: cards.length === 1 || currentCardIndex === cards.length - 1,
    },
    {
      id: 'verify',
      label: 'Verify',
      onClick: onVerifyCardClick,
      disabled: !cardNumberIsValid,
    },
  ];
  
  const renderCtas = useCallback(() => { 
    const ctasToShow = ctas.filter(cta => {
      if (cta.label === 'Verify') return true;
      if (cards.length === 1) {
        if (cta.label === 'Not Interested') {
          return true;
        }
      } else if (cards.length > 1) {
        if (cta.label === 'Next Card') {
          return true;
        }
      }
    });

    return (
      <div className='cta-container'>
        {
          ctasToShow.map(cta => 
            <Button
              key={ cta.id }
              kind={ cta.label === 'Next Card' || cta.label === 'Not Interested' ? ButtonKind.PrimaryGhost : ButtonKind.Primary }
              onClick={ cta.onClick }
              disabled={ cta.disabled }
            >
              { cta.label }
            </Button>)
        }
      </div>);
  }, [ctas, cards]);

  const renderCurrentCard = useCallback(() => {
    if (!cards || !cards.length) return null;
    const cardsToLink = cards.filter( card => {
      if ((card?.subtype === 'checking' || card?.subtype === 'credit card') && !card?.isEnrolledInAutomaticRewards) {
        return true;
      } else {
        return false;
      }
    });
    const currentCard = cardsToLink[currentCardIndex];

    return (
      <ModalBankCardItem
        key={ currentCard?._id }
        card={ currentCard }
        onCardNumberChange={ onCardNumberChange }
        cardNumber={ cardNumber }
        errorVerifying={ cardVerifyingError }
      />
    );
  }, [currentCardIndex, cards, cardNumber]);

  const renderCardToggleButtons = useCallback(() => {
    if (cards.length === 1) return null;
    const buttons = cards.filter(card => !card?.isEnrolledInAutomaticRewards).map((card, index) => (
      <span
        className={ `card-toggle-button ${ currentCardIndex === index ? 'selected' : ''}` }
        key={ card._id }
        onClick={ () => onCardToggleButtonClick(index) }
      />
    ), [ cards, currentCardIndex ]);

    return <div className='toggle-button-container'>{ buttons }</div>;
  }, [cards, currentCardIndex]);

  const onCloseModal = () => {
    setCardVerifyingError(false);
    setCurrentCardIndex(0);
    setCardNumber('');
    onClose();
  };

  return (
    <AutomaticRewardsEnrollModalContainer
      className={ className }
      onClose={ onCloseModal }
      isOpen={ isOpen }
    >
      {
        !!cardVerifying ?
          <LoadingSpinner className='verify-spinner' />
          : null
      }
      <H4 className='earn-more-cashback-title'>Earn More Cashback</H4>
      <p className='card-number-info'>Enter <span>first 6</span> and <span>last 4</span> digits of your card and earn bonus cashback at point of sale!</p>
      { renderCurrentCard() }
      { renderCardToggleButtons() }
      { renderCtas() }
      <p className='terms-of-service-text'>By verifying, you agree to Karma Wallet's <a href='https://cdn.karmawallet.io/terms_of_service.pdf' target='_blank' rel='noreferrer'>Terms of Services</a> and <a href='/privacy-policy'
        target='_blank' rel='noreferrer'>Privacy Policy</a></p>
    </AutomaticRewardsEnrollModalContainer>
  );
};

export const AutomaticRewardsEnrollModal = observer(AutomaticRewardsEnrollModalBase);
