import styled from 'styled-components';
import { H5 } from '../../styles/components/header';
import { FlexCenter, FlexCol, FlexHorizontalCenter, FlexRow, PrimaryFont } from '../../styles/styles';
import { Button } from '../Button';

interface IProps {
  needsRelinking?: boolean;
}

export const BankCardWrapper = styled.div`
  ${FlexCol}
  align-items: center;
  width: 100%;
`;

export const BankContainer = styled.div<IProps>`
  ${FlexRow}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ needsRelinking, theme }) => needsRelinking ? `3px solid ${theme.colors.red}` : `1px solid ${theme.colors.grey2}`};
  border-radius: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  gap: 16px;
  height: 128px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const BankLogoContainer = styled.div`
  ${FlexCenter}
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-radius: 50%;
  height: 88px;
  width: 88px;

  img, svg {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
`;

export const BankInfoContainer = styled.div`
  ${FlexCol}
  align-items: start;
`;

export const BankName = styled(H5)`
  ${PrimaryFont}
  font-size: 18px;
  text-align: left;
`;

export const RelinkMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
  max-width: 213px;
`;

export const CardDetailsWrapper = styled.div<IProps>`
  ${FlexCol}
  background-color: ${({ theme }) => theme.colors.dark};
  border: ${({ needsRelinking, theme }) => needsRelinking ? `3px solid ${theme.colors.dark}` : `1px solid ${theme.colors.grey2}`};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  gap: 10px;
  position: relative;
  padding: 24px 24px 10px;
  top: -12px;
  width: 100%;
  z-index: 0;
`;

export const CardDetailsContainer = styled.div<IProps>`
  ${FlexRow}
  justify-content: space-between;

  .right-content {
    ${FlexRow}
    gap: 12px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .card-details {
    color: ${({ needsRelinking, theme }) => needsRelinking ? theme.colors.red : theme.colors.grey3};
    font-weight: 500;
    margin: unset;
  }
`;

export const RemoveButton = styled(Button)`
  ${FlexHorizontalCenter}
  border: solid 1px ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grey3};
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  height: 24px;
  padding: 0 8px;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const RelinkIconsContainer = styled.div`
  ${FlexCol}
  align-self: flex-end;

  svg {
    height: 24px;
    margin-top: 10px;
    width: 24px;
  }
`;
