import React, { useCallback } from 'react';
import { BenefitCardsContainer, BenefitSectionContainer, MembershipBenefitsContainer } from './styles';
import { BenefitCard, IBenefitCard } from '../BenefitCard';
import { useAnalytics } from '../../contexts/analytics-store';
import { DoneGoodIcon } from '../svgs/logos/DoneGoodIcon';
import { Tooltip } from '../Tooltip';

interface IProps {
  className?: string;
  location: 'Apply' | 'App';
}

interface IBenefitSection {
  header: string;
  cards: IBenefitCard[];
}

export const MembershipBenefits: React.FC<IProps> = ({
  className,
  location,
}) => {
  const analytics = useAnalytics();

  const onWhatIsDoneGoodClick = useCallback(() => {
    analytics.fireEvent(`${location}_WhatIs_DoneGood_Click`);
  }, [location]);

  const onKarmaScoreLearnMoreClick = useCallback(() => {
    analytics.fireEvent(`${location}_KarmaScore_LearnMore_Click`);
  }, [location]);

  const onShopHereClick = useCallback(() => {
    analytics.fireEvent(`${location}_DoneGood_ShopHere_Click`);
  }, [location]);

  const tooltipText = (
    <p className='tooltip-text'>
      DoneGood is an ethical marketplace with 120+ brands and thousands of products.
      <a
        href='https://donegood.co/'
        target='_blank'
        rel='noreferrer'
        onClick={ onShopHereClick }
      >
        Shop here.
      </a>
    </p>
  );

  const whatIsDoneGood = (
    <Tooltip
      className='tooltip'
      text={ tooltipText }
      withBorder
    >
      <p onClick={ onWhatIsDoneGoodClick } className='learn-more'>What is DoneGood?</p>
    </Tooltip>
  );
  
  const karmaScoreLearnMore = (
    <a
      className='learn-more'
      href='https://karmawallet.io/blog/2023/09/what-is-a-karma-score/'
      target='_blank'
      rel='noreferrer'
      onClick={ onKarmaScoreLearnMoreClick }
    >
     Learn More
    </a>
  );
  
  const benefitsData: IBenefitSection[] = [
    {
      header: 'Earn',
      cards: [
        {
          title: '$300 Cashback*',
          description: 'On Average Per Year',
        },
        {
          title: 'Free Shipping',
          description: 'At DoneGood',
          learnMore: whatIsDoneGood,
          icon: <DoneGoodIcon />,
        },
      ],
    },
    {
      header: 'Donate',
      cards: [
        {
          title: 'Fund Reforestation',
          description: 'When You Buy Gas',
        },
        {
          title: 'Fight Hunger',
          description: 'When You Dine Out',
        },
      ],
    },
    {
      header: 'Discover',
      cards: [
        {
          title: '18,000+ Brands',
          description: 'Social & Environmental Ratings',
        },
        {
          title: 'Karma Score',
          description: 'Discover Your Impact',
          learnMore: karmaScoreLearnMore, 
        },
      ],
    },
  ];

  const content = (
    benefitsData.map(({ header, cards }) => (
      <BenefitSectionContainer key={ header }>
        <p className='header'>{ header }</p>
        <BenefitCardsContainer>
          {
            cards.map(({ title, description, learnMore, icon }) => (
              <BenefitCard
                key={ title }
                title={ title }
                description={ description }
                learnMore={ learnMore }
                icon={ icon }
              />
            ))
          }
        </BenefitCardsContainer>
      </BenefitSectionContainer>
    ))
  );

  return (
    <MembershipBenefitsContainer className={ className }>
      { content }
    </MembershipBenefitsContainer>
  );
};
