import styled from 'styled-components';
import { MaxBoundarySection } from '../../../../styles/components/containers';
import { _H4 } from '../../../../styles/components/header';
import { FlexCol, FlexColCenter } from '../../../../styles/styles';

export const EmptyStateContainer = styled(MaxBoundarySection)`
  .empty-header {
    ${_H4}
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const ContentContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 30px;
  margin: 10px auto;
  max-width: 600px;

  @media(${({ theme }) => theme.breakpoints.lgMin}) {
    gap: unset;
    flex-direction: row;
  }
`;

export const RightContentContainer = styled.div`
  ${FlexColCenter}
 
  .title {
    font-weight: bold;
    text-align: center;
  }

  .body {
    margin: 20px 0;
    text-align: center;
  }

  button {
    align-self: center;
  }
`;

export const IconContainer = styled.div`
  .formula-graphic {
    width: 300px;
    height: 200px;
  }

  @media(${({ theme }) => theme.breakpoints.lgMin}) {
    width: 400px;

    &.no-cards {
      margin-right: 50px;
    }
  }
`;
