import React, { useState } from 'react';
import { AppStoreButtonsContainer, InfoContainer, ManageKarmaWalletCardContainer } from './styles';
import { useAnalytics } from '../../contexts/analytics-store';
import EmptyKarmaWalletCard from '../../../public/assets/images/empty-kw-card.png';
import { Button, ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import AppStoreBadge from '../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../public/assets/images/google-play-badge.svg';
import { XIcon } from '../svgs/icons/XIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  className?: string;
  hasCard?: boolean;
}

export const AccountManageKarmaWalletCardSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
  hasCard,
}) => {
  const analytics = useAnalytics();
  const [widgetHidden, setWidgetHidden] = useState(localStorage.getItem('hideManageCardWidget'));

  const onManageCardWidgetClose = () => {
    analytics.fireEvent('AccountDashboard_ManageCardWidget_Close');
    localStorage.setItem('hideManageCardWidget', 'true');
    setWidgetHidden('true');
  };

  const onAppleClick = () => {
    analytics.fireEvent('AccountDashboard_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('AccountDashboard_GoogleStore_Click');
  };

  const renderAppStoreButtons = () =>(
    <AppStoreButtonsContainer>
      <ButtonLink
        className='app-store-link'
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onAppleClick }
        href='https://apps.apple.com/app/karma-wallet/id6451440075'
      >
        <img src={ AppStoreBadge } alt='App Store Badge' />
      </ButtonLink>
      <ButtonLink
        className='app-store-link'
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onGoogleClick }
        href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
      >
        <img src={ GooglePlayBadge } alt='Google Play Badge' />
      </ButtonLink>
    </AppStoreButtonsContainer>
  );

  if (widgetHidden || !hasCard) return null;

  return (
    <ManageKarmaWalletCardContainer className={ className }>
      <InfoContainer>
        <img src={ EmptyKarmaWalletCard } alt='Karma Wallet Card Graphic' />
        <p>To add funds and view your Karma Wallet Card transactions, use the mobile app.</p>
      </InfoContainer>
      { renderAppStoreButtons() }
      <Button
        className='dismiss-icon'
        kind={ ButtonKind.Blank }
        label='Dismiss'
        onClick={ onManageCardWidgetClose }
      >
        <XIcon stroke={ theme.colors.darkGray2 } />
      </Button>
    </ManageKarmaWalletCardContainer>
  );
};

export const AccountManageKarmaWalletCardSection = withTheme(AccountManageKarmaWalletCardSectionBase);
