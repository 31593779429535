import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow, TruncateText } from '../../styles/styles';
import { TextSMDemiBold, TextXSBold } from '../../styles/components/text';

export const TransactionAmount = styled.div`
  ${FlexHorizontalCenter}

  &.reversed span {
    text-decoration: line-through;
  }

  .tiny-popover-anchor {
    margin-left: 5px;
  }
`;
export const TransactionDate = styled.p``;

export const ImpactReportTransactionItemContainer = styled.div`
  ${FlexHorizontalCenter}
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
  padding: 16px;

  p {
    margin: 0;
  }
`;

export const TransactionsItemSkeletonContainer = styled(ImpactReportTransactionItemContainer)``;

export const TransactionsPrimaryInfoSkeletonWrapper = styled.div`
  ${FlexRow}
  justify-content: space-between;
  width: 95%;

  span {
    max-width: 150px;
    width: 50%;

    .amount-date-skele, .company-name-skele {
      width: 95%;
    }
  }
`;

export const Cashback = styled.div`
  ${TruncateText}
  align-self: flex-start;
  background: ${({ theme }) => theme.colors.darkGreen2};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  font-size: 12px;
  max-width: 85%;
  padding: 2px 5px;
`;

export const TransactionCompanyInfoContainer = styled.div`
  ${FlexCol}
  flex: 1;
  gap: 5px;

  .company-logo {
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
    width: 40px;

    .default-company-logo-container {
      height: 40px;
      min-width: unset;
      width: 40px;

      .default-company-logo {
        width: 40px;
        height: 40px;
      }

      svg {
        height: 100%;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    ${FlexRow}
    align-items: center;
  }
`;

export const CompanyNameContainer = styled.div`
  ${FlexCol}
  ${TextXSBold}
  flex: 1;
  gap: 4px;
`;

export const TransactionAmountContainer = styled.div`
  ${FlexColCenter}
  ${TextSMDemiBold}
  align-items: flex-end;
  flex-direction: column-reverse;
  gap: 5px;
  justify-content: flex-end;

  .transaction-rating-tag {
    border-radius: 2px;
    font-size: 10px;
    height: unset;
    margin: 2px 0 0 0;
    padding: 1px 5px;

    p {
      font-weight: 500;
    }

    @media (${({ theme }) => theme.breakpoints.xsMin}) {
      font-size: 12px;
      margin: unset;
      padding: 2px 10px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexHorizontalCenter}
    gap: 15px;
    justify-content: space-between;
    min-width: 240px;
  }
`;

export const CompanyName = styled(Link)`
  ${TruncateText}
  ${TextXSBold}
  max-width: 150px;
  text-decoration: none;
  padding: 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: unset;
  }
`;

export const SkeletonContainer = styled.div`
  ${FlexHorizontalCenter}
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  .company-logo-skele {
    border-radius: 5px;
    height: 40px;
    width: 40px;
  }
`;
