import styled from 'styled-components';
import { DisclaimerText } from '../../styles/components/paragraph';

export const DisclaimerSectionContainer = styled.div`
  padding: 0 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 0;
  }
`;

export const ItemText = styled(DisclaimerText)`
  &.padding-top {
    padding-top: 20px;
  }
`;
