import styled from 'styled-components';
import { FlexColCenter, TruncateText } from '../../styles/styles';
import { TextSMDemiBold } from '../../styles/components/text';

export enum CardSize {
  Regular = 'regular',
  Small = 'small',
}

interface IProps {
  cardSize?: CardSize;
  locked?: string;
}

export const CashbackCardContainer = styled.div<IProps>`
  ${FlexColCenter}
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme, locked }) => locked === 'locked' ? theme.colors.primary : theme.colors.grey2};
  border-radius: 8px;
  gap: 8px;
  min-width: 128px;
  max-height: 187px;
  padding: 24px 12px;
  position: relative;
  width:100%;
  z-index: 1;

  ${({ cardSize, theme }) => {
    switch (cardSize) {
      case CardSize.Small: return `      
        @media (${theme.breakpoints.xxlMin}) {
          width: calc((100% / 6) - (16px * 5 / 6));
        }

        @media (${theme.breakpoints.xlMax}) {
          width: calc((100% / 3) - (16px * 2 / 3));
        }

        @media (${theme.breakpoints.xsMax}) {
          width: calc((100% / 2) - (16px / 2));
        }
      `;
      case CardSize.Regular: return `
        @media (${theme.breakpoints.xxlMin}) {
          width: calc((100% / 5) - (16px * 4 / 5));
        }

        @media (${theme.breakpoints.xlMax}) {
          width: calc((100% / 4) - (16px * 3 / 4));
        }

        @media (${theme.breakpoints.mdMax}) {
          width: calc((100% / 3) - (16px * 2 / 3));
        }

        @media (${theme.breakpoints.xsMax}) {
          width: calc((100% / 2) - (16px * 2 / 3));
        }
      `;
      default: return `
        @media (${theme.breakpoints.xxlMin}) {
          width: calc((100% / 5) - (16px * 4 / 5));
        }

        @media (${theme.breakpoints.xlMax}) {
          width: calc((100% / 4) - (16px * 3 / 4));
        }

        @media (${theme.breakpoints.mdMax}) {
          width: calc((100% / 3) - (16px * 2 / 3));
        }

        @media (${theme.breakpoints.xsMax}) {
          width: calc((100% / 2) - (16px * 2 / 3));
        }
      `;
    }
  }}

  img, svg, .default-company-logo-container {
    height: 47px;
    max-width: 100px;
  }

  .pill {
    font-size: 12px;
    height: 24px;
    min-width: 108px;
    padding: 0;
  }

  .company-name {
    ${TruncateText}
    ${TextSMDemiBold}
    margin: unset;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .start-shopping-button {
    min-width: 108px;
    padding: 0;
  }
`;
