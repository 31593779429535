import React from 'react';
import { ErrorPage } from '../../pages/ErrorPage';
import { ErrorBoundary } from '../ErrorBoundary';

interface IErrorPage {
  Component: React.FC;
}

export const WrappedWithErrorPage: React.FC<IErrorPage> = ({ Component }) => (
  <ErrorBoundary Fallback={ <ErrorPage /> }>
    <Component />
  </ErrorBoundary>
);
