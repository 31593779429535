import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { TextLG } from '../../styles/components/text';
import { FlexColCenter, FlexRow } from '../../styles/styles';

export const AppLandingBenefitsSectionContainer = styled(Section)`
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 65px 15px;
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 115px 15px;
  }
`;

export const AppLandingBenefitsInnerWrapper = styled(MaxBoundaryContainerLarge)`
  color: ${({ theme }) => theme.colors.white};  
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 32px;
    line-height: 34px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 40px;
      line-height: 42px;
    }
  }

  .good-for-you-text {
    ${TextLG}
    color: ${({ theme }) => theme.colors.white};
    margin: 12px auto 42px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 24px;
    }
  }

  .membership-card {
    ${FlexColCenter}
    gap: 18px;
    margin-top: 45px;
    padding: 30px 20px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexRow}
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 45px auto 0;
      width: calc(33.333% - 24px);
    }

    div {
      p {
        margin: 0;
      }
    }
  }

  .karma-card {
    margin-bottom: 12px;
    width: 78px;
  }
`;
