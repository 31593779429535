import React from 'react';
import { Transactions } from '../../svgs/graphics/Transactions';
import { InfoModal } from '../InfoModal';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

export const TransactionsNotLoggedModal: React.FC<IProps> = (props) => (
  <InfoModal
    { ...props }
    graphic={ <Transactions /> }
    title='Logging Transactions'
    body='Your remaining transactions are associated with companies we haven’t been able to evaluate so we’ve excluded them from our analysis.'
  />
);
