import styled from 'styled-components';
import { Section } from '../../styles/components/containers';

export const TrackYourImpactContainer = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};

  .action-section {
    max-width: 650px;
    margin: 0 auto;

    h2 {
      font-size: 40px;
    }

    .subheader {
      font-size: 16px;
      max-width: 85%;
    }
  }
`;
