import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';

export const CashbackWidgetContainer = styled.div`
  ${FlexColCenter}
  min-height: 420px;
  position: relative;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  ${FlexHorizontalCenter}
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px 8px 0 0;
  padding: 16px;
  width: 100%;

  .left-content {
    ${FlexHorizontalCenter}
    gap: 8px;
  }

  .dollar-icon-container {
    ${FlexHorizontalCenter}
    border: solid 1px ${({ theme }) => theme.colors.white};
    border-radius: 68px;
    height: 30px;
    justify-content: center;
    width: 30px;
  }

  .karma-cash {
    color: ${({ theme }) => theme.colors.white};
    margin: unset;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.grey2};
  border-radius: 0 0 8px 8px;
  flex: 1;
  padding: 24px 32px 32px;
`;

export const ContentContainer = styled.div`
  ${FlexCol}
  gap: 32px;
  height: 100%;
  justify-content: space-between;

  .see-all-button {
    text-align: center;
    margin: 0 auto;
  }

  .cta-text {
    text-align: center;
  }

  .earn-cashback-text {
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
  }
`;

export const KarmaCashItemsContainer = styled.div`
  ${FlexCol}
  gap: 10px;
  height: 50%;
  justify-content: flex-start;

  .item-container {
    ${FlexHorizontalCenter}
    justify-content: space-between;
    min-height: 41px;
  }
  
  .balance-text {
    font-size: 18px;
  }

  .left-content {
    ${FlexCol}
    gap: 3px;
  }

  .see-details {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: unset;
  }

  .right-conent {
    ${FlexRow}
  }

  .right-text {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 600;
    line-height: 20px;
    margin: unset;
  }
`;

export const PayPalLinkSection = styled.div`
  ${FlexColCenter}
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  gap: 10px;

  .paypal-text {
    line-height: 13px;
    margin: 0;
    text-align: center;
  }
`;
