import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const BankCardExample: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}example-bank-card-graphic` }
    id={ id }
    height={ 125 } 
    width={ 198 }
  >
    <rect x='0.936523' y='0.413528' width='197' height='124' rx='6' fill='#DCE2E2' />
    <path d='M25.3705 82.9381C25.3705 83.6381 25.3005 84.3055 25.1605 84.9401C25.0205 85.5748 24.7965 86.1348 24.4885 86.6201C24.1805 87.0961 23.7792 87.4788 23.2845 87.7681C22.7992 88.0481 22.2065 88.1881 21.5065 88.1881C20.8065 88.1881 20.2092 88.0481 19.7145 87.7681C19.2198 87.4788 18.8138 87.0961 18.4965 86.6201C18.1885 86.1348 17.9645 85.5748 17.8245 84.9401C17.6845 84.3055 17.6145 83.6381 17.6145 82.9381C17.6145 82.2381 17.6845 81.5755 17.8245 80.9501C17.9645 80.3155 18.1885 79.7648 18.4965 79.2981C18.8138 78.8221 19.2198 78.4441 19.7145 78.1641C20.2092 77.8841 20.8065 77.7441 21.5065 77.7441C22.2065 77.7441 22.7992 77.8841 23.2845 78.1641C23.7792 78.4441 24.1805 78.8221 24.4885 79.2981C24.7965 79.7648 25.0205 80.3155 25.1605 80.9501C25.3005 81.5755 25.3705 82.2381 25.3705 82.9381ZM22.9625 82.9381C22.9625 82.6301 22.9438 82.2895 22.9065 81.9161C22.8692 81.5428 22.7992 81.1975 22.6965 80.8801C22.5938 80.5535 22.4445 80.2828 22.2485 80.0681C22.0618 79.8441 21.8145 79.7321 21.5065 79.7321C21.1985 79.7321 20.9465 79.8441 20.7505 80.0681C20.5545 80.2828 20.4005 80.5535 20.2885 80.8801C20.1858 81.1975 20.1158 81.5428 20.0785 81.9161C20.0412 82.2895 20.0225 82.6301 20.0225 82.9381C20.0225 83.2555 20.0412 83.6055 20.0785 83.9881C20.1158 84.3615 20.1858 84.7115 20.2885 85.0381C20.4005 85.3555 20.5545 85.6261 20.7505 85.8501C20.9465 86.0648 21.1985 86.1721 21.5065 86.1721C21.8145 86.1721 22.0618 86.0648 22.2485 85.8501C22.4445 85.6261 22.5938 85.3555 22.6965 85.0381C22.7992 84.7115 22.8692 84.3615 22.9065 83.9881C22.9438 83.6055 22.9625 83.2555 22.9625 82.9381Z' fill='#7F8C8E' />
    <path d='M34.4213 82.9381C34.4213 83.6381 34.3513 84.3055 34.2113 84.9401C34.0713 85.5748 33.8473 86.1348 33.5393 86.6201C33.2313 87.0961 32.8299 87.4788 32.3353 87.7681C31.8499 88.0481 31.2573 88.1881 30.5573 88.1881C29.8573 88.1881 29.26 88.0481 28.7653 87.7681C28.2706 87.4788 27.8646 87.0961 27.5473 86.6201C27.2393 86.1348 27.0153 85.5748 26.8753 84.9401C26.7353 84.3055 26.6653 83.6381 26.6653 82.9381C26.6653 82.2381 26.7353 81.5755 26.8753 80.9501C27.0153 80.3155 27.2393 79.7648 27.5473 79.2981C27.8646 78.8221 28.2706 78.4441 28.7653 78.1641C29.26 77.8841 29.8573 77.7441 30.5573 77.7441C31.2573 77.7441 31.8499 77.8841 32.3353 78.1641C32.8299 78.4441 33.2313 78.8221 33.5393 79.2981C33.8473 79.7648 34.0713 80.3155 34.2113 80.9501C34.3513 81.5755 34.4213 82.2381 34.4213 82.9381ZM32.0133 82.9381C32.0133 82.6301 31.9946 82.2895 31.9573 81.9161C31.92 81.5428 31.8499 81.1975 31.7473 80.8801C31.6446 80.5535 31.4953 80.2828 31.2993 80.0681C31.1126 79.8441 30.8653 79.7321 30.5573 79.7321C30.2493 79.7321 29.9973 79.8441 29.8013 80.0681C29.6053 80.2828 29.4513 80.5535 29.3393 80.8801C29.2366 81.1975 29.1666 81.5428 29.1293 81.9161C29.0919 82.2895 29.0733 82.6301 29.0733 82.9381C29.0733 83.2555 29.0919 83.6055 29.1293 83.9881C29.1666 84.3615 29.2366 84.7115 29.3393 85.0381C29.4513 85.3555 29.6053 85.6261 29.8013 85.8501C29.9973 86.0648 30.2493 86.1721 30.5573 86.1721C30.8653 86.1721 31.1126 86.0648 31.2993 85.8501C31.4953 85.6261 31.6446 85.3555 31.7473 85.0381C31.8499 84.7115 31.92 84.3615 31.9573 83.9881C31.9946 83.6055 32.0133 83.2555 32.0133 82.9381Z' fill='#7F8C8E' />
    <path d='M43.4721 82.9381C43.4721 83.6381 43.4021 84.3055 43.2621 84.9401C43.1221 85.5748 42.8981 86.1348 42.5901 86.6201C42.2821 87.0961 41.8807 87.4788 41.3861 87.7681C40.9007 88.0481 40.3081 88.1881 39.6081 88.1881C38.9081 88.1881 38.3107 88.0481 37.8161 87.7681C37.3214 87.4788 36.9154 87.0961 36.5981 86.6201C36.2901 86.1348 36.0661 85.5748 35.9261 84.9401C35.7861 84.3055 35.7161 83.6381 35.7161 82.9381C35.7161 82.2381 35.7861 81.5755 35.9261 80.9501C36.0661 80.3155 36.2901 79.7648 36.5981 79.2981C36.9154 78.8221 37.3214 78.4441 37.8161 78.1641C38.3107 77.8841 38.9081 77.7441 39.6081 77.7441C40.3081 77.7441 40.9007 77.8841 41.3861 78.1641C41.8807 78.4441 42.2821 78.8221 42.5901 79.2981C42.8981 79.7648 43.1221 80.3155 43.2621 80.9501C43.4021 81.5755 43.4721 82.2381 43.4721 82.9381ZM41.0641 82.9381C41.0641 82.6301 41.0454 82.2895 41.0081 81.9161C40.9707 81.5428 40.9007 81.1975 40.7981 80.8801C40.6954 80.5535 40.5461 80.2828 40.3501 80.0681C40.1634 79.8441 39.9161 79.7321 39.6081 79.7321C39.3001 79.7321 39.0481 79.8441 38.8521 80.0681C38.6561 80.2828 38.5021 80.5535 38.3901 80.8801C38.2874 81.1975 38.2174 81.5428 38.1801 81.9161C38.1427 82.2895 38.1241 82.6301 38.1241 82.9381C38.1241 83.2555 38.1427 83.6055 38.1801 83.9881C38.2174 84.3615 38.2874 84.7115 38.3901 85.0381C38.5021 85.3555 38.6561 85.6261 38.8521 85.8501C39.0481 86.0648 39.3001 86.1721 39.6081 86.1721C39.9161 86.1721 40.1634 86.0648 40.3501 85.8501C40.5461 85.6261 40.6954 85.3555 40.7981 85.0381C40.9007 84.7115 40.9707 84.3615 41.0081 83.9881C41.0454 83.6055 41.0641 83.2555 41.0641 82.9381Z' fill='#7F8C8E' />
    <path d='M52.5228 82.9381C52.5228 83.6381 52.4528 84.3055 52.3128 84.9401C52.1728 85.5748 51.9488 86.1348 51.6408 86.6201C51.3328 87.0961 50.9315 87.4788 50.4368 87.7681C49.9515 88.0481 49.3588 88.1881 48.6588 88.1881C47.9588 88.1881 47.3615 88.0481 46.8668 87.7681C46.3722 87.4788 45.9662 87.0961 45.6488 86.6201C45.3408 86.1348 45.1168 85.5748 44.9768 84.9401C44.8368 84.3055 44.7668 83.6381 44.7668 82.9381C44.7668 82.2381 44.8368 81.5755 44.9768 80.9501C45.1168 80.3155 45.3408 79.7648 45.6488 79.2981C45.9662 78.8221 46.3722 78.4441 46.8668 78.1641C47.3615 77.8841 47.9588 77.7441 48.6588 77.7441C49.3588 77.7441 49.9515 77.8841 50.4368 78.1641C50.9315 78.4441 51.3328 78.8221 51.6408 79.2981C51.9488 79.7648 52.1728 80.3155 52.3128 80.9501C52.4528 81.5755 52.5228 82.2381 52.5228 82.9381ZM50.1148 82.9381C50.1148 82.6301 50.0962 82.2895 50.0588 81.9161C50.0215 81.5428 49.9515 81.1975 49.8488 80.8801C49.7462 80.5535 49.5968 80.2828 49.4008 80.0681C49.2142 79.8441 48.9668 79.7321 48.6588 79.7321C48.3508 79.7321 48.0988 79.8441 47.9028 80.0681C47.7068 80.2828 47.5528 80.5535 47.4408 80.8801C47.3382 81.1975 47.2682 81.5428 47.2308 81.9161C47.1935 82.2895 47.1748 82.6301 47.1748 82.9381C47.1748 83.2555 47.1935 83.6055 47.2308 83.9881C47.2682 84.3615 47.3382 84.7115 47.4408 85.0381C47.5528 85.3555 47.7068 85.6261 47.9028 85.8501C48.0988 86.0648 48.3508 86.1721 48.6588 86.1721C48.9668 86.1721 49.2142 86.0648 49.4008 85.8501C49.5968 85.6261 49.7462 85.3555 49.8488 85.0381C49.9515 84.7115 50.0215 84.3615 50.0588 83.9881C50.0962 83.6055 50.1148 83.2555 50.1148 82.9381Z' fill='#7F8C8E' />
    <path d='M65.0736 82.9381C65.0736 83.6381 65.0036 84.3055 64.8636 84.9401C64.7236 85.5748 64.4996 86.1348 64.1916 86.6201C63.8836 87.0961 63.4823 87.4788 62.9876 87.7681C62.5023 88.0481 61.9096 88.1881 61.2096 88.1881C60.5096 88.1881 59.9123 88.0481 59.4176 87.7681C58.923 87.4788 58.517 87.0961 58.1996 86.6201C57.8916 86.1348 57.6676 85.5748 57.5276 84.9401C57.3876 84.3055 57.3176 83.6381 57.3176 82.9381C57.3176 82.2381 57.3876 81.5755 57.5276 80.9501C57.6676 80.3155 57.8916 79.7648 58.1996 79.2981C58.517 78.8221 58.923 78.4441 59.4176 78.1641C59.9123 77.8841 60.5096 77.7441 61.2096 77.7441C61.9096 77.7441 62.5023 77.8841 62.9876 78.1641C63.4823 78.4441 63.8836 78.8221 64.1916 79.2981C64.4996 79.7648 64.7236 80.3155 64.8636 80.9501C65.0036 81.5755 65.0736 82.2381 65.0736 82.9381ZM62.6656 82.9381C62.6656 82.6301 62.647 82.2895 62.6096 81.9161C62.5723 81.5428 62.5023 81.1975 62.3996 80.8801C62.297 80.5535 62.1476 80.2828 61.9516 80.0681C61.765 79.8441 61.5176 79.7321 61.2096 79.7321C60.9016 79.7321 60.6496 79.8441 60.4536 80.0681C60.2576 80.2828 60.1036 80.5535 59.9916 80.8801C59.889 81.1975 59.819 81.5428 59.7816 81.9161C59.7443 82.2895 59.7256 82.6301 59.7256 82.9381C59.7256 83.2555 59.7443 83.6055 59.7816 83.9881C59.819 84.3615 59.889 84.7115 59.9916 85.0381C60.1036 85.3555 60.2576 85.6261 60.4536 85.8501C60.6496 86.0648 60.9016 86.1721 61.2096 86.1721C61.5176 86.1721 61.765 86.0648 61.9516 85.8501C62.1476 85.6261 62.297 85.3555 62.3996 85.0381C62.5023 84.7115 62.5723 84.3615 62.6096 83.9881C62.647 83.6055 62.6656 83.2555 62.6656 82.9381Z' fill='#7F8C8E' />
    <path d='M74.1244 82.9381C74.1244 83.6381 74.0544 84.3055 73.9144 84.9401C73.7744 85.5748 73.5504 86.1348 73.2424 86.6201C72.9344 87.0961 72.5331 87.4788 72.0384 87.7681C71.5531 88.0481 70.9604 88.1881 70.2604 88.1881C69.5604 88.1881 68.9631 88.0481 68.4684 87.7681C67.9737 87.4788 67.5677 87.0961 67.2504 86.6201C66.9424 86.1348 66.7184 85.5748 66.5784 84.9401C66.4384 84.3055 66.3684 83.6381 66.3684 82.9381C66.3684 82.2381 66.4384 81.5755 66.5784 80.9501C66.7184 80.3155 66.9424 79.7648 67.2504 79.2981C67.5677 78.8221 67.9737 78.4441 68.4684 78.1641C68.9631 77.8841 69.5604 77.7441 70.2604 77.7441C70.9604 77.7441 71.5531 77.8841 72.0384 78.1641C72.5331 78.4441 72.9344 78.8221 73.2424 79.2981C73.5504 79.7648 73.7744 80.3155 73.9144 80.9501C74.0544 81.5755 74.1244 82.2381 74.1244 82.9381ZM71.7164 82.9381C71.7164 82.6301 71.6977 82.2895 71.6604 81.9161C71.6231 81.5428 71.5531 81.1975 71.4504 80.8801C71.3477 80.5535 71.1984 80.2828 71.0024 80.0681C70.8157 79.8441 70.5684 79.7321 70.2604 79.7321C69.9524 79.7321 69.7004 79.8441 69.5044 80.0681C69.3084 80.2828 69.1544 80.5535 69.0424 80.8801C68.9397 81.1975 68.8697 81.5428 68.8324 81.9161C68.7951 82.2895 68.7764 82.6301 68.7764 82.9381C68.7764 83.2555 68.7951 83.6055 68.8324 83.9881C68.8697 84.3615 68.9397 84.7115 69.0424 85.0381C69.1544 85.3555 69.3084 85.6261 69.5044 85.8501C69.7004 86.0648 69.9524 86.1721 70.2604 86.1721C70.5684 86.1721 70.8157 86.0648 71.0024 85.8501C71.1984 85.6261 71.3477 85.3555 71.4504 85.0381C71.5531 84.7115 71.6231 84.3615 71.6604 83.9881C71.6977 83.6055 71.7164 83.2555 71.7164 82.9381Z' fill='#7F8C8E' />
    <path d='M83.1752 82.9381C83.1752 83.6381 83.1052 84.3055 82.9652 84.9401C82.8252 85.5748 82.6012 86.1348 82.2932 86.6201C81.9852 87.0961 81.5839 87.4788 81.0892 87.7681C80.6039 88.0481 80.0112 88.1881 79.3112 88.1881C78.6112 88.1881 78.0139 88.0481 77.5192 87.7681C77.0245 87.4788 76.6185 87.0961 76.3012 86.6201C75.9932 86.1348 75.7692 85.5748 75.6292 84.9401C75.4892 84.3055 75.4192 83.6381 75.4192 82.9381C75.4192 82.2381 75.4892 81.5755 75.6292 80.9501C75.7692 80.3155 75.9932 79.7648 76.3012 79.2981C76.6185 78.8221 77.0245 78.4441 77.5192 78.1641C78.0139 77.8841 78.6112 77.7441 79.3112 77.7441C80.0112 77.7441 80.6039 77.8841 81.0892 78.1641C81.5839 78.4441 81.9852 78.8221 82.2932 79.2981C82.6012 79.7648 82.8252 80.3155 82.9652 80.9501C83.1052 81.5755 83.1752 82.2381 83.1752 82.9381ZM80.7672 82.9381C80.7672 82.6301 80.7485 82.2895 80.7112 81.9161C80.6739 81.5428 80.6039 81.1975 80.5012 80.8801C80.3985 80.5535 80.2492 80.2828 80.0532 80.0681C79.8665 79.8441 79.6192 79.7321 79.3112 79.7321C79.0032 79.7321 78.7512 79.8441 78.5552 80.0681C78.3592 80.2828 78.2052 80.5535 78.0932 80.8801C77.9905 81.1975 77.9205 81.5428 77.8832 81.9161C77.8459 82.2895 77.8272 82.6301 77.8272 82.9381C77.8272 83.2555 77.8459 83.6055 77.8832 83.9881C77.9205 84.3615 77.9905 84.7115 78.0932 85.0381C78.2052 85.3555 78.3592 85.6261 78.5552 85.8501C78.7512 86.0648 79.0032 86.1721 79.3112 86.1721C79.6192 86.1721 79.8665 86.0648 80.0532 85.8501C80.2492 85.6261 80.3985 85.3555 80.5012 85.0381C80.6039 84.7115 80.6739 84.3615 80.7112 83.9881C80.7485 83.6055 80.7672 83.2555 80.7672 82.9381Z' fill='#ADADAD' />
    <path d='M92.226 82.9381C92.226 83.6381 92.156 84.3055 92.016 84.9401C91.876 85.5748 91.652 86.1348 91.344 86.6201C91.036 87.0961 90.6346 87.4788 90.14 87.7681C89.6546 88.0481 89.062 88.1881 88.362 88.1881C87.662 88.1881 87.0646 88.0481 86.57 87.7681C86.0753 87.4788 85.6693 87.0961 85.352 86.6201C85.044 86.1348 84.82 85.5748 84.68 84.9401C84.54 84.3055 84.47 83.6381 84.47 82.9381C84.47 82.2381 84.54 81.5755 84.68 80.9501C84.82 80.3155 85.044 79.7648 85.352 79.2981C85.6693 78.8221 86.0753 78.4441 86.57 78.1641C87.0646 77.8841 87.662 77.7441 88.362 77.7441C89.062 77.7441 89.6546 77.8841 90.14 78.1641C90.6346 78.4441 91.036 78.8221 91.344 79.2981C91.652 79.7648 91.876 80.3155 92.016 80.9501C92.156 81.5755 92.226 82.2381 92.226 82.9381ZM89.818 82.9381C89.818 82.6301 89.7993 82.2895 89.762 81.9161C89.7246 81.5428 89.6546 81.1975 89.552 80.8801C89.4493 80.5535 89.3 80.2828 89.104 80.0681C88.9173 79.8441 88.67 79.7321 88.362 79.7321C88.054 79.7321 87.802 79.8441 87.606 80.0681C87.41 80.2828 87.256 80.5535 87.144 80.8801C87.0413 81.1975 86.9713 81.5428 86.934 81.9161C86.8966 82.2895 86.878 82.6301 86.878 82.9381C86.878 83.2555 86.8966 83.6055 86.934 83.9881C86.9713 84.3615 87.0413 84.7115 87.144 85.0381C87.256 85.3555 87.41 85.6261 87.606 85.8501C87.802 86.0648 88.054 86.1721 88.362 86.1721C88.67 86.1721 88.9173 86.0648 89.104 85.8501C89.3 85.6261 89.4493 85.3555 89.552 85.0381C89.6546 84.7115 89.7246 84.3615 89.762 83.9881C89.7993 83.6055 89.818 83.2555 89.818 82.9381Z' fill='#ADADAD' />
    <path d='M104.777 82.9381C104.777 83.6381 104.707 84.3055 104.567 84.9401C104.427 85.5748 104.203 86.1348 103.895 86.6201C103.587 87.0961 103.185 87.4788 102.691 87.7681C102.205 88.0481 101.613 88.1881 100.913 88.1881C100.213 88.1881 99.6154 88.0481 99.1208 87.7681C98.6261 87.4788 98.2201 87.0961 97.9028 86.6201C97.5948 86.1348 97.3708 85.5748 97.2308 84.9401C97.0908 84.3055 97.0208 83.6381 97.0208 82.9381C97.0208 82.2381 97.0908 81.5755 97.2308 80.9501C97.3708 80.3155 97.5948 79.7648 97.9028 79.2981C98.2201 78.8221 98.6261 78.4441 99.1208 78.1641C99.6154 77.8841 100.213 77.7441 100.913 77.7441C101.613 77.7441 102.205 77.8841 102.691 78.1641C103.185 78.4441 103.587 78.8221 103.895 79.2981C104.203 79.7648 104.427 80.3155 104.567 80.9501C104.707 81.5755 104.777 82.2381 104.777 82.9381ZM102.369 82.9381C102.369 82.6301 102.35 82.2895 102.313 81.9161C102.275 81.5428 102.205 81.1975 102.103 80.8801C102 80.5535 101.851 80.2828 101.655 80.0681C101.468 79.8441 101.221 79.7321 100.913 79.7321C100.605 79.7321 100.353 79.8441 100.157 80.0681C99.9608 80.2828 99.8068 80.5535 99.6948 80.8801C99.5921 81.1975 99.5221 81.5428 99.4848 81.9161C99.4474 82.2895 99.4288 82.6301 99.4288 82.9381C99.4288 83.2555 99.4474 83.6055 99.4848 83.9881C99.5221 84.3615 99.5921 84.7115 99.6948 85.0381C99.8068 85.3555 99.9608 85.6261 100.157 85.8501C100.353 86.0648 100.605 86.1721 100.913 86.1721C101.221 86.1721 101.468 86.0648 101.655 85.8501C101.851 85.6261 102 85.3555 102.103 85.0381C102.205 84.7115 102.275 84.3615 102.313 83.9881C102.35 83.6055 102.369 83.2555 102.369 82.9381Z' fill='#ADADAD' />
    <path d='M113.828 82.9381C113.828 83.6381 113.758 84.3055 113.618 84.9401C113.478 85.5748 113.254 86.1348 112.946 86.6201C112.638 87.0961 112.236 87.4788 111.742 87.7681C111.256 88.0481 110.664 88.1881 109.964 88.1881C109.264 88.1881 108.666 88.0481 108.172 87.7681C107.677 87.4788 107.271 87.0961 106.954 86.6201C106.646 86.1348 106.422 85.5748 106.282 84.9401C106.142 84.3055 106.072 83.6381 106.072 82.9381C106.072 82.2381 106.142 81.5755 106.282 80.9501C106.422 80.3155 106.646 79.7648 106.954 79.2981C107.271 78.8221 107.677 78.4441 108.172 78.1641C108.666 77.8841 109.264 77.7441 109.964 77.7441C110.664 77.7441 111.256 77.8841 111.742 78.1641C112.236 78.4441 112.638 78.8221 112.946 79.2981C113.254 79.7648 113.478 80.3155 113.618 80.9501C113.758 81.5755 113.828 82.2381 113.828 82.9381ZM111.42 82.9381C111.42 82.6301 111.401 82.2895 111.364 81.9161C111.326 81.5428 111.256 81.1975 111.154 80.8801C111.051 80.5535 110.902 80.2828 110.706 80.0681C110.519 79.8441 110.272 79.7321 109.964 79.7321C109.656 79.7321 109.404 79.8441 109.208 80.0681C109.012 80.2828 108.858 80.5535 108.746 80.8801C108.643 81.1975 108.573 81.5428 108.536 81.9161C108.498 82.2895 108.48 82.6301 108.48 82.9381C108.48 83.2555 108.498 83.6055 108.536 83.9881C108.573 84.3615 108.643 84.7115 108.746 85.0381C108.858 85.3555 109.012 85.6261 109.208 85.8501C109.404 86.0648 109.656 86.1721 109.964 86.1721C110.272 86.1721 110.519 86.0648 110.706 85.8501C110.902 85.6261 111.051 85.3555 111.154 85.0381C111.256 84.7115 111.326 84.3615 111.364 83.9881C111.401 83.6055 111.42 83.2555 111.42 82.9381Z' fill='#ADADAD' />
    <path d='M122.878 82.9381C122.878 83.6381 122.808 84.3055 122.668 84.9401C122.528 85.5748 122.304 86.1348 121.996 86.6201C121.688 87.0961 121.287 87.4788 120.792 87.7681C120.307 88.0481 119.714 88.1881 119.014 88.1881C118.314 88.1881 117.717 88.0481 117.222 87.7681C116.728 87.4788 116.322 87.0961 116.004 86.6201C115.696 86.1348 115.472 85.5748 115.332 84.9401C115.192 84.3055 115.122 83.6381 115.122 82.9381C115.122 82.2381 115.192 81.5755 115.332 80.9501C115.472 80.3155 115.696 79.7648 116.004 79.2981C116.322 78.8221 116.728 78.4441 117.222 78.1641C117.717 77.8841 118.314 77.7441 119.014 77.7441C119.714 77.7441 120.307 77.8841 120.792 78.1641C121.287 78.4441 121.688 78.8221 121.996 79.2981C122.304 79.7648 122.528 80.3155 122.668 80.9501C122.808 81.5755 122.878 82.2381 122.878 82.9381ZM120.47 82.9381C120.47 82.6301 120.452 82.2895 120.414 81.9161C120.377 81.5428 120.307 81.1975 120.204 80.8801C120.102 80.5535 119.952 80.2828 119.756 80.0681C119.57 79.8441 119.322 79.7321 119.014 79.7321C118.706 79.7321 118.454 79.8441 118.258 80.0681C118.062 80.2828 117.908 80.5535 117.796 80.8801C117.694 81.1975 117.624 81.5428 117.586 81.9161C117.549 82.2895 117.53 82.6301 117.53 82.9381C117.53 83.2555 117.549 83.6055 117.586 83.9881C117.624 84.3615 117.694 84.7115 117.796 85.0381C117.908 85.3555 118.062 85.6261 118.258 85.8501C118.454 86.0648 118.706 86.1721 119.014 86.1721C119.322 86.1721 119.57 86.0648 119.756 85.8501C119.952 85.6261 120.102 85.3555 120.204 85.0381C120.307 84.7115 120.377 84.3615 120.414 83.9881C120.452 83.6055 120.47 83.2555 120.47 82.9381Z' fill='#ADADAD' />
    <path d='M131.929 82.9381C131.929 83.6381 131.859 84.3055 131.719 84.9401C131.579 85.5748 131.355 86.1348 131.047 86.6201C130.739 87.0961 130.338 87.4788 129.843 87.7681C129.358 88.0481 128.765 88.1881 128.065 88.1881C127.365 88.1881 126.768 88.0481 126.273 87.7681C125.778 87.4788 125.372 87.0961 125.055 86.6201C124.747 86.1348 124.523 85.5748 124.383 84.9401C124.243 84.3055 124.173 83.6381 124.173 82.9381C124.173 82.2381 124.243 81.5755 124.383 80.9501C124.523 80.3155 124.747 79.7648 125.055 79.2981C125.372 78.8221 125.778 78.4441 126.273 78.1641C126.768 77.8841 127.365 77.7441 128.065 77.7441C128.765 77.7441 129.358 77.8841 129.843 78.1641C130.338 78.4441 130.739 78.8221 131.047 79.2981C131.355 79.7648 131.579 80.3155 131.719 80.9501C131.859 81.5755 131.929 82.2381 131.929 82.9381ZM129.521 82.9381C129.521 82.6301 129.502 82.2895 129.465 81.9161C129.428 81.5428 129.358 81.1975 129.255 80.8801C129.152 80.5535 129.003 80.2828 128.807 80.0681C128.62 79.8441 128.373 79.7321 128.065 79.7321C127.757 79.7321 127.505 79.8441 127.309 80.0681C127.113 80.2828 126.959 80.5535 126.847 80.8801C126.744 81.1975 126.674 81.5428 126.637 81.9161C126.6 82.2895 126.581 82.6301 126.581 82.9381C126.581 83.2555 126.6 83.6055 126.637 83.9881C126.674 84.3615 126.744 84.7115 126.847 85.0381C126.959 85.3555 127.113 85.6261 127.309 85.8501C127.505 86.0648 127.757 86.1721 128.065 86.1721C128.373 86.1721 128.62 86.0648 128.807 85.8501C129.003 85.6261 129.152 85.3555 129.255 85.0381C129.358 84.7115 129.428 84.3615 129.465 83.9881C129.502 83.6055 129.521 83.2555 129.521 82.9381Z' fill='#ADADAD' />
    <path d='M144.48 82.9381C144.48 83.6381 144.41 84.3055 144.27 84.9401C144.13 85.5748 143.906 86.1348 143.598 86.6201C143.29 87.0961 142.889 87.4788 142.394 87.7681C141.909 88.0481 141.316 88.1881 140.616 88.1881C139.916 88.1881 139.319 88.0481 138.824 87.7681C138.329 87.4788 137.923 87.0961 137.606 86.6201C137.298 86.1348 137.074 85.5748 136.934 84.9401C136.794 84.3055 136.724 83.6381 136.724 82.9381C136.724 82.2381 136.794 81.5755 136.934 80.9501C137.074 80.3155 137.298 79.7648 137.606 79.2981C137.923 78.8221 138.329 78.4441 138.824 78.1641C139.319 77.8841 139.916 77.7441 140.616 77.7441C141.316 77.7441 141.909 77.8841 142.394 78.1641C142.889 78.4441 143.29 78.8221 143.598 79.2981C143.906 79.7648 144.13 80.3155 144.27 80.9501C144.41 81.5755 144.48 82.2381 144.48 82.9381ZM142.072 82.9381C142.072 82.6301 142.053 82.2895 142.016 81.9161C141.979 81.5428 141.909 81.1975 141.806 80.8801C141.703 80.5535 141.554 80.2828 141.358 80.0681C141.171 79.8441 140.924 79.7321 140.616 79.7321C140.308 79.7321 140.056 79.8441 139.86 80.0681C139.664 80.2828 139.51 80.5535 139.398 80.8801C139.295 81.1975 139.225 81.5428 139.188 81.9161C139.151 82.2895 139.132 82.6301 139.132 82.9381C139.132 83.2555 139.151 83.6055 139.188 83.9881C139.225 84.3615 139.295 84.7115 139.398 85.0381C139.51 85.3555 139.664 85.6261 139.86 85.8501C140.056 86.0648 140.308 86.1721 140.616 86.1721C140.924 86.1721 141.171 86.0648 141.358 85.8501C141.554 85.6261 141.703 85.3555 141.806 85.0381C141.909 84.7115 141.979 84.3615 142.016 83.9881C142.053 83.6055 142.072 83.2555 142.072 82.9381Z' fill='#7F8C8E' />
    <path d='M153.531 82.9381C153.531 83.6381 153.461 84.3055 153.321 84.9401C153.181 85.5748 152.957 86.1348 152.649 86.6201C152.341 87.0961 151.939 87.4788 151.445 87.7681C150.959 88.0481 150.367 88.1881 149.667 88.1881C148.967 88.1881 148.369 88.0481 147.875 87.7681C147.38 87.4788 146.974 87.0961 146.657 86.6201C146.349 86.1348 146.125 85.5748 145.985 84.9401C145.845 84.3055 145.775 83.6381 145.775 82.9381C145.775 82.2381 145.845 81.5755 145.985 80.9501C146.125 80.3155 146.349 79.7648 146.657 79.2981C146.974 78.8221 147.38 78.4441 147.875 78.1641C148.369 77.8841 148.967 77.7441 149.667 77.7441C150.367 77.7441 150.959 77.8841 151.445 78.1641C151.939 78.4441 152.341 78.8221 152.649 79.2981C152.957 79.7648 153.181 80.3155 153.321 80.9501C153.461 81.5755 153.531 82.2381 153.531 82.9381ZM151.123 82.9381C151.123 82.6301 151.104 82.2895 151.067 81.9161C151.029 81.5428 150.959 81.1975 150.857 80.8801C150.754 80.5535 150.605 80.2828 150.409 80.0681C150.222 79.8441 149.975 79.7321 149.667 79.7321C149.359 79.7321 149.107 79.8441 148.911 80.0681C148.715 80.2828 148.561 80.5535 148.449 80.8801C148.346 81.1975 148.276 81.5428 148.239 81.9161C148.201 82.2895 148.183 82.6301 148.183 82.9381C148.183 83.2555 148.201 83.6055 148.239 83.9881C148.276 84.3615 148.346 84.7115 148.449 85.0381C148.561 85.3555 148.715 85.6261 148.911 85.8501C149.107 86.0648 149.359 86.1721 149.667 86.1721C149.975 86.1721 150.222 86.0648 150.409 85.8501C150.605 85.6261 150.754 85.3555 150.857 85.0381C150.959 84.7115 151.029 84.3615 151.067 83.9881C151.104 83.6055 151.123 83.2555 151.123 82.9381Z' fill='#7F8C8E' />
    <path d='M162.581 82.9381C162.581 83.6381 162.511 84.3055 162.371 84.9401C162.231 85.5748 162.007 86.1348 161.699 86.6201C161.391 87.0961 160.99 87.4788 160.495 87.7681C160.01 88.0481 159.417 88.1881 158.717 88.1881C158.017 88.1881 157.42 88.0481 156.925 87.7681C156.431 87.4788 156.025 87.0961 155.707 86.6201C155.399 86.1348 155.175 85.5748 155.035 84.9401C154.895 84.3055 154.825 83.6381 154.825 82.9381C154.825 82.2381 154.895 81.5755 155.035 80.9501C155.175 80.3155 155.399 79.7648 155.707 79.2981C156.025 78.8221 156.431 78.4441 156.925 78.1641C157.42 77.8841 158.017 77.7441 158.717 77.7441C159.417 77.7441 160.01 77.8841 160.495 78.1641C160.99 78.4441 161.391 78.8221 161.699 79.2981C162.007 79.7648 162.231 80.3155 162.371 80.9501C162.511 81.5755 162.581 82.2381 162.581 82.9381ZM160.173 82.9381C160.173 82.6301 160.155 82.2895 160.117 81.9161C160.08 81.5428 160.01 81.1975 159.907 80.8801C159.805 80.5535 159.655 80.2828 159.459 80.0681C159.273 79.8441 159.025 79.7321 158.717 79.7321C158.409 79.7321 158.157 79.8441 157.961 80.0681C157.765 80.2828 157.611 80.5535 157.499 80.8801C157.397 81.1975 157.327 81.5428 157.289 81.9161C157.252 82.2895 157.233 82.6301 157.233 82.9381C157.233 83.2555 157.252 83.6055 157.289 83.9881C157.327 84.3615 157.397 84.7115 157.499 85.0381C157.611 85.3555 157.765 85.6261 157.961 85.8501C158.157 86.0648 158.409 86.1721 158.717 86.1721C159.025 86.1721 159.273 86.0648 159.459 85.8501C159.655 85.6261 159.805 85.3555 159.907 85.0381C160.01 84.7115 160.08 84.3615 160.117 83.9881C160.155 83.6055 160.173 83.2555 160.173 82.9381Z' fill='#7F8C8E' />
    <path d='M171.632 82.9381C171.632 83.6381 171.562 84.3055 171.422 84.9401C171.282 85.5748 171.058 86.1348 170.75 86.6201C170.442 87.0961 170.041 87.4788 169.546 87.7681C169.061 88.0481 168.468 88.1881 167.768 88.1881C167.068 88.1881 166.471 88.0481 165.976 87.7681C165.482 87.4788 165.076 87.0961 164.758 86.6201C164.45 86.1348 164.226 85.5748 164.086 84.9401C163.946 84.3055 163.876 83.6381 163.876 82.9381C163.876 82.2381 163.946 81.5755 164.086 80.9501C164.226 80.3155 164.45 79.7648 164.758 79.2981C165.076 78.8221 165.482 78.4441 165.976 78.1641C166.471 77.8841 167.068 77.7441 167.768 77.7441C168.468 77.7441 169.061 77.8841 169.546 78.1641C170.041 78.4441 170.442 78.8221 170.75 79.2981C171.058 79.7648 171.282 80.3155 171.422 80.9501C171.562 81.5755 171.632 82.2381 171.632 82.9381ZM169.224 82.9381C169.224 82.6301 169.206 82.2895 169.168 81.9161C169.131 81.5428 169.061 81.1975 168.958 80.8801C168.856 80.5535 168.706 80.2828 168.51 80.0681C168.324 79.8441 168.076 79.7321 167.768 79.7321C167.46 79.7321 167.208 79.8441 167.012 80.0681C166.816 80.2828 166.662 80.5535 166.55 80.8801C166.448 81.1975 166.378 81.5428 166.34 81.9161C166.303 82.2895 166.284 82.6301 166.284 82.9381C166.284 83.2555 166.303 83.6055 166.34 83.9881C166.378 84.3615 166.448 84.7115 166.55 85.0381C166.662 85.3555 166.816 85.6261 167.012 85.8501C167.208 86.0648 167.46 86.1721 167.768 86.1721C168.076 86.1721 168.324 86.0648 168.51 85.8501C168.706 85.6261 168.856 85.3555 168.958 85.0381C169.061 84.7115 169.131 84.3615 169.168 83.9881C169.206 83.6055 169.224 83.2555 169.224 82.9381Z' fill='#7F8C8E' />
    <path fillRule='evenodd' clipRule='evenodd' d='M21.4707 91.9221H20.4707V98.9221H44.4707V103.922H155.971V98.9221H169.471V91.9221H168.471V97.9221H142.471V91.9221H141.471V98.9221H154.971V102.922H45.4707V98.9221H72.4707V91.9221H71.4707V97.9221H21.4707V91.9221Z' fill='black' />
    <path d='M19.5261 25.74H24.7061V26.972H18.1401V17.06H24.4961V18.278H19.5261V21.246H24.2021V22.436H19.5261V25.74Z' fill='#7F8C8E' />
    <path d='M28.1023 23.486L25.6803 20.336H27.3043L28.9283 22.618L30.5663 20.336H32.1063L29.7123 23.472L32.3863 26.972H30.7483L28.9003 24.382L27.0243 26.972H25.4423L28.1023 23.486Z' fill='#7F8C8E' />
    <path d='M37.7587 22.674C37.7587 22.1793 37.6094 21.8153 37.3107 21.582C37.0121 21.3393 36.6154 21.218 36.1207 21.218C35.7474 21.218 35.3927 21.2927 35.0567 21.442C34.7301 21.582 34.4547 21.764 34.2307 21.988L33.5307 21.148C33.8574 20.84 34.2541 20.5973 34.7207 20.42C35.1967 20.2427 35.7054 20.154 36.2467 20.154C36.7227 20.154 37.1334 20.224 37.4787 20.364C37.8334 20.4947 38.1227 20.6767 38.3467 20.91C38.5707 21.1433 38.7387 21.4187 38.8507 21.736C38.9627 22.0533 39.0187 22.394 39.0187 22.758V25.656C39.0187 25.88 39.0234 26.118 39.0327 26.37C39.0514 26.6127 39.0794 26.8133 39.1167 26.972H37.9267C37.8521 26.6547 37.8147 26.3373 37.8147 26.02H37.7727C37.5301 26.3747 37.2221 26.65 36.8487 26.846C36.4847 27.042 36.0507 27.14 35.5467 27.14C35.2854 27.14 35.0101 27.1027 34.7207 27.028C34.4407 26.9627 34.1841 26.8507 33.9507 26.692C33.7174 26.5333 33.5214 26.3233 33.3627 26.062C33.2134 25.8007 33.1387 25.4787 33.1387 25.096C33.1387 24.592 33.2741 24.1953 33.5447 23.906C33.8154 23.6073 34.1701 23.3833 34.6087 23.234C35.0474 23.0753 35.5421 22.9727 36.0927 22.926C36.6434 22.8793 37.1987 22.856 37.7587 22.856V22.674ZM37.4367 23.808C37.1101 23.808 36.7694 23.822 36.4147 23.85C36.0694 23.878 35.7521 23.934 35.4627 24.018C35.1827 24.102 34.9494 24.228 34.7627 24.396C34.5761 24.564 34.4827 24.788 34.4827 25.068C34.4827 25.264 34.5201 25.4273 34.5947 25.558C34.6787 25.6887 34.7861 25.796 34.9167 25.88C35.0474 25.964 35.1921 26.0247 35.3507 26.062C35.5094 26.09 35.6727 26.104 35.8407 26.104C36.4567 26.104 36.9281 25.922 37.2547 25.558C37.5907 25.1847 37.7587 24.718 37.7587 24.158V23.808H37.4367Z' fill='#7F8C8E' />
    <path d='M42.5046 21.428C42.6726 21.0733 42.9386 20.7747 43.3026 20.532C43.676 20.28 44.1146 20.154 44.6186 20.154C45.0666 20.154 45.468 20.2567 45.8226 20.462C46.1866 20.6673 46.4713 21.008 46.6766 21.484C46.9006 21.0547 47.2133 20.728 47.6146 20.504C48.016 20.2707 48.4546 20.154 48.9306 20.154C49.36 20.154 49.7286 20.2287 50.0366 20.378C50.3446 20.518 50.6013 20.7093 50.8066 20.952C51.012 21.1947 51.1613 21.4793 51.2546 21.806C51.3573 22.1327 51.4086 22.4827 51.4086 22.856V26.972H50.0926V23.29C50.0926 23.0193 50.0693 22.7627 50.0226 22.52C49.976 22.2773 49.8966 22.0627 49.7846 21.876C49.6726 21.6893 49.5233 21.54 49.3366 21.428C49.15 21.316 48.912 21.26 48.6226 21.26C48.324 21.26 48.0673 21.3253 47.8526 21.456C47.638 21.5773 47.4606 21.7407 47.3206 21.946C47.19 22.142 47.092 22.366 47.0266 22.618C46.9706 22.87 46.9426 23.1267 46.9426 23.388V26.972H45.6266V23.024C45.6266 22.7627 45.6033 22.5247 45.5566 22.31C45.51 22.0953 45.4306 21.9133 45.3186 21.764C45.2066 21.6053 45.062 21.484 44.8846 21.4C44.7073 21.3067 44.488 21.26 44.2266 21.26C43.7413 21.26 43.3353 21.4607 43.0086 21.862C42.6913 22.254 42.5326 22.7813 42.5326 23.444V26.972H41.2166V21.764C41.2166 21.5867 41.212 21.358 41.2026 21.078C41.1933 20.798 41.1793 20.5507 41.1606 20.336H42.4066C42.4253 20.504 42.4393 20.6953 42.4486 20.91C42.458 21.1153 42.4626 21.288 42.4626 21.428H42.5046Z' fill='#7F8C8E' />
    <path d='M54.9467 30.332H53.6307V20.336H54.9187V21.372H54.9607C55.1753 21.0173 55.488 20.728 55.8987 20.504C56.3187 20.2707 56.804 20.154 57.3547 20.154C57.8493 20.154 58.2974 20.2427 58.6987 20.42C59.1094 20.5973 59.4594 20.8447 59.7487 21.162C60.0474 21.4793 60.276 21.8527 60.4347 22.282C60.5934 22.702 60.6727 23.1547 60.6727 23.64C60.6727 24.1253 60.5934 24.5827 60.4347 25.012C60.276 25.4413 60.052 25.8147 59.7627 26.132C59.4734 26.4493 59.1234 26.7013 58.7127 26.888C58.302 27.0653 57.84 27.154 57.3267 27.154C56.8507 27.154 56.4027 27.0513 55.9827 26.846C55.5627 26.6407 55.2313 26.3513 54.9887 25.978H54.9467V30.332ZM59.3147 23.64C59.3147 23.332 59.268 23.0333 59.1747 22.744C59.0813 22.4547 58.9414 22.198 58.7547 21.974C58.568 21.75 58.3347 21.5727 58.0547 21.442C57.784 21.3113 57.4667 21.246 57.1027 21.246C56.7667 21.246 56.4587 21.3113 56.1787 21.442C55.908 21.5727 55.6747 21.75 55.4787 21.974C55.2827 22.198 55.1287 22.4547 55.0167 22.744C54.914 23.0333 54.8627 23.3367 54.8627 23.654C54.8627 23.9713 54.914 24.2747 55.0167 24.564C55.1287 24.8533 55.2827 25.11 55.4787 25.334C55.6747 25.5487 55.908 25.7213 56.1787 25.852C56.4587 25.9827 56.7667 26.048 57.1027 26.048C57.4667 26.048 57.784 25.9827 58.0547 25.852C58.3347 25.712 58.568 25.53 58.7547 25.306C58.9414 25.082 59.0813 24.8253 59.1747 24.536C59.268 24.2467 59.3147 23.948 59.3147 23.64Z' fill='#7F8C8E' />
    <path d='M63.8477 26.972H62.5317V16.388H63.8477V26.972Z' fill='#7F8C8E' />
    <path d='M70.9985 23.066C70.9891 22.8047 70.9424 22.562 70.8584 22.338C70.7838 22.1047 70.6671 21.904 70.5084 21.736C70.3591 21.568 70.1678 21.4373 69.9344 21.344C69.7104 21.2413 69.4444 21.19 69.1364 21.19C68.8564 21.19 68.5904 21.2413 68.3384 21.344C68.0958 21.4373 67.8811 21.568 67.6945 21.736C67.5171 21.904 67.3678 22.1047 67.2464 22.338C67.1344 22.562 67.0691 22.8047 67.0504 23.066H70.9985ZM72.3284 23.598C72.3284 23.6727 72.3284 23.7473 72.3284 23.822C72.3284 23.8967 72.3238 23.9713 72.3144 24.046H67.0504C67.0598 24.326 67.1204 24.592 67.2324 24.844C67.3538 25.0867 67.5125 25.3013 67.7085 25.488C67.9045 25.6653 68.1285 25.8053 68.3805 25.908C68.6418 26.0107 68.9171 26.062 69.2064 26.062C69.6544 26.062 70.0418 25.964 70.3685 25.768C70.6951 25.572 70.9518 25.334 71.1385 25.054L72.0625 25.796C71.7078 26.2627 71.2878 26.608 70.8024 26.832C70.3264 27.0467 69.7944 27.154 69.2064 27.154C68.7024 27.154 68.2358 27.07 67.8064 26.902C67.3771 26.734 67.0085 26.5007 66.7005 26.202C66.3925 25.894 66.1498 25.5253 65.9725 25.096C65.7951 24.6667 65.7064 24.1907 65.7064 23.668C65.7064 23.1547 65.7905 22.6833 65.9585 22.254C66.1358 21.8153 66.3785 21.442 66.6865 21.134C66.9945 20.826 67.3584 20.588 67.7785 20.42C68.1985 20.2427 68.6511 20.154 69.1364 20.154C69.6218 20.154 70.0604 20.2333 70.4524 20.392C70.8538 20.5507 71.1898 20.7793 71.4604 21.078C71.7404 21.3767 71.9551 21.7407 72.1045 22.17C72.2538 22.59 72.3284 23.066 72.3284 23.598Z' fill='#7F8C8E' />
  </SvgIcon>
);
