import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CommissionsList } from '../../../components/CommissionsList';
import { ErrorComponent } from '../../../components/ErrorComponent';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { PayoutModel } from '../../../models/commisions';
import { H2 } from '../../../styles/components/header';
import { CashbackPayoutInnerWrapper, CashbackPayoutSummaryContainer, HeaderWrapper, PayoutContentContainer } from './styles';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { ChevronDirection, ChevronIcon } from '../../../components/svgs/icons/ChevronIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

const CashbackPayoutSummaryBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const payoutModel = useRef(new PayoutModel()).current;
  const { payoutId } = useParams();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!payoutId) navigate('/account');

    payoutModel.loadPayout(payoutId);
  }, [payoutId]);

  const onBackToCashbackClick = () => {
    analytics.fireEvent('CashbackPayoutsPage_BackToCashback_Click');
  };

  const commissionsSection = useMemo(() => {
    if (payoutModel.busy) return <LoadingSpinner />;
    if (payoutModel.errorLoading) return <ErrorComponent text='Whoops there was an error loading this payout summary' />;

    return (
      <PayoutContentContainer>
        <H2>{ dayjs(payoutModel.date).format('MMMM YYYY') } Payout</H2>
        <CommissionsList
          commissions={ payoutModel.commissions }
          total={ payoutModel.total }
        />
      </PayoutContentContainer>
    );
  }, [payoutModel.errorLoading, payoutModel.busy, payoutModel.commissions, payoutModel.total]);

  return (
    <CashbackPayoutSummaryContainer className={ className }>
      <CashbackPayoutInnerWrapper>
        <HeaderWrapper>
          <ButtonLink
            className='back-to-cashback-button'
            kind={ ButtonKind.Blank }
            href='/account/cashback'
            label='Back to Cashback'
            onClick={ onBackToCashbackClick }
          >
            <ChevronIcon direction={ ChevronDirection.Left } stroke={ theme.colors.dark } />
          </ButtonLink>
        </HeaderWrapper>
        { commissionsSection }
      </CashbackPayoutInnerWrapper>
    </CashbackPayoutSummaryContainer>
  );
};

const CashbackPayoutSummaryObserver = observer(CashbackPayoutSummaryBase);
export const CashbackPayoutSummary = withTheme(CashbackPayoutSummaryObserver);
