import styled from 'styled-components';
import { FlexRow } from '../../styles/styles';
import { theme } from '../../styles/themes';
import { MaxBoundarySectionLarge } from '../../styles/components/containers';

export const NotReadySectionContainer = styled(MaxBoundarySectionLarge)`
  text-align: center;
  
  .not-ready-header {
    margin-bottom: 16px;
  }

  .karma-score-text {
    margin-bottom: 30px;
  }
`;

export const ButtonsContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  .join-button {
    ${FlexRow}
    font-weight: 600;
    min-height: 48px;
    min-width: 220px;
  }

  .karma-score {
    &:hover {
      background: ${theme.colors.darkYellow1};
      border: 1px solid ${theme.colors.darkYellow1};
    }
  }
`;
