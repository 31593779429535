import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexHorizontalCenter, FlexRow } from '../../../styles/styles';

export const DesktopNavItemsContainer = styled(animated.nav)`
  ${FlexHorizontalCenter}
  margin: 0 auto;

  .active {
    display: flex;
  }

  .hidden {
    display: none;
  }

  &.desktop-nav-links * {
    white-space: nowrap;
  }

  &.desktop-nav-links {
    flex-grow: 2;
    justify-content: center;
  }
`;

export const NavMenuItemContainer = styled.ul`
  ${FlexRow}
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0 35px;
  position: relative;
  text-align: center;

  li {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    font-weight: 600;
    list-style: none;
  }

  &.active {
    &::after {
      background: ${({ theme }) => theme.colors.dark};
      bottom: 0;
      content: '';
      height: 5px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;

export const SubMenuContainer = styled.nav`
  ${FlexRow}
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  height: 90px;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 75px;
  width: 100vw;

  ul {
    ${FlexRow}
    align-items: center;
    gap: 30px;
    justify-content: center;
    margin: 0;
  }
`;

export const SubMenuLinkListContainer = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
`;
