import React, { ReactElement, useCallback } from 'react';
import { H3, H5 } from '../../styles/components/header';
import { StatCardContainer } from './styles';

interface IStat {
  label: JSX.Element;
  amount: string;
}

export interface IStatProps {
  className?: string;
  stat: IStat;
  icon: ReactElement;
  header: JSX.Element;
  body: string;
  isAccountCard?: boolean;
}

export const StatCard: React.FC<IStatProps> = ({
  className = '',
  stat,
  icon, 
  header,
  body,
  isAccountCard,
}) => {
  const renderOverviewStat = useCallback(() => (
    <div className='overview-stats'>
      <div className='stat'>
        <div className={ isAccountCard ? 'account-card-label' : 'label' }>{ stat.label }</div>
        <H3 className='amount'>{ stat.amount }</H3>
        { isAccountCard ? <div className='tonnes'>TONNES</div> : null }
      </div>
    </div> 
  ), [stat]);

  const renderBottomStat = useCallback(() => (
    <div className='supporting-stat'>
      <div className='text-section'>
        <H5 className={ isAccountCard ? 'account-card-header' : 'header' }>{ header }</H5>
        <div className={ isAccountCard ? 'account-card-body' : 'body' }>{ body }</div>
      </div>
    </div>
  ), [header, body]); 

  return (
    <StatCardContainer className={ className }>
      { renderOverviewStat() }
      <div className='image-container'>{ icon }</div>
      { renderBottomStat() }
    </StatCardContainer>
  );
};
