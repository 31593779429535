import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useToaster } from '../../../contexts/toaster-store';
import { useUserSession } from '../../../contexts/user';
import { theme } from '../../../styles/themes';
import { passwordRules, isValidEmail, checkZipcodeAndFormat, isValidZipcode } from '../../../utils/input-validation';
import { ButtonKind } from '../../Button/styles';
import { EyeToggle } from '../../EyeToggle';
import { IModalProps } from '../Modal';
import { PasswordRules } from '../../PasswordRules';
import { EnvelopeIcon } from '../../svgs/icons/EnvelopeIcon';
import { LockIcon } from '../../svgs/icons/LockIcon';
import { UserIcon } from '../../svgs/icons/UserIcon';
import { TextField, TextFieldKind } from '../../TextField';
import { UpdateOptions } from '../../UserInfo';
import { ErrorText, UpdateProfileModalContainer, UpdateProfileModalInner, SimpleFormContainer, EmailFormContainer, PasswordFormContainer, ZipCodeFormContainer } from './styles';
import { removeScrollInert } from '../../../utils/removeScrollInert';
import { USStatesDropdown } from '../../USStatesDropdown';
import { IUserUpdateEmail, IUserUpdateMarqeta, IUserUpdateZipAndName } from '../../../models/users';
import { removeNonDigits } from '../../../lib/misc';

export interface IProps extends IModalProps {
  className?: string;
  itemToUpdate: string;
  setItemToUpdate: React.Dispatch<React.SetStateAction<string>>;
}

interface IModalItem {
  header: string;
  enabled: boolean;
  onClick(): void;
}

interface IModalMappings {
  name: IModalItem;
  email: IModalItem;
  password: IModalItem;
  zipcode: IModalItem;
}

type ModalMappingKeys = keyof IModalMappings;

interface IPasswordItem {
  show: boolean;
  set: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IPasswordMappings {
  confirm: IPasswordItem;
  email: IPasswordItem;
  password: IPasswordItem;
  new: IPasswordItem;
}

type PasswordMappingKeys = keyof IPasswordMappings;

enum PasswordType {
  Email = 'email',
  New = 'new',
  Password = 'password',
  Confirm = 'confirm'
}

interface IMessage {
  name: string;
  email: string;
  password: string;
  zipcode: string;
  address: string;
}

type MessageKeys = keyof IMessage;

const toasterMessages: IMessage = {
  name: 'Your name has been updated.',
  email: 'Your email has been updated.',
  password: 'Your password has been updated.',
  zipcode: 'Your zipcode has been updated',
  address: 'Your address has been updated',
};

export const UpdateProfileModalBase: React.FC<IProps> = ({
  className = '',
  itemToUpdate, 
  setItemToUpdate,
}) => {
  const user = useUserSession();
  const analytics = useAnalytics();
  const toaster = useToaster();
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [confirmationPasswordError, setConfirmationPasswordError] = useState(false);
  const [password, setPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [emailPasswordError, setEmailPasswordError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [serverErrorText, setServerErrorText] = useState('');
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [address2, setAddress2] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState('');
  const [stateError, setStateError] = useState('');
  const [zip, setZip] = useState('');
  const [zipError, setZipError] = useState('');

  const reset = () => {
    setConfirmationPassword('');
    setConfirmationPasswordError(false);
    setCurrentPasswordError('');
    setEmail('');
    setEmailError('');
    setEmailPassword('');
    setEmailPasswordError('');
    setItemToUpdate('');
    setName('');
    setNameError('');
    setNewPassword('');
    setNewPasswordError('');
    setPassword('');
    setServerErrorText('');
    setShowConfirmationPassword(false);
    setShowEmailPassword(false);
    setShowNewPassword(false);
    setShowPassword(false);
    setZip('');
    setZipError('');
    setCity('');
    setCityError('');
    setState('');
    setStateError('');
    setAddress('');
    setAddressError('');
    setAddress2('');
    setAddress2Error('');
    removeScrollInert();
  };

  const passwordMappings: IPasswordMappings = useMemo(() => ({
    confirm: {
      set: setShowConfirmationPassword,
      show: showConfirmationPassword,
    },
    email: {
      set: setShowEmailPassword,
      show: showEmailPassword,
    },
    new: {
      set: setShowNewPassword,
      show: showNewPassword,
    },
    password: {
      set: setShowPassword, 
      show: showPassword,
    },
  }), [showConfirmationPassword, setShowConfirmationPassword, showEmailPassword, setShowEmailPassword, showNewPassword, setShowNewPassword, showPassword, setShowPassword]); 

  const onUpdateSuccess = useCallback(() => {
    const formattedItemName = itemToUpdate[0].toUpperCase() + itemToUpdate.substring(1);
    analytics.fireEvent(`EditUserInfoSuccess_${formattedItemName}`);
    toaster.push({ message: toasterMessages[itemToUpdate as MessageKeys] });
    reset();
  }, [itemToUpdate]);

  const onUpdateError = useCallback((errorMessage: string) => {
    const formattedItemName = itemToUpdate[0].toUpperCase() + itemToUpdate.substring(1);
    analytics.fireEvent(`EditUserInfoError_${formattedItemName}`);
    setServerErrorText(errorMessage);
  }, [itemToUpdate, serverErrorText]);

  const onUpdateProfile = useCallback(() => {
    setIsProcessing(prev => !prev);
    let requestBody: IUserUpdateEmail | IUserUpdateZipAndName | IUserUpdateMarqeta;
    if (itemToUpdate === UpdateOptions.Email) {
      requestBody = { email, pw: emailPassword };
    } else if (itemToUpdate === UpdateOptions.Address) {
      requestBody = { integrations: {
        marqeta: {
          address1: address || user.address1,
          address2: address2 || '',
          city: city || user.city,
          postal_code: zip || user.zipcode,
          state: state || user.state,
        },
      },
      zipcode: zip || user.zipcode,
      };
    } else {
      requestBody = { 
        name: name || user.name,
        zipcode: zip || user.zipcode,
      };
    }
    user.updateProfile(requestBody)
      .then(() => {
        setIsProcessing(false);
        onUpdateSuccess();
      }).catch((err) => {
        setIsProcessing(false);
        onUpdateError(err.message);  
      });
  }, [name, zip, email, emailPassword, city, state, address, address2]);

  const onUpdatePassword = useCallback(() => {
    user.updatePassword({
      newPassword,
      password,
    })
      .then(() => {
        onUpdateSuccess();
      }).catch((err) => {
        onUpdateError(err.message);
      });
  }, [password, newPassword]);

  const onCloseModal = useCallback(() => {
    reset();
  }, []);

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.value) {
      const isValid = !passwordRules.find(r => !r.validate(e.target.value));
      setNewPasswordError(isValid ? '' : 'invalid');
    } else {
      setNewPasswordError('');
    }
    setNewPassword(e.target.value);
  }, [newPassword]);

  const onCurrentPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, [password]);

  const onEmailPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailPassword(e.target.value);
  }, [emailPassword]);

  const onConfirmationPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.value) {
      const isValid = e.target.value === newPassword && !newPasswordError;
      setConfirmationPasswordError(!isValid);
    } else {
      setConfirmationPasswordError(false);
    }
    
    setConfirmationPassword(e.target.value);
  }, [password, newPassword, newPasswordError]);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameError('');
    setName(e.target.value);
  }, [name]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onEmailBlur = useCallback(() => {
    if (!isValidEmail(email)) setEmailError('This isn\'t a valid e-mail address');
  }, [email, emailError]);

  const onZipChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setZipError('');
    const digitsOnly = removeNonDigits(e.target.value);
    const { isValid, value, zipErrorText } = checkZipcodeAndFormat(digitsOnly);
    setZip(value); 
    if (!isValid) setZipError(zipErrorText);
  }, [zip]);

  const onZipBlur = useCallback(() => {
    if (!isValidZipcode(zip)) setZipError('Use 5-digit format');
  }, [zip, zipError]);

  const onAddressChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddressError('');
    setAddress(e.target.value); 
  }, [address]);

  const onAddressBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 2) setAddressError('Please enter a valid street address');
  }, [address]);

  const onAddress2Change = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddress2Error('');
    setAddress2(e.target.value); 
  }, [address2]);

  const onCityChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCityError('');
    setCity(e.target.value); 
  }, [city]);

  const onCityBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 2) setCityError('A city is required');
  }, [city]);

  const onStateChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setStateError('');
    setState(e.target.options[e.target.selectedIndex].text); 
  }, [state]);

  const onStateBlur = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.options[e.target.selectedIndex].text === '') setStateError('A state is required');
  }, [state]);

  const onShowPasswordClick = useCallback((passwordType: PasswordType) => () => {
    const passwordItem = passwordMappings[passwordType as PasswordMappingKeys];
    passwordItem.set(!passwordItem.show);
    analytics.fireEvent(`ShowPasswordToggleClick_${!passwordItem.show}`);
  }, [showPassword, showNewPassword, showEmailPassword, showConfirmationPassword]);

  const modalMappings: IModalMappings = useMemo(() => (
    {
      name: {
        header: 'Edit Name',
        enabled: !nameError && !!name,
        onClick: onUpdateProfile,
      }, 
      email: {
        header: 'Update Your Email Address',
        enabled: !emailError && !!email && !!emailPassword && !emailPasswordError,
        onClick: onUpdateProfile,
      },
      password: {
        header: 'Update Your Password',
        enabled: !newPasswordError && !confirmationPasswordError && !!confirmationPassword && !!newPassword && !!password,
        onClick: onUpdatePassword,
      },
      zipcode: {
        header: 'Change or Add Zip Code',
        enabled: !zipError && !!zip && zip.length > 4,
        onClick: onUpdateProfile,
      },
      address: {
        header: 'Change Address',
        enabled: !zipError && !!zip && zip.length > 4 && !addressError && !!address && !cityError && !!city && !stateError && !!state,
        onClick: onUpdateProfile,
      },
    }
  ), [nameError, name, emailError, email, emailPassword, emailPasswordError, newPasswordError, confirmationPasswordError, confirmationPassword, newPassword, password, zipError, zip, city, state, addressError, address, address2, address2Error, cityError, stateError]);

  const getCTAs = useCallback(() => ([
    {
      id: 'cancel',
      text: 'Cancel',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCloseModal,
    },
    {
      id: 'save',
      className: 'save-changes',
      text: 'Save Changes',
      disabled: !modalMappings[itemToUpdate as ModalMappingKeys].enabled,
      kind: ButtonKind.Primary,
      onClick: modalMappings[itemToUpdate as ModalMappingKeys].onClick,
    },
  ]), [modalMappings, itemToUpdate]);

  const renderFormContent = () => {
    if (itemToUpdate === UpdateOptions.Name) {
      return (
        <SimpleFormContainer className='form-wrapper'>
          <TextField
            id='user-name-input'
            label='Update User Name'
            labelHidden
            fieldKind={ TextFieldKind.Pill }
            leftAccessory={ <UserIcon fill={ theme.colors.disabled } /> }
            value={ name }
            maxLength={ 50 }
            onChange={ onNameChange }
            errorText={ nameError }
          />
        </SimpleFormContainer>
      );
    }

    if (itemToUpdate === UpdateOptions.Address) {
      return (
        <SimpleFormContainer className='form-wrapper'>
          <TextField
            id='user-street-address-input'
            fieldKind={ TextFieldKind.Pill }
            label='Street Address'
            value={ address }
            onChange={ onAddressChange }
            onBlur={ onAddressBlur }
            errorText={ addressError }
          />
          <TextField
            id='user-address2-input'
            fieldKind={ TextFieldKind.Pill }
            label='Suite or Apartment # (optional)'
            placeholder='i.e. 500'
            value={ address2 }
            onChange={ onAddress2Change }
            errorText={ address2Error }
          />
          <TextField
            id='user-city-input'
            fieldKind={ TextFieldKind.Pill }
            label='City'
            value={ city }
            onChange={ onCityChange }
            onBlur={ onCityBlur }
            errorText={ cityError }
          />
          <USStatesDropdown
            label={ 'State' }
            labelAlignment='left'
            id={ 'user-state-input' }
            onChange={ onStateChange }
            onBlur={ onStateBlur }        
          />
          <TextField
            id='user-zipcode-input'
            fieldKind={ TextFieldKind.Pill }
            label='Zip Code'
            maxLength={ 5 }
            value={ zip }
            onChange={ onZipChange }
            onBlur={ onZipBlur }
            errorText={ zipError }
          />
        </SimpleFormContainer>
      );
    }

    if (itemToUpdate === UpdateOptions.Email) {
      return (
        <EmailFormContainer className='form-wrapper'>
          <TextField
            id='user-email-input'
            fieldKind={ TextFieldKind.Pill }
            leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
            placeholder='Email Address'
            label='New Email Address'
            value={ email }
            onBlur={ onEmailBlur }
            onChange={ onEmailChange }
            errorText={ emailError }
          />
          <TextField
            id='email-password-input'
            label='Verify Your Password'
            fieldKind={ TextFieldKind.Pill }
            leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
            rightAccessory={ <EyeToggle show={ showEmailPassword } onClick ={ onShowPasswordClick(PasswordType.Email) } /> }
            type={ showEmailPassword ? 'text' : 'password' }
            value={ emailPassword }
            onChange={ onEmailPasswordChange }
          />
        </EmailFormContainer>
      );
    }

    if (itemToUpdate === UpdateOptions.Zipcode) {
      return (
        <ZipCodeFormContainer className='form-wrapper'>
          <TextField
            id='user-zipcode-input'
            fieldKind={ TextFieldKind.Pill }
            label='Zip Code'
            maxLength={ 5 }
            value={ zip }
            onChange={ onZipChange }
            onBlur={ onZipBlur }
            errorText={ zipError }
          />
        </ZipCodeFormContainer>
      );
    }

    return (
      <PasswordFormContainer className='form-wrapper'>
        <TextField
          id='current-password-input'
          label='Current Password'
          fieldKind={ TextFieldKind.Pill }
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          rightAccessory={ <EyeToggle show={ showPassword } onClick ={ onShowPasswordClick(PasswordType.Password) } /> }
          type={ showPassword ? 'text' : 'password' }
          value={ password }
          onChange={ onCurrentPasswordChange }
          errorText={ currentPasswordError }
        />
        <TextField
          id='new-password-input'
          label='New Password'
          // ariaDescription='8 character minimum. One uppercase character. One lowercase character. One number. One special character: @$!%*?&'
          fieldKind={ TextFieldKind.Pill }
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          rightAccessory={ <EyeToggle show={ showNewPassword } onClick={ onShowPasswordClick(PasswordType.New) } /> }
          type={ showNewPassword ? 'text' : 'password' }
          value={ newPassword }
          onChange={ onPasswordChange }
        />
        <PasswordRules password={ newPassword } />
        { 
          (!newPasswordError && !!newPassword) && (
            <TextField
              id='confirm-password-input'
              label='Confirm New Password'
              fieldKind={ TextFieldKind.Pill }
              leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
              rightAccessory={ <EyeToggle show={ showConfirmationPassword } onClick={ onShowPasswordClick(PasswordType.Confirm) } /> }
              type={ showConfirmationPassword ? 'text' : 'password' }
              value={ confirmationPassword }
              onChange={ onConfirmationPasswordChange }
              errorText={ !!confirmationPasswordError ? 'Passwords are not matching' : null }
            />
          )
        }
      </PasswordFormContainer>
    );
  };

  if (itemToUpdate.length === 0) return null;

  return (
    <UpdateProfileModalContainer
      className={ className }
      title={ modalMappings[itemToUpdate as ModalMappingKeys].header }
      ctas={ getCTAs() }
      isOpen
      onClose={ onCloseModal }
      processing={ isProcessing }
    >
      <UpdateProfileModalInner>
        { renderFormContent() }
      </UpdateProfileModalInner>
      { !!serverErrorText && <ErrorText>{ serverErrorText }</ErrorText> }
    </UpdateProfileModalContainer>
  );
};

export const UpdateProfileModal = observer(UpdateProfileModalBase);
