import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { UserImpactReportsSummaryModel, UserMonthlyImpactReportModel, UserMonthlyImpactReportsModel } from '../../../../../models/userMonthlyImpactReport';
import dayjs from 'dayjs';
import { DateSelect, DateSelectContainer, DateSelectorContainer } from './styles';
import { useAnalytics } from '../../../../../contexts/analytics-store';

interface IProps {
  className?: string;
  userImpactReportsModel: UserMonthlyImpactReportsModel;
  userImpactReportsSummary: UserImpactReportsSummaryModel;
  activeMonthReport: UserMonthlyImpactReportModel;
}

const DateSelectorBase: React.FC<IProps> = ({
  className = '',
  userImpactReportsModel,
  userImpactReportsSummary,
  activeMonthReport,
}) => {
  const analytics = useAnalytics();
  const [datePickerYear, setDatePickerYear] = useState(parseInt(activeMonthReport ? activeMonthReport.date.format('YYYY') : dayjs().format('YYYY'), 10));

  const allYearSummaries = userImpactReportsSummary?.annualData;

  useEffect(() => {
    renderMonths();
  }, [datePickerYear]);

  const handleMonthSelect = useCallback(async(event: React.ChangeEvent<HTMLSelectElement>) => {
    const reportId = event.target.value;
    if (!reportId) return;

    try {
      await userImpactReportsModel.loadUserImpactReport(reportId);
    } catch (error) {
      analytics.fireEvent('Account_ImpactReport_MonthSelector_Error');
      return;
    }
  }, [userImpactReportsModel]);

  const renderMonthOption = useCallback(({
    summary,
    isActive,
    isDisabled,
  }: any) => (
    <option
      className={ `${isActive ? 'active' : ''} month-button` }
      key={ summary.date.toISOString() }
      disabled={ isDisabled }
      value={ summary.reportId }
    >
      { summary.date.utc().format('MMMM') }
    </option>
  ), []);

  const onYearChangeHandler = useCallback( async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const yearSelected = parseInt(event.target.value);
    setDatePickerYear(yearSelected);
    const yearSelectedSummary = allYearSummaries.find(summary => summary.year === yearSelected);
    const firstMonthOfSelectedYearInRange = yearSelectedSummary?.data.find(month => month.withinDataRange && month.reportId);

    try {
      await userImpactReportsModel.loadUserImpactReport(firstMonthOfSelectedYearInRange.reportId);
    } catch (error) {
      analytics.fireEvent('Account_ImpactReport_YearSelector_Error');
      return;
    }
  }, [userImpactReportsModel, userImpactReportsSummary]);

  const renderMonths = useCallback(() => {
    const currentYearSummaries = userImpactReportsSummary?.getAnnualData(datePickerYear)?.data;
    return (
      <DateSelectContainer>
        <DateSelect value={ userImpactReportsModel.selectedReportId || '' }
          onChange={ handleMonthSelect }>
          {
            currentYearSummaries?.map((summary) => {
              const isActive = activeMonthReport?.date.format('MMMM YYYY') === summary.date?.utc().format('MMMM YYYY');
              const isDisabled = !summary.reportId;
              const withinRangeWithoutData = !summary.reportId && summary.withinDataRange;

              if (!withinRangeWithoutData) return renderMonthOption({ summary, isActive, isDisabled });
            })
          }
        </DateSelect>
      </DateSelectContainer>
    );
  }, [activeMonthReport, userImpactReportsSummary, datePickerYear, onYearChangeHandler]);
  
  const renderYearSelector = useCallback(() => {
    const options = allYearSummaries?.map( year =>
      <option
        value={ year.year }
        key={ year.year }
      >
        { year.year }
      </option>,
    );

    return (
      <DateSelectContainer>
        <DateSelect value={ datePickerYear } onChange={ onYearChangeHandler }>
          { options }
        </DateSelect>
      </DateSelectContainer>
    );
  }, [datePickerYear, onYearChangeHandler]);

  return (
    <DateSelectorContainer className={ className }>
      { renderYearSelector() }
      { renderMonths() }
    </DateSelectorContainer>
  );
};

export const DateSelector = observer(DateSelectorBase);
