import React, { useCallback } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { CompanyModel } from '../../models/companies';
import { CardSize, CashbackCardContainer } from './styles';
import { PillTag } from '../PillTag';
import { PillTagKind, PillTagSize } from '../PillTag/styles';
import { CompanyLogo } from '../CompanyLogo';
import { Button, ButtonLink } from '../Button';
import { ButtonKind, ButtonSize } from '../Button/styles';
import { useUserSession } from '../../contexts/user';
import { buildShoppingUrl } from '../../lib/urlBuilders';
import { observer } from 'mobx-react';
import { AllBrandsOffersWidgetLocation } from '../AllBrandsWidget';
import { KarmaCollectiveOffersWidgetLocation } from '../KarmaCollectiveWidget';

interface IProps {
  className?: string;
  company: CompanyModel;
  location: AllBrandsOffersWidgetLocation | KarmaCollectiveOffersWidgetLocation;
  cardSize?: CardSize;
  hideCTA?: boolean;
}

export const CashbackCardBase: React.FC<IProps> = ({
  className = '',
  company,
  location,
  cardSize,
  hideCTA,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const shoppingUrl = buildShoppingUrl(user, company);

  const onShopNowClick = useCallback(() => {
    window.open(`${shoppingUrl}`, '_blank');
    analytics.fireEvent(`${location}_CashbackCard_ShopNow_Click`, `${company._id}`);
  }, [company, shoppingUrl]);

  const onCardClick = useCallback(() => {
    analytics.fireEvent(`${location}_CashbackCard_CompanyClick`);
  }, []);
  
  return (
    <CashbackCardContainer
      className={ className }
      cardSize={ cardSize }
      locked={ !user.hasKarmaWalletCard ? 'locked' : '' }
    >
      <ButtonLink
        kind={ ButtonKind.Blank }
        href={ `/company/${company._id}/${company.slug}` }
        onClick={ onCardClick }
      >
        <CompanyLogo className='company-logo' company={ company } />
      </ButtonLink>
      <PillTag
        className='pill'
        kind={ PillTagKind.CashbackLightGreen }
        size={ PillTagSize.Small }
      >
        <div>Up to { company.maxRate }</div>
      </PillTag>
      <p className='company-name'>{ company.companyName }</p>
      {
        !hideCTA
          ? (
            <Button
              className='start-shopping-button'
              disabled={ !user.hasKarmaWalletCard }
              kind={ ButtonKind.PrimaryGhost }
              size={ ButtonSize.Small }
              onClick={ onShopNowClick }
            >
              Shop Now
            </Button>
          )
          : null
      }
    </CashbackCardContainer>
  );
};

export const CashbackCard = observer(CashbackCardBase);
