import React from 'react';
import { DisclaimerSectionContainer, ItemText } from './styles';

export interface IDisclaimerTextItem {
  text: string;
  paddingTop?: boolean;
}

interface IProps {
  className?: string;
  textItems: IDisclaimerTextItem[];
}

export const DisclaimerSection: React.FC<IProps> = ({
  className = '',
  textItems,
}) => (
  <DisclaimerSectionContainer className={ className }>
    {
      textItems.map((item, index) => (
        <ItemText
          key={ index }
          className={ !!item.paddingTop ? 'padding-top' : '' }
        >
          { item.text }
        </ItemText>
      ))
    }
  </DisclaimerSectionContainer>
);
