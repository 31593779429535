import React from 'react';
import { DebitCardTextContainer, KarmaCardAndAppImgContainer, KarmaCardSection, KarmaCardAppSectionContainer, BackgroundContainer, ButtonWrapper, MembershipHeadingContainer } from './styles';
import { H2M, H2MInverted, H5 } from '../../styles/components/header';
import { theme } from '../../styles/themes';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { CoinB } from '../svgs/graphics/CoinB';
import { Bottle } from '../svgs/graphics/Bottle';
import { EarthWithClouds } from '../svgs/graphics/EarthWithClouds';
import { LeafA } from '../svgs/graphics/LeafA';
import { Light } from '../svgs/graphics/Light';
import { Food } from '../svgs/graphics/Food';
import { Recycling } from '../svgs/graphics/Recycling';
import { useAnalytics } from '../../contexts/analytics-store';
import { GenericTag } from '../GenericTag';
import { TextWithArrowPosition, TextWithUpArrow } from '../TextWithUpArrow';

interface IProps {
  className?: string;
}

export const KarmaWalletCardSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  
  const renderBackground = () => (
    <BackgroundContainer>
      <CoinB
        className='svg-icon coin-b-1'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-2'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-3'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-4'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-5'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-6'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <Bottle
        className='svg-icon bottle'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <EarthWithClouds
        className='svg-icon earth-clouds'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <LeafA
        className='svg-icon leaf-a'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <Light
        className='svg-icon light'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Food
        className='svg-icon food'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Recycling
        className='svg-icon recycling'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
    </BackgroundContainer>
  );
  
  const onLearnMoreClick = () => {
    analytics.fireEvent('HP_DebitCardSection_LearnMore_Click');
  };

  return (
    <KarmaCardAppSectionContainer
      className={ className }
      id='karma-card-section'
      backgroundColor={ theme.colors.dark }
    >
      <KarmaCardSection>
        { renderBackground() }
        <DebitCardTextContainer>
          <GenericTag
            color={ theme.colors.secondary }
            tagText='Prepaid Reloadable Debit Card'
          />
          <MembershipHeadingContainer role='heading' aria-level={ 1 }>
            <H2M className='debit-card-text' role='presentation'>Your membership</H2M>
            <H2M className='debit-card-text block' role='presentation'>to a</H2M><H2MInverted className='inverted-text' role='presentation'>better future.</H2MInverted>
          </MembershipHeadingContainer>
          <H5>Earn up to 20% cashback* on select purchases and automatically donate to nonprofits for everyday spending with your Karma Wallet Card.</H5>
          <p>No credit check required</p>
          <ButtonWrapper>
            <ButtonLink
              className='learn-more-button'
              href='/app'
              kind={ ButtonKind.Secondary }
              onClick={ onLearnMoreClick }
            >
              Learn More
            </ButtonLink>
          </ButtonWrapper>
        </DebitCardTextContainer>
        <KarmaCardAndAppImgContainer>
          <img
            alt='Karma Wallet App and Card'
            className='app-and-card'
            src='https://cdn.karmawallet.io/uploads/hXu4Vddq8w-app-and-card.webp'
          />
          <img
            alt='Karma Wallet App'
            className='app-mockup'
            src='https://cdn.karmawallet.io/uploads/tn8sHAmCJy-app-mockup-with-card.webp'
          />
          <TextWithUpArrow
            className='recycled-plastic-text'
            text={ <>85%<br />recycled<br />plastic</> }
            color={ theme.colors.white }
            arrowPosition={ TextWithArrowPosition.Left }
          />
        </KarmaCardAndAppImgContainer>
      </KarmaCardSection>
    </KarmaCardAppSectionContainer>
  );};
