import styled from 'styled-components';
import { FlexCol, SecondaryFont } from '../../../../styles/styles';

export const UserImpactDataVisualizationChartContainer = styled.div`
  ${FlexCol}
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
  }

  .x-label {
    color: black;
    font-weight: bold;
    height: 20px;
    margin-top: 30px;
    text-transform: uppercase;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-right: -40px;
      transform: rotate(-90deg);
    }
  }

  .recharts-surface {
    width: calc(100% + 10px);
  }

  .recharts-wrapper {
    margin: 0 auto;

    .recharts-yAxis {
      .recharts-cartesian-axis-tick:nth-of-type(2), .recharts-cartesian-axis-tick:nth-of-type(4), line {
        display: none;
      }
    }

    .recharts-text {
      ${SecondaryFont}
      fill: black;
    }

    .recharts-cartesian-axis-tick-line {
      display: none;
    }
  }

  .chart-wrapper {
    width: 99%;

    .recharts-layer.recharts-bar.bar {
      transform: scaleY(0.80);
    }

    .recharts-rectangle.bar {
      transform: translateY(-150px);
    }

    .recharts-bar-rectangle {
      transform: scaleY(3);
    }

    .bar, .recharts-rectangle, .recharts-bar-rectangle, .circle, .recharts-active-dot, .recharts-dot {
      cursor: pointer;
    }

    .recharts-dot {
      fill: ${({ theme }) => theme.colors.tertiary};
    }

    .recharts-line-curve {
      stroke-width: 2;
    }
  }
`;

export const CustomTooltipContainer = styled.div`
  ${SecondaryFont}
  background: ${({ theme }) => theme.colors.tertiary};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 12px;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
`;
