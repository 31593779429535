import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CompanyModel } from '../../models/companies';
import { CompanyContainer, CompanyInnerWrapper } from './styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { ChildrenCompaniesSection } from '../../components/ChildrenCompaniesSection';
import { CompanyImpactCategories } from '../../components/CompanyImpactCategories';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { CompanyTopSection } from '../../components/CompanyTopSection';
import { BackedByResearch } from '../../components/BackedByResearch';
import { RelatedCompanies } from '../../components/RelatedCompanies';
import { CompanyCashbackOffers } from '../../components/CompanyCashbackOffers';
import { CompanyRatingBreakdown } from './CompanyRatingBreakdown';
import { useUserSession } from '../../contexts/user';

interface IProps {
  className?: string;
}

const CompanyBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const user = useUserSession();
  const { companyId } = useParams();
  const { companySlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CompanyModel>(null);

  const previewInfo = useMemo(() => {
    if (!company) return null;

    return {
      title: company.companyName,
    };
  }, [company]);
  
  useEffect(() => {
    if (!!company) {
      analytics.fireEvent('Company_View', `${company._id}`);
    }
  }, [company]);

  useEffect(() => {
    setLoading(true);
    CompanyModel.loadCompany(companyId)
      .then(setCompany)
      .then(() => setLoading(false))
      .catch(() => {
        navigate('/');
        setLoading(false);
      });
  }, [companyId, companySlug]);

  useEffect(() => {
    if (!company) return;
    if (company.slug !== companySlug) {
      window.history.replaceState(null, null, `/company/${company._id}/${company.slug}`);
    }
  }, [company]);

  const renderChildrenCompanies = useCallback(() => {
    if (!company || !company?.companiesOwned?.length) return null;
    return <ChildrenCompaniesSection company={ company } />;
  }, [company]);

  const renderCashbackSection = useCallback(() => {
    if (!company || !company?.hasCashback) return null;
    const hasWildfireCashback = !!company?.merchant?.integrations?.wildfire?.maxRate?.amount;
    const hasKardCashback = !!company?.merchant?.integrations?.kard?.maxRate?.amount;
    const shouldDisplayCashbackSection = hasKardCashback || hasWildfireCashback;

    return shouldDisplayCashbackSection ? <CompanyCashbackOffers company={ company } /> : null;
  }, [company, user.hasKarmaWalletCard, user.isLoggedIn]); 

  const companyContent = useMemo(() => {
    if (!company && !loading) return null;

    let content = (
      <>
        <CompanyTopSection company={ company } />
        { company && <CompanyRatingBreakdown company={ company } /> }
        { company && <CompanyImpactCategories companies={ [company] } /> }
        { renderCashbackSection() }
        { renderChildrenCompanies() }
        <RelatedCompanies baseCompanyId={ company?._id } company={ company } />
        <BackedByResearch />
      </>
    );

    if (!!loading) content = <LoadingSpinner />;

    return (
      <CompanyInnerWrapper>
        { content }
      </CompanyInnerWrapper>
    );
  }, [company, loading, renderCashbackSection]);

  return (
    <CompanyContainer className={ className } title={ previewInfo?.title ? previewInfo.title : 'Company Page' }>
      { companyContent }
    </CompanyContainer>
  );
};

export const Company = observer(CompanyBase);
