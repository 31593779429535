import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const LinkedCardsPageContainer = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  ${FlexCol}
  gap: 6px;
  margin-bottom: 50px;
  width: 100%;

  .link-your-cards-text {
    font-weight: 500;
    margin: unset;
  }
`;

export const ContentContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 40px;
  margin: 0 auto;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: flex-start;
  }
`;

export const CardsContainer = styled.div`
  gap: 18px;
  width: 100%;

  .account-cards {
    align-items: center;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 50%;

    .account-cards {
      align-items: flex-start;
    }
  }
`;

export const PlaidContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 22px;
  text-align: center;
  width: 100%;

  .plaid-text {
    font-size: 12px;
    line-height: 16px;
    width: 90%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 50%;
  }
`;
