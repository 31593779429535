import styled from 'styled-components';
import { AccountHubBasicContainer, FlexRow } from '../../../styles/styles';

export const KarmaCashPageContainer = styled.div`
  width: 100%;
`;

export const KarmaCashPageInnerWrapper = styled.div`
  margin: 0 auto;
`;

export const KarmaCashPageContent = styled.div`
  .cashback-skeleton {
    height: 900px;
  }
`;

export const KarmaCashPageHeader = styled.div`
  margin-bottom: 40px;
`;

export const ErrorMessageContainer = styled.div`
  ${AccountHubBasicContainer}
  ${FlexRow}
  align-items: center;
  color: ${({ theme }) => theme.colors.grey2};
  height: 500px;
  justify-content: center;
  width: 100%;
`;

export const SectionWrapper = styled.div``;
