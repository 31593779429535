import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessageContainer, KarmaCashPageContainer, KarmaCashPageContent, KarmaCashPageHeader, SectionWrapper } from './styles';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { CommissionsDashboardModel } from '../../../models/commisions';
import { YourCashback } from './CashbackSection';
import Skeleton from 'react-loading-skeleton';
import { Payouts } from './PayoutsSection';
import { AccountHubH1 } from '../../../styles/components/header';

interface IProps extends IThemeProps {
  className?: string;
}

const KarmaCashPageBase: React.FC<IProps> = ({
  className,
}) => {
  const commissionsDashboardModel = useRef(new CommissionsDashboardModel()).current;
  const [loadingCommissionDashboard, setLoadingCommissionDashboard ] = useState(true);

  useEffect(() => {
    try {
      setLoadingCommissionDashboard(true);
      commissionsDashboardModel.loadDashboardSummary();
    } catch (error) {
      setLoadingCommissionDashboard(false);
    } finally {
      setLoadingCommissionDashboard(false);
    }
  }, [commissionsDashboardModel, loadingCommissionDashboard]);

  const renderKarmaCashPageContent = useCallback(() => {
    let content: JSX.Element;

    if (commissionsDashboardModel.errorLoading) {
      content = <ErrorMessageContainer className='full-width'>Sorry, we are having trouble loading your Karma Cash information.</ErrorMessageContainer>;
    }

    if (commissionsDashboardModel.busy || loadingCommissionDashboard) {
      content = <Skeleton className='cashback-skeleton full-width' />;
    }

    content = (
      <SectionWrapper>
        <YourCashback className='full-width' commissionsDashboardModel={ commissionsDashboardModel } loading={ loadingCommissionDashboard } />
        <Payouts className='full-width' payouts={ commissionsDashboardModel.payouts } loading={ loadingCommissionDashboard } />
      </SectionWrapper>
    );

    return (
      <KarmaCashPageContent>
        { content }
      </KarmaCashPageContent>
    );
  }, [commissionsDashboardModel, loadingCommissionDashboard]);

  return(
    <KarmaCashPageContainer className={ className }>
      <KarmaCashPageHeader>
        <AccountHubH1>Karma Cash</AccountHubH1>
        <p>Earn up to 20% cashback at select sustainable companies when you use the Karma Wallet Card</p>
      </KarmaCashPageHeader>
      { renderKarmaCashPageContent() }
    </KarmaCashPageContainer>
  );
};

const KarmaCashPageObserver = observer(KarmaCashPageBase);
export const KarmaCashPage = withTheme(KarmaCashPageObserver);
