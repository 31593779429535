import styled from 'styled-components';
import { FlexColCenter, FlexRow } from '../../../../styles/styles';

export const ModalBankCardItemContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: 20px 0 0;
  position: relative;

  .card-security-text {
    font-size: 10px;
    font-style: italic;
    line-height: 1rem;
    margin-top: 10px;
  }

  .error-text {
    color: ${ ({ theme }) => theme.colors.red };
    font-size: 12px;
    line-height: 1rem;
  }

  input {
    width: 60%;
  }

  .card-digits-input-field {
    max-width: 300px;
    width: 90%;
`;

export const BankCardWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  .bank-card-container {
    align-items: center;
    border: 1px solid ${ ({ theme }) => theme.colors.midGray };
    border-radius: 4px;
    display: flex;
    height: 124px;
    justify-content: center;
    max-width: 249px;
    max-height: 124px;
    padding: 16px;

    .card-info {
      margin-left: 10px;
      text-align: left;
    }
  }

  .bank-logo-container {
    border: 1px solid ${ ({ theme }) => theme.colors.midGray };
    border-radius: 50%;
    max-height: 68px;
    max-width: 68px;
    min-width: 68px;
    min-height: 68px;
    padding: 10px;
  
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    .no-bank-logo {
      max-height: 40px;
      object-fit: contain;
      width: auto;
    }
  }

  .bank-card-example {
    display: none;
  }

  @media (${ ({ theme }) => theme.breakpoints.smMin }) {
    ${FlexRow}

    .bank-card-example {
      display: block;
    }
  }
`;
