import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectedAccountLogosContainer, ConnectedAccountsContainer, FilterContainer, FilterOptionsContainer, LoadingSpinnerContainer, NoTransactions, TransactionFilterCheckbox, TransactionsContainer, TransactionsPageContainer, TransactionsPageHeader, TransactionsPageInnerWrapper } from './styles';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { TransactionsModel } from '../../../models/transactions';
import { useUserSession } from '../../../contexts/user';
import { CompanyRating } from '../../../models/companies';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { useNavigate } from 'react-router';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { AccountHubH1 } from '../../../styles/components/header';
import { useAnalytics } from '../../../contexts/analytics-store';
import { TransactionsNotLoggedModal } from '../../../components/modals/TransactionNotLoggedModal';
import { TransactionItem } from './TransactionItem';
import { TransactionsItemSkeleton } from './TransactionItem/skeleton';
import { CardModel } from '../../../models/cards';

interface IProps extends IThemeProps {
  className?: string;
}

interface ITransactionQuery {
  ratings: CompanyRating[];
}

interface ITransactionsFilterOption {
  text: string;
  id: string;
  selected: boolean;
  context: CompanyRating;
}

type CompanyCardAcc = {
  [key: string]: CardModel;
}

const TransactionsPageBase: React.FC<IProps> = ({
  className,
}) => {
  const user = useUserSession();
  const analytics = useAnalytics();
  const transactionsModel = useRef(new TransactionsModel(null, { url: '/transaction/rated' })).current;
  const mountedRef = useRef(true);
  const navigate = useNavigate();
  const filterEngaged = useRef(false);
  const [checkingUserForTransactions, setCheckingUserForTransactions] = useState(true);
  const [showTransactionNotLoggedModal, setShowTransactionNotLoggedModal] = useState(false);
  const errorMessages = useErrorMessages();
  const [filterOptions, setFilterOptions] = useState<ITransactionsFilterOption[]>(Object.values(CompanyRating).map(rating => ({
    id: rating,
    text: rating,
    selected: true,
    context: rating,
  })));

  const onTransactionsNotLoggedModalClose = useCallback(() => {
    setShowTransactionNotLoggedModal(false);
  }, []);

  const onTransactionsNotLoggedClick = useCallback(() => {
    analytics.fireEvent('Transactions_WhyNotLogged_Click');
    setShowTransactionNotLoggedModal(true);
  }, []);

  const onFilterOptionChange = useCallback((option: ITransactionsFilterOption) => () => {
    analytics.fireEvent(`Transactions_Filter_Click_${option}`);
    filterEngaged.current = true;
    const updatedOptions = filterOptions.map(o => {
      if (o.id === option.id) o.selected = !option.selected;
      return o;
    });

    setFilterOptions(updatedOptions);
  }, [filterOptions]);

  useEffect(() => {
    if (!filterEngaged.current) return;
    loadMore(true)();
    return () => { 
      mountedRef.current = false;
    };
  }, [filterOptions]);

  useEffect(() => {
    user.checkIfHasTransactions()
      .catch(() => {
        analytics.fireEvent('Transactions_HasTransactions_Error');
      })
      .finally(() => {
        setCheckingUserForTransactions(false);
        if (!user.hasTransactions) navigate('/account/transactions');
      });
    return () => { 
      mountedRef.current = false;
    };
  }, []);

  const loadMore = (refresh?: boolean) => () => {
    const query: ITransactionQuery = {
      ratings: filterOptions.filter(option => option.selected).map(option => option.context),
    };

    transactionsModel.transactions[refresh ? 'refresh' : 'loadMore'](query)
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Transactions',
          message: err.message,
        });
      });
  };

  const renderTransactions = () => {
    let transactions: JSX.Element[] = [];

    if (checkingUserForTransactions) return <LoadingSpinner />;

    if (!user.hasTransactions) transactions.push(<NoTransactions key='no-transactions'>No transactions found</NoTransactions>);

    if (transactionsModel.transactions.results.length > 0) {
      transactions = transactionsModel.transactions.results.map(transaction => (
        <TransactionItem
          key={ transaction._id }
          className='transaction-item'
          transaction={ transaction }
        />
      ));
    }

    if (transactionsModel.transactions.busy) {
      if (transactionsModel.transactions.firstPageLoaded) {
        transactions.push((
          <LoadingSpinnerContainer key='loading-transactionns-spinner'>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ));
      } else {
        const skeletons: JSX.Element[] = [];
        for (let i = 0; i < 25; i++) {
          skeletons.push(<TransactionsItemSkeleton key={ `transaction-item-skele-${i}` } />);
        }
        transactions = [...transactions, ...skeletons];
      }
    }

    return transactions;
  };
  
  useEffect(() => {
    if (!user.hasTransactions) return;
    loadMore(true)();
    return () => { 
      mountedRef.current = false;
    };
  }, [user.hasTransactions]);

  const loadMoreButton = () => {
    if (transactionsModel.transactions.results.length < 25) return null;

    return (
      <Button
        kind={ ButtonKind.Primary }
        onClick={ loadMore(false) }
        className='load-more-button'
        disabled= { transactionsModel.transactions.allResultsFetched || transactionsModel.transactions.busy }
      >
        Load More
      </Button>
    );
  };

  const connectedAccounts = () => {
    const connectedAccountInstitutions = Object.values(user.cards.reduce( (acc: CompanyCardAcc, card: CardModel) => {
      acc[card.institutionId] = acc[card.institutionId] || card;
      return acc;
    }, {}));

    const connectedAccountsCount = connectedAccountInstitutions.length;

    return (
      <ConnectedAccountsContainer>
        <p>{ connectedAccountsCount } { connectedAccountsCount === 1 ? 'Linked account' : 'Linked accounts' }</p>
        <ConnectedAccountLogosContainer>
          {
            connectedAccountInstitutions.map(card => (
              <div key={ card._id } className='connected-account-icon'>
                <img src={ card.institution === 'Karma Wallet' ? 'https://s3.amazonaws.com/assets.karmawallet/kw_logo.png' : `https://s3.amazonaws.com/plaid.karmawallet/${card.institutionId}.png` } />
              </div>
            ))
          }
        </ConnectedAccountLogosContainer>
      </ConnectedAccountsContainer>
    );
  };

  const renderFilterOptions = useCallback(() => {
    const checkboxes = filterOptions.map(option => (
      <TransactionFilterCheckbox
        key={ option.id }
        id={ `${option.id}-dt` }
        className={ option.id }
        checked={ option.selected }
        label={ option.text }
        onChange={ onFilterOptionChange(option) }
        disabled={ transactionsModel.transactions.busy || (option.selected && filterOptions.filter(option => option.selected).length === 1) || !user.hasTransactions }
      />
    ));

    return (
      <FilterContainer>
        <p>Filter By:</p>
        <FilterOptionsContainer>
          { checkboxes }
        </FilterOptionsContainer>
      </FilterContainer>
    );
  }, [filterOptions, transactionsModel.busy, onFilterOptionChange]);

  return(
    <TransactionsPageContainer className={ className }>
      <TransactionsPageInnerWrapper>
        <TransactionsPageHeader>
          <div>
            <AccountHubH1>Rated Transactions</AccountHubH1>
            { connectedAccounts() }
          </div>
          { renderFilterOptions() }
        </TransactionsPageHeader>
        <Button
          kind={ ButtonKind.Blank }
          className='transaction-not-logged-button'
          onClick={ onTransactionsNotLoggedClick }
        >
            Why aren't all my transactions logged?
        </Button>
        <TransactionsContainer>
          { renderTransactions() }
        </TransactionsContainer>
      </TransactionsPageInnerWrapper>
      { loadMoreButton() }
      <TransactionsNotLoggedModal
        isOpen={ showTransactionNotLoggedModal }
        onClose={ onTransactionsNotLoggedModalClose }
      />
    </TransactionsPageContainer>
  );
};

const TransactionsPageObserver = observer(TransactionsPageBase);
export const TransactionsPage = withTheme(TransactionsPageObserver);
