import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { ButtonLink } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';
import { CommissionsList } from '../../components/CommissionsList';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { useAnalytics } from '../../contexts/analytics-store';
import { PayoutModel } from '../../models/commisions';
import { CashbackAccrualsContainer, CashbackAccrualsInnerWrapper, HeaderWrapper, NoAccrualItemsContainer } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { H1 } from '../../styles/components/header';
import { useNavigate } from 'react-router';
import { ChevronDirection, ChevronIcon } from '../../components/svgs/icons/ChevronIcon';

interface IProps extends IThemeProps {
  className?: string;
}

const CashbackAccrualsBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const payoutModel = useRef(new PayoutModel()).current;
  const navigate = useNavigate();

  useEffect(() => {
    payoutModel.loadPayout();
  }, []);

  const onBackToCashbackClick = () => {
    analytics.fireEvent('CashbackAccrualsPage_BackToCashback_Click');
    navigate('/account/cashback');
  };

  const accruals = useMemo(() => {
    const accrualItems = payoutModel.commissions;

    const onViewAllCashbackCompaniesClick = () => {
      analytics.fireEvent('CashbackAccroualsPage_ViewAllCashbackCompanies_Click');
    };

    if (payoutModel.busy) return <LoadingSpinner />;
    if (payoutModel.errorLoading) {
      analytics.fireEvent('CashbackAccrualsPage_ErrorLoading');
      return <div>Error loading your current accruals.</div>;
    }

    if (accrualItems.length === 0) {
      return (
        <NoAccrualItemsContainer>
          <div>Whoops! Doesn't look like you have any accrued cashback.</div>
          <ButtonLink
            kind={ ButtonKind.Primary }
            href='/browse-and-compare-companies?cashback=true'
            onClick={ onViewAllCashbackCompaniesClick }
          >
            Explore Cashback Companies
          </ButtonLink>
        </NoAccrualItemsContainer>
      );
    }

    if (accrualItems.length > 0) {
      return (
        <CommissionsList
          commissions={ payoutModel.commissions }
          total={ payoutModel.total }
        />
      ); 
    }
  }, [payoutModel.commissions, payoutModel.busy, payoutModel.errorLoading]);

  return (
    <CashbackAccrualsContainer className={ className }>
      <HeaderWrapper>
        <ButtonLink
          className='back-to-cashback-button'
          kind={ ButtonKind.Blank }
          href='/account/cashback'
          label='Back to Cashback'
          onClick={ onBackToCashbackClick }
        >
          <ChevronIcon direction={ ChevronDirection.Left } stroke={ theme.colors.dark } />
        </ButtonLink>
      </HeaderWrapper>
      <CashbackAccrualsInnerWrapper>
        <H1>Current Cashback Accruals</H1>
        { accruals }
      </CashbackAccrualsInnerWrapper>
    </CashbackAccrualsContainer>
  );
};

const CashbackAccrualsObserver = observer(CashbackAccrualsBase);
export const CashbackAccruals = withTheme(CashbackAccrualsObserver);
