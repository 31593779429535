import styled from 'styled-components';
import { AccountContentArea } from '../styles';
import { FlexColCenter, FlexHorizontalCenter } from '../../../styles/styles';

export const AccountDashboardContainer = styled(AccountContentArea)`
  width: 100%;
`;

export const OffersHeaderContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 5px;
  width: 100%;
  
  .karma-card {
    width: 34px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexHorizontalCenter}
    justify-content: space-between;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 32px;
    justify-content: flex-start;
  }
`;
