import styled from 'styled-components';
import { FlexCenter } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const UpdateProfileModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 650px;
  overflow-y: auto;

  .ctas-container {
    flex-direction: column-reverse;

    .save-changes {
      margin-bottom: 10px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      flex-direction: row;

      .save-changes {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
`;

export const UpdateProfileModalInner = styled.div`
  ${FlexCenter}
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGray1};
  text-align: center;

  .update-profile {
    justify-content: center;
  }

  .pill-label {
    text-align: left;
  }

  .form-wrapper {
    width: 100%;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  text-align: right;
`;

export const SimpleFormContainer = styled.div`
  #user-state-input {
    margin-bottom: 20px;
  }
`;

export const PasswordFormContainer = styled.div``;

export const EmailFormContainer = styled.div``;

export const ZipCodeFormContainer = styled.div``;
