import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useSignInModal } from '../../contexts/sign-in';
import { SignInMode } from '../../models/sign-in';
import { ActionSection } from '../ActionSection';
import { ButtonKind } from '../Button/styles';
import { ConditionalAccess } from '../ConditionalAccess';
import { TrackYourImpactContainer } from './styles';

interface IProps {
  className?: string;
}

const LivingYourValuesCTASectionBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const signInModal = useSignInModal();

  const onCreateAccountClick = () => {
    signInModal.open(SignInMode.CREATE_ACCOUNT);
    analytics.fireEvent('Home_LivingYourValuesCreateAccount_Click');
  };

  const ctas = useMemo(() => (
    [
      {
        id: 'get-started',
        text: 'Check My Score',
        kind: ButtonKind.Primary,
        onClick: onCreateAccountClick,
      },
    ]
  ), [signInModal]);

  return (
    <ConditionalAccess loggedIn={ false }>
      <TrackYourImpactContainer className={ className }>
        <ActionSection
          ctas={ ctas }
          header='Not ready for a Karma Wallet Card?'
          subheader='Create a free Karma Wallet account to check your Karma Score and get personalized data insights.'
          isMarketing={ true }
          className='action-section'
        />
      </TrackYourImpactContainer>
    </ConditionalAccess>
  );
};

export const LivingYourValuesCTASection = observer(LivingYourValuesCTASectionBase);
