import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UpArrowCurledIcon: FC<IProps> = ({ className = '', fill = 'white', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }up-arrow-curled` }
    id={ id }
    width={ 23 }
    height={ 18 }
    fill='none'
  >
    <g clipPath='url(#clip0_1800_21726)'>
      <path d='M0.230044 17.2399C9.73004 20.3199 18.94 13.2499 20.36 4.05992C20.51 4.42992 20.66 4.79992 20.82 5.17992C21.06 5.74992 22.09 5.71992 22.11 5.01992C22.16 3.57992 21.81 0.529918 20.04 0.0499181C19.41 -0.120082 18.95 0.199918 18.47 0.549918C17.29 1.42992 16.15 2.51992 15.15 3.58992C14.69 4.07992 15.43 4.77992 15.95 4.40992C16.98 3.66992 17.93 2.38992 19.03 1.80992C19.09 1.77992 19.15 1.74992 19.21 1.72992C19.21 6.27992 17.19 10.7699 13.6 13.5999C9.84004 16.5599 5.08004 17.3399 0.450044 16.4999C0.0300441 16.4199 -0.179956 17.0999 0.240044 17.2399H0.230044Z' fill={ fill } />
    </g>
    <defs>
      <clipPath id='clip0_1800_21726'>
        <rect width='22.11' height='17.97' fill={ fill } />
      </clipPath>
    </defs>
  </SvgIcon>
);
