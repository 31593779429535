import styled from 'styled-components';
import { AccountHubBasicContainer, FlexRow } from '../../../styles/styles';

export const CashbackPayoutSummaryContainer = styled.div`
  width: 100%;
`;

export const CashbackPayoutInnerWrapper = styled.div``;

export const PayoutContentContainer = styled.div`
  ${AccountHubBasicContainer}
  
  & > H2 {
    text-align: center;
    margin-top: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  
  .back-to-cashback-button {
    ${FlexRow}
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 20px;
  }
`;
