import styled from 'styled-components';
import { AccountContentArea } from '../styles';
import { AccountHubBasicContainer, FlexColCenter, FlexRow } from '../../../styles/styles';

export const ImpactReportsPageContainer = styled.div`
  width: 100%;
  
  .apply-for-card {
    margin-bottom: 24px;
  }
`;

export const ImpactReportsInnerWrapper = styled(AccountContentArea)`
  ${AccountHubBasicContainer}
`;

export const VisualizationSectionContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 20px;
  width: 100%;

  .error-loading-container {
    ${FlexRow}
    align-items: center;
    color: ${({ theme }) => theme.colors.grey2};
    justify-content: center;
    height: 300px;
    text-align: center;
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    gap: 0;
  }
`;

export const DateSelectorChartContainer = styled.div`
  width: 100%;
`;
