import styled from 'styled-components';
import { FlexRow, TruncateText } from '../../styles/styles';

export const CashbackAccrualItemRowContainer = styled.div`
  padding: 10px 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey2};

  .top-row {
    a.disabled {
      color: ${({ theme }) => theme.colors.darkGray1 };
      opacity: 1;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 12px 0;
    border-bottom: solid 1px ${({ theme }) => theme.colors.grey2};
  }

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      font-size: 16px;
  
      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        font-size: 16px;
      }
    }

    .date {
      ${FlexRow}
      font-size: 14px;
      font-weight: 600;
      min-width: 110px;
    }

    .date-name-container {
      ${FlexRow}
      align-items: center;
      min-width: 100px;
    }
  
    .company {
      ${TruncateText}
      padding: 0 2px;
      font-weight: 600;
      font-size: 14px;
    }

    .amount-pending-container {
      ${FlexRow}
      justify-content: flex-end;
      width: 130px;
    }

    .pending {
      font-size: 14px;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.tertiary};
      font-weight: 500;
      font-style: italic;
    }
  
    .amount {
      font-size: 14px;
      text-align: right;
      font-weight: 600;
      margin-left: 16px;
    }
  }
`;
