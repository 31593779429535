import styled from 'styled-components';
import { AccountHubBasicContainer, FlexColCenter, FlexRow } from '../../styles/styles';

export const CashbackAccrualsContainer = styled.div`
  width: 100%;
`;

export const CashbackAccrualsInnerWrapper = styled.div`
  ${AccountHubBasicContainer}
  text-align: center;
  `;

export const HeaderWrapper = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    flex: 1;
    text-align: center;
  }

  .back-to-cashback-button {
    ${FlexRow}
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 20px;
  }
`;

export const NoAccrualItemsContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  
  a {
    margin: 20px auto 0;
  }
`;
