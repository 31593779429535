import React, { useCallback } from 'react';
import { AccountDashboardContainer, OffersHeaderContainer } from './styles';
import { LatestTransactionsWidget } from '../../../components/LatestTransactionsWidget';
import { ConnectedAccountsWidget } from '../../../components/ConnectedAccountsWidget';
import { CashbackWidget } from '../../../components/CashbackWidget';
import { useUserSession } from '../../../contexts/user';
import { ApplyForCardWidget } from '../../../components/ApplyForCardWidget';
import { useAnalytics } from '../../../contexts/analytics-store';
import { observer } from 'mobx-react';
import { KarmaCollectiveOffersWidgetLocation, KarmaCollectiveWidget } from '../../../components/KarmaCollectiveWidget';
import { AccountHubH2LG } from '../../../styles/components/header';
import { PillTag } from '../../../components/PillTag';
import KarmaCardImage from '../../../../public/assets/images/empty-kw-card.png';
import { PillTagKind, PillTagSize } from '../../../components/PillTag/styles';
import { CardSize } from '../../../components/CashbackCard/styles';
import { AllBrandsOffersWidgetLocation, AllBrandsWidget } from '../../../components/AllBrandsWidget';
import { AccountManageKarmaWalletCardSection } from '../../../components/AccountManageKarmaWalletCardSection';

interface IProps {
  className?: string;
}

export const AccountDashboardBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const hasKarmaWalletCard = user.hasKarmaWalletCard && user.cardsLoaded;
  const hasLinkedCards = user.hasCards && user.cardsLoaded;
    
  const onApplyClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_ApplyForCard_Click');
  }, []);

  return (
    <AccountDashboardContainer className={ className }>
      {
        !!hasLinkedCards && !hasKarmaWalletCard && !user.hasUnpaidMembership
          ? <ApplyForCardWidget className='full-width' onApplyClick={ onApplyClick } />
          : null
      }
      <AccountManageKarmaWalletCardSection hasCard={ hasKarmaWalletCard } />
      <ConnectedAccountsWidget className='full-width' />
      <div className='half-width-container'>
        <LatestTransactionsWidget className='half-width' />
        <CashbackWidget className='half-width' />
      </div>
      <OffersHeaderContainer>
        <AccountHubH2LG>Deals & Offers</AccountHubH2LG>
        <PillTag size={ PillTagSize.Medium } kind={ PillTagKind.ForMembers }>
          For Karma Wallet Members
          <img alt='Karma Wallet App and Card' className='karma-card' src={ KarmaCardImage } />
        </PillTag>
      </OffersHeaderContainer>
      <KarmaCollectiveWidget
        className='full-width'
        location={ KarmaCollectiveOffersWidgetLocation.Dashboard }
        hideCardsCTA={ true }
        numberOfCards={ 6 }
        cardsSize={ CardSize.Small }
      />
      <AllBrandsWidget
        className='full-width'
        location={ AllBrandsOffersWidgetLocation.Dashboard }
        cardsSize={ CardSize.Regular }
      />
    </AccountDashboardContainer>
  );
};

export const AccountDashboard = observer(AccountDashboardBase);
