import styled, { css } from 'styled-components';
import { MaxBoundarySectionLarge } from '../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';

const ScrollContainerStyles = css`
  overflow: auto;
  padding-bottom: 10px;
  scrollbar-width: thin;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    scrollbar-width: auto;

    ::-webkit-scrollbar {
      background: transparent;
      color: transparent;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.grey3};
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.grey4};
    }
  }

  @media (min-width: 1280px) {
    justify-content: space-between;
  }
`;

export const VideosAndArticlesSectionContainer = styled(MaxBoundarySectionLarge)`
  .header {
    margin-bottom: 40px;
  }
`;

export const ArticlesContainer = styled.div`
  ${FlexHorizontalCenter}
  ${ScrollContainerStyles}
  gap: 24px;
`;

export const ArticleContainer = styled.div`
  ${FlexCol}
  align-items: start;
  gap: 22px;

  img {
    max-width: 291px;
  }
`;

export const ArticleTextContainer = styled.div`
  ${FlexCol}
  align-items: start;
  gap: 12px;
  max-width: 263px;

  .read-more {
    font-weight: 600;
    padding: 6px 24px;
  }
`;

export const VideosContainer = styled.div`
  ${FlexRow}
  ${ScrollContainerStyles}
  align-items: start;
  gap: 24px;
  margin-bottom: 56px;
`;

export const VideoWrapper = styled.div`
  ${FlexColCenter}
  gap: 16px;

  .title {
    max-width: 250px;
  }
`;

export const VideoContainer = styled.iframe`
  border: 0;
  border-radius: 9px;
  height: 444px;
  width: 250px;
`;
