import React, { useEffect, useMemo, useState } from 'react';
import { OfferContainer, OffersContainer, TopBrandsOffersSectionContainer, TopBrandsOffersSectionInnerContainer } from './styles';
import { H4 } from '../../styles/components/header';
import { theme } from '../../styles/themes';
import { CompanyModel } from '../../models/companies';
import { PillTag } from '../PillTag';
import { PillTagKind, PillTagSize } from '../PillTag/styles';

interface IProps {
  className?: string;
}

interface IOffer {
  companyId: string;
  companyName: string;
  CompanyLogo: string;
}

const offersData: IOffer[] = [
  {
    companyId: '62deee5e7b212526d1e01fe3',
    companyName: 'Merit',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/vg7rlqEWiM-merit-1.png',
  },
  {
    companyId: '621b994b5f87e75f5365b1f5',
    companyName: 'Olly',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/bcUNzrl5HP-olly-1.png',
  },
  {
    companyId: '621b99b15f87e75f5365ed81',
    companyName: 'Burt’s Bees',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/SdssiynsQq-burtsbees-1.svg',
  },
  {
    companyId: '621b993f5f87e75f5365aaed',
    companyName: 'Thrive Market',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/gZXyvvlTN8-thrive-1.svg',
  },
  {
    companyId: '621b99315f87e75f5365a341',
    companyName: 'Columbia',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/7YmPSZeOSD-columbia-1.svg',
  },
  {
    companyId: '621b994b5f87e75f5365b1e1',
    companyName: 'Bombas',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/CA1ce4Tmhn-bombas-1.svg',
  },
  {
    companyId: '621b99505f87e75f5365b4b9',
    companyName: 'AllBirds',
    CompanyLogo: 'https://cdn.karmawallet.io/uploads/63RfztG3lO-allbirds-1.svg',
  },
];

export const TopBrandsOffersSection: React.FC<IProps> = ({
  className,
}) => {
  const [companiesOffers, setCompaniesOffers] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchCashbackOffers = async () => {
      try {
        const companiesCashbackOffers: Record<string, string> = {};

        const getCashbackOffers = offersData.map(async (offer) => {
          try {
            const company = await CompanyModel.loadCompany(offer.companyId);
            companiesCashbackOffers[offer.companyId] = company?.maxDescription || 'Coming Soon';
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error loading company ${offer.companyId}`, error);
          }
        });

        await Promise.all(getCashbackOffers);
        setCompaniesOffers(companiesCashbackOffers);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error Loading Cashback Offers', error);
      }
    };

    fetchCashbackOffers();
  }, []);

  const offers = useMemo(() => (
    <OffersContainer>
      {
        offersData.map(({ companyId, companyName, CompanyLogo }) => (
          <OfferContainer key={ companyId }>
            <img src={ CompanyLogo } alt={ companyName } />
            <PillTag
              className='cashback-pill'
              kind={ PillTagKind.CashbackLightGreen }
              size={ PillTagSize.Small }
            >
              { companiesOffers[companyId] || 'Coming Soon' }
            </PillTag>
          </OfferContainer>
        ))
      }
    </OffersContainer>
  ), [offersData, companiesOffers]); 

  return (
    <TopBrandsOffersSectionContainer className={ className } backgroundColor={ theme.colors.green2 }>
      <TopBrandsOffersSectionInnerContainer>
        <H4>Up to 20% Cashback** with Top Brands</H4>
        { offers }
      </TopBrandsOffersSectionInnerContainer>
    </TopBrandsOffersSectionContainer>
  );
};
