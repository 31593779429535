import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useUserSession } from '../../../contexts/user';
import { useNavigate } from 'react-router';
import { SessionEndingModalContainer } from './styles';
import { ButtonKind } from '../../Button/styles';

interface ISessionEndingModalProps {
  onClose?: () => void;
  className?: string;
  isOpen?: boolean;
}

const SessionEndingModalBase: React.FC<ISessionEndingModalProps> = ({
  className,
  isOpen,
}) => { 
  const user = useUserSession();
  const navigate = useNavigate();
  const [countDown, setCountdown] = useState(30);

  useEffect(() => {
    // prevent scrolling when this modal is open
    document.body.classList[user.sessionIsIdle ? 'add' : 'remove']('no-scroll');
  }, [user.sessionIsIdle]);

  const onInactivity = () => {
    user.logout();
    user.setSessionIsIdle(false);
    navigate('/?signin=true');
  };

  const onStayLoggedInClick = (): any => {
    user.setSessionIsIdle(false);
  };

  useEffect(() => {
    if (countDown <= 0) onInactivity();

    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  const CTAs = [
    {
      id: 'stay-logged-in',
      label: 'Stay Logged In',
      kind: ButtonKind.Primary,
      onClick: onStayLoggedInClick,
      text: 'Stay Logged In',
    },
    {
      id: 'log-out',
      label: 'Log Out',
      onClick: onInactivity,
      kind: ButtonKind.QuaternaryGhost,
      text: 'Log Out',
    },
  ];
  
  return (
    <SessionEndingModalContainer
      className={ className }
      isOpen={ user.sessionIsIdle || isOpen }
      onClose={ onInactivity }
      ctas={ CTAs }
    >
      <div className='modal-content'>
        <h1>Session Ending</h1>
        <p>Your session is ending in { countDown } seconds due to inactivity.</p>
      </div>
    </SessionEndingModalContainer>
  );
};

export const SessionEndingModal = observer(SessionEndingModalBase);
