import styled from 'styled-components';
import { ScreenReaderOnly } from '../../styles/styles';
import { ChevronIcon } from '../svgs/icons/ChevronIcon';

interface IProps {
  labelAlignment?: string;
}

export const USStatesDropdownContainer = styled.div<IProps>`
  label {
    display: block;
    margin: 0 0 5px 10px;
    color: ${({ theme }) => theme.colors.midGray};
    font-weight: 500;
    ${({ labelAlignment }) => `text-align: ${labelAlignment};`}
    
    &.regular-label {
      margin-bottom: 10px;
    }

    &.pill-label {
      margin: 0 0 5px 10px;
    }

    &.sr-only {
      ${ScreenReaderOnly}
    }
  }

  .field-text, .field-error {
    margin: 5px 0 0;
    padding-left: 10px;
    text-align: left;
  }

  .field-error {
    color: ${({ theme }) => theme.colors.danger};
    margin-bottom: 0;
  }

  .field-text {
    padding-left: 25px;
    text-align: left;
  }

  .placeholder {
    color: ${({ theme }) => theme.colors.disabled};
  }
`;

export const SelectFieldContainer = styled.div`
  position: relative;
`;

export const SelectField = styled.select`
  appearance: none;
  border-radius: 99px;
  border-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.dark};
  height: 45px;
  padding: 0 11px;
  width: 100%;
  --webkit-appearance: none;
`;

export const SelectChevronIcon = styled(ChevronIcon)`
  height: auto;
  position: absolute;
  pointer-events: none;
  right: 17px;
  stroke: ${({ theme }) => theme.colors.dark};
  top: 17px;
  width: 7px;
`;
