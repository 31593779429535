import React, { useEffect } from 'react';
import { MembershipCardContainer } from './styles';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { membershipPriceAtom } from '../../models/membership/atom';
import { loadMembershipPrice } from '../../models/membership/api';
import { useAppData } from '../../contexts/app';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  kind?: 'light' | 'dark'
}

export const MembershipCard: React.FC<IProps> = ({
  className,
  children,
  kind,
}) => {
  const [searchParams] = useSearchParams();
  const membershipPrice = useRecoilValue(membershipPriceAtom);
  const appData = useAppData();
  
  useEffect(() => {
    const membershipPromoCode = searchParams.get('membershipPromoCode');
    loadMembershipPrice(membershipPromoCode);
  }, []);
  
  return (
    <MembershipCardContainer className={ className } kind={ kind }>
      <div>
        <p className='amount'>
          { membershipPrice.price }
          <span className='period'> { appData.isDesktop ? 'per ' : '/' }year</span>
        </p>
      </div>
      { children }
    </MembershipCardContainer>
  );
};
