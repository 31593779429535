import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { BreakdownItemContainer, KarmaScorePieChartContainer, MonthScoreContainer, RatingBreakdownPieChartContainer, RatingRangeBreakdownContainer } from './styles';
import { IRatings, ImpactModel, Ratings } from '../../../../../models/impact';
import { UserMonthlyImpactReportModel } from '../../../../../models/userMonthlyImpactReport';
import { H5 } from '../../../../../styles/components/header';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../../../styles/themes';

interface IProps extends IThemeProps{
  className?: string;
  activeMonthReport: UserMonthlyImpactReportModel;
  impactRanges: IRatings;
}

const KarmaScorePieChartBase: React.FC<IProps> = ({
  className = '',
  activeMonthReport,
  impactRanges,
  theme,
}) => {
  const ratingRange = useMemo(() => ImpactModel.getUserImpactScoreRange(impactRanges, parseInt(activeMonthReport?.impact?.score.toFixed(0))), [impactRanges, activeMonthReport]);

  const ratingBreakdownData = useMemo(() => [
    {
      rating: Ratings.Negative,
      percentage: +(activeMonthReport?.impact?.negative * 100).toFixed(0),
      fill: theme.colors.negative,
    },
    {
      rating: Ratings.Positive,
      percentage: +(activeMonthReport?.impact?.positive * 100).toFixed(0),
      fill: theme.colors.positive,
    },
    {
      rating: Ratings.Neutral,
      percentage: +(activeMonthReport?.impact?.neutral * 100).toFixed(0),
      fill: theme.colors.neutral,
    },
  ], [activeMonthReport]); 

  const getBackgroundColor = useCallback((rating: Ratings) => {
    if (rating === Ratings.Positive) return theme.colors.green;
    if (rating === Ratings.Negative) return theme.colors.danger;
    return theme.colors.neutral;
  }, []);

  const renderRatingBreakdownPieChart = () => {
    if (!activeMonthReport) return null;

    return (
      <RatingBreakdownPieChartContainer>
        <ResponsiveContainer width='100%' height='100%' className='radial-chart-container'>
          <PieChart>
            <Pie
              className='radial-chart'
              dataKey='percentage'
              data={ ratingBreakdownData }
              cx={ '50%' }
              cy={ '50%' }
              innerRadius={ 75 }
              rotate={ 135 }
              outerRadius={ 102 }
              isAnimationActive={ false }
            />
          </PieChart>
        </ResponsiveContainer>
        <MonthScoreContainer background={ getBackgroundColor(ratingRange) }>
          <h3>
            { activeMonthReport.impact.score.toFixed(0) }
          </h3>
          <p>Neutral</p>
        </MonthScoreContainer>
      </RatingBreakdownPieChartContainer>
    );
  };

  const renderRatingBreakdownPercentages = () => {
    if (!activeMonthReport) return null;

    return (
      <RatingRangeBreakdownContainer>
        {
          ratingBreakdownData.map((data) => (
            <BreakdownItemContainer key={ data.rating }>
              <div className={ `rating-color ${data.rating}` } />
              <p>{ `${data.rating} (${ data.percentage }%)` }</p>
            </BreakdownItemContainer>
          ))
        }
      </RatingRangeBreakdownContainer>
    );
  };

  return (
    <KarmaScorePieChartContainer className={ className }>
      <H5>{ activeMonthReport?.date?.format('MMMM YYYY') }</H5>
      { renderRatingBreakdownPieChart() }
      { renderRatingBreakdownPercentages() }
    </KarmaScorePieChartContainer>
  );
};

const KarmaScorePieChartObserver = observer(KarmaScorePieChartBase);
export const KarmaScorePieChart = withTheme(KarmaScorePieChartObserver);
