import React from 'react';
import { ArticleContainer, ArticlesContainer, ArticleTextContainer, VideoContainer, VideosAndArticlesSectionContainer, VideosContainer, VideoWrapper } from './styles';
import { H2MobileMarketing, H6 } from '../../styles/components/header';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps {
  className?: string;
}

interface IVideos {
  title: string;
  url: string;
  id: string;
} 

interface IArticle {
  title: string;
  image: string;
  url: string;
  id: string;
}

const videosData: IVideos[] = [ 
  {
    title: 'How To: Sign Up for the Karma Wallet Card',
    url: 'https://www.youtube.com/embed/Y-IFGzmeNpE?si=eSnEQ8wrse3BiW8w&',
    id: '1',
  },
  {
    title: 'How To: Set Up Your Karma Wallet Card',
    url: 'https://youtube.com/embed/lGp5UkORoiI',
    id: '2',
  },
  {
    title: 'All About the New Karma Wallet Card',
    url: 'https://youtube.com/embed/Jbtf3Yy298Q',
    id: '3',
  },
  {
    title: 'How To: Transfer Money Onto Your Card',
    url: 'https://youtube.com/embed/w_pwi6ou8mk',
    id: '4',
  },
  {
    title: 'Benefits of the Karma Wallet Card',
    url: 'https://youtube.com/embed/cEtpaLyu_Nc',
    id: '5',
  },
];

const articlesData: IArticle[] = [
  {
    title: 'How To: Sign Up for the Karma Wallet Card',
    image: 'https://cdn.karmawallet.io/uploads/F35Zoz4-vB-how-to-sign-up-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/07/how-to-sign-up-for-the-karma-wallet-card/',
    id: '1',
  },
  {
    title: 'What is a prepaid reloadable debit card?',
    image: 'https://cdn.karmawallet.io/uploads/NSIeibFHcv-prepaid-debit-card-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/02/what-is-a-prepaid-reloadable-debit-card/',
    id: '2',
  },
  {
    title: 'Get Rewarded: Karma Wallet Rewards',
    image: 'https://cdn.karmawallet.io/uploads/_x8k8iCjDV-card-and-gas-pump-photo.png',
    url: 'https://karmawallet.io/blog/2024/04/get-rewarded-karma-wallet-rewards/',
    id: '3',
  },
  {
    title: 'Compare Prepaid Debit Cards: Karma Wallet Card vs Others',
    image: 'https://cdn.karmawallet.io/uploads/Iy0wDmua84-compare-cards-article-photo.png',
    url: 'https://karmawallet.io/blog/2023/08/compare-eco-friendly-debit-cards-karma-wallet-card-vs-others/',
    id: '4',
  },
];

export const VideosAndArticlesSection: React.FC<IProps> = ({
  className,
}) => {
  const analytics = useAnalytics();

  const onReadMoreClick = (id: string) => {
    analytics.fireEvent(`AppPage_ReadMore_Article_${id}_Click`);
  };

  const videosContent = (
    <VideosContainer>
      {
        videosData.map(({ title, url, id }) => (
          <VideoWrapper key={ id }>
            <VideoContainer
              src={ url }
              title={ title }
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            />
            <H6 className='title'>{ title }</H6>
          </VideoWrapper>
        ))
      }
    </VideosContainer>
  );

  const articlesContent = (
    <ArticlesContainer>
      {
        articlesData.map(({ title, image, url, id }) => (
          <ArticleContainer key={ id }>
            <img alt={ `${title} Photo` } src={ image } />
            <ArticleTextContainer>
              <H6>{ title }</H6>
              <ButtonLink
                className='read-more'
                kind={ ButtonKind.PrimaryGhost }
                href={ url }
                onClick={ () => onReadMoreClick(id) }
                target='_blank'
              >
                Read More
              </ButtonLink>
            </ArticleTextContainer>
          </ArticleContainer>
        ))
      }
    </ArticlesContainer>
  );
  
  return (
    <VideosAndArticlesSectionContainer className={ className }>
      <H2MobileMarketing className='header'>Video Walkthroughs</H2MobileMarketing>
      { videosContent }
      <H2MobileMarketing className='header'>Learn More</H2MobileMarketing>
      { articlesContent }
    </VideosAndArticlesSectionContainer>
  );
};
