import styled from 'styled-components';
import { AccountHubBasicContainer, FlexCol, FlexRow } from '../../../../styles/styles';

export const CarbonSectionContainer = styled.div`
  ${AccountHubBasicContainer}
  width: 100%;

  .header {
    margin-bottom: 32px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      margin-bottom: 60px;
    }
  }

  .cta-container {
    ${FlexRow}
    justify-content: center;
    margin: auto;
    max-width: 290px;

    .offset-emissions {
      text-align: center;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin: 30px auto 0;
    }
  }
`;

export const CardsSection = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
  }

  .content-card {
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 30px;
    width: 100%;

    &:first-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
      padding-top: 0;
    }

    &:last-of-type {
      padding-top: 30px;
    }

    .header {
      text-align: center;
    }

    .text-section {
      max-width: 400px;
    }

    .supporting-stat {
      height: unset;
    }

    svg {
      height: 50px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: unset;
      width: 50%;

      &:first-of-type {
        border-bottom: none;
        border-right: 1px solid ${({ theme }) => theme.colors.grey2};
      }

      &:last-of-type {
        padding-top: 0;
      }
    }
  }
`;
