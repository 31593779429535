import { observer } from 'mobx-react-lite';
import React from 'react';
import { AllBrandsOffersContainer, HeaderWrapper } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { ChevronDirection, ChevronIcon } from '../../../components/svgs/icons/ChevronIcon';
import { CardSize } from '../../../components/CashbackCard/styles';
import { AllBrandsOffersWidgetLocation, AllBrandsWidget } from '../../../components/AllBrandsWidget';

interface IProps extends IThemeProps {
  className?: string;
}

const AllBrandsOffersPageBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onBackToDashboardClick = () => {
    analytics.fireEvent('AllBrandsOffers_BackToDashboard_Click');
  };

  return (
    <AllBrandsOffersContainer className={ className }>
      <HeaderWrapper>
        <ButtonLink
          className='back-to-dashboard-button'
          kind={ ButtonKind.Blank }
          href='/account/dashboard'
          label='Back to Dashboard'
          onClick={ onBackToDashboardClick }
        >
          <ChevronIcon direction={ ChevronDirection.Left } stroke={ theme.colors.dark } />
        </ButtonLink>
      </HeaderWrapper>
      <AllBrandsWidget
        location={ AllBrandsOffersWidgetLocation.AllBrandsOffersPage }
        cardsSize={ CardSize.Regular }
      />
    </AllBrandsOffersContainer>
  );
};

const AllBrandsOffersPageObserver = observer(AllBrandsOffersPageBase);
export const AllBrandsOffersPage = withTheme(AllBrandsOffersPageObserver);
