import styled from 'styled-components';
import { MaxBoundarySectionLarge } from '../../styles/components/containers';
import { FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextMDDemiBold } from '../../styles/components/text';

export const SafetyAndSecuritySectionContainer = styled(MaxBoundarySectionLarge)``;

export const TopSectionContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: auto;
  max-width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    max-width: 80%;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 1000px;
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {

    .safety-phone {
      margin-top: 40px;
    }
  }
`;

export const LeftContent = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  text-align: left;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    align-items: flex-start;
    margin-left: 0;
  }
`;

export const PartnershipsContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;
  gap: 20px 40px;
  justify-content: center;
  margin-top: 24px;
  max-width: 560px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    justify-content: flex-start;
  }
`;

export const BottomSectionContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 8px;
  width: 100%;
`;

export const DataItem = styled.div`
  ${FlexColCenter}
  ${TextMDDemiBold}
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  line-height: 23px;
  max-width: 213px;
  min-height: 85px;
  padding: 8px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    max-width: 90%;
    padding: 8px 34px;
    width: 90%;
  }
`;
