import styled from 'styled-components';
import { AccountHubBasicContainer, FlexRow } from '../../styles/styles';
import { AccountHubH2 } from '../../styles/components/header';

export const ImpactReportTransactionsContainer = styled.div`
  ${AccountHubBasicContainer}

  .no-transactions-text, .error-loading-text {
    text-align: center;
    color: ${({ theme }) => theme.colors.grey2};
  }
`;

export const ImpactReportTransactionsHeaderContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;
`;

export const ImpactReportTransactionsHeader = styled(AccountHubH2)``;
