import { observer } from 'mobx-react';
import React, { useCallback, useRef } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { PayPalModel } from '../../models/paypal';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { PayPal } from '../Paypal';
import { PaypalSectionContainer, UserInfoWrapper } from './styles';

interface IProps {
  className?: string;
  onClick?: () => void;
  ref?: React.LegacyRef<HTMLElement>;
}

const PaypalSectionBase: React.FC<IProps> = React.forwardRef(({ className = '' }, ref) => {
  const analytics = useAnalytics();
  const userModel = useUserSession();
  const paypalModel = useRef(new PayPalModel()).current;

  const onDisconnectHandler = useCallback(async () => {
    analytics.fireEvent('Disconnect_Paypal_Account_Click');

    try {
      const unlinkResponse = await paypalModel.unlinkPayPalAccount();
      const { user } = unlinkResponse;
      analytics.fireEvent('PayPal_Unlink_Success');
      userModel.updateUserSession(user);
    } catch (err) {
      analytics.fireEvent('PayPal_Unlink_Error');
    }
  }, []);

  const onLinkHandler = () => {
    analytics.fireEvent('Link_Paypal_Account_Click');
  };

  const renderUserPayPalInfo = useCallback(() => {
    // need to update this with the email that comes back from PayPal
    const link = (
      <div className='user-info-container'>
        <span>{ userModel?.payPalEmail }</span>
        <Button
          className='disconnect-paypal'
          onClick={ onDisconnectHandler }
          kind={ ButtonKind.Blank }
        >
          Disconnect PayPal Account
        </Button>
      </div>
    );

    return (
      <UserInfoWrapper className={ userModel?.hasPayPal ? 'linked' : '' }>
        <img
          className='paypal-logo'
          src='https://cdn.karmawallet.io/uploads/ZzNGqfhDAy-paypal-logo.png'
          alt='PayPal logo'
        />
        {
          userModel?.hasPayPal
            ? link 
            : <PayPal onClick={ onLinkHandler } />
        }
      </UserInfoWrapper>
    );
  }, [userModel.hasPayPal, userModel.payPalEmail]);

  return (
    <PaypalSectionContainer ref={ ref } className={ className }>
      { renderUserPayPalInfo() }
    </PaypalSectionContainer>
  );
});

export const PaypalSection = observer(PaypalSectionBase);
