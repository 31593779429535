import styled from 'styled-components';
import { FlexColCenter, FlexRow } from '../../../../../styles/styles';

interface IMonthScoreContainerProps {
  background: string;
}

export const KarmaScorePieChartContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  max-width: 390px;
  width: 100%;

  h5 {
    font-size: 18px;
  }

  .radial-chart {
    trasform: rotate(135deg);
  }
`;

export const RatingRangeBreakdownContainer = styled.div`
  ${FlexRow}
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 5px;
  gap: 10px;
  justify-content: space-between;
  max-width: 390px;
  padding: 16px;
  text-align: center;
  width: 100%;
`;

export const BreakdownItemContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  font-size: 13px;
  font-weight: 600;

  p {
    line-height: .75rem;
    margin: unset;
    margin-top: 5px;
    text-transform: capitalize;
  }

  .rating-color {
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }

  .neutral {
    background-color: ${({ theme }) => theme.colors.neutral};
  }

  .positive {
    background-color: ${({ theme }) => theme.colors.positive};
  }
  .negative {
    background-color: ${({ theme }) => theme.colors.negative};
  }
`;

export const RatingBreakdownPieChartContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 10px;
  height: 250px;
  position: relative;
  rotate: 90deg;
  width: 250px;
`;

export const MonthScoreContainer = styled.div<IMonthScoreContainerProps>`
  ${FlexColCenter}
  align-items: center;
  background: ${ props => props.background };
  border-radius: 50%;
  height: 36%;
  left: 50%;
  position: absolute;
  rotate: -90deg;
  top: 50%;
  transform: translate(50%, -50%);
  width: 36%;

  h3 {
    font-size: 39px;
    font-weight: 700;
    line-height: 1rem;
    margin-top: 15px;
  }

  p {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
  }
`;
