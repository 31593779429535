import styled from 'styled-components';
import { FlexRow } from '../../../../../styles/styles';
import { TextSMDemiBold } from '../../../../../styles/components/text';

export const DateSelectorContainer = styled.div`
  ${FlexRow};
  gap: 10px;
  justify-content: center;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    justify-content: flex-end;
  }
`;

export const DateSelectContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey3};
  min-width: 113px;
  max-width: 120px;
  padding-right: 10px;
`;

export const DateSelect = styled.select`
  ${TextSMDemiBold}
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 15px 7px;
  width: 100%;
`;
