import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

export interface IGenericTagProps {
  backgroundColor: string;
}

export const GenericTagContainer = styled.div<IGenericTagProps>`
  ${FlexCenter}
  align-self: flex-start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  height: 24px;
  margin-bottom: 10px;
  padding: 12px 10px 10px 10px;
  text-transform: uppercase;
`;
