import styled from 'styled-components';
import { BasicContainer, FlexCol, SecondaryFont } from '../../styles/styles';

export const StatCardContainer = styled.div`
  ${BasicContainer}
  ${FlexCol}
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  background-color: #fff;

  .overview-stats {
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;

      P {
        margin-bottom: 0px;
      }
    }

    .label {
      ${SecondaryFont}
      font-weight: normal;
      line-height: 22px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.darkGray1};
      margin-bottom: 5px;

      div {
        text-align: center;
        font-size: 20px;
      }
    }

    .account-card-label {
      div {
        color: ${({ theme }) => theme.colors.grey5};
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 6px;
      }
    }

    .tonnes {
      color: ${({ theme }) => theme.colors.grey5};
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      margin-top: 6px;
    }

    .amount { 
      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        margin-bottom: 0;
        font-weight: 700;
        color: black;
      }
    }
  }

  .supporting-stat {
    ${FlexCol}
    text-align: center;
    align-items: center;
    justify-content: start;
    height: 70px;

    .image-container {
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.danger};

      img, svg {
        max-height: 100%;
        object-fit: fill;
      }
    }

    .garbage-truck {
      max-height: 100%;
    }

    .body {
      text-align: center;
      margin-bottom: 0;
    }

    .account-card-body {
      color: ${({ theme }) => theme.colors.grey5};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .header {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .account-card-header {
      div {
        color: ${({ theme }) => theme.colors.black};
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 4px;
      }

      sub {
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;
