import styled from 'styled-components';
import { Page } from '../../styles';
import { FlexRow } from '../../../../styles/styles';
import { TextLGDemiBold, TextXS, TextXSBold } from '../../../../styles/components/text';

export const PageOneContainer = styled(Page)`
  .continue-cta {
    ${TextLGDemiBold}
    margin-bottom: 30px;
  }
`;

export const CheckboxContainer = styled.span`
  ${FlexRow}
  align-items: flex-start;
  justify-content: flex-start;

  label {
    padding-left: 10px;
  }

  p, a {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 12px;
    line-height: 17px;
  }

  .agree-checkbox {
    accent-color: ${({ theme }) => theme.colors.primary};
    margin-top: 5px;
  }

  .amount-text {
    font-weight: 600;
  }
`;

export const InputFieldsContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px 24px;
`;

export const LogInContainer = styled.span`
  ${TextXS}
  display: inline;
  font-size: 13px;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.grey4};
    display: inline;
  }

  button {
    ${TextXSBold}
    color: ${({ theme }) => theme.colors.primary};
    display: inline;
    font-size: 13px;
    margin-left: 5px;
    text-decoration: underline;
    width: unset;
  }

  &.not-ready {
    margin-top: 26px;
  }
`;
