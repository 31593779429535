import React, { useCallback } from 'react';
import { LinkingOptions, NoCardsLinkedInfoWrapper, NoLinkedCardsContainer } from './styles';
import { ApplyForCardWidget } from '../../ApplyForCardWidget';
import { ButtonKind } from '../../Button/styles';
import { PlaidButton } from '../../PlaidButton';
import { PlaidDisclaimer } from '../../PlaidDisclaimer';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useUserSession } from '../../../contexts/user';

interface IProps {
  className?: string;
}

export const NoLinkedCards: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  
  const onApplyClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_ConnectedAccounts_ApplyForCard_Click');
  }, []);

  const onLinkCardClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_ConnectedAccounts_LinkCard_Click');
  }, []);

  const onLinkSuccess = useCallback(() => {
    analytics.fireEvent('AccountDashboard_ConnectedAccounts_Plaid_Success');
  }, []);
  
  return (
    <NoLinkedCardsContainer className={ className }>
      <NoCardsLinkedInfoWrapper>
        <p>❗You currently have no cards linked. To see your personal Karma Score, apply for a Karma Wallet card or link cards you use frequently.</p>
      </NoCardsLinkedInfoWrapper>
      <LinkingOptions>
        {
          !user.hasUnpaidMembership
            ? <ApplyForCardWidget className='apply-section' onApplyClick={ onApplyClick } />
            : null
        }
        <div className={ `plaid-section ${user.hasUnpaidMembership ? 'full-width' : '' }` }>
          <PlaidDisclaimer className='plaid-disclaimer' />
          <PlaidButton
            className='link-cards-button'
            kind={ ButtonKind.PrimaryGhost }
            onClick={ onLinkCardClick }
            onPlaidSuccess={ onLinkSuccess }
          >
            Link Cards
          </PlaidButton>
        </div>
      </LinkingOptions>
    </NoLinkedCardsContainer>
  );
}; 
