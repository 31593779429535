import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { ApplyForCardContainer, ContentWrapper, FormWrapper, InnerWrapper } from './styles';
import { withTheme } from 'styled-components';
import { LeftRail } from './LeftRail';
import { useSearchParams } from 'react-router-dom';
import { SocketRoomTypeEnum } from '../../constants/socket';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import useSocketOn from '../../hooks/useSocketOn';
import useSocketRoom from '../../hooks/useSocketRoom';
import { validateEmail } from '../../lib/formatValidators';
import { ApplicationLocalStorageKey, ApplyForCardModel, CurrentPage, TemplateIdForPage } from '../../models/karmaCard';
import { ISocketEvent } from '../../models/socket-client';
import { IUrlParam } from '../../models/users';
import { IThemeProps } from '../../styles/themes';
import { AnalyticsModel } from '../../models/analytics';
import { ApplyHeader } from './rightRailContent/ApplyHeader';
import { PageOne } from './rightRailContent/PageOne';
import { PageTwo } from './rightRailContent/PageTwo';
import { PaymentPage } from './rightRailContent/PaymentPage';
import { PendingPage } from './rightRailContent/PendingPage';
import { ApplyPersonaModal } from './ApplyPersonaModal';
import { ApplyLoadingOverlay } from './ApplyLoadingOverlay';
import { ApplyDisclaimer } from './ApplyDisclaimer';
import { CardholderView } from './CardholderView';
import { AbandonedPage } from './rightRailContent/AbandonedPage';
import { ApplyProgressBar } from './rightRailContent/ApplyProgressBar';
import { SuccessPage } from './rightRailContent/SuccessPage';
import { getLocalStorageItemWithExpiry } from '../../lib/localStorage';
import { ArticlesSection } from './ArticlesSection';
import { BenefitsSection } from './BenefitsSection';
import { GroupModel } from '../../models/group';
import { DeclinedPage } from './rightRailContent/DeclinedPage';

interface IProps extends IThemeProps {
  className?: string;
}

export const getExistingApplication = async (applyModel: ApplyForCardModel, analyticsModel: AnalyticsModel) => {
  try {
    await applyModel.getLatestApplicationDecision(true);
  } catch (e) {
    analyticsModel.fireEvent('Get_Existing_Application_No_Existing_Application_Found');
  }
};

export const ApplyForCardBase: React.FC<IProps> = ({ className = '' }) => {
  const pageOneRef = useRef(null);
  const analytics = useAnalytics();
  const userSession = useUserSession();
  const applyForCardModel = useRef(new ApplyForCardModel()).current;
  const [group, setGroup] = useState<GroupModel>(null);
  const [searchParams] = useSearchParams();

  const getGroup = async () => {
    const groupCode = searchParams.get('groupCode');
    if (!groupCode) return;
    const group = await GroupModel.getGroupByCode(groupCode);
    if (!group) return;
    setGroup(group);
  };

  const onSocketEvent = ({ type, data }: ISocketEvent) => {
    if (type === 'cardApplicationDecision') {
      if (!data || !data.status) return;
      applyForCardModel.setCurrentPageFromApplicationDecision(data, false);
    }
  };

  useSocketOn('update', onSocketEvent);

  useSocketRoom(
    SocketRoomTypeEnum.CardApplication,
    (() => (validateEmail(applyForCardModel.email) && applyForCardModel.startConnection ? applyForCardModel.email : ''))(),
    [applyForCardModel.email, applyForCardModel.startConnection],
  );

  useEffect(() => {
    if (userSession.isLoggedIn) {
      const userEmail = userSession.emails.find((email) => email.primary).email;
      applyForCardModel.setEmail(userEmail);  
      applyForCardModel.setConfirmationEmail(userEmail);
    } else {
      const email = getLocalStorageItemWithExpiry(ApplicationLocalStorageKey.email);
      applyForCardModel.setEmail(email || '');
      applyForCardModel.setConfirmationEmail(email || '');
    }
  }, [userSession.isLoggedIn]);

  useEffect(() => {
    (async () => {
      if (!applyForCardModel.email || !applyForCardModel.confirmationEmail || applyForCardModel.email !== applyForCardModel.confirmationEmail) return;

      const pageBeforeFetchingPendingApplication = applyForCardModel.currentPage;
      await getExistingApplication(applyForCardModel, analytics);

      if (pageBeforeFetchingPendingApplication !== applyForCardModel.currentPage) {
        applyForCardModel.restartSocketConnection();
      }

      await applyForCardModel.loadPersonaAccountId();
    })();
  }, [applyForCardModel.email, applyForCardModel.confirmationEmail, applyForCardModel.currentPage]);

  const updateUserParams = async (paramsArray: IUrlParam[]) => {
    if (userSession.isLoggedIn) {
      await userSession.updateProfile({
        referralParams: paramsArray,
      });
    }
  };

  useEffect(() => {
    const paramsArray: IUrlParam[] = [];
    if (!!searchParams) {
      searchParams.forEach((value: string, key: string) => {
        paramsArray.push({ key, value });
      });
    }
    applyForCardModel.setUrlParams(paramsArray);

    if (userSession.isLoggedIn) {
      updateUserParams(paramsArray);
    }

    getGroup();
  }, [searchParams, userSession.isLoggedIn]);

  const renderCurrentPage = useCallback(() => {
    switch (applyForCardModel.currentPage) {
      case CurrentPage.PageOne:
        return <PageOne group={ group } applyModel={ applyForCardModel } />;
      case CurrentPage.PageTwo_CollectDocs:
        return <PageTwo applyModel={ applyForCardModel } templateId={ TemplateIdForPage[CurrentPage.PageTwo_CollectDocs] } />;
      case CurrentPage.Pending:
        return <PendingPage />;
      case CurrentPage.Abandoned:
        return <AbandonedPage />;
      case CurrentPage.AbandonedWithInfo:
        return <AbandonedPage pageType={ CurrentPage.AbandonedWithInfo } />;
      case CurrentPage.ReturningAbandonedWithInfo:
        return <AbandonedPage pageType={ CurrentPage.ReturningAbandonedWithInfo } />;
      case CurrentPage.Payment:
        if (!applyForCardModel.stripeClientSecret) return null;
        return <PaymentPage clientSecret={ applyForCardModel.stripeClientSecret } />;
      case CurrentPage.Success:
        return <SuccessPage />;
      case CurrentPage.Declined:
        return <DeclinedPage />;
      default:
        return null;
    }
  }, [
    applyForCardModel.currentPage,
    applyForCardModel.email,
    applyForCardModel.applicationDecision,
    applyForCardModel.stripeClientSecret,
    group,
  ]);

  // Right Rail Content
  const rightRail = () => {
    if (userSession?.authenticating || userSession.loadingCards) return null;
    if (userSession.isLoggedIn && userSession.hasKarmaWalletCard) return <CardholderView />;

    return (
      <FormWrapper className='form-wrapper' ref={ pageOneRef }>
        <InnerWrapper>
          <ApplyLoadingOverlay display={ applyForCardModel.awaitingApplicationDecision } />
          <ApplyPersonaModal applyForCardModel={ applyForCardModel } />
          <ApplyProgressBar applyForCardModel={ applyForCardModel } />
          <ApplyHeader applyModel={ applyForCardModel } />
          { renderCurrentPage() }
          {
            !applyForCardModel.awaitingApplicationDecision && <ApplyDisclaimer applyForCardModel={ applyForCardModel } />
          }
        </InnerWrapper>
      </FormWrapper>
    );
  };

  return (
    <ApplyForCardContainer className={ `${className} ${applyForCardModel.currentPage}` } title='Apply For Card'>
      <ContentWrapper>
        <LeftRail
          page={ applyForCardModel.currentPage }
          group={ group }
        />
        { rightRail() }
      </ContentWrapper>
      {
        applyForCardModel.currentPage !== CurrentPage.Success ? (
          <>
            <ArticlesSection />
            <BenefitsSection />
          </>
        ) : null
      }
    </ApplyForCardContainer>
  );
};

export const ApplyForCardObserver = observer(ApplyForCardBase);
export const ApplyForCard = withTheme(ApplyForCardObserver);
