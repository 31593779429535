import styled, { css } from 'styled-components';
import { BasicContainer, FlexCenter, FlexColCenter, FlexRow } from '../../styles/styles';

interface IProps {
  accountCards: number;
}

export const MultipleCardsStyling = css`
  position: absolute;

  .connected-accounts-card {
    justify-content: flex-end;
  }
`;

export const BankCardsContainer = styled.div`
  ${FlexRow}
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  text-align: center;

  .plaid-button {
    all: unset;
    cursor: pointer;
  }

  .warning {
    position: absolute;
    top: 11px;
    left: 110px;
    z-index: 5;

    svg {
      width: 25px;
      height: 25px;
    }
  }
  
  .tiny-popover-anchor {
    position: absolute;
  }
`;

export const AccountCardsContainer = styled.div<IProps>`
  position: relative;
  padding-bottom: ${({ accountCards }) => `${accountCards > 1 ? (accountCards - 1) * 32 : 0}px`};

  .dashboard-card {
    width: unset;
  }

  .relink-card {
    .connected-accounts-card {
      border: solid 3px ${({ theme }) => theme.colors.red};
    }
  }

  .card-0 {
    position: relative;
    z-index: 4;
  }

  .card-1 {
    ${MultipleCardsStyling}
    top: 32px;
    z-index: 3;
  }

  .card-2 {
    ${MultipleCardsStyling}
    top: 64px;
    z-index: 2;
  }

  .card-3 {
    ${MultipleCardsStyling}
    top: 96px;
    z-index: 1;
  }

  .card-4 {
    ${MultipleCardsStyling}
    top: 128px;
    z-index: 0;
  }
`;

export const ErrorLoadingCards = styled.div`
  ${FlexCenter}
  color: ${({ theme }) => theme.colors.disabled};
  height: 100px;
`;

export const LoadingSpinnerContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: auto;
  position: relative;
`;

export const RelinkTooltip = styled.div`
  ${BasicContainer}
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  font-size: 12px;
  padding: 15px;
  text-align: center;
  width: 180px;

  p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .attention {
    font-weight: 500;
  }

  .re-link {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
