import React, { useCallback, useMemo } from 'react';
import { ButtonLink } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { IStatProps, StatCard } from '../../../../components/StatCard';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { UserMonthlyImpactReportModel } from '../../../../models/userMonthlyImpactReport';
import { CarbonSectionContainer, CardsSection } from './styles';
import { COCloud } from '../../../../components/svgs/graphics/COCloud';
import { RefreshIcon } from '../../../../components/svgs/icons/RefreshIcon';
import { theme } from '../../../../styles/themes';
import { AccountHubH3 } from '../../../../styles/components/header';

enum Type {
  Emissions = 'emissions',
  Offsets = 'offsets',
}

interface IProps {
  className?: string;
  activeMonthReport: UserMonthlyImpactReportModel;
}

export const CarbonSection: React.FC<IProps> = ({
  className = '',
  activeMonthReport,
}) => {
  const analytics = useAnalytics();
  const emissions = useMemo(() => activeMonthReport?.carbon?.monthlyEmissions, [activeMonthReport]);
  const offsets = useMemo(() => activeMonthReport?.carbon?.offsets?.totalOffset, [activeMonthReport]);

  const onOffsetEmissionsClick = useCallback(() => {
    analytics.fireEvent('UserImpactReport_OffsetEmissions_Click');
  }, []);

  const generateStat = useCallback((carbonType: Type) => {
    if (carbonType === Type.Emissions) {
      return { 
        label: <div>Your Emissions This Month</div>,
        amount: emissions > 0 ? emissions.toFixed(2) : '0',
      };
    }

    return { 
      label: <div>Your Offsets This Month</div>,
      amount: `${offsets === 0 ? '0' : offsets.toFixed(2)}`,
    };
  }, [offsets, emissions]); 

  const offsetsContent = useMemo(() => {
    const offsetsContent: IStatProps = {
      header: <div>{ offsets.toFixed(2) } Tonnes of CO<sub>2</sub> is like...</div>,
      icon: <RefreshIcon stroke={ theme.colors.black } />,
      stat: generateStat(Type.Offsets),
      body: 'You didn\'t offset emissions this month. Purchase offsets to see the impact on your next Report.',
    };

    if (offsets === 0) {
      // no header text (bold text) when 0 offsets 
      offsetsContent.header = <div />;
    }

    if (offsets > 0 && (offsets >= emissions)) {
      // no header text (bold text) when offset more than emissions
      offsetsContent.header = <div />;
      offsetsContent.body = 'Great job! You offset more than you emitted this month.';
    }

    if (offsets > 0 && (offsets < emissions)) {
      offsetsContent.body = activeMonthReport.carbon.equivalencies[2].text;
    }

    return offsetsContent;
  }, [offsets, emissions, activeMonthReport]);

  if (activeMonthReport.carbon.equivalencies[1] === null) return null;

  return (
    <CarbonSectionContainer className={ className }>
      <AccountHubH3 className='header'>Shopping Carbon Footprint</AccountHubH3>
      <CardsSection>
        <StatCard
          className='content-card'
          key='total-emissions-card'
          stat={ generateStat(Type.Emissions) }
          icon={ <COCloud /> }
          header={ <div>{ emissions.toFixed(2) } Tonnes of CO<sub>2</sub> is like...</div> }
          body={ activeMonthReport.carbon.equivalencies[1].text }
          isAccountCard
        />
        <StatCard
          className='content-card'
          key='total-offsets-card'
          stat={ offsetsContent.stat }
          icon={ offsetsContent.icon }
          header={ offsetsContent.header }
          body={ offsetsContent.body }
          isAccountCard
        />
      </CardsSection>
      <div className='cta-container'>
        <ButtonLink
          className='offset-emissions'
          kind={ ButtonKind.Primary }
          href='/offset-emissions'
          onClick={ onOffsetEmissionsClick }
        >
          Offset Emissions
        </ButtonLink>
      </div>
    </CarbonSectionContainer>
  );
};
