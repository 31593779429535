import dayjs from 'dayjs';
import React, { useCallback, ReactEventHandler, useState } from 'react';
import { CardModel } from '../../models/cards';
import { NoBankLogo } from '../svgs/graphics/NoBankLogo';
import { BankCardContainer, BankCardFooter, BankCardWrapper, BankLogoContainer, BankName, CardDetails, CardInfo, CardInfoContainer, CardMask, CardType, DashBoardBankLogoContainer, DashBoardCard, DashBoardCardInfoContainer, LastUpdated, MainBankCardInfo } from './styles';
import { AutomaticRewardsEnrollModal } from '../modals/AutomaticRewardsEnrollModal';
import { H6 } from '../../styles/components/header';
import KarmaLogo from '../../../public/android-chrome-512x512.png';
import { capitalizeFirstLetters } from '../../lib/misc';
import { PlusIcon } from '../svgs/icons/PlusIcon';
import { theme } from '../../styles/themes';

interface IProps {
  className?: string;
  card?: CardModel | null;
  empty?: boolean;
  dashboardCard?: boolean;
  dashboardAddCard?: boolean;
}

const getLastUpdated = (date: dayjs.Dayjs) => {
  let diff = dayjs().diff(date, 'minutes');

  // less than an hour
  if (diff < 60) {
    return [diff, `minute${diff === 1 ? '' : 's'}`];
  }
  
  // less than 24 hours
  if (diff < 1440) {
    diff = dayjs().diff(date, 'hours');
    return [diff, `hour${diff === 1 ? '' : 's'}`];
  }

  // more than 24 hours
  diff = dayjs().diff(date, 'days');
  return [diff, `day${diff === 1 ? '' : 's'}`];
};

export const BankCard: React.FC<IProps> = ({
  className = '',
  card,
  empty,
  dashboardCard,
  dashboardAddCard,
}) => {
  const [hasBankLogo, setHasBankLogo] = useState(true);
  const [isAutomaticRewardsModalOpen, setIsAutomaticRewardsModalOpen] = useState(false);
  // Comments out Earn More button and CashbackOptionsContainer until later
  // const analytics = useAnalytics();
 
  const onImgLoadError: ReactEventHandler<HTMLImageElement> = useCallback(() => {
    setHasBankLogo(false);
  }, []);

  // Comments out Earn More button and CashbackOptionsContainer until later
  // const onEarnMoreClick = () => {
  //   analytics.fireEvent('Account-Card-Earn_More_Click');
  //   setIsAutomaticRewardsModalOpen(true);
  // };

  // const onInfoIconClick = () => {
  //   analytics.fireEvent('Account_BankCard_Tooltip_Click');
  // };

  const renderBankLogo = () => {
    if(dashboardAddCard) return <PlusIcon stroke={ theme.colors.black } />;
    if (!card) return null;
    if (!hasBankLogo) return <NoBankLogo />;

    return (
      <img
        src={ card.institution === 'Karma Wallet' ? KarmaLogo : `https://s3.amazonaws.com/plaid.karmawallet/${ card.institutionId }.png` }
        onError={ onImgLoadError }
      />
    );
  };

  const renderLastUpdated = () => {
    if (!card) return null;
    const [num, label] = getLastUpdated(card.lastTransactionSync);
    return `Last updated ${num} ${label} ago`;
  };

  const renderCardInfo = () => {
    const displayCardType = () => {
      switch (card.subtype) {
        case 'credit':
          return 'Credit Card';
        case 'checking':
          return 'Debit Card';
        default:
          return capitalizeFirstLetters(card.subtype);
      }
    };

    if (dashboardAddCard) return (
      <DashBoardCard className='connected-accounts-card'>
        <DashBoardBankLogoContainer className='add-card'>
          { renderBankLogo() }
        </DashBoardBankLogoContainer>
        <DashBoardCardInfoContainer>
          <CardDetails>
            <p className='card-details'>Add More</p>
          </CardDetails>
        </DashBoardCardInfoContainer>
      </DashBoardCard>
    );

    if (!card || empty) return (
      <BankCardContainer>
        <MainBankCardInfo>
          <BankLogoContainer />
          <CardInfoContainer>
            <span className='blank-card-placeholder bank-name' />
            <CardInfo>
              <span className='blank-card-placeholder info-line-one' />
              <span className='blank-card-placeholder info-line-two' />
            </CardInfo>
          </CardInfoContainer>
        </MainBankCardInfo>
        <BankCardFooter />
      </BankCardContainer>
    );

    if (dashboardCard) return (
      <DashBoardCard className='connected-accounts-card'>
        <DashBoardBankLogoContainer>
          { renderBankLogo() }
        </DashBoardBankLogoContainer>
        <DashBoardCardInfoContainer>
          <H6 className='bank-name'>{ card.institution ?? card.name }</H6>
          <CardDetails>
            {
              card.institution === 'Karma Wallet' ? (
                <p className='card-details'>Prepaid Card</p>
              ) : (
                <>
                  { !!card.institution && <p className='card-details'>{ displayCardType() }</p> }
                  <p className='card-details'>: { card.mask }</p>
                </>
              )
            }
          </CardDetails>
        </DashBoardCardInfoContainer>
      </DashBoardCard>
    );
    
    return (
      <BankCardContainer>
        <MainBankCardInfo>
          <BankLogoContainer>
            { renderBankLogo() }
          </BankLogoContainer>
          <CardInfoContainer>
            <BankName className='bank-card-name'>{ card.institution ?? card.name }</BankName>
            <CardInfo>
              {
                !!card.institution &&
                  <CardType>{ displayCardType() }</CardType>
              }
              <CardMask>*********{ card.mask }</CardMask>
            </CardInfo>
          </CardInfoContainer>
        </MainBankCardInfo>
        <BankCardFooter>
          {
            <LastUpdated>{ renderLastUpdated() }</LastUpdated>
          }
        </BankCardFooter>
      </BankCardContainer>
    );
  };
  // Comments out Earn More button and CashbackOptionsContainer until later
  // const renderCashbackOptions = () => {
  //   if (!card || (card.subtype !== 'checking' && card.subtype !== 'credit card')) return null;
  //   return (
  //     <CashbackOptionsContainer verified={ !!card.isEnrolledInAutomaticRewards }>
  //       <div className='info-container'>
  //         <div className='icon-container'>
  //           <MoneyIcon />
  //           <p>Cashback { !!card.isEnrolledInAutomaticRewards ? 'Verified' : 'Options' }</p>
  //           {
  //             !!card.isEnrolledInAutomaticRewards ?
  //               <RibbonIcon />
  //               : <div className='card-info-icon-container'>
  //                 <Button
  //                   className='card-info-icon'
  //                   onClick={ onInfoIconClick }
  //                   kind={ ButtonKind.Blank }
  //                 />
  //                 <Tooltip text='Add first 6 & last 4 digits of your linked bank card here and we can offer additional cashback at point of swipe.' />
  //               </div>
  //           }
  //         </div>
  //         {
  //           !card.isEnrolledInAutomaticRewards ?
  //             <Button
  //               className='cashback-options-earn-button'
  //               onClick={ onEarnMoreClick }
  //               kind={ ButtonKind.Primary }
  //             >
  //             Earn More
  //             </Button>
  //             : null
  //         }
  //       </div>
  //     </CashbackOptionsContainer>
  //   );
  // };

  return (
    <BankCardWrapper className={ className }>
      { renderCardInfo() }      
      { /* 
      Comments out Earn More button and CashbackOptionsContainer until later
      { renderCashbackOptions() } 
      */ }
      <AutomaticRewardsEnrollModal
        isOpen={ !!isAutomaticRewardsModalOpen }
        onClose={ () => setIsAutomaticRewardsModalOpen(false) }
        userCards={ [card] }
      />
    </BankCardWrapper>
  );
};
