import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';
import { theme } from '../../../../styles/themes';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const PersonaLogo: FC<IProps> = ({
  className = '',
  id = '',
  fill = theme.colors.secondary,
}) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }persona-logo` }
    id={ id }
    width={ 135 }
    height={ 25 }
    fill={ fill }
  >
    <g clipPath='url(#clip0_7544_9745)'>
      <path d='M96.2286 9.07827C96.2286 4.25769 99.9794 0.67572 104.947 0.67572C109.914 0.67572 113.665 4.25769 113.665 9.07827V17.4474H109.407V9.07827C109.407 6.40017 107.481 4.55897 104.947 4.55897C102.413 4.55897 100.486 6.40017 100.486 9.07827V17.4474H96.2286V9.07827Z' fill={ fill } />
      <path d='M33.1987 12.2448C32.4553 13.2156 31.2388 13.6173 29.8872 13.6173C27.7921 13.6173 26.0688 12.4122 25.6294 10.504H37.997C38.0646 9.96841 38.0646 9.56669 38.0646 9.03107C38.0646 4.34439 34.6517 0.728946 29.7182 0.728946C24.7509 0.728946 21.2028 4.34439 21.2028 9.09802C21.2028 13.9186 24.886 17.5005 29.8872 17.5005C33.3339 17.5005 36.5778 15.4585 37.7942 12.2448H33.1987ZM25.7308 7.35725C26.0012 5.54953 27.7921 4.34439 29.7182 4.34439C31.6781 4.34439 33.2662 5.51605 33.5366 7.35725H25.7308Z' fill={ fill } />
      <path d='M72.3666 5.85083C71.4881 2.771 68.8186 0.728946 64.0202 0.728946C59.4584 0.728946 56.7214 2.90491 56.7214 5.98473C56.7214 8.86369 58.6474 10.3366 62.2969 10.7049L65.4733 11.0062C67.0952 11.1735 67.9738 11.6088 67.9738 12.5126C67.9738 13.5169 66.6897 14.019 65.0678 14.019C62.8376 14.019 61.2832 13.4164 60.7763 12.044H56.2483C57.0255 15.5255 59.9991 17.5005 64.7298 17.5005C69.4607 17.5005 72.2315 15.2911 72.2315 12.2448C72.2315 9.19845 70.0689 7.72549 66.4533 7.42421L63.5472 7.18988C61.8914 7.05597 60.9791 6.65425 60.9791 5.78387C60.9791 4.81306 62.0942 4.21048 63.9189 4.21048C65.845 4.21048 67.1966 4.71263 67.8386 5.85083H72.3666Z' fill={ fill } />
      <path d='M46.1949 17.0598H41.9372V8.97806C41.9372 5.0418 44.4321 1.02464 50.4188 1.02464H53.4346V5.0418H50.385C47.6479 5.0418 46.1949 6.78257 46.1949 9.32677V17.0598Z' fill={ fill } />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.96371 0.728946C3.99642 0.728946 0.245605 4.31091 0.245605 9.1315V24.2432H4.50329V17.5005H8.96371C13.931 17.5005 17.6818 13.9186 17.6818 9.1315C17.6818 4.31091 13.931 0.728946 8.96371 0.728946ZM13.4241 9.1315C13.4241 6.4534 11.4981 4.6122 8.96371 4.6122C6.42938 4.6122 4.50329 6.4534 4.50329 9.1315V13.6173H8.96371C11.4981 13.6173 13.4241 11.7761 13.4241 9.1315Z' fill={ fill } />
      <path fillRule='evenodd' clipRule='evenodd' d='M126.011 0.728946C130.977 0.728946 134.728 4.31091 134.728 9.1315V17.5005H126.011C121.043 17.5005 117.293 13.9186 117.293 9.1315C117.293 4.31091 121.043 0.728946 126.011 0.728946ZM121.55 9.1315C121.55 6.4534 123.476 4.6122 126.011 4.6122C128.544 4.6122 130.471 6.4534 130.471 9.1315V13.6173H126.011C123.476 13.6173 121.55 11.7761 121.55 9.1315Z' fill={ fill } />
      <path d='M83.9019 0.728946C78.9347 0.728946 75.1838 4.31091 75.1838 9.1315C75.1838 13.9186 78.9347 17.5005 83.9019 17.5005C88.8692 17.5005 92.62 13.9186 92.62 9.1315C92.62 4.31091 88.8692 0.728946 83.9019 0.728946ZM83.9019 4.6122C86.4363 4.6122 88.3623 6.4534 88.3623 9.1315C88.3623 11.7761 86.4363 13.6173 83.9019 13.6173C81.3676 13.6173 79.4416 11.7761 79.4416 9.1315C79.4416 6.4534 81.3676 4.6122 83.9019 4.6122Z' fill={ fill } />
    </g>
    <defs>
      <clipPath id='clip0_7544_9745'>
        <rect width='134.483' height='24.2236' fill='white' transform='translate(0.245605 0.67572)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
