import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { SignInContext, useSignInModal } from '../../../../contexts/sign-in';
import { useUserSession } from '../../../../contexts/user';
import { isValidEmailFormat } from '../../../../lib/formatValidators';
import { SignInMode } from '../../../../models/sign-in';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { CTAs } from '../../../CTAs';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';
import { TextFieldKind } from '../../../TextField';
import { FormField, MembershipFormContainer, ModesContainer, SelectedMode, UnselectedMode } from '../styles';
import { CtaType } from '../../../CTAs/index';
import { IUrlParam } from '../../../../models/users';
import { getLocalStorageWithExpirationDayjs } from '../../../../lib/localStorage';
import { ICreateAccountRequest, VisitorModel } from '../../../../models/visitor';
import { usePromos } from '../../../../contexts/promos';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';

interface IProps extends IThemeProps {
  className?: string;
}

const CreateAccountEmailOnlyBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const signInModel = useContext(SignInContext);
  const promosContext = usePromos();
  const analytics = useAnalytics();
  const visitorModel = useRef(new VisitorModel()).current;
  const affiliateData = getLocalStorageWithExpirationDayjs('affiliateParam');
  const xTypeParam = getLocalStorageWithExpirationDayjs('xTypeParam');
  const sscidCreatedOn = getLocalStorageWithExpirationDayjs('sscidCreatedOn');
  const [searchParams, setSearchParams] = useSearchParams();
  const signInModal = useSignInModal();
  const userSession = useUserSession();
  const [referralParams, setReferralParams] = useState<IUrlParam[]>([]);
  const [genError, setGenError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [verificationSentTo, setVerificationSentTo] = useState('');
  const [code, setCode] = useState('');
  const [emailSendSuccess, setEmailSendSuccess] = useState(false);
  const [inputWithArrowFocused, setInputWithArrowFocused] = useState(false);

  const reset = () => {
    setEmail('');
    setEmailError('');
    setGenError('');
    setVerificationSentTo('');
    setEmailSendSuccess(false);
    setInputWithArrowFocused(false);
  };

  const enableAccountCreation = () => !!email && !emailError && isValidEmailFormat(email);

  const autofillFields = () => {
    const groupCode = searchParams.get('groupCode');

    if (!!groupCode) {
      searchParams.delete('groupCode');
      setSearchParams(searchParams);
      setCode(groupCode);
      if (!userSession.isLoggedIn) analytics.fireEvent('CreateAccount_GroupCode_Prepopulated');
    }
  };

  const captureReferralParams = () => {
    if (!searchParams) return;
    const paramsArray: IUrlParam[] = [];

    searchParams.forEach((value, key) => {
      paramsArray.push({ key, value });
    });

    setReferralParams(paramsArray);
  };

  useEffect(() => {
    analytics.fireEvent('CreateAccount_Open');
    reset();
    captureReferralParams();
    autofillFields();
  }, []);

  const onModeClick = useCallback((mode: SignInMode) => () => {
    signInModal.setMode(mode);
    analytics.fireEvent(`SignInModalModeChange_${mode}`);
  }, [signInModal.mode]);

  const onFieldFocus = useCallback((fieldName: string) => () => {
    analytics.fireEvent(`CreateAccount_FieldInt_${fieldName}`);
    setEmailSendSuccess(false);
  }, []);

  const onInputWithArrowBlur = useCallback(() => {
    setInputWithArrowFocused(false);
  }, [inputWithArrowFocused]);

  const onEmailBlur = useCallback(() => {
    onInputWithArrowBlur();
    if (!!email && !isValidEmailFormat(email)) setEmailError('This isn\'t a valid e-mail address');
  }, [email, emailError]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setGenError('');
    setEmail(e.target.value.trim());
  }, [email]);

  const onCreateAccountClick = async ( Event: React.SyntheticEvent ) => {
    Event.preventDefault();
    analytics.fireEvent('CreateAccountRequestClick');

    try {
      if (!enableAccountCreation()) return;
      const data: ICreateAccountRequest = { email };
      if (!!code) data.groupCode = code;
      if (!!referralParams.length) data.params = referralParams;
      if (!!affiliateData) data.sscid = affiliateData;
      if (!!xTypeParam) data.xTypeParam = xTypeParam;
      if (!!sscidCreatedOn) data.sscidCreatedOn = sscidCreatedOn;
      await visitorModel.createSignupVisitor(data);
      setEmailSendSuccess(true);
      setVerificationSentTo(email);
      setEmail('');
      analytics.fireEvent('CreateAccountRequest_Success');
    } catch (err: any) {
      analytics.fireEvent('CreateAccountRequestClick_Error');
      setGenError(err.message);
    }
  };

  const onLinkClick = useCallback((linkName: string) => () => {
    analytics.fireEvent(`CreateAccount_LinkClick_${linkName}`);
  }, []);

  const renderForm = () => (
    <form aria-label='Email To Create Account'>
      <FormField
        className={ `input-with-arrow arrow-right email-field ${ inputWithArrowFocused ? 'focused' : '' }` }
        id='user-email-input'
        autoComplete='email'
        fieldKind={ TextFieldKind.Pill }
        label='Email Address'
        labelHidden
        leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
        placeholder='Email Address'
        value={ email }
        onFocus={ onFieldFocus('Email') }
        onBlur={ onEmailBlur }
        errorText={ emailError }
        onChange={ onEmailChange }
      />
      { !!genError && <p className='field-error'>{ genError }</p> }
      { emailSendSuccess && <p className='field-success'>{ `A verification link has been sent to ${verificationSentTo}` }</p> }
      <CTAs
        className='ctas-container check-score'
        ctas={ [
          {
            id: 'create-account-cta',
            disabled: !enableAccountCreation(),
            onClick: onCreateAccountClick,
            text: 'Check My Score',
            ctaType: CtaType.Submit,
          },
        ] }
      />
    </form>
  );

  const legalDisclaimer = useMemo(() => (
    <p className='legal white'>By registering, you agree to Karma Wallet's <a onClick={ onLinkClick('TermsConditions') } className='white' target='_blank' href='https://cdn.karmawallet.io/terms_of_service.pdf' rel='noreferrer'>Terms of Service</a> and <a onClick={ onLinkClick('PrivacyPolicy') } className='white' target='_blank' href='/privacy-policy'
      rel='noreferrer'>Privacy Policy</a></p>
  ), [onLinkClick]);

  const promoDisclaimer = useMemo(() => {
    if (!promosContext?.activeCreateAccountPromo) return null;

    return <p className='promo-legal'>{ promosContext?.activeCreateAccountPromo?.disclaimerText }</p>;
  }, [signInModel.mode, promosContext?.activeCreateAccountPromo]);

  return (
    <MembershipFormContainer className={ className }>
      <ModesContainer>
        <UnselectedMode onClick={ onModeClick(SignInMode.MEMBERSHIP) }>Karma Wallet Card</UnselectedMode>
        <SelectedMode>Karma Score</SelectedMode>
      </ModesContainer>
      <H1 className='karma-score-header'>Explore your impact on the world.</H1>
      <p className='body-text'>Join a community that has put $250M towards a better future.</p>
      <p className='yellow-text'>Link your cards to get your Karma Score and other personalized data insights.</p>
      { renderForm() }
      { legalDisclaimer }
      { promoDisclaimer }
      <Button
        className='have-account-cta'
        kind={ ButtonKind.Blank }
        onClick={ onModeClick(SignInMode.SIGN_IN) }
      >
        Have an account?
      </Button>
    </MembershipFormContainer>
  );
};

const CreateAccountEmailOnlyAsObserver = observer(CreateAccountEmailOnlyBase);
export const CreateAccountEmailOnly = withTheme(CreateAccountEmailOnlyAsObserver);
