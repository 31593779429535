import styled from 'styled-components';
import { AccountHubBasicContainer, FlexRow } from '../../../styles/styles';

export const AllBrandsOffersContainer = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .back-to-dashboard-button {
    padding: 5px;
    width: 20px;
  }
`;

export const ContentContainer = styled.div`
  ${AccountHubBasicContainer}
  text-align: center;
`;
