import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import { useUserSession } from '../../contexts/user';
import { CommissionsDashboardModel } from '../../models/commisions';
import { IThemeProps } from '../../styles/themes';
import { ErrorComponent } from '../ErrorComponent';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { ContentContainer, PayPalLinkSection, CashbackWidgetContainer, HeaderContainer, KarmaCashItemsContainer, ContentWrapper } from './styles';
import { H5, H3, AccountHubH2, H6 } from '../../styles/components/header';
import { PaypalSection } from '../PaypalSection';
import { DollarIcon } from '../svgs/icons/DollarIcon';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonKind } from '../Button/styles';
import { useRecoilValue } from 'recoil';
import { appVersionAtom } from '../../models/misc/atom';
import { AppVersion } from '../../models/misc/types';

interface IProps extends IThemeProps {
  className?: string;
}

interface ICashbackItem {
  title: JSX.Element;
  buttonLabel?: string;
  buttonHref?: string;
  onClick?: () => void;
  rightContent: JSX.Element;
  showButton?: boolean;
}

const CashbackWidgetBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const userSession = useUserSession();
  const appVersion = useRecoilValue(appVersionAtom);
  const commissionsDashboardModel = useRef(new CommissionsDashboardModel()).current;
  const [loadingCommissionDashboard, setLoadingCommissionDashboard] = useState(true);

  useEffect(() => {
    setLoadingCommissionDashboard(true);
    try {
      commissionsDashboardModel.loadDashboardSummary();
    } catch (error) {
      setLoadingCommissionDashboard(false);
    } finally {
      setLoadingCommissionDashboard(false);
    }
  }, []);

  const onSeePayoutsClick = () => {
    analytics.fireEvent('Account_KarmaCash_Dashboard_SeePayouts_Click');
  };

  const onSeeCurrentCashbackClick = () => {
    analytics.fireEvent('Account_KarmaCash_Dashboard_SeeCurrentCashback_Click');
  };

  const cashbackInfo = useMemo(() => {
    const currentBalance = commissionsDashboardModel.balance.toFixed(2);
    const lifetimeCashbackAmount = commissionsDashboardModel.lifeTimeCashback.toFixed(2);
    const cashbackInfoItems: ICashbackItem[] = [
      {
        title: <H5 className='balance-text'>Balance</H5>,
        rightContent: <H3>${ currentBalance }</H3>,
      },
      {
        title: <H6>Lifetime Karma Cash</H6>,
        buttonLabel: 'See Past Payouts',
        buttonHref: '/account/cashback#payouts',
        rightContent: <p className='right-text'>${ lifetimeCashbackAmount }</p>,
        showButton: commissionsDashboardModel.payouts.length > 0,
        onClick: onSeePayoutsClick,
      },
    ];

    return (
      <KarmaCashItemsContainer>
        { 
          cashbackInfoItems.map((item, index) => (
            <div className='item-container' key={ index }>
              <div className='left-content'>
                { item.title }
                { 
                  item.showButton ? (
                    <ButtonLink 
                      className='see-details'
                      href={ item.buttonHref }
                      onClick={ item.onClick }
                    >
                      { item.buttonLabel }
                    </ButtonLink>
                  ) : null
                }
              </div>
              { item.rightContent }
            </div>
          ))
        }
      </KarmaCashItemsContainer>
    );
  }, [commissionsDashboardModel.balance, commissionsDashboardModel.nextPayoutDate, commissionsDashboardModel.lifeTimeCashback, commissionsDashboardModel.payouts]);

  const cta = useMemo(() => {
    if (commissionsDashboardModel.payouts.length > 0) {
      return (
        <ButtonLink
          className='see-all-button'
          onClick={ onSeeCurrentCashbackClick }
          href='/account/cashback#payouts'
          kind={ ButtonKind.PrimaryGhost }
        >
          View Cashback Dashboard
        </ButtonLink>
      );
    }

    if (userSession.hasKarmaWalletCard && !commissionsDashboardModel.payouts.length) {
      return (
        <ButtonLink
          className='see-all-button'
          href='/browse-and-compare-companies?cashback=true'
          kind={ ButtonKind.PrimaryGhost }
        >
          View Companies with Cashback
        </ButtonLink>
      );
    }

    if (!userSession.hasKarmaWalletCard && !commissionsDashboardModel.payouts.length) {
      return (
        <ButtonLink
          className='see-all-button'
          href='/apply'
          kind={ ButtonKind.PrimaryGhost }
        >
          { 
            appVersion.appVersion === AppVersion.beta ? 'Join the Waitlist' : 'Apply Now'
          }
        </ButtonLink>
      );
    }

    return null;
  }, [commissionsDashboardModel.payouts, userSession.hasKarmaWalletCard, appVersion.appVersion]);

  const supportingText = useMemo(() => {
    if (commissionsDashboardModel.busy) return null;
    if (commissionsDashboardModel.payouts.length && userSession.hasKarmaWalletCard) return null;

    if (userSession.hasKarmaWalletCard && !commissionsDashboardModel.payouts.length) {
      return (
        <p className='cta-text'>
          You have not accrued any cashback. Start earning cashback by using your Karma Wallet Card at participating sustainable companies.
        </p>
      );
    }

    return (
      <p className='cta-text'>
        Earn Cashback at select sustainable companies with the Karma Wallet Card.
      </p>
    );
  }, [userSession.hasKarmaWalletCard, commissionsDashboardModel.payouts]);

  const renderContent = () => {
    if (loadingCommissionDashboard || commissionsDashboardModel.busy) return <LoadingSpinner />;
    if (commissionsDashboardModel.errorLoading) return <ErrorComponent text='Error loading cashback dashboard' />;

    return (
      <ContentContainer>
        { cashbackInfo }
        { supportingText }
        { cta }
        { 
          userSession.hasPayPal
            ? (
              <PayPalLinkSection>
                <PaypalSection />
                <p className='paypal-text'>Starting April 2024, cashback can be earned exclusively through the Karma Wallet Card only.</p>
              </PayPalLinkSection>
            )
            : null
        }
      </ContentContainer>
    );
  };

  const renderHeader = () => (
    <HeaderContainer>
      <div className='left-content'>
        <div className='dollar-icon-container'>
          <DollarIcon fill='white' />
        </div>
        <AccountHubH2 className='karma-cash'>Karma Cash</AccountHubH2>
      </div>
    </HeaderContainer>
  );

  return (
    <CashbackWidgetContainer className={ className }>
      { renderHeader() }
      <ContentWrapper>
        { renderContent() }
      </ContentWrapper>
    </CashbackWidgetContainer>
  );
};

const CashbackWidgetObserver = observer(CashbackWidgetBase);
export const CashbackWidget = withTheme(CashbackWidgetObserver);
