import styled from 'styled-components';

export const KarmaCollectiveOffersContainer = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 20px;

  .back-to-dashboard-button {
    padding: 5px;
    width: 20px;
  }
`;
