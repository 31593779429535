import React from 'react';
import { PlaidDisclaimerContainer, PlaidDisclaimerWidgetContainer } from './styles';
import { H5 } from '../../styles/components/header';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';

interface IProps {
  className?: string;
  isWidget?: boolean;
}

export const PlaidDisclaimer: React.FC<IProps> = ({
  className = '',
  isWidget,
}) => {
  const analytics = useAnalytics();
  const PLAIDGRAPHIC = 'https://cdn.karmawallet.io/uploads/zmwv-nz5Gb-plaid-graphic.webp';

  const onPlaidLearnMoreClick = () => {
    analytics.fireEvent('Account_Plaid_Learn_Click');
  };

  const learnMore = <a onClick={ onPlaidLearnMoreClick } href='https://plaid.com/discover-apps/' target='_blank' rel='noreferrer'>Learn More About Plaid</a>;

  if (isWidget) return (
    <PlaidDisclaimerWidgetContainer className={ className }>
      <img src={ PLAIDGRAPHIC } alt='Plaid Graphic' />
      <H5 className='header'>We use Plaid (Bank grade security) to connect your financial accounts.</H5>
      <p className='description'>With Plaid, we securely connect your financial accounts to Karma Wallet in seconds</p>
      <ButtonLink
        className='learn-more'
        kind={ ButtonKind.PrimaryGhost }
        href='https://plaid.com/discover-apps/'
        target='_blank'
        rel='noreferrer'
        onClick={ onPlaidLearnMoreClick }
      >
        Learn More
      </ButtonLink>
    </PlaidDisclaimerWidgetContainer>
  );

  return (
    <PlaidDisclaimerContainer className={ className }>
      <div>
        <H5>Link a card through Plaid</H5>
        <div className='graphic-container'>
          <img src={ PLAIDGRAPHIC } alt='Plaid Graphic' />
          { learnMore }
        </div>
        <p>Like Venmo & American Express, we use Plaid (Bank grade security) to connect your financial accounts. None of your personal credit card or bank credentials are stored with us, and we never share or sell your data.</p>
      </div>
    </PlaidDisclaimerContainer>
  );
};
