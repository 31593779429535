import React, { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useUserSession } from '../../contexts/user';
import { PayPalModel } from '../../models/paypal';

export interface PayPalOnLoginResponse {
  err: any;
  body: {
    code: string;
  };  
}

interface IProps {
  onLoginSuccess?: () => void;
  onClick?: () => void;
}

const AACComponent = (window as any).paypal.PayoutsAAC.driver('react', {
  React,
  ReactDOM,
});

const clientId = {
  sandbox: 'AaCvhQZTlWBDmEftS_FgzHz-QLEgQ7ExkZr1fSRo5JTpNktx0st9QFr6KEmdfIIbJLvQtHYlhrPnpLuA',
  production: 'AQAIWWqPfTSTvCcUYZilhRRfN6VApXo_DF60bJysX0wwg-vx32Eg_iqnTz1wlJng13xn16IQvNbvbji6',
};

const merchantId = 'Z5HQBAGD2A2D2';

export const PayPal: React.FC<IProps> = ({ onLoginSuccess, onClick }) => {
  const paypalModel = useRef(new PayPalModel()).current;
  const userModel = useUserSession();
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();

  const onLogin = useCallback(async (response: PayPalOnLoginResponse) => {
    if (response.err) return;

    try {
      const linkResponse = await paypalModel.linkPayPalAccount(response.body.code);
      const { user } = linkResponse;
      analytics.fireEvent('PayPal_Link_Success');
      userModel.updateUserSession(user);
      if (onLoginSuccess) onLoginSuccess();

    } catch (err) {
      analytics.fireEvent('PayPal_Link_Error');
      errorMessages.push({
        title: 'Error Linking PayPal Account',
        message: 'There was an error linking the PayPal account. Please try again.',
      });
    }
  }, [paypalModel]);

  return (
    <AACComponent
      className='paypal-component'
      clientId={ clientId }
      merchantId={ merchantId }
      env='production'
      pageType='login'
      onLogin={ onLogin }
      onClick={ onClick }
    />
  );
};
