import React, { useEffect, useMemo, useRef } from 'react';
import { ApplyInfoContainer, ContentWrapper, HeaderContainer, LeftRailContainer, SupportedByContainer } from './styles';
import { Underline } from '../../../components/svgs/shapes/Undeline';
import { useAppData } from '../../../contexts/app';
import { CurrentPage } from '../../../models/karmaCard';
import { H1MInverted, H1M, H1 } from '../../../styles/components/header';
import { observer } from 'mobx-react';
import { MembershipCard } from '../../../components/MembershipCard';
import KarmaCardImage from '../../../../public/assets/images/empty-kw-card.png';
import { theme } from '../../../styles/themes';
import { GenericTag } from '../../../components/GenericTag';
import { GroupModel } from '../../../models/group';
import { BCorpLogo } from '../../../components/svgs/logos/BCorp/BCorpLogo';
import { OnePercentForThePlanetLogo } from '../../../components/svgs/logos/OnePercentForThePlanetLogo';
import { VisaLogo } from '../../../components/svgs/logos/VisaLogo';

interface IProps {
  page: CurrentPage;
  group?: GroupModel;
}

export const LeftRailBase: React.FC<IProps> = ({
  page,
  group,
}) => {
  const ref = useRef(null);
  const app = useAppData();

  const backgroundImg = useMemo(() => {
    if (app.isLargeDesktop) return 'https://cdn.karmawallet.io/uploads/hFZT0xooKL-rainforest.webp';
    return 'https://cdn.karmawallet.io/uploads/IULhYSvcKm-rainforest-sm.webp';
  }, [app]);

  useEffect(() => {
    if (app.isDesktop) return;

    if (!!ref.current && page === CurrentPage.Success) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [ref.current, page]);

  if (page !== CurrentPage.Success) {
    return (
      <LeftRailContainer className='apply-info'>
        <ApplyInfoContainer className={ `${!group?.logo ? 'no-group-logo' : ''}` }>
          <HeaderContainer>
            {
              !!group?.logo &&
              <img
                className='group-logo'
                src={ group?.logo }
                alt={ `${group.company} name` }
              />
            } 
            <GenericTag
              className='generic-tag'
              color={ theme.colors.secondary }
              tagText='Prepaid Reloadable Debit Card'
            />
            <H1>Become a Karma Wallet Member</H1>
            <img alt='Karma Wallet App and Card' className='karma-card' src={ KarmaCardImage } />
            <MembershipCard kind='light' className='membership-card' />
          </HeaderContainer>
          <SupportedByContainer>
            <p className='supported'>Supported by:</p>
            <div className='logos-wrapper'>
              <BCorpLogo />
              <VisaLogo />
              <OnePercentForThePlanetLogo />
            </div>
          </SupportedByContainer>
        </ApplyInfoContainer>
      </LeftRailContainer>
    );  
  }

  return (
    <LeftRailContainer className='success' ref={ ref } backgroundImage={ backgroundImg }>
      <ContentWrapper className='content-wrapper'>
        <div className='text-wrapper' role='heading' arial-level={ 1 }>
          <span className='top-line-wrapper'>
            <H1MInverted className='inverted-text success-text' role='presentation'>Your sustainability journey</H1MInverted>
          </span>
          <span className='underlined-text-wrapper block'>
            <H1M className='regular-text success-text' role='presentation'>
              starts
              <span className='underlined-word-wrapper'>
                <H1M className='regular-text underlined-word success-text' role='presentation'> here</H1M>
                <Underline id='form-text-underline' />
              </span>
            </H1M>
          </span>
        </div>
        <img src='https://cdn.karmawallet.io/uploads/_HWw2GX_OY-karmaapp.png' alt='Picture of Karma Wallet mobile app in an iphone.' className='karma-app' />
      </ContentWrapper>
    </LeftRailContainer>
  );  
};

export const LeftRail = observer(LeftRailBase);
