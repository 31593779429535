import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { ButtonKind } from '../../../../components/Button/styles';
import { PlaidButton } from '../../../../components/PlaidButton';
import { CreditCards } from '../../../../components/svgs/graphics/CreditCards';
import { Formula } from '../../../../components/svgs/graphics/Formula';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { noop } from '../../../../lib/misc';
import { ContentContainer, EmptyStateContainer, IconContainer, RightContentContainer } from './styles';

interface IProps {
  className?: string;
  hasCards: boolean;
}

const EmptyStateBase: React.FC<IProps> = ({
  className = '',
  hasCards,
}) => {
  const analytics = useAnalytics();

  const text = useMemo(() => {
    if (hasCards) {
      return 'You currently have no Impact Reports. Personalized Impact Reports are added on the first of each month, please check back then.';
    }
    
    return 'Personalized Impact Reports are generated from transaction data from linked cards. Link a card using Plaid to see your impact each month.';
  }, [hasCards]);

  const icon = useMemo(() => {
    if (hasCards) return <Formula />;
    return <CreditCards />;
  }, [hasCards]);

  const onClick = useCallback(() => {
    analytics.fireEvent('UserImpactReport_EmptyState_AddCard_Click');
  }, []);
  
  return (
    <EmptyStateContainer className={ className }>
      <div className='empty-header'>No Impact Reports Available { hasCards ? 'Yet' : '' }</div>
      <ContentContainer>
        <IconContainer className={ hasCards ? 'has-cards' : 'no-cards' }>
          { icon }
        </IconContainer>
        <RightContentContainer>
          { !hasCards && <div className='title'>Add a Card</div> }
          <div className='body'>{ text }</div>
          { 
            !hasCards && (
              <PlaidButton
                kind={ ButtonKind.PrimaryGhost }
                onClick={ onClick }
                onPlaidSuccess={ noop }
              >
                Add a Card
              </PlaidButton>
            )
          }
        </RightContentContainer>
      </ContentContainer>
    </EmptyStateContainer>
  );
};

export const EmptyState = observer(EmptyStateBase);
