import React, { useMemo } from 'react';
import { ButtonKind } from '../../Button/styles';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { IConfirmationModalProps } from '../ConfirmationModal';
import { RemoveCardModalContainer } from './styles';

interface IProps extends IConfirmationModalProps {
  className?: string;
  onConfirm(): any;
  onCancel(): void;
  onClose(): void;
  isOpen: boolean;
  removeData?: boolean;
  loading: boolean;
}

export const RemoveBankModal: React.FC<IProps> = ({
  className = 'remove-card-modal',
  onConfirm,
  onCancel,
  onClose,
  isOpen,
  removeData,
  loading,
  ...restProps
}) => {
  const body = useMemo(() => (!!removeData
    ? 'Sure you want to remove this bank? You won’t be able to view the impact of past or future purchases made using it.'
    : 'Sure you want to remove this bank? You won’t be able to view the impact of future purchases made using it.'
  ), [removeData]);

  const removalCtas = useMemo(() => ([
    {
      id: 'confirm-removal',
      text: `Yes, Remove ${ removeData ? 'All' : 'Bank' }`,
      kind: ButtonKind.Primary,
      onClick: onConfirm,
    },
    {
      id: 'cancel-removal',
      text: 'Cancel',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCancel,
    },
  ]), [onConfirm, onCancel, removeData]);

  return (
    <RemoveCardModalContainer
      className={ className }
      title={ `Remove Bank${ !!removeData ? ' & Data' : '' }` }
      onClose={ onClose }
      isOpen={ isOpen }
      body={ !!loading ? <LoadingSpinner className='loading-spinner' /> : body }
      ctas={ removalCtas }
      { ...restProps }
    />
  );
};
