import styled from 'styled-components';
import { FlexColCenter, FlexHorizontalCenter } from '../../styles/styles';
import { TextMDMedium } from '../../styles/components/text';

export enum BenefitCardVariant {
  Green = 'green',
  Blue = 'blue',
}
interface IProps {
  variant?: BenefitCardVariant;
}

export const BenefitCardContainer = styled.div<IProps>`
  ${FlexColCenter}
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.secondary}0D`};
  border: 1px solid ${({ theme, variant }) => variant === BenefitCardVariant.Blue ? theme.colors.primary : theme.colors.secondary};
  border-radius: 20px;
  gap: 4px;
  min-height: 110px;
  padding: 8px;
  text-align: center;
  width: 100%;

  p {
    margin: unset;
  }

  .description {
    ${TextMDMedium}
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }

  .description-container {
    ${FlexHorizontalCenter}
    justify-content: center;

    svg {
      height: 15px;
      margin-right: 4px;
      width: 15px;
    }
  }

  .title {
    color: ${({ theme, variant }) => variant === BenefitCardVariant.Blue ? theme.colors.primary : theme.colors.secondary};
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    .title {
      font-size: 23px;
    }
  }
`;

export const InnerWrapper = styled.div`
  ${FlexColCenter}
  gap: 4px;
  height: 100%;
  justify-content: center;
`;
