import React from 'react';
import { SvgIcon } from '../../SvgIcon';

export const COCloud = ({ 
  className = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}cloud-graphic graphic` }
    id={ id }
    height={ 45 }
    width={ 69 }
  >
    <g clipPath='url(#clip0_6527_91653)'>
      <path d='M15.4592 44.8559C13.5492 44.8884 11.6519 44.54 9.87793 43.831C8.10393 43.122 6.48877 42.0666 5.12661 40.7263C3.76444 39.3861 2.68257 37.7878 1.94406 36.0248C1.20556 34.2617 0.825195 32.3692 0.825195 30.4575C0.825195 28.5458 1.20556 26.6532 1.94406 24.8902C2.68257 23.1271 3.76444 21.5289 5.12661 20.1887C6.48877 18.8484 8.10393 17.793 9.87793 17.084C11.6519 16.375 13.5492 16.0266 15.4592 16.0591C15.9038 16.0591 16.3401 16.0591 16.7764 16.1173C18.6171 8.44923 25.8181 2.73145 34.4152 2.73145C43.0124 2.73145 50.205 8.44506 52.0541 16.109C52.4696 16.0757 52.8602 16.0591 53.2674 16.0591C55.1773 16.0266 57.0746 16.375 58.8486 17.084C60.6226 17.793 62.2378 18.8484 63.6 20.1887C64.9621 21.5289 66.044 23.1271 66.7825 24.8902C67.521 26.6532 67.9014 28.5458 67.9014 30.4575C67.9014 32.3692 67.521 34.2617 66.7825 36.0248C66.044 37.7878 64.9621 39.3861 63.6 40.7263C62.2378 42.0666 60.6226 43.122 58.8486 43.831C57.0746 44.54 55.1773 44.8884 53.2674 44.8559H15.4592Z' fill='black' />
      <path d='M15.4592 43.1926C13.5492 43.2251 11.6519 42.8767 9.87793 42.1677C8.10393 41.4586 6.48877 40.4032 5.12661 39.063C3.76444 37.7228 2.68257 36.1245 1.94406 34.3615C1.20556 32.5984 0.825195 30.7059 0.825195 28.7942C0.825195 26.8825 1.20556 24.9899 1.94406 23.2269C2.68257 21.4638 3.76444 19.8656 5.12661 18.5253C6.48877 17.1851 8.10393 16.1297 9.87793 15.4207C11.6519 14.7117 13.5492 14.3633 15.4592 14.3957C15.9038 14.3957 16.3401 14.3957 16.7764 14.454C18.6171 6.7859 25.8181 1.06812 34.4152 1.06812C43.0124 1.06812 50.205 6.78173 52.0541 14.4456C52.4696 14.4124 52.8602 14.3957 53.2674 14.3957C55.1773 14.3633 57.0746 14.7117 58.8486 15.4207C60.6226 16.1297 62.2378 17.1851 63.6 18.5253C64.9621 19.8656 66.044 21.4638 66.7825 23.2269C67.521 24.9899 67.9014 26.8825 67.9014 28.7942C67.9014 30.7059 67.521 32.5984 66.7825 34.3615C66.044 36.1245 64.9621 37.7228 63.6 39.063C62.2378 40.4032 60.6226 41.4586 58.8486 42.1677C57.0746 42.8767 55.1773 43.2251 53.2674 43.1926H15.4592Z' fill='#F8F8F9' stroke='black' strokeWidth='2' strokeLinejoin='round' />
      <path d='M22.7981 29.9398C21.885 29.4733 21.1206 28.7603 20.5917 27.8814C20.069 26.9695 19.7939 25.9365 19.7939 24.8853C19.7939 23.834 20.069 22.8011 20.5917 21.8892C21.1206 21.0103 21.885 20.2972 22.7981 19.8308C23.7761 19.3304 24.8619 19.0777 25.9602 19.0947C26.9482 19.0783 27.9283 19.2725 28.8356 19.6644C29.6571 20.0348 30.3717 20.607 30.9132 21.3278L28.5572 23.4319C28.2848 23.0402 27.9237 22.7185 27.5035 22.493C27.0832 22.2675 26.6156 22.1447 26.1388 22.1345C25.7909 22.1211 25.444 22.1808 25.1206 22.3097C24.7971 22.4386 24.5042 22.6339 24.2607 22.883C23.8007 23.4544 23.5498 24.1661 23.5498 24.8998C23.5498 25.6336 23.8007 26.3453 24.2607 26.9166C24.5026 27.1682 24.7951 27.3654 25.1189 27.4952C25.4427 27.6249 25.7904 27.6843 26.1388 27.6693C26.6156 27.6591 27.0832 27.5363 27.5035 27.3108C27.9237 27.0853 28.2848 26.7636 28.5572 26.3719L30.9132 28.476C30.3694 29.1894 29.6549 29.7543 28.8356 30.1186C27.929 30.5125 26.9484 30.7068 25.9602 30.6883C24.861 30.6994 23.7757 30.4425 22.7981 29.9398Z' fill='black' />
      <path d='M34.3492 29.9397C33.423 29.4623 32.6461 28.7388 32.1037 27.8487C31.5613 26.9585 31.2744 25.9361 31.2744 24.8935C31.2744 23.8509 31.5613 22.8284 32.1037 21.9382C32.6461 21.0481 33.423 20.3246 34.3492 19.8473C35.3477 19.3574 36.445 19.1028 37.557 19.1028C38.6691 19.1028 39.7663 19.3574 40.7648 19.8473C41.691 20.3246 42.4679 21.0481 43.0103 21.9382C43.5527 22.8284 43.8396 23.8509 43.8396 24.8935C43.8396 25.9361 43.5527 26.9585 43.0103 27.8487C42.4679 28.7388 41.691 29.4623 40.7648 29.9397C39.7655 30.4268 38.6686 30.6799 37.557 30.6799C36.4455 30.6799 35.3485 30.4268 34.3492 29.9397ZM38.816 27.3199C39.1962 27.0858 39.5051 26.7522 39.7094 26.3551C39.9238 25.8999 40.0349 25.4029 40.0349 24.8997C40.0349 24.3965 39.9238 23.8995 39.7094 23.4443C39.5063 23.0463 39.1971 22.7124 38.816 22.4795C38.437 22.2605 38.0071 22.1453 37.5695 22.1453C37.1318 22.1453 36.7019 22.2605 36.3229 22.4795C35.9409 22.7112 35.6314 23.0454 35.4295 23.4443C35.2126 23.8987 35.1 24.396 35.1 24.8997C35.1 25.4034 35.2126 25.9007 35.4295 26.3551C35.6326 26.7531 35.9418 27.087 36.3229 27.3199C36.7019 27.5389 37.1318 27.6542 37.5695 27.6542C38.0071 27.6542 38.437 27.5389 38.816 27.3199Z' fill='black' />
      <path d='M50.5958 30.2141V32.0189H44.8118V30.5801L47.3257 28.4302C47.7038 28.11 47.8908 27.8272 47.8908 27.5736C47.8936 27.5106 47.8806 27.448 47.853 27.3914C47.8254 27.3347 47.7841 27.2859 47.7329 27.2492C47.5915 27.1608 47.4255 27.12 47.2592 27.1328C47.0444 27.1203 46.8305 27.1702 46.6433 27.2764C46.456 27.3826 46.3035 27.5407 46.2038 27.7316L44.3672 26.8625C44.6267 26.3734 45.0299 25.9757 45.5223 25.7231C46.1318 25.4247 46.8056 25.2819 47.4836 25.3072C48.2281 25.2718 48.9649 25.4711 49.5903 25.8769C49.8408 26.0426 50.0454 26.269 50.1849 26.5352C50.3244 26.8013 50.3943 27.0985 50.3881 27.3989C50.3879 27.7399 50.2961 28.0746 50.1222 28.3678C49.8486 28.7893 49.5123 29.1665 49.1249 29.4864L48.2565 30.2266L50.5958 30.2141Z' fill='black' />
    </g>
    <defs>
      <clipPath id='clip0_6527_91653'>
        <rect width='68.7273' height='45' fill='#fff' />
      </clipPath>
    </defs>
  </SvgIcon>
);
