import React from 'react';
import { KarmaCardSection, YouShopWeDonateSectionContainer, TextContainer, KarmaAppNotificationsImgContainer, DonationsContainer, DonationContainer, PartnershipsContainer } from './styles';
import { H2MobileMarketing, H6 } from '../../styles/components/header';
import { theme } from '../../styles/themes';
import AppWithAlerts from '../../../public/assets/images/mobile-app-alerts.png';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { BCorpLogo } from '../svgs/logos/BCorp/BCorpLogo';
import { OnePercentForThePlanetLogo } from '../svgs/logos/OnePercentForThePlanetLogo';
import { OneTreePlantedLogo } from '../svgs/logos/OneTreePlantedLogo';
import { FeedingAmericaLogo } from '../svgs/logos/FeedingAmericaLogo';

interface IProps {
  className?: string;
}

interface IDonation {
  text: string;
  image: string;
  id: string;
  learnMoreUrl?: string;
}

const donationsData: IDonation[] = [
  {
    text: 'When you fill up on gas, we donate to reforestation projects.',
    image: 'https://cdn.karmawallet.io/uploads/8PxOlRrErX-trees-graphic.svg',
    learnMoreUrl: 'https://karmawallet.io/blog/2024/01/impact-partner-one-tree-planted/',
    id: '1',
  },
  {
    text: 'When you dine out or order food delivery, we donate a meal to someone in need.',
    image: 'https://cdn.karmawallet.io/uploads/-UQ73nhubj-fruit-salad-graphic.png',
    learnMoreUrl: 'https://karmawallet.io/blog/2024/01/impact-partner-feeding-america/',
    id: '2',
  },
  {
    text: 'Karma Wallet donates 5% of our revenue to nonprofit partners!',
    image: 'https://cdn.karmawallet.io/uploads/4WAhBR_viZ-dollar-sign-graphic.svg',
    id: '3',
  },
];

export const YouShopWeDonateSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onLearnMoreClick = (id: string) => {
    analytics.fireEvent(`AppPage_YouShop_LearnMore_${id}_Click`);
  };

  const donationsContent = (
    <DonationsContainer>
      {
        donationsData.map(({ text, image, id, learnMoreUrl }) => (
          <DonationContainer key={ id }>
            <img alt='Donation Graphic' src={ image } />
            <p>{ text }</p>
            {
              !!learnMoreUrl && (
                <ButtonLink
                  className='learn-more'
                  href={ learnMoreUrl }
                  onClick={ () => onLearnMoreClick(id) }
                  target='_blank'
                >
                  Learn More
                </ButtonLink>
              )
            }
          </DonationContainer>
        ))
      }
    </DonationsContainer>
  );

  return (
    <YouShopWeDonateSectionContainer className={ className } backgroundColor={ theme.colors.teal1 }>
      <KarmaCardSection>
        <TextContainer>
          <H2MobileMarketing>You shop. We donate.</H2MobileMarketing>
          <H6 className='subheader'>Create positive change with your everyday spending.</H6>
          { donationsContent }
        </TextContainer>
        <KarmaAppNotificationsImgContainer>
          <img
            alt='Karma Wallet App with alerts'
            className='app-with-alerts'
            src={ AppWithAlerts }
          />
        </KarmaAppNotificationsImgContainer>
      </KarmaCardSection>
      <PartnershipsContainer>
        <BCorpLogo fill={ theme.colors.black } className='b-corp' />
        <OnePercentForThePlanetLogo fill={ theme.colors.black } className='one-percent' />
        <OneTreePlantedLogo className='one-tree' />
        <FeedingAmericaLogo className='feeding-america' />
      </PartnershipsContainer>
    </YouShopWeDonateSectionContainer>
  );
};
