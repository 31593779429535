import styled from 'styled-components';
import { BasicContainer, FlexCenter, FlexCol, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { ButtonLink } from '../Button';

export const LatestTransactionItemContainer = styled.div`
  ${FlexRow}
  align-items: start;
  border-radius: unset;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey2};
  justify-content: space-between;
  min-height: 82px;
  padding: 10px 16px;
`;

export const LeftContainer = styled(ButtonLink)`
  ${FlexCol}
  align-items: flex-start;

  .company-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .date {
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
  }
`;

export const ValuesContainer = styled.div`
  ${FlexRow}
  gap: 6px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const RightContainer = styled.div`
  ${FlexCol}
  justify-content: start;
`;

export const TransactionAmount = styled.div`
  ${FlexHorizontalCenter}
  font-size: 16px;
  font-weight: 600;

  &.reversed {
    text-decoration: line-through;
  }

  .tiny-popover-anchor {
    margin-left: 5px;
  }
`;

export const TransactionAmountTooltipAnchor = styled.div`
  ${FlexCenter}

  &:hover {
    cursor: pointer;
  }
`;

export const TransactionAmountTooltip = styled.div`
  ${BasicContainer}
  background: ${({ theme }) => theme.colors.white};
  width: 200px;
`;
