import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { IUser } from './users';

type PrivateFields = '_busy';

interface IPayPalResponse {
  user: IUser;
  message: string;
}

export class PayPalModel extends BaseModel {
  private _busy = false;

  constructor () {
    super();
    makeObservable<PayPalModel, PrivateFields>(this, {
      _busy: observable,
      busy: computed,
      unlinkPayPalAccount: action.bound,
      linkPayPalAccount: action.bound,
    });
  }

  get busy() { return this._busy; }

  public linkPayPalAccount = async (code: string) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IPayPalResponse>({
      path: '/integrations/paypal/link-account',
      method: 'POST',
      data: { code },
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public unlinkPayPalAccount = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IPayPalResponse>({
      path: '/integrations/paypal/unlink-account',
      method: 'PUT',
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
