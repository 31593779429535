import React from 'react';
import { withTheme } from 'styled-components';
import { DebitCardTextContainer, KarmaCardSection, KarmaCardAppSectionContainer, SubheaderText, GenericTagContainer, BackgroundContainer, ApplyContainer, SupportedByContainer, AppButtonsWrapper } from './styles';
import { EarthWithClouds } from '../../../components/svgs/graphics/EarthWithClouds';
import { LeafA } from '../../../components/svgs/graphics/LeafA';
import { Light } from '../../../components/svgs/graphics/Light';
import { Food } from '../../../components/svgs/graphics/Food';
import { Recycling } from '../../../components/svgs/graphics/Recycling';
import { GenericTag } from '../../../components/GenericTag';
import { H2M, H2MInverted } from '../../../styles/components/header';
import { IThemeProps } from '../../../styles/themes';
import { ApplyOrJoinWaitlist } from '../../../components/ApplyOrJoinWaitlist';
import { useAnalytics } from '../../../contexts/analytics-store';
import { CoinB } from '../../../components/svgs/graphics/CoinB';
import { VisaLogo } from '../../../components/svgs/logos/VisaLogo';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import AppStoreBadge from '../../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../../public/assets/images/google-play-badge.svg';

interface IProps extends IThemeProps {
  className?: string;
}

export const AppLandingPageHeroBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onPrepaidCardClick = () => {
    analytics.fireEvent('AppLandingHero_PrepaidCard_Click');
  };

  const onAppleClick = () => {
    analytics.fireEvent('AppLandingHero_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('AppLandingHero_GoogleStore_Click');
  };

  const backgroundIcons = (
    <BackgroundContainer>
      <CoinB
        className='svg-icon coin-b-1'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-3'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-4'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-5'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <EarthWithClouds
        className='svg-icon earth-clouds'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <LeafA
        className='svg-icon leaf'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <Light
        className='svg-icon light'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Food
        className='svg-icon food'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Recycling
        className='svg-icon recycling'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
    </BackgroundContainer>
  );
  
  const appButtons = (
    <AppButtonsWrapper>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onAppleClick }
        href='https://apps.apple.com/app/karma-wallet/id6451440075'
      >
        <img src={ AppStoreBadge } alt='App Store Badge' />
      </ButtonLink>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onGoogleClick }
        href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
      >
        <img className='google-button' src={ GooglePlayBadge } alt='Google Play Badge' />
      </ButtonLink>
    </AppButtonsWrapper>
  );

  return (
    <KarmaCardAppSectionContainer
      className={ className }
      backgroundColor={ theme.colors.dark }
    >
      <KarmaCardSection>
        { backgroundIcons }
        <DebitCardTextContainer>
          <GenericTagContainer
            onClick={ onPrepaidCardClick }
            href='https://karmawallet.io/blog/2024/02/what-is-a-prepaid-reloadable-debit-card/'
            target='_blank'
          >
            <GenericTag
              className='tag'
              color={ theme.colors.secondary }
              tagText='Prepaid Reloadable Debit Card'
            />
          </GenericTagContainer>
          <H2M className='debit-card-text'>Your membership</H2M>
          <H2MInverted className='inverted-text'>to a better future.</H2MInverted>
          <SubheaderText>
            Earn up to $300 cashback* a year and automatically donate to nonprofits for everyday spending with the Karma Wallet Card.
          </SubheaderText>
          <ApplyContainer>
            <ApplyOrJoinWaitlist className='cta-button' locationOfButton='LandingPage_HeroSection' />
            <SupportedByContainer>
              <p className='supported'>Supported by:</p>
              <div className='logo-wrapper'>
                <VisaLogo fill={ theme.colors.grey3 } />
              </div>
            </SupportedByContainer>
          </ApplyContainer>
          { appButtons }
        </DebitCardTextContainer>
        <img
          alt='People with cards'
          className='people-with-cards'
          src='https://cdn.karmawallet.io/uploads/WK6aLEEoBe-people-with-cards.png'
        />
      </KarmaCardSection>
    </KarmaCardAppSectionContainer>
  );
};

export const AppLandingPageHero = withTheme(AppLandingPageHeroBase);
