export enum AppVersion {
  beta = 'beta',
  live = 'live',
}

export interface IAppVersionAtom {
  appVersion: AppVersion | '';
  error: string;
  loaded: boolean;
  loading: boolean;
}

export interface IAppVersionRequestData {
  version: AppVersion;
}
