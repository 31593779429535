import styled from 'styled-components';
import { FlexCol, FlexColCenter } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const ChangeEmailRequestModalContainer = styled(CustomModal)`
  max-width: 650px;
  overflow-y: auto;
  width: 90%;

  .ctas-container {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 0;

    button {
      margin: 0;
      width: 100%;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      flex-direction: row;
      gap: 10px;

      button {
        margin: 0;
        width: unset;
      }
    }
  }
`;

export const ChangeEmailRequestModalInner = styled.div`
  ${FlexColCenter}
  color: ${({ theme }) => theme.colors.darkGray1};
  gap: 10px;
  text-align: flex-start;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    text-decoration: none;
  }

  .necessary-fields-container {
    ${FlexCol}
    gap: 20px;
  }

  .pill-label {
    text-align: left;
  }

  .form-wrapper {
    width: 100%;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  text-align: right;
`;

export const SimpleFormContainer = styled.div`
  #user-state-input {
    margin-bottom: 20px;
  }
`;
