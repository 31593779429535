import React from 'react';
import { ICommission } from '../../models/commisions';
import { CashbackAccrualItemRow } from '../CashbackAccrualtemRow';
import { CommissionsListContainer } from './styles';

interface IProps {
  className?: string;
  commissions: ICommission[];
  total: number;
}

export const CommissionsList: React.FC<IProps> = ({
  className = '',
  commissions,
  total,
}) => (
  <CommissionsListContainer className={ className }>
    {
      commissions.map(commission => (
        <CashbackAccrualItemRow
          key={ commission._id }
          accrualItem={ commission }
        />
      ))
    }
    <div className='total'>
      <span>Total:</span> ${ total.toFixed(2) }
    </div>
  </CommissionsListContainer>
);
