import React from 'react';
import { TextWithUpArrowContainer } from './styles';
import { UpArrowCurledIcon } from '../svgs/icons/upArrowCurled';
import { H5 } from '../../styles/components/header';

interface IProps {
  className?: string;
  text: string | JSX.Element;
  color: string;
  arrowPosition?: TextWithArrowPosition
}

export enum TextWithArrowPosition {
  Left = 'left',
  Right = 'right'
}

export const TextWithUpArrow: React.FC<IProps> = ({
  className = '',
  text,
  color,
  arrowPosition = TextWithArrowPosition.Right,
}) => (
  <TextWithUpArrowContainer className={ className } aria-hidden={ true }>
    { arrowPosition === TextWithArrowPosition.Right && <H5>{ text }</H5> }
    <UpArrowCurledIcon fill={ color } className={ arrowPosition as string } />
    { arrowPosition === TextWithArrowPosition.Left && <H5>{ text }</H5> }
  </TextWithUpArrowContainer>
);
