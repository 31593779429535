import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { BasicContainer, FlexCol, TruncateText } from '../../styles/styles';

export const UserInfoContainer = styled(MaxBoundarySection)``;

export const UserInfoWrapper = styled.div`
  .label-wrapper {
    margin-bottom: 5px;
  }

  .form-wrapper {
    width: 100%;
  }

  .modal-cta-container {
    justify-content: center;
  }

  .info-row {
    ${FlexCol}
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin})  {
      flex-direction: row;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${BasicContainer}
    padding: 20px;
  }
`;

export const UserInfoItemContainer = styled.div`
  width: 100%;
  min-height: 60px;
  padding-right: 10px;

  .label-wrapper {
    display: flex;
    justify-content: space-between;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      justify-content: flex-start;
    }

    .pencil-icon {
      margin-left: 5px;
    }
  }

  &.email, &.password, &.zipcode {
    margin-bottom: 20px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.darkGray1 };

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      border-bottom: none;
    }

    .field-text {
      ${TruncateText}
      -webkit-line-clamp: 1;
    }
  }

  .name {
    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      .field-text {
        display: none;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-width: 30%;

    &.email {
      width: 40%;
    }
  }
`;
