import styled from 'styled-components';
import { BasicContainer, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextXSBold } from '../../styles/components/text';

export const KarmaCollectiveWidgetContainer = styled.div`
  ${BasicContainer}
  min-height: 322px;
  padding: 32px 16px;
  position: relative;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    padding: 32px;
  }
`;

export const HeaderContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;

  .inner-container {
    ${FlexHorizontalCenter}
    gap: 10px;
  }

  h2 {
    margin: unset;
  }

  .karma-card {
    width: 34px;
  }

  .see-all-button {
    ${FlexRow}
    ${TextXSBold}
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    gap: 8px;
    line-height: 12px;

    svg {
      height: 15px;
    }

    @media (${({ theme }) => theme.breakpoints.xsMin}) {
      font-size: 14px;
      line-height: 13px;
    }
  }
`;

export const KarmaCollectiveDescriptionContainer = styled.div`
  margin-top: 12px;

  p {
    color: ${({ theme }) => theme.colors.grey5};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: unset;
  }

  .get-card {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    text-decoration: none;
  }
`;

export const CashbackCardsContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-evenly;
  margin-top: 24px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    justify-content: flex-start;
  }

  .company-placeholder {
    width: 100%;
    height: auto;

    @media (${({ theme }) => theme.breakpoints.xxlMin}) {
      width: calc((100% / 5) - (16px * 4 / 5));
    }

    @media (${({ theme }) => theme.breakpoints.xlMax}) {
      width: calc((100% / 4) - (16px * 3 / 4));
    }

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      width: calc((100% / 3) - (16px * 2 / 3));
    }

    @media (${({ theme }) => theme.breakpoints.xsMax}) {
      width: calc((100% / 2) - (16px * 2 / 3));
    }
  }
`;
