import axios, { AxiosInstance } from 'axios';

declare const __KARMA_ENV__: string;
declare const __REACT_APP_API_URL__: string;
declare const __REACT_APP_API_PORT__: string;
declare const __REACT_APP_AUTH__: string;

interface ISendRequestParams {
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  queryParams?: any;
  data?: any;
  headers?: any;
}

class _WebServiceHelper {
  client: AxiosInstance;
  private _apiBaseUrl: string;

  constructor() {
    this._apiBaseUrl = `${__KARMA_ENV__}` === 'local'
      ? `${__REACT_APP_API_URL__}:${__REACT_APP_API_PORT__}`
      : `${__REACT_APP_API_URL__}`;

    this.client = axios.create({
      baseURL: this._apiBaseUrl,
      headers: {
        Authorization: `${__REACT_APP_AUTH__}`,
      },
    });
  }

  buildQuery = (params: any) => {
    if (typeof params === 'string') return params;
    const query: string[] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        query.push(`${key}=${value}`);
      }
    });
    return query.length > 0 ? `?${query.join('&')}` : '';
  };

  sendRequest = async ({
    method,
    queryParams,
    path,
    data,
    headers,
  }: ISendRequestParams) => {
    const query = queryParams ? this.buildQuery(queryParams) : '';
    const err: any = null;

    try {
      const res = await this.client[method.toLowerCase() as 'get' | 'post' | 'put' | 'patch' | 'delete'](`${path}${query}`, data, headers || {});

      if (err === null && res.status >= 200 && res?.status < 300) {
        return {
          success: true,
          data: res?.data,
        };
      } else {
        return {
          success: false,
          error: res?.data.message,
        };
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        return {
          success: false,
          error: e.response.data.message,
        };
      } else {
        return {
          success: false,
          error: e.response?.data?.metadata?.message || 'error',
        };
      }
    }
  };
}

export const WebServiceHelper = new _WebServiceHelper();
