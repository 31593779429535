import styled from 'styled-components';
import { AccountHubBasicContainer, FlexRow } from '../../../../styles/styles';

export const PayoutsContainer = styled.div`
  ${AccountHubBasicContainer}
  width: 100%;

 .no-info-past-payout {
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 16px;
 }

  h5 {
    font-size: 18px;
  }
`;

export const PayoutsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PayoutItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  a {
    ${FlexRow}
    justify-content: space-between;
    padding: 15px 0;
    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const LeftContent = styled.div`
  ${FlexRow}
  align-items: center;
  flex: 2;
  font-size: 16px;
  font-weight: 600;
  text-align: left;

  .dollar-icon-container {
    ${FlexRow}
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 4px;
    justify-content: center;
    margin-right: 16px;
    min-width: 41px;
    min-height: 41px;

    svg {
      height: auto;
      width: 10px;
    }
  }
`;

export const RightContent = styled.div`
  ${FlexRow}
  align-items: center;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-end;
  text-align: right;
`;
