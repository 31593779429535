import React from 'react';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { ApplyNowButtonContainer } from './styles';

interface IProps {
  className?: string;
  locationOfButton: string;
  kind?: ButtonKind;
}

export const ApplyNowButton: React.FC<IProps> = ({
  className = '',
  locationOfButton,
  kind,
}) => {
  const analytics = useAnalytics();  

  const onClick = () => {
    analytics.fireEvent('Apply_Now_Clicked', locationOfButton);
  };

  return (
    <ApplyNowButtonContainer
      className={ `${className} apply-for-card` }
      kind={ kind ? kind : ButtonKind.Secondary }
      onClick={ onClick }
      href='/apply'
    >
      Apply Now
    </ApplyNowButtonContainer>
  );
};
