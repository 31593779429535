import React, { useState } from 'react';
import { CardModel } from '../../../../models/cards';
import { BankCardWrapper, ModalBankCardItemContainer } from './styles';
import { BankCardExample } from '../../../svgs/graphics/BankCardExample';
import { TextField, TextFieldKind } from '../../../TextField';
import { CreditCardIcon } from '../../../svgs/icons/CreditCardIcon';
import { IThemeProps } from '../../../../styles/themes';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { NoBankLogo } from '../../../svgs/graphics/NoBankLogo';

interface IProps extends IThemeProps {
  className?: string;
  card: CardModel;
  onCardNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cardNumber: string;
  errorVerifying: boolean;
}

export const ModalBankCardItemBase: React.FC<IProps> = ({
  className = '',
  card,
  theme,
  onCardNumberChange,
  cardNumber,
  errorVerifying,
}) => {
  const [ hasBankLogo, setHasBankLogo ] = useState(true);
  
  const renderBankLogo = () => {
    if (!card) return null;

    return (
      <div className='bank-logo-container'>
        { !!hasBankLogo ?
          <img
            src={ `https://s3.amazonaws.com/plaid.karmawallet/${ card?.institutionId }.png` }
            onError={ () => setHasBankLogo(false) }
          />
          : <NoBankLogo className='no-bank-logo' />
        }
      </div>
    );
  };

  return (
    <ModalBankCardItemContainer className={ className }>
      <BankCardWrapper>
        <div className='bank-card-container'>
          { renderBankLogo() }
          <div className='card-info'>
            <div className='card-name'>{ card?.institution }</div>
            <div className='card-number'>******{ card?.mask }</div>
          </div>
        </div>
        <BankCardExample className='bank-card-example' />
      </BankCardWrapper>
      <p className='card-security-text'>We never store your full card information. The first 6 and last 4 digits are all we need to verify the cashback offer.</p>
      <TextField
        fieldKind={ TextFieldKind.Pill }
        id='bank-card-first-6-last-4'
        label='First 6 and last 4 digits of card'
        labelHidden
        placeholder='_ _ _ _ _ _ - _ _ _ _'
        type='text'
        value={ cardNumber }
        onChange={ onCardNumberChange }
        className={ 'card-digits-input-field' }
        leftAccessory={ <CreditCardIcon stroke={ theme.colors.midGray } /> }
        maxLength={ 11 }
      />
      {
        !!errorVerifying ?
          <p className='error-text'>We couldn't verify your card. Please check your numbers and try again.</p>
          : null
      }
    </ModalBankCardItemContainer>
  );
};

const ModalBankCardItemObserver = observer(ModalBankCardItemBase);
export const ModalBankCardItem = withTheme(ModalBankCardItemObserver);
