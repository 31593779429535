import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ComposedChart, Line, Area, YAxis, XAxis, Bar, ReferenceLine, Tooltip, ResponsiveContainer } from 'recharts';
import { withTheme } from 'styled-components';
import { UserImpactDataVisualizationChartContainer } from './styles';
import { CustomTooltip } from './CustomTooltip';
import { CustomDot } from './CustomDot';
import { IThemeProps } from '../../../../styles/themes';
import { useAppData } from '../../../../contexts/app';
import { IUserImpactReportsSummaryMonth, UserMonthlyImpactReportModel } from '../../../../models/userMonthlyImpactReport';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface IProps extends IThemeProps {
  className?: string;
  onDotClick(index: number): void;
  data: IUserImpactReportsSummaryMonth[];
  maxScore: number;
  activeMonthReport: UserMonthlyImpactReportModel;
}

interface IChartItem {
  month: string;
  impactScore: number | null;
  reportId?: string;
}

const mobileMargins = { top: 10, right: 8, bottom: 10, left: -35 };
const desktopMargins = { top: 10, right: 10, bottom: 10, left: 10 };

const UserImpactDataVisualizationChartBase: React.FC<IProps> = ({
  className = '',
  onDotClick, 
  data,
  theme,
  activeMonthReport,
}) => {
  const appData = useAppData();
  const [mappedChartData, setMappedChartData] = useState([]);
  const [ticksArray, setTicksArray] = useState([]);
  const [currentDot, setCurrentDot] = useState(null);

  useEffect(() => {
    let startMonth;
    let endMonth;
    let mappedData: IChartItem[] = [];
    // no data in past 12 months but has transactions
    if (!data && data.length === 0) {
      startMonth = '';
      endMonth = '';
      setMappedChartData(mappedData);
    } else {
      const filteredByYear = data?.filter((item) => dayjs(item.date).add(1, 'M').year() === dayjs(activeMonthReport?.date).year() && item.withinDataRange === true );
      mappedData = filteredByYear.map((item) => { 
        const chartItem = {
          month: dayjs(item.date).add(1, 'month').format('MMM YY'),
          impactScore: 0,
          reportId: item.reportId,
        };

        if (item.score !== null && item.score > 0 && item.score <= 100) chartItem.impactScore = Math.ceil(item.score);
        if (item.score > 100) chartItem.impactScore = 100;
        
        return chartItem;
      });

      startMonth = mappedData[0]?.month;
      endMonth = mappedData[mappedData.length - 1].month;
      setCurrentDot(mappedData.findIndex((item) => item.reportId === activeMonthReport?._id));
    }

    setMappedChartData(mappedData);
    setTicksArray([startMonth, endMonth]);
  }, [data, activeMonthReport]);

  const referenceLines = useMemo(() => [0, 25, 50, 75, 100].map(x => <ReferenceLine key={ `reference-${x}` } y={ x } stroke={ theme.colors.lightGray1 } />), []);

  const onClick = (payload: any) => {
    onDotClick(payload);
    setCurrentDot(payload.index);
  };

  const activeDot: any = useMemo(() => (
    {
      fill: theme.colors.darkGray1,
      stroke: theme.colors.darkGray1,
      r: 6,
      strokeWidth: 4,
      onTouchEnd: (e: any, payload: any) => {
        onClick(payload);
      },
      onClick: (e: any, payload: any) => {
        onClick(payload);
      },
    }
  ), [onClick]);

  const area = useMemo(() => (
    <Area 
      type='linear'
      strokeWidth='0px'
      dataKey='impactScore'
      stroke={ theme.colors.darkGray1 }
      fillOpacity={ 1 }
      isAnimationActive={ false }
      fill='transparent'
    />
  ), []);

  const bar = useMemo(() => (
    <Bar 
      className='bar'
      dataKey='impactScore'
      fill='transparent'
      barSize={ 20 }
      onTouchEnd={ 
        (payload) => {
          onClick(payload);
        } 
      }
      onClick={
        (payload) => {
          onClick(payload);
        } 
      }
    />
  ), []);

  const line = useMemo(() => (
    <Line
      type='linear'
      strokeLinecap='round'
      strokeWidth={ 4 }
      dataKey='impactScore'
      key='impactScore'
      isAnimationActive={ false }
      activeDot={ activeDot }
      dot={ <CustomDot onTouchEnd={ onClick } currentDot={ currentDot } /> }
      stroke={ theme.colors.dark }
      legendType='none'
    />
  ), [currentDot]);
  
  const renderDesktopChart = useCallback(() => (
    <ResponsiveContainer
      className='desktop-chart'
      height={ 300 }
      width='100%'
    >
      <ComposedChart data={ mappedChartData } margin={ appData.isDesktop ? desktopMargins : mobileMargins }>
        <YAxis dataKey='impactScore' tick={ { fontSize: 12, fontWeight: '600' } } domain={ [0, 100] } dx={ appData.isDesktop ? -10 : 0 } />
        <XAxis dataKey='month' tick={ { fontSize: 12, fontWeight: '600' } } scale='point' ticks={ ticksArray } dy={ 10 } />
        { referenceLines }
        <Tooltip
          wrapperStyle={ { outline: 'none', top: '-50px', left: '-30px' } }
          filterNull={ false }
          content={ <CustomTooltip /> }
        />
        { area }
        { bar }
        { line }
      </ComposedChart>
    </ResponsiveContainer>
  ), [data, currentDot, ticksArray, mappedChartData]);

  return (
    <UserImpactDataVisualizationChartContainer className={ className }>
      <div className='chart-wrapper'>
        { renderDesktopChart() }
      </div>
    </UserImpactDataVisualizationChartContainer>
  );
};

export const UserImpactDataVisualizationChart = withTheme(UserImpactDataVisualizationChartBase);
