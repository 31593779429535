import { observer } from 'mobx-react';
import React from 'react';
import { CardsContainer, ContentContainer, HeaderContainer, LinkedCardsPageContainer, PlaidContainer } from './styles';
import { PlaidDisclaimer } from '../../../components/PlaidDisclaimer';
import { useUserSession } from '../../../contexts/user';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { H4 } from '../../../styles/components/header';
import { LinkedBankAccounts } from './LinkedBankAccounts';

interface IProps {
  classname?: string;
}

const LinkedCardsPageBase: React.FC<IProps> = ({
  classname = '',
}) => {
  const userSession = useUserSession();

  if (userSession.authenticating || userSession.loadingCards) return <LoadingSpinner />;

  return (
    <LinkedCardsPageContainer className={ classname }>
      <HeaderContainer>
        <H4>Linked Accounts</H4>
        <p className='link-your-cards-text'>Link your cards through your bank to track your individual impact and view the impact of companies you do business with.</p>
      </HeaderContainer>
      <ContentContainer>
        <CardsContainer>
          { <LinkedBankAccounts className='account-cards' /> }
        </CardsContainer>
        <PlaidContainer>
          <PlaidDisclaimer isWidget />
          <p className='plaid-text'>None of your personal credit card or bank credentials are stored with us, and we never share or sell your data.</p>
        </PlaidContainer>
      </ContentContainer>
    </LinkedCardsPageContainer>
  );
};

export const LinkedCardsPage = observer(LinkedCardsPageBase);
