import styled from 'styled-components';
import { BasicContainer, FlexCol, FlexColCenter } from '../../styles/styles';

export const LatestTransactionsWidgetContainer = styled.div`
  ${BasicContainer}
  min-height: 478px;
  padding: 32px 16px;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    padding: 32px;
  }

  .header-text {
    margin-bottom: 20px;
  }
`;

export const ContentContainer = styled.div`


  h5 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
  }

  p {
    font-weight: 500;
    margin-bottom: 32px;
  }
  
  .no-linked-cards {
    ${FlexColCenter}
    align-items: center;
    height: 100%;
    margin-top: 95px;
    text-align: center;
  }

  .transactions-list {
    ${FlexCol}
    min-height: 380px;
    justify-content: space-between;
  }

  .see-all-transactions {
    align-self: center;
    margin-top: 23px;
  }
`;
