import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useSignInModal } from '../../../../contexts/sign-in';
import { SignInMode } from '../../../../models/sign-in';
import { H1 } from '../../../../styles/components/header';
import { MembershipFormContainer, ModesContainer, SelectedMode, UnselectedMode } from '../styles';
import { Button, ButtonLink } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';

interface IProps {
  className?: string;
}

const MembershipBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const signInModal = useSignInModal();

  useEffect(() => {
    analytics.fireEvent('MembershipModal_Open');
  }, []);

  const onModeClick = useCallback((mode: SignInMode) => () => {
    signInModal.setMode(mode);
    analytics.fireEvent(`SignInModalModeChange_${mode}`);
  }, [signInModal.mode]);

  const onLinkClick = useCallback((linkName: string) => () => {
    analytics.fireEvent(`MembershipModal_LinkClick_${linkName}`);
  }, []);

  const onBecomeAMemberClick = useCallback(() => { 
    analytics.fireEvent('MembershipModal_ApplyClick');
    signInModal.close();
  }, []);

  const content = useMemo(() => (
    <>
      <p className='body-text'>Join a community of 12,000+ members.</p>
      <p className='yellow-text'>Earn up to $300 in cashback per year.*</p>
      <ButtonLink
        className='become-a-member-cta'
        href='/apply'
        kind={ ButtonKind.Primary }
        onClick={ onBecomeAMemberClick }
      >
        Become a Member
      </ButtonLink>
      <p className='disclaimer-text'>*est, $500 average monthly spend on the Karma Wallet Card</p>
    </>
  ), [onBecomeAMemberClick]);

  const legalDisclaimer = useMemo(() => (
    <p className='legal white'>By registering, you agree to Karma Wallet's <a onClick={ onLinkClick('TermsConditions') } className='white' target='_blank' href='https://cdn.karmawallet.io/terms_of_service.pdf' rel='noreferrer'>Terms of Service</a> and <a onClick={ onLinkClick('PrivacyPolicy') } className='white' target='_blank' href='/privacy-policy' rel='noreferrer'>Privacy Policy</a></p>
  ), [onLinkClick]);

  return (
    <MembershipFormContainer className={ className }>
      <ModesContainer>
        <SelectedMode>Karma Wallet Card</SelectedMode>
        <UnselectedMode onClick={ onModeClick(SignInMode.CREATE_ACCOUNT) }>Karma Score</UnselectedMode>
      </ModesContainer>
      <H1 className='membership-header'>Your membership to a better future.</H1>
      { content }
      { legalDisclaimer }
      <Button
        className='have-account-cta'
        kind={ ButtonKind.Blank }
        onClick={ onModeClick(SignInMode.SIGN_IN) }
      >
        Have an account?
      </Button>
    </MembershipFormContainer>
  );
};

export const Membership = observer(MembershipBase);
