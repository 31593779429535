import React from 'react';
import { ButtonsContainer, NotReadySectionContainer } from './styles';
import { H2MobileMarketing, H5 } from '../../styles/components/header';
import { Button, ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { useSignInModal } from '../../contexts/sign-in';
import { SignInMode } from '../../models/sign-in';
import { useUserSession } from '../../contexts/user';

interface IProps {
  className?: string;
}

export const NotReadySection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const signInModal = useSignInModal();
  const user = useUserSession();

  const onApplyForCardClick = () => {
    analytics.fireEvent('App_NotReady_ApplyForCard_Click');
  };

  const onKarmaScoreClick = () => {
    signInModal.open(SignInMode.CREATE_ACCOUNT);
    analytics.fireEvent('App_NotReady_KarmaScore_Click');
  };

  if (user.isLoggedIn) return null;
  
  return (
    <NotReadySectionContainer className={ className }>
      <H2MobileMarketing className='not-ready-header'>Not ready for the Karma Wallet Card?</H2MobileMarketing>
      <H5 className='karma-score-text'>Access your Karma Score for free to learn the impact of your purchases!</H5>
      <ButtonsContainer>
        <ButtonLink
          className='join-button'
          href='/apply'
          onClick={ onApplyForCardClick }
          kind={ ButtonKind.PrimaryGhost }
        >
          Apply for the Card
        </ButtonLink>
        <Button
          className='join-button karma-score'
          onClick={ onKarmaScoreClick }
          kind={ ButtonKind.Secondary }
        >
          Discover Karma Score
        </Button>
      </ButtonsContainer>
    </NotReadySectionContainer>
  );
};
