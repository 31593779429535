import React, { useCallback, useRef, useState } from 'react';
import { TransactionModel } from '../../models/transactions';
import { LatestTransactionItemContainer, LeftContainer, RightContainer, TransactionAmount, TransactionAmountTooltip, TransactionAmountTooltipAnchor, ValuesContainer } from './styles';
import { useAnalytics } from '../../contexts/analytics-store';
import dayjs from 'dayjs';
import { H6 } from '../../styles/components/header';
import { formatCurrency } from '../../lib/formatters';
import { InfoIcon } from '../svgs/icons/InfoIcon';
import { theme } from '../../styles/themes';
import { TinyPopover } from '../TinyPopover';

interface IProps {
  className?: string;
  transaction: TransactionModel;
  companyValues: any;
}

export const LatestTransactionItem: React.FC<IProps> = ({
  className = '',
  transaction,
  companyValues,
}) => {
  const analytics = useAnalytics();
  const now = useRef(dayjs().local()).current;
  const [amountTooltipTimeout, setAmountTooltipTimeout] = useState<number>(null);
  const [showAmountTooltip, setShowAmountTooltip] = useState(false);
  
  const onTransactionClick = useCallback(() => {
    analytics.fireEvent('Account_LatestTransactionItem_Click');
  }, []);

  const onAmountTooltipClick = useCallback(() => {
    if (!!amountTooltipTimeout) return null;
    analytics.fireEvent('Account_LatestTransactionItem_Tooltip_Click');
    setShowAmountTooltip(true);

    setAmountTooltipTimeout(window.setTimeout(() => {
      window.clearTimeout(amountTooltipTimeout);
      setShowAmountTooltip(false);
      setAmountTooltipTimeout(null);
    }, 5000));
  }, [amountTooltipTimeout]);

  const renderTransactionAmount = () => {
    const anchor = (
      <TransactionAmountTooltipAnchor onClick={ onAmountTooltipClick }>
        <InfoIcon fill={ theme.colors.lightGray1 } />
      </TransactionAmountTooltipAnchor>
    );

    return (
      <TransactionAmount className={ transaction.reversed ? 'reversed' : '' }>
        <span>${ formatCurrency(transaction.amount) }</span>
        {
          transaction.reversed && (
            <TinyPopover
              anchor={ anchor }
              engageOnHover={ true }
              isOpen={ showAmountTooltip }
              placement={ ['top', 'bottom'] }
            >
              <TransactionAmountTooltip>
                This transaction appears to have been reversed. Common reasons for this are due to a refund, or the transaction being cancelled.
              </TransactionAmountTooltip>
            </TinyPopover>
          )
        }
      </TransactionAmount>
    );
  };

  return (
    <LatestTransactionItemContainer className={ className }>
      <LeftContainer
        href={ `/company/${transaction.company._id}/${transaction.company.slug}` }
        onClick={ onTransactionClick }
      >
        <H6 className='company-name'>{ transaction.company.companyName }</H6>
        <p className='date'>{ dayjs.utc(transaction.date).format(`MMM DD${dayjs.utc(transaction.date).year() !== now.year() ? ', YYYY' : '' }`) }</p>
        <ValuesContainer>
          { companyValues.values && companyValues.values.map((value: any) => <img key={ value.name } src={ value.icon } />) }
        </ValuesContainer>
      </LeftContainer>
      <RightContainer>
        { renderTransactionAmount() }
      </RightContainer>
    </LatestTransactionItemContainer>
  );
};
