import React from 'react';
import { HomeDisclaimerSectionContainer, InnerWrapper } from './styles';

interface IProps {
  className?: string;
}

export const HomeDisclaimerSection: React.FC<IProps> = ({
  className = '',
}) => (
  <HomeDisclaimerSectionContainer className={ className }>
    <InnerWrapper>
      <p>*This optional offer is not a Pathward product or service nor does Pathward endorse this offer. Up to 20%* cashback with eligible brands.</p>
      <p>
      The Karma Wallet Visa® Prepaid Card is issued by Pathward®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Karma Wallet Visa® Prepaid Card is powered by Marqeta. The Karma Wallet Visa® Prepaid Card can be used everywhere Visa debit cards are accepted.
      </p>
    </InnerWrapper>
  </HomeDisclaimerSectionContainer>
);
