import styled from 'styled-components';
import { BasicContainer, FlexCenter, FlexCol } from '../../../../styles/styles';
import { PlaidButton } from '../../../../components/PlaidButton';

export const BankAccountsContainer = styled.div`
  ${FlexCol}
  gap: 10px;
`;

export const ErrorLoadingCards = styled.div`
  ${FlexCenter}
  color: ${({ theme }) => theme.colors.disabled};
  height: 100px;
`;

export const LoadingSpinnerContainer = styled.div`
  height: 100px;
  position: relative;
`;

export const AddAccountButton = styled(PlaidButton)`
  ${BasicContainer}
  ${FlexCenter}
  border-radius: 16px;
  gap: 16px;
  height: 128px;
  width: 100%;

  .add-account-text {
    font-size: 18px;
  }
`;
