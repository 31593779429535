import React from 'react';
import { ErrorComponentContainer } from './styles';

interface IProps {
  className?: string;
  text: string;
}

export const ErrorComponent: React.FC<IProps> = ({
  className = '',
  text,
}) => (
  <ErrorComponentContainer className={ className }>
    <div>{ text }</div>
  </ErrorComponentContainer>
);
