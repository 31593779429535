import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const NoBankLogo: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}no-bank-logo-graphic` }
    id={ id }
    width={ 126 }
    height={ 123 }
  >
    <g clipPath='url(#clip0_4718:70511)'>
      <path d='M120.16 105.62H5.84375V112.17H120.16V105.62Z' fill='#BABAC2' />
      <path d='M116.964 98.0361H9.54785V103.814H116.964V98.0361Z' fill='#BABAC2' />
      <path d='M116.964 35.0679H9.54785V40.8457H116.964V35.0679Z' fill='#BABAC2' />
      <path d='M126 115.778H0V123H126V115.778Z' fill='#BABAC2' />
      <path d='M63.2576 0L10.2402 33.2625H116.271L63.2576 0Z' fill='#BABAC2' />
      <path d='M28.1249 40.8457H15.5V98.0358H28.1249V40.8457Z' fill='#BABAC2' />
      <path d='M69.5702 40.8457H56.9453V98.0358H69.5702V40.8457Z' fill='#BABAC2' />
      <path d='M111.013 40.8457H98.3877V98.0358H111.013V40.8457Z' fill='#BABAC2' />
      <path d='M11.9033 40.8457H31.7424V42.7524C31.7424 43.2313 31.5524 43.6905 31.2142 44.0291C30.876 44.3678 30.4172 44.558 29.9389 44.558H13.7069C13.2285 44.558 12.7698 44.3678 12.4316 44.0291C12.0933 43.6905 11.9033 43.2313 11.9033 42.7524V40.8457Z' fill='#BABAC2' />
      <path d='M53.082 40.8457H72.9211V42.7524C72.9211 43.2313 72.7311 43.6905 72.3929 44.0291C72.0547 44.3678 71.5959 44.558 71.1176 44.558H54.8856C54.4073 44.558 53.9485 44.3678 53.6103 44.0291C53.272 43.6905 53.082 43.2313 53.082 42.7524V40.8457Z' fill='#BABAC2' />
      <path d='M94.7803 40.8457H114.619V42.7524C114.619 43.2313 114.429 43.6905 114.091 44.0291C113.753 44.3678 113.294 44.558 112.816 44.558H96.5838C96.1055 44.558 95.6467 44.3678 95.3085 44.0291C94.9703 43.6905 94.7803 43.2313 94.7803 42.7524V40.8457Z' fill='#BABAC2' />
      <path d='M114.623 98.0361H94.7839V96.1294C94.7839 95.6506 94.9739 95.1913 95.3122 94.8527C95.6504 94.5141 96.1092 94.3239 96.5875 94.3239H112.819C113.298 94.3239 113.757 94.5141 114.095 94.8527C114.433 95.1913 114.623 95.6506 114.623 96.1294V98.0361Z' fill='#BABAC2' />
      <path d='M73.4336 98.0361H53.5945V96.1294C53.5945 95.6506 53.7845 95.1913 54.1227 94.8527C54.461 94.5141 54.9197 94.3239 55.398 94.3239H71.63C72.1084 94.3239 72.5671 94.5141 72.9053 94.8527C73.2436 95.1913 73.4336 95.6506 73.4336 96.1294V98.0361Z' fill='#BABAC2' />
      <path d='M31.7354 98.0361H11.8962V96.1294C11.8962 95.6506 12.0863 95.1913 12.4245 94.8527C12.7627 94.5141 13.2215 94.3239 13.6998 94.3239H29.9318C30.4101 94.3239 30.8689 94.5141 31.2071 94.8527C31.5453 95.1913 31.7354 95.6506 31.7354 96.1294V98.0361Z' fill='#BABAC2' />
    </g>
    <defs>
      <clipPath id='clip0_4718:70511'>
        <rect width='126' height='123' fill='#fff' />
      </clipPath>
    </defs>
  </SvgIcon>
);
