import styled from 'styled-components';

export const TextWithUpArrowContainer = styled.div`
  display: flex;

  h5 {
    color: white;
    margin-right: 12px;
    margin-left: 5px;
    line-height: 1.2;
  }

  .up-arrow-curled {
    margin-bottom: 12px;
  }

  .left {
    transform: scaleX(-1);
    left: -25px;
    position: absolute;
  }
`;
