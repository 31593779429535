import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { LeftContent, PayoutItem, PayoutsContainer, PayoutsList, RightContent } from './styles';
import { DollarIcon } from '../../../../components/svgs/icons/DollarIcon';
import { AccountHubH2 } from '../../../../styles/components/header';
import { ButtonLink } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { IPayoutSummary } from '../../../../models/commisions';

interface IProps {
  className?: string;
  payouts: IPayoutSummary[];
  loading: boolean;
}

const PayoutsBase: React.FC<IProps> = ({
  className,
  payouts,
  loading,
}) => {
  const analytics = useAnalytics();

  const onViewPayoutsClick = () => {
    analytics.fireEvent('AccountHub_Cashback_ViewPayout_Click');
  };

  const pastPayouts = useMemo(() => {
    let content: JSX.Element[] | JSX.Element = [];

    if (payouts.length > 0) {
      content = payouts.map(({ date, amount, _id } : any) => (
        <PayoutItem key={ _id }>
          <ButtonLink
            href={ `/account/payout-summary/${_id}` }
            onClick={ onViewPayoutsClick }
            kind={ ButtonKind.Blank }
            label='View Payout Details'
          >
            <LeftContent>
              <div className='dollar-icon-container'><DollarIcon /></div>
              <div className='date'>{ dayjs(date).format('MMMM YYYY') } Payment</div>
            </LeftContent>
            <RightContent>
              ${ amount.toFixed(2) }
            </RightContent>
          </ButtonLink>
        </PayoutItem>
      ));
    }

    if (loading) return content = <p className='no-info-past-payout'>Loading</p>;

    if (!payouts.length) return content = <p className='no-info-past-payout'>You have no past payouts</p>;

    return (
      <PayoutsList>
        { content }
      </PayoutsList>
    );

  }, [payouts]);

  if (!payouts) return null;

  return (
    <PayoutsContainer className={ className }>
      <AccountHubH2 id='payouts'>Past Payouts</AccountHubH2>
      { pastPayouts }
    </PayoutsContainer>
  );
};

export const Payouts = observer(PayoutsBase);
