import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { GenericTagContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  tagText: string;
  color: string;
}

export const GenericTagBase: React.FC<IProps> = ({
  className = '',
  tagText,
  color,
}) => (
  <GenericTagContainer
    className={ className }
    backgroundColor={ color }
  >
    { tagText }
  </GenericTagContainer>
);

export const GenericTag = withTheme(GenericTagBase);
