import styled from 'styled-components';
import { BasicContainer, FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextMDMedium, TextSMMedium, TextSMDemiBold, TextXSBold } from '../../styles/components/text';
import { AllBrandsOffersWidgetLocation } from '.';

interface Iprops {
  location: AllBrandsOffersWidgetLocation;
}

export const AllBrandsWidgetContainer = styled.div`
  ${BasicContainer}
  padding: 32px 16px;
  position: relative;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    padding: 32px;
  }
`;

export const HeaderContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;

  .inner-container {
    ${FlexHorizontalCenter}
    gap: 10px;
  }

  h2 {
    margin: unset;
  }

  .karma-card {
    width: 34px;
  }

  .see-all-button {
    ${FlexRow}
    ${TextXSBold}
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    gap: 8px;
    line-height: 12px;

    svg {
      height: 15px;
    }

    @media (${({ theme }) => theme.breakpoints.xsMin}) {
      font-size: 14px;
      line-height: 13px;
    }
  }
`;

export const DescriptionContainer = styled.div`
  margin: 12px 0 24px;

  p {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey5};
    margin: unset;
  }

  .take-advantage {
    margin-top: 4px;
  }

  .get-card {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    text-decoration: none;
  }
`;

export const LoadingSpinnerContainer = styled.div<Iprops>`
  bottom: ${({ location }) => (location === AllBrandsOffersWidgetLocation.Dashboard ? '50%' : '0')};
  left: 50%;
  position: absolute;
  z-index: 1;
`;

export const CashbackCardsContainer = styled.div<Iprops>`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-evenly;
  margin-top: 24px;
  min-height: ${({ location }) => (location === AllBrandsOffersWidgetLocation.Dashboard ? '390px' : '50px')};;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    justify-content: flex-start;
  }

  .company-placeholder {
    height: auto;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.xxlMin}) {
      width: calc((100% / 5) - (16px * 4 / 5));
    }

    @media (${({ theme }) => theme.breakpoints.xlMax}) {
      width: calc((100% / 4) - (16px * 3 / 4));
    }

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      width: calc((100% / 3) - (16px * 2 / 3));
    }

    @media (${({ theme }) => theme.breakpoints.xsMax}) {
      width: calc((100% / 2) - (16px * 2 / 3));
    }
  }
`;

export const SearchContainer = styled.div`
  ${FlexCol}
  margin: 20px auto;
  max-width: 420px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    margin: 24px auto;
  }
`;

export const SearchInnerContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  
  .text-field {
    margin: unset;
    width: 100%;

    svg {
      margin-right: 3px;
    }
  }
  
  input.pill-text-field {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.midGray};
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 500;

    ::placeholder {
      color: ${({ theme }) => theme.colors.dark};
      font-weight: 400;
    }
  }

  input.pill-text-field:focus:not(:disabled) {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    font-weight: 500;
  }

  .input-container.small input.pill-text-field {
    ${TextMDMedium}
    padding: 13px 35px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 16px;

    .input-container.small input.pill-text-field {
      font-size: 16px;
      padding: 13px 35px;
    }
  }
`;

export const GetCardSection = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  text-align: center;

  p {
    ${TextSMDemiBold}
    margin: unset;
  }
`;
