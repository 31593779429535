import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

interface IProps {
  backgroundImage?: string;
}

export const ApplyForCardContainer = styled.div<IProps>`
  ${FlexCol}
  align-items: center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  justify-content: space-between;
  min-height: 239px;
  width: 100%;
  padding: 24px;

  .sub-text {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 10px;
    text-align: center;
  }

  .text-wrapper {
    text-align: center;
    margin-bottom: 10px;

    .inverted-text {
      color: transparent;
      font-size: 38px;
      line-height: 38px;
      text-align: center;
      display: inline;
      margin-right: 10px;

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        font-size: 43px;
        line-height: 43px;
        margin-right: 12px;
      }
    }

    .regular-text {
      color: ${({ theme }) => theme.colors.white};
      display: inline;
      font-size: 38px;
      line-height: 38px;
      position: relative;
      z-index: 1;

      &.underlined-word {
        padding-left: 10px;
      }

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        font-size: 43px;
        line-height: 43px;
      }
    }

    .underlined-text-wrapper {
      position: relative;

      &.block {
        display: block;
      }

      #form-text-underline {
        bottom: 0px;
        left: 9px;
        position: absolute;
        width: 95%;

        @media (${({ theme }) => theme.breakpoints.smMax}) {
          bottom: -2px;
        }
      }
    }

    .underlined-word-wrapper {
      position: relative;
    }
  }

  .top-line-wrapper {
    display: inline;

    .inverted-text {
      display: inline;
    }

    .regular-text {
      padding: 0 10px;
    }
  }

  .apply-button {
    font-weight: 600;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 16px;
    }
  }
`;
