import React from 'react';
import { CustomTooltipContainer } from '../styles';

export const CustomTooltip = ({
  active,
  payload,
  label,
}: any) => {
  if (active && payload && payload.length) {
    const hasScore = payload[0].value !== undefined;
    return (
      <CustomTooltipContainer>
        <p className='label'>{ hasScore ? label : `${label} (no data)` }</p>
      </CustomTooltipContainer>
    );
  } 
  
  return null;
};
