import styled from 'styled-components';
import { MaxBoundaryLarge, Section } from '../../styles/components/containers';
import { FlexCenter, FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextSM, TextSMMedium } from '../../styles/components/text';

export const WhatIsTheKarmaWalletCardSectionContainer = styled(Section)`
  overflow: hidden;
`;

export const InnerContainer = styled.div`
  ${MaxBoundaryLarge}
  position: relative;

  .print-wave-a {
    height: 637px;
    position: absolute;
    right: -100%;
    top: -4%;
    transform: rotate(-77deg);
    width: 849px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      right: -54%;
      transform: rotate(-64deg);
    }

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: -12%;
      top: -35%;
    }
  }
`;

export const TopSectionContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 35px;
  margin: auto;
  max-width: 100%;
  position: relative;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-between;
    max-width: 1152px;
  }
`;

export const LeftContent = styled.div`
  ${FlexColCenter}
  align-items: flex-start;
  gap: 24px;
  max-width: 560px;
  text-align: left;
`;

export const AppButtonsWrapper = styled.div`
  ${FlexRow}
  align-items: flex-start;
  gap: 12px;
  margin-top: 18px;

  img {
    height: 38px;
  }
`;

export const RightContent = styled.div`
  ${FlexColCenter}

  h5 {
    color: ${({ theme }) => theme.colors.dark};
  }

  .karma-card {
    display: block;
    margin: 0 auto;
    max-width: 356px;

    @media (${({ theme }) => theme.breakpoints.smMax}) {
      width: 90%;
    }
  }

  .recycled-plastic-text {
    ${FlexCenter}
    margin-top: 10px;

    svg {
      position: relative;
      left: -6px;
      top: 6px;
    }
  }
`;

export const BottomSectionContainer = styled.div`
  ${FlexHorizontalCenter}
  gap: 5px;
  justify-content: center;
  margin: auto;
  margin-top: 44px;
  position: relative;
  z-index: 1;

  .dotted-line {
    margin-top: -67px;
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: none;
    }
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    ${FlexCol}

    .dotted-line {
      display: inline;
      margin-left: -177px;
      margin-top: 0;
      max-width: 875px;
    }
  }
`;

export const StepsContainer = styled.div`
  ${FlexColCenter}
  gap: 20px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    flex-wrap: wrap;
  }
`;

export const StepContainer = styled.div`
  ${FlexCol}
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.green2};
  border-radius: 8px;
  max-width: 273px;
  min-height: 126px;
  padding: 16px;

  p {
    margin: unset;
  }

  .step {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }

  .title {
    ${TextSMMedium}
    line-height: 20px;
  }

  .description {
    ${TextSM}
    color: ${({ theme }) => theme.colors.grey5};
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    max-width: 96%;
    width: 96%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    flex: 1 1 auto;
    max-width: calc(50% - 10px);
  }

  @media (min-width: 1200px) {
    max-width: 273px;
  }
`;
