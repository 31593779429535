import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Transactions: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}transactions-graphic` }
    id={ id }
    height={ 89 }
    width={ 90 }
  >
    <mask id='path-1-outside-1' maskUnits='userSpaceOnUse' x='2.64746' y='1.95691' width='73' height='84' fill='black'>
      <rect fill='#fff' x='2.64746' y='1.95691' width='73' height='84' />
      <path d='M66.7475 13.1897V81.4655L56.3974 75.5379L46.0475 81.5431L35.6974 75.5379L25.3475 81.5431L14.9974 75.5379L4.64746 81.5431V10.1638C4.64746 8.51763 5.3014 6.93888 6.46541 5.77486C7.62943 4.61084 9.20819 3.95691 10.8544 3.95691H72.9233' />
    </mask>
    <path d='M66.7475 13.1897V81.4655L56.3974 75.5379L46.0475 81.5431L35.6974 75.5379L25.3475 81.5431L14.9974 75.5379L4.64746 81.5431V10.1638C4.64746 8.51763 5.3014 6.93888 6.46541 5.77486C7.62943 4.61084 9.20819 3.95691 10.8544 3.95691H72.9233' fill='#fff' />
    <path d='M68.7475 13.1897C68.7475 12.0851 67.852 11.1897 66.7475 11.1897C65.6429 11.1897 64.7475 12.0851 64.7475 13.1897H68.7475ZM66.7475 81.4655L65.7535 83.2011L68.7475 84.9157V81.4655H66.7475ZM56.3974 75.5379L57.3914 73.8024L56.3909 73.2295L55.3937 73.808L56.3974 75.5379ZM46.0475 81.5431L45.0438 83.273L46.0475 83.8554L47.0512 83.273L46.0475 81.5431ZM35.6974 75.5379L36.7011 73.808L35.6974 73.2257L34.6937 73.808L35.6974 75.5379ZM25.3475 81.5431L24.3438 83.273L25.3475 83.8554L26.3512 83.273L25.3475 81.5431ZM14.9974 75.5379L16.0011 73.808L14.9974 73.2257L13.9937 73.808L14.9974 75.5379ZM4.64746 81.5431H2.64746V85.0158L5.65117 83.273L4.64746 81.5431ZM72.9233 5.95691C74.0279 5.95691 74.9233 5.06148 74.9233 3.95691C74.9233 2.85234 74.0279 1.95691 72.9233 1.95691V5.95691ZM64.7475 13.1897V81.4655H68.7475V13.1897H64.7475ZM67.7414 79.73L57.3914 73.8024L55.4035 77.2735L65.7535 83.2011L67.7414 79.73ZM55.3937 73.808L45.0437 79.8132L47.0512 83.273L57.4011 77.2679L55.3937 73.808ZM47.0512 79.8132L36.7011 73.808L34.6937 77.2679L45.0438 83.273L47.0512 79.8132ZM34.6937 73.808L24.3437 79.8132L26.3512 83.273L36.7011 77.2679L34.6937 73.808ZM26.3512 79.8132L16.0011 73.808L13.9937 77.2679L24.3438 83.273L26.3512 79.8132ZM13.9937 73.808L3.64375 79.8132L5.65117 83.273L16.0011 77.2679L13.9937 73.808ZM6.64746 81.5431V10.1638H2.64746V81.5431H6.64746ZM6.64746 10.1638C6.64746 9.04806 7.09069 7.97802 7.87963 7.18908L5.0512 4.36065C3.51211 5.89974 2.64746 7.98721 2.64746 10.1638H6.64746ZM7.87963 7.18908C8.66857 6.40013 9.73861 5.95691 10.8544 5.95691V1.95691C8.67776 1.95691 6.5903 2.82155 5.0512 4.36065L7.87963 7.18908ZM10.8544 5.95691H72.9233V1.95691H10.8544V5.95691Z' fill='#323232' mask='url(#path-1-outside-1)' />
    <path d='M43.1302 34.8362C41.766 36.1071 40.0206 36.8925 38.1647 37.0707V40.2672H34.7198V37.1638C33.331 37.0778 31.9565 36.8331 30.6233 36.4345C29.4933 36.1043 28.4167 35.613 27.4268 34.9758L29.3043 30.7707C30.2672 31.3927 31.3116 31.8783 32.4078 32.2138C33.5491 32.5659 34.7358 32.7488 35.9302 32.7569C38.1026 32.7569 39.2043 32.2138 39.2043 31.1121C39.202 30.8292 39.1109 30.5541 38.9439 30.3258C38.7768 30.0975 38.5422 29.9274 38.2733 29.8396C37.2988 29.454 36.2912 29.158 35.2629 28.9552C33.9727 28.6922 32.7024 28.3396 31.4612 27.9C30.4456 27.5379 29.5418 26.9176 28.8388 26.1C28.0681 25.1644 27.6751 23.9744 27.7371 22.7638C27.7293 21.9695 27.8851 21.1821 28.1948 20.4507C28.5045 19.7192 28.9614 19.0593 29.5371 18.5121C30.9623 17.231 32.7766 16.466 34.6888 16.3397V13.2362H38.1336V16.3397C39.1839 16.4412 40.2228 16.6385 41.2371 16.9293C42.1772 17.182 43.0798 17.5577 43.9216 18.0466L42.1681 22.2827C40.4655 21.3056 38.5446 20.7721 36.5819 20.731C35.7244 20.664 34.865 20.8359 34.0992 21.2276C33.8691 21.3603 33.677 21.5498 33.5411 21.778C33.4053 22.0062 33.3303 22.2655 33.3233 22.531C33.3291 22.8057 33.4226 23.0713 33.5901 23.2891C33.7576 23.5068 33.9903 23.6653 34.2543 23.7414C35.2105 24.1228 36.2038 24.4036 37.2181 24.5793C38.5139 24.8403 39.7895 25.1929 41.0354 25.6345C42.0399 26.0018 42.9362 26.6154 43.6422 27.4189C44.4243 28.3429 44.824 29.5308 44.7595 30.7397C44.7738 31.4965 44.6371 32.2486 44.3574 32.952C44.0776 33.6554 43.6604 34.2959 43.1302 34.8362Z' fill='#323232' />
    <path d='M84.8713 62.1608L86.3683 63.0182V61.2931V10.0862C86.3683 8.17478 85.609 6.34168 84.2575 4.99012C82.9059 3.63856 81.0728 2.87927 79.1614 2.87927H76.058C74.1466 2.87927 72.3135 3.63856 70.9619 4.99012C69.6104 6.34168 68.8511 8.17478 68.8511 10.0862V58.3603V60.0957L70.3525 59.2255L75.0213 56.5196L84.8713 62.1608Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M32.5786 50.431H58.9734' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M12.4062 50.431H26.3873' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M12.4062 58.1897H41.9045' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M48.1113 58.1897H58.9734' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M37.249 65.9482H58.9732' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M12.4062 65.9482H31.0425' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
  </SvgIcon>
);
