import React from 'react';
import { H2MobileMarketing, H5 } from '../../styles/components/header';
import { AppButtonsWrapper, BottomSectionContainer, InnerContainer, LeftContent, RightContent, StepContainer, StepsContainer, TopSectionContainer, WhatIsTheKarmaWalletCardSectionContainer } from './styles';
import { theme } from '../../styles/themes';
import { ApplyButton } from '../ApplyButton';
import { ButtonKind } from '../Button/styles';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { TextWithArrowPosition, TextWithUpArrow } from '../TextWithUpArrow';
import { PrintWaveA } from '../svgs/shapes/PrintWave';
import { useAppData } from '../../contexts/app';
import AppStoreBadge from '../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../public/assets/images/google-play-badge.svg';
import KarmaCardImage from '../../../public/assets/images/empty-kw-card.png';

interface Iprops {
  className?: string;
}

interface IStep {
  step: string;
  title: string;
  description: string;
  id: string;
}

const stepsData: IStep[] = [
  {
    step: 'Step 1',
    title: 'Apply for the Karma Wallet Card.',
    description: 'We\'ll verify your identity then ship out your new card.',
    id: '1',
  },
  {
    step: 'Step 2',
    title: 'Download the Karma Wallet app.**',
    description: 'Our app helps you manage your money and track your impact.',
    id: '2',
  },
  {
    step: 'Step 3',
    title: 'Transfer money from your bank.',
    description: 'There\'s no transfer fees, and direct deposit moves money fast.',
    id: '3',
  },
  {
    step: 'Step 4',
    title: 'Create impact.',
    description: 'Start earning cashback and donating to nonprofits with your purchases.',
    id: '4',
  },
];

export const WhatIsTheKarmaWalletCardSection: React.FC<Iprops> = ({
  className,
}) => {
  const analytics = useAnalytics();
  const app = useAppData();
  const dottedLine = app.isLargeDesktop ? 'https://cdn.karmawallet.io/uploads/VCQrJJK5Oh-horizontal-dotted-line.svg' : 'https://cdn.karmawallet.io/uploads/eKWDvjM9lJ-vertical-dotted-line.svg';

  const onAppleClick = () => {
    analytics.fireEvent('AppPage_WhatIsKarmaSection_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('AppPage_WhatIsKarmaSection_GoogleStore_Click');
  };
  
  const appButtons = (
    <AppButtonsWrapper>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onAppleClick }
        href='https://apps.apple.com/app/karma-wallet/id6451440075'
      >
        <img src={ AppStoreBadge } alt='App Store Badge' />
      </ButtonLink>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onGoogleClick }
        href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
      >
        <img className='google-button' src={ GooglePlayBadge } alt='Google Play Badge' />
      </ButtonLink>
    </AppButtonsWrapper>
  );

  const topSection = (
    <TopSectionContainer>
      <LeftContent>
        <H2MobileMarketing>What is the Karma Wallet Card?</H2MobileMarketing>
        <H5>The Karma Wallet Card is a prepaid, reloadable debit card integrated with an easy-to-use app that helps you reach your financial and sustainability goals.</H5>
        <ApplyButton locationOfButton='App_WhatIsKarmaCardSection' kind={ ButtonKind.Primary } />
        { appButtons }
      </LeftContent>
      <RightContent>
        <img
          alt='Karma Wallet Card'
          className='karma-card'
          src={ KarmaCardImage }
        />
        <TextWithUpArrow
          className='recycled-plastic-text'
          text='85% recycled plastic'
          arrowPosition={ TextWithArrowPosition.Left }
          color={ theme.colors.dark }
        />
      </RightContent>
    </TopSectionContainer>
  );

  const bottomSection = (
    <BottomSectionContainer>
      <img className='dotted-line' src={ dottedLine } />
      <StepsContainer>          
        { 
          stepsData.map(({ step, title, description, id }) => (
            <StepContainer key={ id }>
              <p className='step'>{ step }</p>
              <p className='title'>{ title }</p>
              <p className='description'>{ description }</p>
            </StepContainer>
          ))
        }
      </StepsContainer>
    </BottomSectionContainer>
  );

  return (
    <WhatIsTheKarmaWalletCardSectionContainer className={ className } backgroundColor={ theme.colors.green2 }>
      <InnerContainer>
        <PrintWaveA />
        { topSection }
        { bottomSection }
      </InnerContainer>
    </WhatIsTheKarmaWalletCardSectionContainer>
  );
};
