import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useRef } from 'react';
import { withTheme } from 'styled-components';
import { CompanyName, CompanyNameContainer, ImpactReportTransactionItemContainer, TransactionAmount, TransactionAmountContainer, TransactionCompanyInfoContainer, TransactionDate } from './styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { formatCurrency } from '../../lib/formatters';
import { CompanyLogo } from '../CompanyLogo';
import { CompanyRatingTag } from '../CompanyRatingTag';
import { IThemeProps } from '../../styles/themes';
import { IUserImpactTransaction } from '../../models/userMonthlyImpactReport';

dayjs.extend(utc);

interface IProps extends IThemeProps {
  className?: string;
  transaction: IUserImpactTransaction;
}

const ImpactReportTransactionItemBase: React.FC<IProps> = ({
  className = '',
  transaction,
}) => {
  const analytics = useAnalytics();
  const now = useRef(dayjs().local()).current;

  const onTransactionClick = useCallback(() => {
    analytics.fireEvent('UserImpactReport_Company_Click');
  }, []);

  const renderTransactionAmount = () => (
    <TransactionAmount>
      <span>${ formatCurrency(transaction.amount) }</span>
    </TransactionAmount>
  );

  const renderTransactionDate = () => (
    <TransactionDate>
      { dayjs(transaction.date).format(`MMM DD${dayjs(transaction.date).year() !== now.year() ? ', YYYY' : '' }`) }
    </TransactionDate>
  );

  return (
    <ImpactReportTransactionItemContainer className={ className }>
      <TransactionCompanyInfoContainer>
        <CompanyLogo
          className='company-logo'
          company={ transaction.company }
        />
        <CompanyNameContainer>
          <CompanyName
            to={ `/company/${transaction.company._id}/${transaction.company.slug}` }
            onClick={ onTransactionClick }
          >
            { transaction.company.companyName }
          </CompanyName>
        </CompanyNameContainer>
      </TransactionCompanyInfoContainer>
      <TransactionAmountContainer>
        <CompanyRatingTag className='transaction-rating-tag' rating={ transaction.company.rating } />
        { renderTransactionAmount() }
        { renderTransactionDate() }
      </TransactionAmountContainer>
    </ImpactReportTransactionItemContainer>
  );
};

export const ImpactReportTransactionItem = withTheme(ImpactReportTransactionItemBase);
