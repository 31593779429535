import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { TransactionModel, TransactionsModel } from '../../models/transactions';
import { ContentContainer, LatestTransactionsWidgetContainer } from './styles';
import { AccountHubH2, H5 } from '../../styles/components/header';
import { useUserSession } from '../../contexts/user';
import { ReceiptIcon } from '../svgs/icons/Receipt';
import { ButtonKind } from '../Button/styles';
import { LoadingSpinnerContainer } from '../loading/LoadingSpinner/styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { LatestTransactionItem } from '../LatestTransactionItem';
import { PlaidButton } from '../PlaidButton';
import { ButtonLink } from '../Button';
import { ValuesModel } from '../../models/values';
import { Tooltip } from '../Tooltip';

interface IProps {
  className?: string;
}

export const LatestTransactionsWidgetBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const transactionsModel = useRef(new TransactionsModel()).current;
  const valuesModel = useRef(new ValuesModel()).current;
  const [transactionsListLoaded, setTransactionsListLoaded] = useState(false);

  const loadRecentTransactionsAndValues = async () => {
    await transactionsModel.loadRecentTransactions()
      .then(async () => {
        const transactionCompaniesIds = transactionsModel.mostRecentTransactions.map(transaction => transaction.company?._id);
        await valuesModel.loadCompaniesValues(transactionCompaniesIds);
      })
      .catch(() => {
        analytics.fireEvent('Account_LoadRecentTransactions_Error');
      })
      .finally(() => {
        setTransactionsListLoaded(true);
      });
  };

  useEffect(() => {
    loadRecentTransactionsAndValues();
  }, []);

  const onLinkCardClick = useCallback(() => {
    analytics.fireEvent('Account_LatestTransactions_LinkCard_Click');
  }, [transactionsModel.mostRecentTransactions]);

  const onLinkSuccess = useCallback(() => {
    analytics.fireEvent('Account_LatestTransactions_Plaid_Success');
  }, []);

  const onSeeAllTransactionsClick = useCallback(() => {
    analytics.fireEvent('Account_SeeAllTransactions_Click');
  }, []);

  const plaidButton = (
    <PlaidButton
      className='button'
      kind={ ButtonKind.PrimaryGhost }
      onClick={ onLinkCardClick }
      onPlaidSuccess={ onLinkSuccess }
    >
      Link cards
    </PlaidButton>
  );

  const renderTransactionItem = useCallback((transaction: TransactionModel) => {
    const values = valuesModel.values.find((value: any ) => value.company === transaction.company._id);
    if (!!transaction.company) {
      return (
        <LatestTransactionItem key={ transaction._id } transaction={ transaction } companyValues={ values } />
      );
    }
  }, [valuesModel.values]);

  const renderTransactions = useCallback(() => {
    if (!!transactionsModel.loadingMostRecentTransactions || !transactionsListLoaded) {
      return (
        <ContentContainer>
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        </ContentContainer>
      );
    }

    if (!user.hasCards && !user.hasKarmaWalletCard) {
      return (
        <ContentContainer>
          <div className='no-linked-cards'>
            <ReceiptIcon />
            <H5>You currently don't have a card linked</H5>
            <p>Please link a card to see transactions</p>
            { plaidButton }
          </div>
        </ContentContainer>
      );
    }

    if (!transactionsModel.mostRecentTransactions.length) {
      return (
        <ContentContainer>
          <div className='no-linked-cards'>
            <ReceiptIcon />
            <H5>No rated transactions found. <Tooltip text='A rated transaction is a transaction from a merchant that we have analyzed and rated using our Karma Wallet rating system. Only rated transactions appear here. If you have a Karma Wallet card, any unrated transactions can be viewed in the Karma Wallet mobile app.' /></H5>
            <p>Enhance your impact tracking by linking another card</p>
            { plaidButton }
          </div>
        </ContentContainer>
      );
    }
    
    if (!!transactionsModel.mostRecentTransactions.length && !!valuesModel.values.length) {
      return (
        <ContentContainer>
          <div className='transactions-list'>
            <div>
              { 
                transactionsModel.mostRecentTransactions.filter(t => !!t.company).map((t) => (
                  renderTransactionItem(t)
                )).slice(0, 4)
              }
            </div>
            <ButtonLink
              className='button see-all-transactions'
              onClick={ onSeeAllTransactionsClick }
              href='/account/transactions'
              kind={ ButtonKind.PrimaryGhost }
            >
              See All Transactions
            </ButtonLink>
          </div>
        </ContentContainer>
      );
    }
  }, [transactionsModel.loadingMostRecentTransactions, transactionsModel.mostRecentTransactions, user.hasCards, user.hasKarmaWalletCard, transactionsListLoaded, valuesModel]);

  return (
    <LatestTransactionsWidgetContainer className={ className }>
      <AccountHubH2 className='header-text'>Latest Rated Transactions</AccountHubH2>
      { renderTransactions() }
    </LatestTransactionsWidgetContainer>
  );
};

export const LatestTransactionsWidget = observer(LatestTransactionsWidgetBase);
