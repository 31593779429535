import styled from 'styled-components';
import { BasicContainer, FlexColCenter, FlexRow } from '../../styles/styles';

interface IProps {
  hasKarmaCard?: boolean;
}

export const ConnectedAccountsWidgetContainer = styled.div<IProps>`
  ${BasicContainer}
  min-height: 333px;
  padding: 32px 16px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    padding: 32px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: ${({ hasKarmaCard }) => hasKarmaCard ? 'column' : 'row'};
    justify-content: space-between;

    .bank-cards {
      justify-content: flex-start;
      width: 100%;
      min-height: 239px;
      overflow: auto;
      flex-wrap: nowrap;

      ::-webkit-scrollbar {
        width: 2px;
        height: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.grey2};
        border-radius: 40px;
      }
    }

    .header {
      margin-bottom: 10px;
    }

    .has-karma-card {
      align-self: center;
    }

    .apply-for-card {
      width: ${({ hasKarmaCard }) => hasKarmaCard ? 'unset' : '40%'};
      max-width: unset;
    }
  }  
`;

export const ConnectedAccountsInnerContainer = styled.div<IProps>`
  ${FlexColCenter}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 100%;
  }

  .top-text {
    ${FlexRow}
    justify-content: space-between;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: auto;
  position: relative;
  height: 80%;
  width: 80%;
`;

export const ButtonWrapper = styled.div`
  ${FlexRow}
  justify-content: center;
  margin-top: 20px;
`;
