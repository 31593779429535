import styled from 'styled-components';
import { TextXSItalic } from '../../styles/components/text';

export const HomeDisclaimerSectionContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 10px 15px 80px;

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    padding: 30px 15px;
  }
`;  

export const InnerWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  p {
    ${TextXSItalic}
    color: ${({ theme }) => theme.colors.grey4};
    margin: 0 auto 15px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      max-width: 800px;
    }
  }
`;
