import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexColCenter, FlexHorizontalCenter } from '../../styles/styles';

export const TopBrandsOffersSectionContainer = styled(Section)``;

export const TopBrandsOffersSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  text-align: center;

  h4 {
    font-size: 24px;
  }
`;

export const OffersContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;
  gap: 35px 30px;
  justify-content: center;
  margin-top: 30px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    gap: 35px 60px;
    margin-top: 40px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 35px 80px;
  }
`;

export const OfferContainer = styled.div`
  ${FlexColCenter}
  gap: 24px;
  height: 115px;
  justify-content: flex-end;
  max-width: 125px;

  img {
    max-width: 100%;
  }

  .cashback-pill {
    background-color: ${({ theme }) => theme.colors.secondary}1A;
    font-size: 12px;
    min-height: 30px;
  }

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    max-width: 130px;
  }
`;
