import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../../styles/styles';
import { Section } from '../../../styles/components/containers';
import { TextXLDemiBold, TextXS, TextXXS } from '../../../styles/components/text';

export const BenefitsSectionContainer = styled(Section)`
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding-top: 80px;
  }
`;

export const BenefitsSectionInnerContainer = styled.div`
  margin: auto;
  max-width: 981px;
`;

export const HeaderContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 29px;
  margin-bottom: 20px;

  .text-section {
    ${FlexCol}
    gap: 8px;
  }

  img {
    width: 100%;
    max-width: 378px;

    @media (max-width: 460px) {
      max-width: 330px;
    }
  }

  .text-wrapper {
    display: inline-block;
    margin-bottom: 0px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 10px;
    }

    .inverted-text {
      color: transparent;
      font-size: 62px;
      line-height: 62px;
      margin-bottom: -15px;
      -webkit-text-stroke-color: ${({ theme }) => theme.colors.white};

      @media (${({ theme }) => theme.breakpoints.lgMax}) {
        font-size: 50px;
        line-height: 50px;
      }

      @media (${({ theme }) => theme.breakpoints.xsMax}) {
        font-size: 44px;
        line-height: 44px;
        margin-bottom: -10px;
      }

      @media (max-width: 460px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    .regular-text  {
      color: ${({ theme }) => theme.colors.white};
      display: inline;
      font-size: 62px;
      line-height: 62px;
      position: relative;
      z-index: 2;

      @media (${({ theme }) => theme.breakpoints.lgMax}) {
        font-size: 50px;
        line-height: 50px;
      }

      @media (${({ theme }) => theme.breakpoints.xsMax}) {
        font-size: 44px;
        line-height: 44px;
      }

      @media (max-width: 460px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    .underlined-text-wrapper {
      position: relative;

      &.block {
        display: block;
      }

      #form-text-underline {
        bottom: 0px;
        left: -4px;
        position: absolute;
        width: 100%;

        @media (${({ theme }) => theme.breakpoints.smMax}) {
          bottom: -4px;
        }
      }
    }

    .underlined-word-wrapper {
      position: relative;
    }
  }

  .cashback-text {
    color: ${({ theme }) => theme.colors.grey3};
    margin: unset;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.lgMax}) {
      max-width: 460px;
    }

    @media (${({ theme }) => theme.breakpoints.xsMax}) {
      max-width: 400px;
    }

    @media (max-width: 460px) {
      max-width: 330px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 40px;
    
    .text-section {
      min-width: 460px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 80px;

    .cashback-text {
      max-width: 498px;
      font-size: 20px;
    }
  }
`;

export const ImpactMetricsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 10px;
  margin-top: 24px;
  min-width: 278px;

  .card {
    min-height: 96px;
    width: 100%;

    .title {
      ${TextXLDemiBold}
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    justify-content: space-between;
    margin-top: 60px;

    .card {
      max-width: 452px;
    }
  }
`;

export const DisclaimerWrapper = styled.div`
  ${FlexCol}
  gap: 14px;
  margin: 24px auto 0;
  max-width: 614px;
  text-align: center;
  width: 100%;

  p {
    ${TextXXS}
    color: ${({ theme }) => theme.colors.grey4};
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-top: 70px;

    p {
      ${TextXS}
    }
  }
`;
