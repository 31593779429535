import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { theme } from '../../../styles/themes';
import { passwordRules } from '../../../utils/input-validation';
import { ButtonKind } from '../../Button/styles';
import { EyeToggle } from '../../EyeToggle';
import { IModalProps } from '../Modal';
import { LockIcon } from '../../svgs/icons/LockIcon';
import { TextField, TextFieldKind } from '../../TextField';
import { ErrorText, ChangeEmailRequestModalContainer, ChangeEmailRequestModalInner } from './styles';
import { removeScrollInert } from '../../../utils/removeScrollInert';
import { Checkbox, CheckboxType } from '../../Checkbox';
import { Link } from 'react-router-dom';
import { useUserSession } from '../../../contexts/user';
import { useToaster } from '../../../contexts/toaster-store';
import { ToastType } from '../../../models/toaster';

export interface IProps extends IModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ChangeEmailRequestModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
}) => {
  const user = useUserSession();
  const toaster = useToaster();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [serverErrorText, setServerErrorText] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasAccessToCurrentEmail, setHasAccessToCurrentEmail] = useState(false);

  const reset = () => {
    setPassword('');
    setPasswordError('');
    setServerErrorText('');
    setShowPassword(false);
    setHasAccessToCurrentEmail(false);
    removeScrollInert();
  };

  const onCloseModal = useCallback(() => {
    reset();
    onClose();
  }, []);

  const onHasAccessToCurrentEmailClick = useCallback(() => {
    setHasAccessToCurrentEmail( prev => !prev );
  }, [hasAccessToCurrentEmail]);

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.value) {
      const isValid = !passwordRules.find(r => !r.validate(e.target.value));
      setPasswordError(isValid ? '' : 'Invalid password format');
    } else {
      setPasswordError('');
    }
    setPassword(e.target.value);
  }, [password]);

  const onConfirmEmailChangeRequest = useCallback( async () => {
    try {
      setServerErrorText('');
      setIsProcessing(true);
      await user.changeEmailRequest(password);
      toaster.push({ message: 'Your email change request was successful', type: ToastType.Success });
      onCloseModal();
    } catch (error: any) {
      setServerErrorText(error.message);
    } finally {
      setIsProcessing(false);
    }
  },[password, isProcessing, user]);

  const getCTAs = () => ([
    {
      id: 'cancel',
      text: 'Cancel',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCloseModal,
    },
    {
      id: 'send-request',
      className: 'send-request',
      text: 'Request Email Change',
      disabled: !password.length || !hasAccessToCurrentEmail || !!passwordError || isProcessing,
      kind: ButtonKind.Primary,
      onClick: onConfirmEmailChangeRequest,
    },
  ]);

  const renderPasswordField = () => (
    <div>
      <TextField
        id='current-password-input'
        label='Password'
        fieldKind={ TextFieldKind.Pill }
        leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
        rightAccessory={ <EyeToggle show={ showPassword } onClick ={ ()=> setShowPassword(prev => !prev) } /> }
        type={ showPassword ? 'text' : 'password' }
        value={ password }
        onChange={ onPasswordChange }
        errorText={ passwordError }
      />
    </div>
  );

  return (
    <ChangeEmailRequestModalContainer
      className={ className }
      title='Request Change of Email'
      ctas={ getCTAs() }
      isOpen={ isOpen }
      onClose={ onCloseModal }
      processing={ isProcessing }
    >
      <ChangeEmailRequestModalInner>
        <p>
          <strong>NOTE:</strong> To request a change of email address you must have access to <strong>both</strong> your current email address and new address. If you do not, please contact support directly at <strong>support@karmawallet.io</strong> or through our <Link to='/contact-us'>Contact Us</Link> page.
        </p>
        <p>
          After you submit the email change request, you will receive an email at your <strong>current email address</strong> with a link to continue with the change request. Please note that the link will expire after 24 hours.
        </p>
        <div className='necessary-fields-container'>
          <Checkbox
            type={ CheckboxType.Square }
            label='I have access to my current email address and new email address.'
            checked={ hasAccessToCurrentEmail }
            onChange={ onHasAccessToCurrentEmailClick }
            id='has-access-to-emails-checkbox'
          />
          { renderPasswordField() }
        </div>
      </ChangeEmailRequestModalInner>
      { !!serverErrorText && <ErrorText>{ serverErrorText }</ErrorText> }
    </ChangeEmailRequestModalContainer>
  );
};

export const ChangeEmailRequestModal = observer(ChangeEmailRequestModalBase);
