import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { AccountInformationFieldsWrapper, AccountUserInformationSectionContainer, AddressAndEditButtonContainer, AddressInformationFieldsContainer, EditButton, FieldAndButtonContainer, ZipAndStateContainer } from './styles';
import { useUserSession } from '../../contexts/user';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { TextField, TextFieldKind } from '../TextField';
import { EnvelopeIcon } from '../svgs/icons/EnvelopeIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { LockIcon } from '../svgs/icons/LockIcon';
import { ButtonKind, ButtonSize } from '../Button/styles';
import { PencilIcon } from '../svgs/icons/PencilIcon';
import { UpdateProfileModal } from '../modals/UpdateProfileModal';
import { AccountHubH2 } from '../../styles/components/header';
import { MapPinIcon } from '../svgs/icons/MapPinIcon';
import { ChangeEmailRequestModal } from '../modals/ChangeEmailRequestModal';
import dayjs from 'dayjs';
import { isOlderThan30Days } from '../../lib/dates';

interface IProps extends IThemeProps{
  classname?: string;
}

const AccountInformationSectionBase: React.FC<IProps> = ({
  classname = '',
  theme,
}) => {
  const userSession = useUserSession();
  const [itemToUpdate, setItemToUpdate] = useState('');
  const [ isChangeEmailRequestModalOpen, setIsChangeEmailRequestModalOpen ] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => { };

  const marqetaCreatedAtLeast30DaysAgo = isOlderThan30Days(dayjs(userSession.marqetaCreatedOn).toDate());

  const onEditPasswordClick = useCallback(() => {
    setItemToUpdate('password');
  }, []);

  const onEditEmailClick = useCallback(() => {
    setIsChangeEmailRequestModalOpen(true);
  }, []);

  const onCloseChangeEmailRequestModal = useCallback(() => {
    setIsChangeEmailRequestModalOpen(prev => !prev);
  }, [isChangeEmailRequestModalOpen]);

  const onEditAddressClick = useCallback(() => {
    setItemToUpdate('address');
  }, []);

  const onEditZipCodeClick = useCallback(() => {
    setItemToUpdate('zipcode');
  }, []);

  const renderUserAddressFields = useCallback(() => {
    if (userSession.address1 && userSession.city && userSession.state && userSession.zipcode) {
      return (
        <AddressInformationFieldsContainer>
          <AddressAndEditButtonContainer>
            <TextField
              aria-readonly
              id='user-address'
              className='user-info-text-field'
              fieldKind={ TextFieldKind.Pill }
              label='Address'
              placeholder='Address'
              type='text'
              value={ userSession.address1 }
              onChange={ noop }
              disabled
            />
          </AddressAndEditButtonContainer>
          {
            userSession.address2 ? (
              <TextField
                aria-readonly
                id='user-address-continued'
                className='user-info-text-field'
                fieldKind={ TextFieldKind.Pill }
                label='Address continued'
                labelHidden
                placeholder='Address continued'
                type='text'
                value={ userSession.address2 || null }
                onChange={ noop }
                disabled
              /> ) : null
          }
          <TextField
            aria-readonly
            id='user-city'
            className='user-info-text-field'
            fieldKind={ TextFieldKind.Pill }
            label='City'
            labelHidden
            placeholder='City'
            type='text'
            value={ userSession.city }
            onChange={ noop }
            disabled
          />
          <ZipAndStateContainer>
            <TextField
              aria-readonly
              id='user-state'
              className='user-info-text-field'
              fieldKind={ TextFieldKind.Pill }
              label='State'
              labelHidden
              placeholder='State'
              type='text'
              value={ userSession.state }
              onChange={ noop }
              disabled
            />
            <TextField
              aria-readonly
              id='user-zip'
              className='user-info-text-field'
              fieldKind={ TextFieldKind.Pill }
              label='Zip'
              labelHidden
              placeholder='Zip'
              type='text'
              value={ userSession.zipcode }
              onChange={ noop }
              disabled
            />
            <EditButton
              className='edit-button'
              onClick={ onEditAddressClick }
              kind={ ButtonKind.QuaternaryGhost }
              size={ ButtonSize.Regular }
              disabled={ !marqetaCreatedAtLeast30DaysAgo }
            >
              <PencilIcon stroke={ theme.colors.dark } /> Edit
            </EditButton>
          </ZipAndStateContainer>
          {
            !marqetaCreatedAtLeast30DaysAgo ? 
              <p className='address-change'>If you need to request an address change, please contact us at <b>support@karmawallet.io</b></p>
              : null
          }
        </AddressInformationFieldsContainer>
      );
    }

    // for users with no KWC that want to add or update their zip code to receive local offers
    return ( 
      <FieldAndButtonContainer>
        <TextField
          aria-readonly
          id='user-zip'
          className='user-info-text-field'
          fieldKind={ TextFieldKind.Pill }
          label='Zip Code'
          leftAccessory={ <MapPinIcon stroke={ theme.colors.grey3 } /> }
          placeholder='Add your zip code'
          type='text'
          value={ userSession.zipcode || null }
          onChange={ noop }
          disabled
        />
        <EditButton
          className='edit-button'
          onClick={ onEditZipCodeClick }
          kind={ ButtonKind.QuaternaryGhost }
          size={ ButtonSize.Regular }
        >
          <PencilIcon stroke={ theme.colors.dark } />
          { !userSession.zipcode ? 'Add' : 'Edit' }
        </EditButton>
      </FieldAndButtonContainer>
    );
  }, [userSession.address1, userSession.address2, userSession.city, userSession.state, userSession.zipcode]);

  const renderUserInformationFields = useCallback(() => {
    if (!userSession.initialized) {
      return <LoadingSpinner />;
    }

    return (
      <AccountInformationFieldsWrapper>
        <AccountHubH2>Account Information</AccountHubH2>
        <form>
          <TextField
            aria-readonly
            id='user-name'
            className='user-info-text-field'
            fieldKind={ TextFieldKind.Pill }
            label='User Name'
            placeholder='User Name'
            type='text'
            value={ userSession.name }
            onChange={ noop }
            leftAccessory={ <EnvelopeIcon stroke={ theme.colors.grey3 } /> }
            disabled
          />
          <FieldAndButtonContainer>
            <TextField
              aria-readonly
              id='user-email'
              className='user-info-text-field'
              fieldKind={ TextFieldKind.Pill }
              label='Email Address'
              placeholder='Email Address'
              type='text'
              value={ userSession.emails.find(e => e.primary===true).email }
              onChange={ noop }
              leftAccessory={ <EnvelopeIcon stroke={ theme.colors.grey3 } /> }
              disabled
            />
            <EditButton
              className='edit-button'
              onClick={ onEditEmailClick }
              kind={ ButtonKind.QuaternaryGhost }
              size={ ButtonSize.Regular }
            >
              <PencilIcon stroke={ theme.colors.dark } /> Edit
            </EditButton>
          </FieldAndButtonContainer>
          <FieldAndButtonContainer>
            <TextField
              aria-readonly
              fieldKind={ TextFieldKind.Pill }
              className='user-info-text-field'
              id='user-password'
              label='Password'
              leftAccessory={ <LockIcon fill={ theme.colors.grey3 } /> }
              onChange={ noop }
              placeholder='Password'
              type={ 'password' }
              value={ 'userpassword' }
              disabled
            />
            <EditButton
              className='edit-button'
              onClick={ onEditPasswordClick }
              kind={ ButtonKind.QuaternaryGhost }
              size={ ButtonSize.Regular }
            >
              <PencilIcon stroke={ theme.colors.dark } /> Edit
            </EditButton>
          </FieldAndButtonContainer>
          { renderUserAddressFields() }
        </form>
      </AccountInformationFieldsWrapper>
    );
  }, [userSession]);

  return (
    <AccountUserInformationSectionContainer className={ classname }>
      { renderUserInformationFields() }
      { /* <UserGroupsSection /> Hiding groups from account until we decide what to do with them in the future */ }
      <UpdateProfileModal
        itemToUpdate={ itemToUpdate }
        setItemToUpdate={ setItemToUpdate }
        onClose={ noop }
      />
      <ChangeEmailRequestModal
        isOpen={ isChangeEmailRequestModalOpen }
        onClose={ onCloseChangeEmailRequestModal }
      />
    </AccountUserInformationSectionContainer>
  );
};

const AccountInformationSectionObserver = observer(AccountInformationSectionBase);
export const AccountInformationSection = withTheme(AccountInformationSectionObserver);
