import React from 'react';
import { ArticleContainer, ArticlesContainer, ArticleTextContainer, AsFeaturedInSectionContainer } from './styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ButtonLink } from '../../../components/Button';
import { H5 } from '../../../styles/components/header';
import { ButtonKind } from '../../../components/Button/styles';

interface IProps {
  className?: string;
}

interface IArticle {
  title: string;
  image: string;
  url: string;
}

const articlesData: IArticle[] = [
  {
    title: 'All About the Karma Wallet Membership',
    image: 'https://cdn.karmawallet.io/uploads/9AERrFRCqS-membership-article-photo.png',
    url: 'https://karmawallet.io/blog/2023/07/all-about-the-karma-wallet-card/',
  },
  {
    title: 'How to Sign Up for the Karma Wallet Membership',
    image: 'https://cdn.karmawallet.io/uploads/F35Zoz4-vB-how-to-sign-up-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/07/how-to-sign-up-for-the-karma-wallet-card/',
  },
  {
    title: 'What is a prepaid reloadable debit card?',
    image: 'https://cdn.karmawallet.io/uploads/NSIeibFHcv-prepaid-debit-card-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/02/what-is-a-prepaid-reloadable-debit-card/',
  },
];

export const ArticlesSection: React.FC<IProps> = ({
  className,
}) => {
  const analytics = useAnalytics();

  const onReadPostClick = (index: number) => {
    analytics.fireEvent(`ApplyPage_Article_${index}_Click`);
  };

  const articlesContent = articlesData.map(({ title, image, url }, index) => (
    <ArticleContainer key={ title }>
      <img alt={ `${title} Photo` } src={ image } />
      <ArticleTextContainer>
        <H5>{ title }</H5>
        <ButtonLink
          className='read-post'
          kind={ ButtonKind.PrimaryGhost }
          href={ url }
          onClick={ () => onReadPostClick(index) }
          target='_blank'
          rel='noreferrer'
        >
          Read Post
        </ButtonLink>
      </ArticleTextContainer>
    </ArticleContainer>
  ));

  return (
    <AsFeaturedInSectionContainer className={ className }>
      <ArticlesContainer>
        { articlesContent }
      </ArticlesContainer>
    </AsFeaturedInSectionContainer>
  );
};
