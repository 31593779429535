import styled from 'styled-components';

export const ErrorComponentContainer = styled.div`
  text-align: center;
  margin-top: 30px;

  & > div {
    color: ${({ theme }) => theme.colors.disabled};
  }
`;
