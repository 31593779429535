import React, { useCallback, ReactEventHandler, useState } from 'react';
import { CardModel, CardStatus } from '../../models/cards';
import { NoBankLogo } from '../svgs/graphics/NoBankLogo';
import { BankCardWrapper, BankContainer, BankInfoContainer, BankLogoContainer, BankName, CardDetailsContainer, CardDetailsWrapper, RelinkIconsContainer, RelinkMessage, RemoveButton } from './styles';
import KarmaLogo from '../../../public/android-chrome-512x512.png';
import { capitalizeFirstLetters } from '../../lib/misc';
import { ButtonKind } from '../Button/styles';
import { TrashcanIcon2 } from '../svgs/icons/TrashCanIcon2';
import { theme } from '../../styles/themes';
import { WarningNegativeIcon } from '../svgs/icons/WarningNegativeIcon';
import { ReloadIcon } from '../svgs/icons/ReloadIcon';
import { Button } from '../Button';

interface IProps {
  className?: string;
  accountCards: CardModel[];
  onRemoveAccount(): void;
  onRelinkAccount(): void;
}

export const BankAccountWidget: React.FC<IProps> = ({
  className = '',
  accountCards,
  onRemoveAccount,
  onRelinkAccount,
}) => {
  const [hasBankLogo, setHasBankLogo] = useState(true);

  const onImgLoadError: ReactEventHandler<HTMLImageElement> = useCallback(() => {
    setHasBankLogo(false);
  }, []);

  const renderBankLogo = () => {
    if (!hasBankLogo) return <NoBankLogo />;

    return (
      <img
        src={ accountCards[0].institution === 'Karma Wallet' ? KarmaLogo : `https://s3.amazonaws.com/plaid.karmawallet/${ accountCards[0].institutionId }.png` }
        onError={ onImgLoadError }
        alt='Bank Logo'
      />
    );
  };

  const renderAccountCard = () => {
    const displayCardType = (card: CardModel) => {
      switch (card.subtype) {
        case 'credit':
          return 'Credit Card';
        case 'checking':
          return 'Debit Card';
        default:
          return capitalizeFirstLetters(card.subtype);
      }
    };

    const needsRelink = (institution: CardModel[]) => {
      const needsRelinking = institution.filter(bank => bank.status === CardStatus.Unlinked);
      return needsRelinking;
    };

    const needsRelinking = !!needsRelink(accountCards)[0];

    const removeButton = (
      <RemoveButton
        kind={ ButtonKind.Blank }
        onClick={ onRemoveAccount }
      >
        Remove
        <TrashcanIcon2 stroke={ theme.colors.grey3 } />
      </RemoveButton>
    );

    const relinkSection = (
      <RelinkIconsContainer>
        <WarningNegativeIcon fill={ theme.colors.red } />
        <Button
          kind={ ButtonKind.Blank }
          onClick={ onRelinkAccount }
        >
          <ReloadIcon fill={ theme.colors.red } />
        </Button>
      </RelinkIconsContainer>
    );

    const cardsDetails = (
      <CardDetailsWrapper needsRelinking={ needsRelinking }>
        {
          accountCards.map((card, index) => (
            card.institution === 'Karma Wallet' && index !== 0
              ? null
              : (
                <CardDetailsContainer key={ card._id } needsRelinking={ needsRelinking }>
                  <p className='card-details'>{ card.institution === 'Karma Wallet' ? 'Prepaid Card' : displayCardType(card) }</p>
                  <div className='right-content'>
                    { needsRelinking && <WarningNegativeIcon fill={ theme.colors.red } /> }
                    <p className='card-details'>{ card.institution === 'Karma Wallet' ? 'Reloadable' : `xxxx${card.mask}` }</p>
                  </div>
                </CardDetailsContainer>
              )
          ))
        }
      </CardDetailsWrapper>
    );

    return (
      <>
        <BankContainer needsRelinking={ needsRelinking }>
          <BankLogoContainer>
            { renderBankLogo() }
          </BankLogoContainer>
          <BankInfoContainer>
            <BankName>{ accountCards[0].institution ?? accountCards[0].name }</BankName>
            { needsRelinking && <RelinkMessage>Attention: Your sustainability impact is no longer being tracked. { accountCards[0].institution } needs relinking.</RelinkMessage> }
          </BankInfoContainer>
          { accountCards[0].institution !== 'Karma Wallet' && removeButton }
          { needsRelinking && relinkSection }
        </BankContainer>
        { cardsDetails }
      </>
    );
  };

  return (
    <BankCardWrapper className={ className }>
      { renderAccountCard() }      
    </BankCardWrapper>
  );
};
