import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCol, SecondaryFont } from '../../styles/styles';

export const PaypalSectionContainer = styled(MaxBoundarySection)`
  padding: 0px;
`;

export const UserInfoWrapper = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }

  .paypal-logo {
    max-width: 118px;
    height: auto;
    text-align: center;
    height: auto;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      margin: 0 auto 20px;
      max-width: 118px;
    }
  }

  &.linked {

    .user-info-container {
      ${FlexCol}
      ${SecondaryFont}
      align-items: center;
      justify-content: space-around;
  
      .disconnect-paypal, .link-paypal {
        text-decoration: underline;
      }
  
      span {
        font-weight: 700;
        font-size: 14px;
      }
  
      a {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  & > div {
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      width: 100%;

      .zoid-outlet {
        min-height: 200px;
      }
    }
  }
`;
