import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import { CompanyName, CompanyNameAndValuesContainer, CompanyValuesContainer, TransactionAmount, TransactionAmountContainer, TransactionAmountTooltip, TransactionAmountTooltipAnchor, TransactionCompanyInfoContainer, TransactionDate, TransactionItemContainer } from './styles';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { IThemeProps } from '../../../../styles/themes';
import { TransactionModel } from '../../../../models/transactions';
import { IValue } from '../../../../models/values';
import { InfoIcon } from '../../../../components/svgs/icons/InfoIcon';
import { formatCurrency } from '../../../../lib/formatters';
import { TinyPopover } from '../../../../components/TinyPopover';
import { CompanyRatingTag } from '../../../../components/CompanyRatingTag';
import { CompanyLogo } from '../../../../components/CompanyLogo';

dayjs.extend(utc);

interface IProps extends IThemeProps {
  className?: string;
  transaction: TransactionModel;
}

const TransactionItemBase: React.FC<IProps> = ({
  className = '',
  transaction,
  theme,
}) => {
  const analytics = useAnalytics();
  const now = useRef(dayjs().local()).current;
  const [amountTooltipTimeout, setAmountTooltipTimeout] = useState<number>(null);
  const [showAmountTooltip, setShowAmountTooltip] = useState(false);

  const onAmountTooltipClick = useCallback(() => {
    if (!!amountTooltipTimeout) return null;
    setShowAmountTooltip(true);

    setAmountTooltipTimeout(window.setTimeout(() => {
      window.clearTimeout(amountTooltipTimeout);
      setShowAmountTooltip(false);
      setAmountTooltipTimeout(null);
      setShowAmountTooltip(false);
    }, 3000));
  }, [amountTooltipTimeout]);

  const onTransactionClick = useCallback(() => {
    analytics.fireEvent('Transactions_Click', `${transaction.company._id}`);
  }, []);

  const renderCompanyValues = () => {
    const content: JSX.Element[] = [];

    if (!transaction?.values || transaction.values.length < 1) return null;

    transaction?.values?.forEach((value: IValue) => {
      content.push(
        <img
          key={ value._id }
          src={ value.icon }
          alt={ `${value.name} icon` }
        />,
      );
    });

    return (
      <CompanyValuesContainer>
        { content }
      </CompanyValuesContainer>
    );
  };

  const renderTransactionAmount = () => {
    const anchor = (
      <TransactionAmountTooltipAnchor
        onClick={ onAmountTooltipClick }
      >
        <InfoIcon fill={ theme.colors.lightGray1 } />
      </TransactionAmountTooltipAnchor>
    );

    return (
      <TransactionAmount className={ transaction.reversed ? 'reversed' : '' }>
        <span>${ formatCurrency(transaction.amount) }</span>
        {
          transaction.reversed && (
            <TinyPopover
              anchor={ anchor }
              engageOnHover={ true }
              isOpen={ showAmountTooltip }
              placement={ ['top', 'bottom'] }
            >
              <TransactionAmountTooltip>
                This transaction appears to have been reversed. Common reasons for this are due to a refund, or the transaction being cancelled.
              </TransactionAmountTooltip>
            </TinyPopover>
          )
        }
      </TransactionAmount>
    );
  };

  return (
    <TransactionItemContainer className={ className }>
      <TransactionCompanyInfoContainer>
        <CompanyLogo
          className='company-logo'
          company={ transaction.company }
        />
        <CompanyNameAndValuesContainer>
          <CompanyName
            to={ `/company/${transaction.company._id}/${transaction.company.slug}` }
            onClick={ onTransactionClick }
          >
            { transaction.company.companyName }
          </CompanyName>
          { renderCompanyValues() }
        </CompanyNameAndValuesContainer>
      </TransactionCompanyInfoContainer>
      <TransactionAmountContainer>
        <CompanyRatingTag className='transaction-rating-tag' rating={ transaction.company.rating } />
        { renderTransactionAmount() }
        <TransactionDate>{ transaction.date.format(`MMM DD${transaction.date.year() !== now.year() ? ', YYYY' : '' }`) }</TransactionDate>
      </TransactionAmountContainer>
    </TransactionItemContainer>
  );
};

export const TransactionItem = withTheme(TransactionItemBase);
