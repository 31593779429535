import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AllBrandsWidgetContainer, CashbackCardsContainer, DescriptionContainer, GetCardSection, HeaderContainer, LoadingSpinnerContainer, SearchContainer, SearchInnerContainer } from './styles';
import { AccountHubH2 } from '../../styles/components/header';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { observer } from 'mobx-react';
import KarmaCardImage from '../../../public/assets/images/empty-kw-card.png';
import { CompaniesModel } from '../../models/companies';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CashbackCard } from '../CashbackCard';
import { CardSize } from '../CashbackCard/styles';
import { Button, ButtonLink } from '../Button';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { ButtonKind } from '../Button/styles';
import { XIcon } from '../svgs/icons/XIcon';
import { TextField, TextFieldKind } from '../TextField';
import { IParamsObject } from '../../models/browse-query';
import { SearchIcon } from '../svgs/icons/SearchIcon';
import { SectorFilter } from '../SectorFilter';
import { Waypoint } from 'react-waypoint';

export enum AllBrandsOffersWidgetLocation {
  Dashboard = 'AccountDashboard_AllBrandsOffers',
  AllBrandsOffersPage = 'AllBrandsOffers',
}

interface IProps extends IThemeProps {
  className?: string;
  location: AllBrandsOffersWidgetLocation;
  hideCardsCTA?: boolean;
  numberOfCards?: number;
  cardsSize?: CardSize;
}

export const AllBrandsWidgetBase: React.FC<IProps> = ({
  className = '',
  hideCardsCTA,
  numberOfCards,
  cardsSize,
  location,
  theme,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const errorMessages = useErrorMessages();
  const companiesModel = useRef(new CompaniesModel({ limit: location === AllBrandsOffersWidgetLocation.Dashboard ? 10 : 20 })).current;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<number>(null);
  const [activeSector, setActiveSector] = useState('');
  const hasLoadedCompanies = useRef(false);

  const QueryOptions: IParamsObject = {
    sort: 'companyName',
    'merchant!': 'null',
    rating: 'neutral,positive',
    companyName: searchQuery,
    'sectors.sector': activeSector,
  };

  const loadCompanies = useCallback((refresh?: boolean) => async () => {
    try {
      await companiesModel.companies[refresh ? 'refresh' : 'loadMore'](QueryOptions);
    } catch (err: any) {
      analytics.fireEvent(`${location}_Error`);
      errorMessages.push({
        title: 'Error Loading Companies',
        message: err.message,
      });
    }
  }, [searchQuery, activeSector, QueryOptions]);

  useEffect(() => {
    loadCompanies(true)();
    hasLoadedCompanies.current = true;
  }, [activeSector]);

  useEffect(() => {
    window.clearTimeout(searchTimeout);
    if (!searchQuery && !hasLoadedCompanies.current) return () => null;
    setSearchTimeout(window.setTimeout(() => {
      loadCompanies(true)();
      if (searchQuery) analytics.fireEvent(`${location}_SearchInput`, `${searchQuery}`);
    }, 300));
  }, [searchQuery]);

  const onSeeAllClick = useCallback(() => {
    analytics.fireEvent('AllBrandsOffers_SeeAll_Click');
  }, []);

  const onGetCardClick = useCallback(() => {
    analytics.fireEvent(`${location}_GetCard_Click`);
  }, []);

  const onSectorClick = useCallback((sectorId: any) => {
    setActiveSector(sectorId);
    analytics.fireEvent(`${location}_SortSelect_${sectorId}`);
  }, []);

  const onSearchClearClick = useCallback(() => {
    setSearchQuery('');
    analytics.fireEvent(`${location}_ClearSearch`);
  }, []);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const onSearchEnter = useCallback((e: any) => {
    analytics.fireEvent(`${location}_Search_Enter`);
    if (e.key === 'Enter') {
      const searchElement = document.getElementById('all-brands-offers-search');
      searchElement.blur();
    }
  }, []);

  const renderClearIcon = useCallback(() => (
    <Button
      kind={ ButtonKind.Blank }
      label='Clear Search'
      onClick={ onSearchClearClick }
    >
      <XIcon stroke={ theme.colors.grey3 } />
    </Button>
  ), []);

  const searchField = useMemo(() => (
    <SearchContainer className='search-container'>
      <SearchInnerContainer>
        <TextField
          className='search-text-field text-field'
          fieldKind={ TextFieldKind.Pill }
          id='all-brands-offers-search'
          label='Search Brand'
          labelHidden
          onChange={ onSearchChange }
          placeholder='Search Brand'
          leftAccessory={ <SearchIcon stroke={ theme.colors.grey3 } /> }
          rightAccessory={ !!searchQuery ? renderClearIcon() : null }
          value={ searchQuery }
          onKeyPress={ onSearchEnter }
        />
      </SearchInnerContainer>
    </SearchContainer>
  ), [searchQuery]);

  const cashbackOffersList = useMemo(() => {
    let content: JSX.Element[] = [];
    let placeholderKey = 0;

    if (companiesModel.companies.results.length) {
      content = companiesModel.companies.results.slice(0, numberOfCards || undefined).map((c) => (
        <CashbackCard
          company={ c }
          key={ c._id }
          cardSize={ cardsSize }
          hideCTA={ hideCardsCTA }
          location={ location }
        />
      ));
      
      if (content.length % 2 !== 0) {
        content.push(<div className='company-placeholder' key={ placeholderKey++ } />);
      }
    } else if (!companiesModel.companies.busy && companiesModel.companies.firstPageLoaded) {
      content.push(<p key='no-companies'>No companies found</p>);
    }

    if (companiesModel.companies.busy) {
      content.push(
        (
          <LoadingSpinnerContainer key='loading-spinner' location={ location }>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ),
      );
    }

    if (!companiesModel.companies.allResultsFetched && !companiesModel.companies.busy && location === AllBrandsOffersWidgetLocation.AllBrandsOffersPage) {
      content.push(<Waypoint key='waypoint' onEnter={ loadCompanies() } topOffset={ 200 } />);
    }

    return (
      <CashbackCardsContainer location={ location }>
        { content }
      </CashbackCardsContainer>
    );
  }, [companiesModel.companies, loadCompanies]);
  
  const headerSection = useMemo(() => {
    const renderSeeAllButton = () => {
      if (location !== AllBrandsOffersWidgetLocation.Dashboard) return null;
      
      return (
        <ButtonLink
          href='/account/all-brands-offers'
          className='see-all-button'
          onClick={ onSeeAllClick }
        >
          See All
          <ChevronIcon direction={ ChevronDirection.Right } stroke={ theme.colors.primary } />
        </ButtonLink>
      );
    };

    const renderTakeAdvantageText = () => {
      if (location !== AllBrandsOffersWidgetLocation.AllBrandsOffersPage) return null;
      
      return (
        <p className='take-advantage'>
          {
            user.hasKarmaWalletCard
              ?
              <span className='get-card'>Use your Karma Wallet Card </span>
              :
              <a className='get-card' href='/app' onClick={ onGetCardClick }>Get the Karma Wallet Card </a>
          }
          to take advantage of these cashback rewards.
        </p>
      );
    };

    return (
      <>
        <HeaderContainer>
          <div className='inner-container'>
            <AccountHubH2 className='header'>All Brands</AccountHubH2>
            <img alt='Karma Wallet App and Card' className='karma-card' src={ KarmaCardImage } />
          </div>
          { renderSeeAllButton() }
        </HeaderContainer>
        <DescriptionContainer>
          <p>Automatically earn cashback by using { user.hasKarmaWalletCard ? 'your' : 'the' } Karma Wallet Card at 700+ brands by clicking the "Shop Now" button.</p>
          { renderTakeAdvantageText() }
        </DescriptionContainer>
      </>
    );
  }, [location, user.hasKarmaWalletCard, onSeeAllClick, onGetCardClick]);

  const getStartedSection = useMemo(() => {
    if (location !== AllBrandsOffersWidgetLocation.Dashboard || user.hasKarmaWalletCard) return null;
    
    return (
      <GetCardSection>
        <p>Become a Karma Wallet Member to unlock 700+ exclusive deals</p>
        <ButtonLink
          className='get-card-button'
          onClick={ onGetCardClick }
          kind={ ButtonKind.Primary }
          href='/app'
        >
          Get Your Karma Wallet Card
        </ButtonLink>
      </GetCardSection>
    );
  }, [location, user.hasKarmaWalletCard]);

  return (
    <AllBrandsWidgetContainer className={ className }>
      { headerSection }
      { location === AllBrandsOffersWidgetLocation.AllBrandsOffersPage ? searchField : null }
      <SectorFilter className='sector-filter' isAccountHubFilter onSectorClick={ onSectorClick } />
      { cashbackOffersList }
      { getStartedSection }
    </AllBrandsWidgetContainer>
  );
};

const AllBrandsWidgetObserver = observer(AllBrandsWidgetBase);
export const AllBrandsWidget = withTheme(AllBrandsWidgetObserver);
