import React from 'react';
import { H2MobileMarketing } from '../../styles/components/header';
import { BottomSectionContainer, DataItem, LeftContent, PartnershipsContainer, SafetyAndSecuritySectionContainer, TopSectionContainer } from './styles';
import { SafetyPhone } from '../svgs/graphics/SafetyPhone';
import { VisaLogo } from '../svgs/logos/VisaLogo';
import { theme } from '../../styles/themes';
import { VGSLogo } from '../svgs/logos/VGSLogo';
import { PersonaLogo } from '../svgs/logos/PersonaLogo';
import { PathwardLogo } from '../svgs/logos/PathwardLogo';
import { MarqetaLogo } from '../svgs/logos/MarqetaLogo';

interface Iprops {
  className?: string;
}

const safetyBenefits: string[] = [
  'FDIC Insurance up to $250,000',
  'Responsive Customer Support',
  'Shop Worry-Free with Visa’s Zero Liability Policy***',
  'Multi-Level Data Encryption ',
  'Application Identity Verification Protects Customer Security',
];

export const SafetyAndSecuritySection: React.FC<Iprops> = ({
  className = '',
}) => (
  <SafetyAndSecuritySectionContainer className={ className }>
    <TopSectionContainer>
      <LeftContent>
        <H2MobileMarketing>Safety & Security</H2MobileMarketing>
        <PartnershipsContainer>
          <VisaLogo fill={ theme.colors.secondary } />
          <VGSLogo />
          <PersonaLogo />
          <PathwardLogo />
          <MarqetaLogo />
        </PartnershipsContainer>
      </LeftContent>
      <SafetyPhone />
    </TopSectionContainer>
    <BottomSectionContainer>
      {
        safetyBenefits.map((item, index) => (
          <DataItem key={ index }>{ item }</DataItem>
        ))
      }
    </BottomSectionContainer>
  </SafetyAndSecuritySectionContainer>
);
