import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useUserSession } from '../../../../contexts/user';
import { H5 } from '../../../../styles/components/header';
import { useToaster } from '../../../../contexts/toaster-store';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { ButtonKind } from '../../../../components/Button/styles';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';
import { RelinkCardModal } from '../../../../components/modals/RelinkCardModal';
import { AddAccountButton, BankAccountsContainer, ErrorLoadingCards, LoadingSpinnerContainer } from './styles';
import { RemoveBankModal } from '../../../../components/modals/RemoveCardModal';
import { PlusIcon } from '../../../../components/svgs/icons/PlusIcon';
import { theme } from '../../../../styles/themes';
import { useSearchParams } from 'react-router-dom';
import { BankAccountWidget } from '../../../../components/BankAccountWidget';

interface IProps {
  className?: string;
}

const LinkedBankAccountsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const cards = user.cards;
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [errorLoadingCards, setErrorLoadingCards] = useState<boolean>(false);
  const [showRelinkBankModal, setShowRelinkBankModal] = useState<boolean>(false);
  const [isRemoveBankModalOpen, setisRemoveBankModalOpen] = useState<boolean>(false);
  const [bankToDelete, setBankToDelete] = useState<string>('');
  const [isRemovingBankLoading, setIsRemovingBankLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('relink') === 'true') {
      setShowRelinkBankModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    user.loadCards()
      .catch(() => {
        analytics.fireEvent('Account_Cards_LoadError');
        setErrorLoadingCards(true);
      });
  }, [user.isLoggedIn, user.cards, user.groupedCards]);

  const onRelinkBankClick = useCallback(() => {
    analytics.fireEvent('Account_RelinkBank_Click');
    setShowRelinkBankModal(true);
  }, []);

  const onRelinkBankModalClose = useCallback(() => {
    analytics.fireEvent('Account_RelinkBank_Confirm_Close');
    setSearchParams('');
    setShowRelinkBankModal(false);
  }, []);

  const onRelinkSuccess = useCallback(async () => {
    try {
      setShowRelinkBankModal(false);
      setSearchParams('');
      user.loadCards(true);
    } catch (err) {
      analytics.fireEvent('Account_LoadCards_Error');
    }
  }, [user]);
   
  const onPlaidClick = useCallback(() => {
    analytics.fireEvent('Account_LinkCard_Click');
  }, []);

  const onPlaidSuccess = useCallback(() => {
    analytics.fireEvent('Account_LinkCard_Success');
  }, []);

  const onRemoveBankClick = useCallback((bank: string) => () => {
    setBankToDelete(bank);
    setisRemoveBankModalOpen(true);
  }, []);

  const onRemoveBankClose = useCallback((method: 'close' | 'cancel') => () => {
    analytics.fireEvent(method === 'cancel' ? 'Account_RemoveCardCancel_Click' : 'Account_RemoveCardClose_Click');
    setBankToDelete('');
    setisRemoveBankModalOpen(false);
  }, []);

  const onConfirmRemove = useCallback(async () => {
    try {
      setIsRemovingBankLoading(true);
      const cards = user.groupedCards[bankToDelete];
      await user.removeCard(cards, false);
      analytics.fireEvent('Account_RemoveCardConfirm_Click');
      toaster.push({ message: 'Bank removed successfully.' });
    } catch (err: any) {
      analytics.fireEvent('Account_RemoveCard_Error');
      errorMessages.push({
        title: 'Error Removing Card',
        message: err.message,
      });
    } finally {
      setisRemoveBankModalOpen(false);
      setIsRemovingBankLoading(false);
    }
  }, [user.cards, user.groupedCards, bankToDelete]);
  
  const renderAccountCards = useCallback(() => {
    if (errorLoadingCards) {
      return (
        <ErrorLoadingCards>
          Error Loading Cards
        </ErrorLoadingCards>
      );
    }

    if (user.loadingCards) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );
    }

    if (user.cardsLoaded && !user.groupedCards) return null;

    return (
      Object.entries(user.groupedCards).map(([key, institution], index) =>
        <BankAccountWidget
          key={ index }
          accountCards={ institution }
          onRelinkAccount={ onRelinkBankClick }
          onRemoveAccount={ onRemoveBankClick(key) }
        />,
      )
    );  
  }, [errorLoadingCards, user.loadingCards, user.cardsLoaded, user.groupedCards, cards, onRelinkBankClick, onRemoveBankClick]);

  const renderLinkCardButton = () => (
    <AddAccountButton
      kind={ ButtonKind.Blank }
      onClick={ onPlaidClick }
      onPlaidSuccess={ onPlaidSuccess }
    >
      <PlusIcon stroke={ theme.colors.black } />
      <H5 className='add-account-text'>Add Acount</H5>
    </AddAccountButton>
  );

  return (
    <BankAccountsContainer className={ className }>
      { renderAccountCards() }
      { renderLinkCardButton() }
      <RemoveBankModal
        isOpen={ isRemoveBankModalOpen }
        loading={ isRemovingBankLoading }
        onClose={ onRemoveBankClose('close') }
        onConfirm={ onConfirmRemove }
        onCancel={ onRemoveBankClose('cancel') }
      />
      <RelinkCardModal
        isOpen={ showRelinkBankModal }
        onClose={ onRelinkBankModalClose }
        onRelinkSuccess={ onRelinkSuccess }
      />
    </BankAccountsContainer>
  );
};

export const LinkedBankAccounts = observer(LinkedBankAccountsBase);
