import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';
import { TextSMMedium } from '../../styles/components/text';

interface IProps {
  items: number;
}

export const PillProgressBarContainer = styled.ul<IProps>`
  ${FlexRow}
  list-style-type: none;
  padding-left: 0;

  .item-wrapper {
    ${FlexCol}
    border: solid 2px ${({ theme }) => theme.colors.white};
    justify-content: space-between;
    width: calc(100% / ${props => props.items});

    .pill {
      display: block;
      background: ${({ theme }) => theme.colors.lightGray2};
      border-radius: 4px;
      height: 8px;
    }

    &.complete {
      .pill {
        background: ${({ theme }) => theme.colors.primary};
      }
    }

    &.active {
      .pill {
        background: ${({ theme }) => theme.colors.primary};
      }
    }

    div {
      ${FlexCol}
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;

      .item-label {
        ${TextSMMedium}
        text-align: center;
      }

      @media (${({ theme }) => theme.breakpoints.xsMin}) {
        ${FlexRow}
        gap: 4px;
      }
    }

  }
`;
