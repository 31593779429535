import React from 'react';
import { ApplyForCardContainer } from './styles';
import { H2M, H2MInverted, H5 } from '../../styles/components/header';
import { Underline } from '../svgs/shapes/Undeline';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { appVersionAtom } from '../../models/misc/atom';
import { useRecoilValue } from 'recoil';

interface IProps {
  className?: string;
  onApplyClick(): void;
}

const backgroundImage = 'https://cdn.karmawallet.io/uploads/IULhYSvcKm-rainforest-sm.webp';

export const ApplyForCardWidget: React.FC<IProps> = ({
  className = '',
  onApplyClick,
}) => {
  const appVersionData = useRecoilValue(appVersionAtom);
  const buttonText = appVersionData.appVersion === 'beta' ? 'Join the Waitlist' : 'Apply Now';

  return (
    <ApplyForCardContainer className={ className } backgroundImage={ backgroundImage }>
      <H5 className='sub-text'>Get the Karma Wallet Card</H5>
      <span className='text-wrapper'>
        <H2MInverted className='inverted-text'>Protect</H2MInverted>
        <span className='underlined-text-wrapper'>
          <H2M className='regular-text'>
              the planet with every
            <span className='underlined-word-wrapper'>
              <H2M className='regular-text underlined-word'>swipe</H2M>
              <Underline id='form-text-underline' />
            </span>
          </H2M>
        </span>
      </span>
      <ButtonLink
        className='karma-card'
        href='/apply'
        kind={ ButtonKind.Secondary }
        onClick={ onApplyClick }
      >
        { buttonText }
      </ButtonLink>
    </ApplyForCardContainer>
  ); 
};
