import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { ICommission } from '../../models/commisions';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { CashbackAccrualItemRowContainer } from './styles';

export interface IAccrualItem {
  date: string;
  company: string;
  amount: number;
  _id: string;
  status: string;
}

interface IProps {
  className?: string;
  accrualItem: ICommission;
}

export const CashbackAccrualItemRow: React.FC<IProps> = ({
  className = '',
  accrualItem,
}) => {
  const analytics = useAnalytics();
  const { createdOn, company, allocation, status, merchantName } = accrualItem;

  const onCompanyNameClick = useCallback(() => {
    analytics.fireEvent('CurrentAccrualsPage_Company_Click');
  }, []);

  const companyLink = useMemo(() => {
    if (!!company) {
      return (
        <ButtonLink
          kind={ ButtonKind.Blank }
          // disabled if this is ImpactKarma
          disabled={ company?._id === '62def0e77b212526d1e055ca' }
          onClick={ onCompanyNameClick }
          href={ `/company/${company._id}/${company?.slug}` }
          label={ `View ${company?.companyName} page` }
          className='company'
        >
          { company?.companyName }
        </ButtonLink>
      );
    }

    // Kard local offer commission
    return (
      <span className='company'>{ merchantName }</span>
    );
  }, []);

  return (
    <CashbackAccrualItemRowContainer className={ className }>
      <div className='top-row'>
        <div className='date-name-container'>
          <div className='date'>{ dayjs(createdOn).format('MMM DD, YYYY') }</div>
          { companyLink }
        </div>
        <div className='amount-pending-container'>
          { status === 'pending' && <div className='pending'>pending</div> }
          <div className='amount'>${ allocation.user.toFixed(2) }</div>
        </div>
      </div>
    </CashbackAccrualItemRowContainer>
  );
};
