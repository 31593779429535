import styled from 'styled-components';
import { H5 } from '../../styles/components/header';
import { FlexCenter, FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow, PrimaryFont } from '../../styles/styles';
import { MoreMenu } from '../MoreMenu';

interface ICashbackOptionsContainerProps {
  verified: boolean;
}

export const BankCardWrapper = styled.div`
  ${FlexCol}
  max-width: 380px;

  .connected-accounts-card {
    justify-content: space-evenly;
  }
`;

export const BankCardContainer = styled.div`
  ${FlexCol}
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGray};
  border-radius: 9px;
  height: 186px;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 25px;
  width: 100%;
  z-index: 2;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    border-radius: 12px;
    height: 238px;
    max-width: 380px;
    width: 380px;
  }
`;

export const BankLogoContainer = styled.div`
  ${FlexCenter}
  border: 1px solid ${({ theme }) => theme.colors.midGray};
  border-radius: 50%;
  height: 60px;
  min-width: 60px;
  width: 60px;

  img,
  svg {
    border-radius: 50%;
    width: 60%;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    height: 100px;
    min-width: 100px;
    width: 100px;
  }
`;

export const BankCardFooter = styled.div`
  ${FlexHorizontalCenter}
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray1};
  padding-top: 10px;
`;

export const BankCardMoreMenu = styled(MoreMenu)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const BankName = styled(H5)`
  ${PrimaryFont}
  font-size: 16px;
  text-align: left;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    font-size: 18px;
  }
`;

export const CardInfo = styled.div`
  ${FlexCol}
  align-items: start;
`;

export const CardInfoContainer = styled.div`
  ${FlexCol}
  align-items: flex-start !important;
  padding-left: 10px;

  .bank-card-name {
    align-self: flex-start;
  }

  .blank-card-placeholder {
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: 2px;
    display: block;
    height: 16px;
    margin-bottom: 6px;
    width: 100px;
  }

  .info-line-one {
    height: 12px;
  }

  .info-line-two {
    height: 12px;
    width: 75px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .blank-card-placeholder {
      height: 1.5rem;
      width: 130px;
    }
  
    .info-line-one {
      height: 1rem;
    }
  
    .info-line-two {
      height: 1rem;
      width: 97px;
    }
  }
`;

export const CardMask = styled.p`
  ${PrimaryFont}
  margin: 0;
  padding-right: 10px;
`;

export const CardType = styled(CardMask)`
  text-transform: capitalize;
`;

export const LastUpdated = styled.span`
  color: ${({ theme }) => theme.colors.darkGray3};
  font-style: italic;
`;

export const MainBankCardInfo = styled.div`
  ${FlexHorizontalCenter}
`;

export const RelinkMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  margin: 0;
`;

export const CashbackOptionsContainer = styled.div<ICashbackOptionsContainerProps>`
  background-color: ${( props ) => props.verified ? ({ theme }) => theme.colors.green : ({ theme }) => theme.colors.lightGray};
  border-radius: 0 0 9px 9px;
  display: flex;
  height: 40px;
  position: relative;
  padding: 0 20px;
  top: 0px;
  width: 99%;

  &::before {
    background-color: ${( props ) => props.verified ? ({ theme }) => theme.colors.green : ({ theme }) => theme.colors.lightGray};
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    top: -15px;
    width: 100%;
  }

  .info-container {
    ${FlexRow}
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .icon-container {
      ${FlexRow}
      align-items: center;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 0px;
        margin: 2px 13px 0;
      }

      .card-info-icon-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
      }
    }

    .cashback-options-earn-button {
      font-size: 10px;
      font-weight: 500;
      padding: 0 5px;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        font-size: 12px;
        padding: 0 15px;
      }
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      border-radius: 0 0 12px 12px;
    }
  }
`;

export const DashBoardCard = styled.div`
  ${FlexColCenter}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 16px;
  min-height: 196px;
  padding: 6px 0;
  width: 145px;

  .add-card {
    background: ${({ theme }) => theme.colors.grey6};
  }
`;

export const DashBoardBankLogoContainer = styled.div`
  ${FlexCenter}
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-radius: 50%;
  height: 88px;
  width: 88px;

  img, svg {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .plus-icon {
    width: 24px;
    height: 24px;
  }
`;

export const DashBoardCardInfoContainer = styled.div`
  .bank-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const CardDetails = styled.div`
  .card-details {
    color: ${({ theme }) => theme.colors.midGray};
    display: inline;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin: unset;
  }
`;
