import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Formula: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }formula-graphic` }
    id={ id }
    width={ 82 }
    height={ 100 }
    fill='none'
  >
    <g clipPath='url(#clip0_1192_9305)'>
      <path d='M40.4373 12.9232C50.8424 12.9232 59.2775 10.2491 59.2775 6.95037C59.2775 3.65167 50.8424 0.977539 40.4373 0.977539C30.0322 0.977539 21.5972 3.65167 21.5972 6.95037C21.5972 10.2491 30.0322 12.9232 40.4373 12.9232Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
      <path d='M21.5972 6.95044L25.6888 27.0654L1.57401 83.2886C0.903867 84.8446 0.796159 86.5853 1.26937 88.2121C1.74258 89.8388 2.76721 91.2502 4.16742 92.2039C8.75577 95.32 18.7783 98.5239 40.4453 99.0286C62.1123 98.5239 72.1328 95.32 76.7232 92.2039C78.1234 91.2502 79.148 89.8388 79.6212 88.2121C80.0944 86.5853 79.9867 84.8446 79.3166 83.2886L55.1859 27.0654L59.2795 6.95044' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
      <path d='M76.1826 84.6172L53.9551 32.8008H46.1349C46.1349 32.8606 46.1349 32.9185 46.1349 32.9803C46.1349 33.9607 45.7454 34.901 45.0522 35.5942C44.3589 36.2875 43.4187 36.6769 42.4383 36.6769C41.4579 36.6769 40.5176 36.2875 39.8244 35.5942C39.1311 34.901 38.7417 33.9607 38.7417 32.9803C38.7417 32.9185 38.7417 32.8606 38.7417 32.8008H26.9217L4.69216 84.6172C4.3327 85.4481 4.27306 86.3781 4.52341 87.2482C4.77376 88.1182 5.31856 88.8743 6.06467 89.3871C9.85505 91.9526 18.8761 95.1165 40.4374 95.6252C61.9986 95.1165 71.0297 91.9526 74.8101 89.3871C75.5565 88.8745 76.1015 88.1185 76.3519 87.2483C76.6023 86.3782 76.5424 85.448 76.1826 84.6172Z' fill='#64949B' />
      <path d='M36.2839 28.342C38.0016 28.342 39.394 26.9496 39.394 25.2319C39.394 23.5143 38.0016 22.1218 36.2839 22.1218C34.5663 22.1218 33.1738 23.5143 33.1738 25.2319C33.1738 26.9496 34.5663 28.342 36.2839 28.342Z' fill='#64949B' />
      <path d='M33.5904 47.196C36.0892 47.196 38.1149 45.1703 38.1149 42.6715C38.1149 40.1727 36.0892 38.147 33.5904 38.147C31.0916 38.147 29.0659 40.1727 29.0659 42.6715C29.0659 45.1703 31.0916 47.196 33.5904 47.196Z' fill='#fff' />
      <path d='M27.7935 61.5037C30.1358 61.5037 32.0347 59.6048 32.0347 57.2625C32.0347 54.9201 30.1358 53.0212 27.7935 53.0212C25.4511 53.0212 23.5522 54.9201 23.5522 57.2625C23.5522 59.6048 25.4511 61.5037 27.7935 61.5037Z' fill='#fff' />
      <path d='M41.0178 54.8107C42.2837 54.8107 43.3099 53.7844 43.3099 52.5185C43.3099 51.2526 42.2837 50.2263 41.0178 50.2263C39.7518 50.2263 38.7256 51.2526 38.7256 52.5185C38.7256 53.7844 39.7518 54.8107 41.0178 54.8107Z' fill='#fff' />
      <path d='M50.2881 51.3975C52.6305 51.3975 54.5293 49.4986 54.5293 47.1563C54.5293 44.8139 52.6305 42.915 50.2881 42.915C47.9457 42.915 46.0469 44.8139 46.0469 47.1563C46.0469 49.4986 47.9457 51.3975 50.2881 51.3975Z' fill='#fff' />
      <path d='M47.3039 26.4168C49.7652 26.4168 51.7605 24.4215 51.7605 21.9601C51.7605 19.4987 49.7652 17.5034 47.3039 17.5034C44.8425 17.5034 42.8472 19.4987 42.8472 21.9601C42.8472 24.4215 44.8425 26.4168 47.3039 26.4168Z' fill='#64949B' />
    </g>
    <defs>
      <clipPath id='clip0_1192_9305'>
        <rect width='81.7308' height='100' fill='#fff' />
      </clipPath>
    </defs>
  </SvgIcon>
);
