import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { AccrualsContainer, LifetimeCashbackContainer, YourBalanceContainer, YourCashbackContainer } from './styles';
import { AccountHubH3, H5 } from '../../../../styles/components/header';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { CommissionsDashboardModel } from '../../../../models/commisions';
import { Tooltip } from '../../../../components/Tooltip';
import { ButtonLink } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { CashbackAccrualItemRow } from '../../../../components/CashbackAccrualtemRow';

interface IProps {
  className?: string;
  commissionsDashboardModel: CommissionsDashboardModel;
  loading: boolean;
}

const YourCashbackBase: React.FC<IProps> = ({
  className,
  commissionsDashboardModel,
  loading,
}) => {
  const analytics = useAnalytics();

  const onTooltipClick = (identifier: string) => {
    analytics.fireEvent(`AccountHub_${identifier}_Tooltip_Click`);
  };

  const onViewAllAccrualsClick = () => {
    analytics.fireEvent('Account_Cashback_ViewAllAccruals_Click');
  };

  const lifetimeCashback = useMemo(() => {
    const lifetimeCashbackAmount = commissionsDashboardModel.lifeTimeCashback.toFixed(2);

    return (
      <LifetimeCashbackContainer>
        <AccountHubH3 className='left-content'>Lifetime Karma Cash</AccountHubH3>
        <H5 className='right-content'>${ lifetimeCashbackAmount }</H5>
      </LifetimeCashbackContainer>
    );
  }, [commissionsDashboardModel.lifeTimeCashback]);

  const yourBalance = useMemo(() => {
    const currentBalance = commissionsDashboardModel.balance.toFixed(2);

    return (
      <YourBalanceContainer>
        <AccountHubH3>Your Balance ${ currentBalance }</AccountHubH3>
      </YourBalanceContainer>
    );
  }, [commissionsDashboardModel.balance, commissionsDashboardModel.nextPayoutDate]);

  const cashbackAccruals = useMemo(() => {
    let children;

    if (commissionsDashboardModel.accruals.length > 0) {
      const rows = commissionsDashboardModel.accruals.map(item => <CashbackAccrualItemRow key={ item._id } accrualItem={ item } />);

      if (commissionsDashboardModel.accruals.length > 3) {
      // if there are more than 3 accruals, take the user to the accrual list to see all of them
        children = (
          <div className='accruals-wrapper'>
            { rows.slice(0, 3) }
            <div className='view-all-accruals-wrapper'>
              <ButtonLink
                className='view-all-accruals-button'
                kind={ ButtonKind.PrimaryGhost }
                label='View All Accruals'
                href='/account/current-cashback-accruals'
                onClick={ onViewAllAccrualsClick }
              >
                View All
              </ButtonLink>
            </div>
          </div>
        );
      } else {
        children = rows;
      }
    }

    if (loading) {
      children = <div className='no-info-text'>Loading cashback accruals...</div>;
    }
    
    if (!commissionsDashboardModel.accruals.length && !loading) {
      children = <div className='no-info-text'>You have no current cashback accruals</div>;
    }

    const headerDiv = (
      <div className='accruals-header-container'>
        <AccountHubH3 className='accruals-header'>Cashback Accruals</AccountHubH3>
        <div
          className='cashback-accruals-info-icon'
          onClick={ () => onTooltipClick('CashbackAccruals') }
        >
          <Tooltip text='All accrued cashback not in a pending state is deposited on your Karma Wallet card on a monthly basis.' />
        </div>
      </div>
    );
  
    return (
      <AccrualsContainer>
        { headerDiv }
        { children }
      </AccrualsContainer>
    );
  }, [commissionsDashboardModel.accruals]);

  return (
    <YourCashbackContainer className={ className }>
      { yourBalance }
      { cashbackAccruals }
      { lifetimeCashback }
    </YourCashbackContainer>
  );
};

export const YourCashback = observer(YourCashbackBase);
