import React from 'react';
import { withTheme } from 'styled-components';
import { useAppData } from '../../../../../contexts/app';

export const CustomDotBase: React.FC<any> = ({
  cx,
  cy,
  value,
  currentDot,
  index,
  onTouchEnd,
  theme,
}) => {
  const appData = useAppData();
  
  const renderSelectedRectangle = () => {
    if (index === currentDot) {
      return (
        <>
          <rect
            x={ appData.isDesktop ? cx - 20 : cx - 10 }
            y={ 10 }
            width={ appData.isDesktop ? 40 : 20 }
            height={ 250 }
            fill={ theme.colors.tertiary + '77' }
            style={ { mixBlendMode: 'multiply' } }
            rx={ 0 }
            ry={ 0 }
          />
          <line
            x1={ cx }
            y1={ 10 }
            x2={ cx }
            y2={ 261 }
            stroke={ theme.colors.dark }
            strokeWidth='1'
            strokeDasharray='2,2'
          />
        </>
      );
    }
  };

  return(
    <>
      { renderSelectedRectangle() }
      <circle
        className={ !!value ? 'circle' : '' }
        xmlns='http://www.w3.org/2000/svg'
        cx={ cx }
        cy={ cy }
        r={ index === currentDot ? 6 : 3 }
        fill={ (!value || index !== currentDot) ? theme.colors.dark : theme.colors.tertiary }
        stroke={ theme.colors.darkGray1 }
        strokeWidth='4'
        onTouchEnd={ () => onTouchEnd(index) }
      />
    </>
  );
};

export const CustomDot = withTheme(CustomDotBase);
