import styled from 'styled-components';
import { FlexColCenter } from '../../styles/styles';

interface Iprops {
  kind?: 'light' | 'dark';
}

export const MembershipCardContainer = styled.div<Iprops>`
  ${FlexColCenter}
  align-items: center;
  border: ${({ kind, theme }) => kind === 'light' ? `1px solid ${theme.colors.primary}` : 'none'};
  justify-content: center;
  background-color: ${({ kind, theme }) => kind === 'light' ? `${theme.colors.secondary}0D`: `${theme.colors.white}1A`};
  border-radius: 16px;
  gap: 14px;
  min-height: ${({ kind }) => kind === 'light' ? '88px' : '117px'};
  width: 100%;

  p {
    margin: 0;
  }

  div {
    ${FlexColCenter}
    align-items: center;
    gap: 4px;
  }

  .amount {
    color: ${({ kind, theme }) => kind === 'light' ? theme.colors.primary : theme.colors.secondary};
    font-size: 42px;
    font-weight: 600;
    line-height: 42px;
  }

  .period {
    color: ${({ kind, theme }) => kind === 'light' ? theme.colors.primary : theme.colors.secondary};
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }

  .join-now {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  }

  .yellow-text {
    color: ${({ theme }) => theme.colors.tertiary};
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
  }
`;
