import React, { useMemo } from 'react';
import { HomeContainer } from './styles';
import { ConditionalAccess } from '../../components/ConditionalAccess';
import { LivingYourValuesCTASection } from '../../components/LivingYourValuesCTASection';
import { useUserSession } from '../../contexts/user';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { PromoCreateAccountHomeModal } from '../../components/modals/PromoCreateAccountHomeModal';
import { OurImpactSection } from '../../components/OurImpact';
import { KarmaWalletCardSection } from '../../components/KarmaWalletCardSection';
import { HomePageBlogSection } from '../../components/HomePageBlogSection';
import { AsFeaturedInSection } from '../../components/AsFeaturedInSection';
import { HomeDisclaimerSection } from '../../components/HomeDisclaimerSection';
import DiscoverTheTruthSection from './DiscoverTheTruthSection';

interface IProps {
  className?: string;
}

const HomeBase: React.FC<IProps> = ({
  className = '',
}) => {
  const user = useUserSession();

  const transparent = useMemo(() => {
    if (!!user.isLoggedIn) return false;
    return true;
  }, [user.isLoggedIn]); 

  if (!!user.authenticating || !user.initialized) {
    return (
      <HomeContainer transparent>
        <LoadingSpinner />
      </HomeContainer>
    );
  }
  
  // Temporarily Removes apply for card feature Component
  /*const applyForCardFeature = () => {
    if (!user.authenticating && user.initialized && user.isLoggedIn && !user.hasKarmaWalletCard && !user.loadingCards) {
      return (
        <ConditionalAccess loggedIn>
          <ApplyForCardFeature />
        </ConditionalAccess>
      );
    } else {
      return null;
    }
  }; */

  return (
    <HomeContainer 
      className={ `${className} ${!!user.isLoggedIn ? 'logged-in' : 'marketing'}` } 
      transparent={ transparent }
      title='Home'
    >
      <ConditionalAccess loggedIn={ false }>
        <PromoCreateAccountHomeModal />
      </ConditionalAccess>
      <ConditionalAccess karmaCardCardholder={ false }>
        <KarmaWalletCardSection />
        <AsFeaturedInSection />
      </ConditionalAccess>
      <DiscoverTheTruthSection />
      <ConditionalAccess karmaCardCardholder={ true }>
        <AsFeaturedInSection />
      </ConditionalAccess>
      <HomePageBlogSection />
      <OurImpactSection />
      <ConditionalAccess loggedIn={ false }>
        <LivingYourValuesCTASection />
        <HomeDisclaimerSection />
      </ConditionalAccess>
    </HomeContainer>
  );
};

export const Home = observer(HomeBase);
