import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { useUserSession } from '../../../../contexts/user';
import { validateEmail } from '../../../../lib/formatValidators';
import { withTheme } from 'styled-components';
import { Button } from '../../../../components/Button';
import { EnvelopeIcon } from '../../../../components/svgs/icons/EnvelopeIcon';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { getLocalStorageWithExpirationDayjs, setLocalStorageItemWithExpiry } from '../../../../lib/localStorage';
import { ApplicationLocalStorageKey, ApplyForCardModel, CurrentPage } from '../../../../models/karmaCard';
import { IThemeProps } from '../../../../styles/themes';
import { CTAContainer } from '../../styles';
import { PageOneContainer, InputFieldsContainer, LogInContainer, CheckboxContainer } from './styles';
import { ButtonKind } from '../../../../components/Button/styles';
import { useSignInModal } from '../../../../contexts/sign-in';
import { SignInMode } from '../../../../models/sign-in';
import { getExistingApplication } from '../..';
import { GroupModel } from '../../../../models/group';
import { useRecoilValue } from 'recoil';
import { membershipPriceAtom } from '../../../../models/membership/atom';
import { ArrowDirection, ArrowIcon } from '../../../../components/svgs/icons/ArrowIcon';

interface IProps extends IThemeProps {
  applyModel: ApplyForCardModel;
  group?: GroupModel;
}

interface ITermsUrls {
  digitalWalletTermsAndConditions: string;
  karmaCardHolderAgreement: string;
  karmaCashbackProgram: string;
  karmaElectronicConsentPolicy: string;
  karmaPrivacyPolicy: string;
  karmaTermsOfService: string;
  pathwardBankPrivacyPolicy: string;
  usPatriotAct: string;
  shortFormAgreement: string;
}

const termsUrls: ITermsUrls = {
  digitalWalletTermsAndConditions: 'https://cdn.karmawallet.io/documents/digital_wallet_terms_and_conditions.pdf',
  karmaCardHolderAgreement: 'https://cdn.karmawallet.io/documents/karma_wallet_pathward_cardholder_agreement.pdf',
  karmaCashbackProgram: 'https://cdn.karmawallet.io/documents/karmawallet_Terms_and_Conditions_Cashback_Program.pdf',
  karmaElectronicConsentPolicy: 'https://cdn.karmawallet.io/documents/karma_wallet_electronic_consent_policy.pdf',
  karmaPrivacyPolicy: 'https://karmawallet.io/privacy-policy',
  karmaTermsOfService: 'https://cdn.karmawallet.io/documents/impactkarma_terms_and_conditions.pdf',
  pathwardBankPrivacyPolicy: 'https://cdn.karmawallet.io/documents/pathward_privacy_policy.pdf',
  usPatriotAct: 'https://cdn.karmawallet.io/documents/us_partriot_act_policy.pdf',
  shortFormAgreement: 'https://cdn.karmawallet.io/documents/Karma_Wallet_Card_Short_form_agreement.pdf',
};

const terms = (
  <p>
    I have read, understand and agree to  <a href={ termsUrls.karmaElectronicConsentPolicy } target='_blank' rel='noreferrer noopener'>Karma Wallet's Electronic Consent Policy</a> and agree to receive in app notices. I have read and agree to <a href={ termsUrls.karmaCashbackProgram } target='_blank' rel='noreferrer noopener'>Karma Wallet's Cashback Program Terms of Use</a>, <a href={ termsUrls.karmaCardHolderAgreement } target='_blank' rel='noreferrer noopener'>Karma Wallet's Cardholder Agreement</a>, <a href={ termsUrls.shortFormAgreement } target='_blank' rel='noreferrer noopener'>Karma Wallet's Short Form Agreement</a>, <a href={ termsUrls.digitalWalletTermsAndConditions } target='_blank' rel='noreferrer noopener'>Digital Wallet Terms & Conditions</a>, <a href={ termsUrls.karmaPrivacyPolicy } target='_blank' rel='noreferrer noopener'>Karma Wallet's Privacy Policy</a>, <a href={ termsUrls.pathwardBankPrivacyPolicy } target='_blank' rel='noreferrer noopener'>Pathward Bank's Privacy Policy</a>, <a href={ termsUrls.usPatriotAct } target='_blank' rel='noreferrer noopener'>The USA PATRIOT Act</a>, and <a href={ termsUrls.karmaTermsOfService } target='_blank' rel='noreferrer noopener'>Karma Wallet's Terms of Service</a>.
  </p>
);

export const PageOneBase: React.FC<IProps> = ({
  applyModel,
  group,
  theme,
}) => {
  const analytics = useAnalytics();
  const userSession = useUserSession();
  const signInModal = useSignInModal();
  const membershipPrice = useRecoilValue(membershipPriceAtom);
  const [email, setEmail] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const userModel = useUserSession();
  const [agreedToTerms, setAgreedToTerms] = useState<{ [key: string]: boolean }>({});
  const [emailError, setEmailError] = useState('');
  const [confirmationEmailError, setConfirmationEmailError] = useState('');
  const hidePage = useMemo(() => applyModel.currentPage !== CurrentPage.PageOne, [applyModel.currentPage]);
  const donationAmount = useMemo(() => {
    if (!!group?.defaultGroupDonation) {
      return group.defaultGroupDonation.amount;
    }
  }, [group]);

  const membershipText = useMemo(() => (
    <p>
      I understand that Karma Wallet is a paid membership, and I will be charged { membershipPrice.price } per year. I acknowledge that I can cancel my membership at any time, but my membership will automatically renew unless I cancel 30 days prior to my renewal date.
    </p>
  ), [membershipPrice.price]);

  const checkboxItems = useMemo(() => {
    const items = [
      {
        id: 'membership-checkbox',
        label: membershipText,
      },
      {
        id: 'terms-checkbox',
        label: terms,
      },
    ];

    if (!!group && group?.defaultGroupDonation) {
      const donationText = (
        <p>
          { `I understand that I am applying for the Karma Wallet Card through the ${group.name} and Karma Wallet partnership. I agree that` }
          <span className='amount-text'> { donationAmount }% </span> 
          { `of the cashback earned with my Karma Wallet Card will be automatically donated back to ${group.defaultGroupDonation.donationRecipient.name}.` }
        </p>
      );
      items.unshift({
        id: 'donation-checkbox',
        label: donationText,
      });
    }

    return items;
  }, [group]);

  useEffect(() => {
    if (!!userModel.isLoggedIn) {
      setEmail(userModel.primaryEmail.email);
      setConfirmationEmail(userModel.primaryEmail.email);
      applyModel.setEmail(userModel.primaryEmail.email);
      applyModel.setUserId(userModel._id);
      applyModel.setConfirmationEmail(userModel.primaryEmail.email);
      setConfirmationEmailError('');
      setEmailError('');
    } else {
      setEmail('');
      setConfirmationEmail('');
    }
  }, [userModel.isLoggedIn, userModel.emails]);

  useEffect(() => {
    const affiliateInfo = getLocalStorageWithExpirationDayjs(ApplicationLocalStorageKey.affiliateParam);
    const xTypeParam = getLocalStorageWithExpirationDayjs(ApplicationLocalStorageKey.xTypeParam);
    const sscidCreatedOn = getLocalStorageWithExpirationDayjs(ApplicationLocalStorageKey.sscidCreatedOn);

    if (!!affiliateInfo) {
      applyModel.setSSCID(affiliateInfo);
      applyModel.setXType(xTypeParam);
      applyModel.setSSCIDCreatedOn(sscidCreatedOn);
    }
  }, []);

  const isDisabled =
    !!emailError || !!confirmationEmailError || !email || !confirmationEmail || email !== confirmationEmail || !agreedToTerms.agreedToTerms;

  const onEmailChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    const lowercasedEmail = e.target.value.toLowerCase();
    setEmail(lowercasedEmail);

    if (!validateEmail(e.target.value)) return;

    try {
      const emailCheckResponse = await ApplyForCardModel.checkIfEmailAvailable(e.target.value);
      if (!emailCheckResponse.success) {
        setEmailError(emailCheckResponse.error);
      } else {
        setEmailError('');
        applyModel.setEmail(lowercasedEmail);

        if (e.target.value === confirmationEmail || !confirmationEmail) {
          setConfirmationEmailError('');
        } else {
          setConfirmationEmailError('Emails do not match');
        }
      }
    } catch {
      setEmailError('An error has occurred, please contact support@karmawallet.io');
    }
  };

  const onEmailBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!validateEmail(e.target.value) && !!email) {
      setEmailError('Invalid email address');
      return;
    }
  };

  const onConfirmationEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationEmailError('');
    const lowercasedEmail = e.target.value.toLowerCase();
    setConfirmationEmail(lowercasedEmail);
    const isValid = validateEmail(e.target.value) && e.target.value === email;
    if (!isValid && !!e.target.value) {
      setConfirmationEmailError('Emails do not match');
      return;
    }
    applyModel.setConfirmationEmail(lowercasedEmail);
  };

  const handleCheckboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreedToTerms((prevState) => {
      const updatedState = {
        ...prevState,
        [e.target.id]: e.target.checked,
      };
      updatedState.agreedToTerms = updatedState['terms-checkbox'] && updatedState['membership-checkbox'];
      return updatedState;
    });
  };

  const handleDonationCheckboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    applyModel.setDonationsOptedIn(e.target.checked);
  };

  const onContinueClick = () => {
    analytics.fireEvent('Continue_To_Persona_Data_Collection_Click');
    setLocalStorageItemWithExpiry(ApplicationLocalStorageKey.email, applyModel.email, dayjs().add(7, 'day'));
    (async () => { await getExistingApplication(applyModel, analytics); })();
    setLocalStorageItemWithExpiry(ApplicationLocalStorageKey.page, applyModel.currentPage, dayjs().add(7, 'day'));
    if (applyModel.currentPage !== CurrentPage.PageOne) return;
    applyModel.setAwaitingApplicationDecision(true);
    applyModel.restartSocketConnection();
    applyModel.setOpenPersonaModal(true);
  };

  const onLoginClick = () => {
    signInModal.open(SignInMode.SIGN_IN);
    analytics.fireEvent('Apply_SignIn_Click');
  };

  const onKarmaScoreHereClick = () => {
    signInModal.open(SignInMode.CREATE_ACCOUNT);
    analytics.fireEvent('Apply_Karma_Score_Here_Click');
  };

  const emailField = useMemo(() => (
    <TextField
      id='email'
      className={ emailError ? 'error' : '' }
      fieldKind={ TextFieldKind.Pill }
      label='Email'
      disabled={ userModel.isLoggedIn }
      leftAccessory={ <EnvelopeIcon stroke={ theme.colors.grey3 } /> }
      placeholder={ userModel.isLoggedIn ? userModel.primaryEmail.email : 'Email Address' }
      type='email'
      value={ email }
      errorText={ !!emailError ? emailError : '' }
      onChange={ onEmailChange }
      onBlur={ onEmailBlur }
    />
  ), [emailError, confirmationEmail, onEmailChange]);

  const confirmEmailField = useMemo(() => (
    <TextField
      id='confirm-email'
      className={ confirmationEmailError ? 'error' : '' }
      fieldKind={ TextFieldKind.Pill }
      label='Confirm Email'
      placeholder='Confirm Email'
      leftAccessory={ <EnvelopeIcon stroke={ theme.colors.grey3 } /> }
      type='email'
      value={ confirmationEmail }
      errorText={ !!confirmationEmailError ? confirmationEmailError : '' }
      onChange={ onConfirmationEmailChange }
    />
  ), [confirmationEmailError, confirmationEmail, onConfirmationEmailChange]);

  const loginSection = useMemo(() => {
    if (userSession.isLoggedIn) return null;

    return (
      <>
        <LogInContainer>
          <span>Already have a Karma Wallet account?</span>
          <Button kind={ ButtonKind.Blank } onClick={ onLoginClick }>Log In</Button>
        </LogInContainer>
        <LogInContainer className='not-ready'>
          <span>Not ready yet? Get your free Karma Score</span>
          <Button kind={ ButtonKind.Blank } onClick={ onKarmaScoreHereClick }>here!</Button>
        </LogInContainer>
      </>
    );
  }, [userSession.isLoggedIn, onLoginClick, onKarmaScoreHereClick]);

  if (hidePage) return null;

  return (
    <PageOneContainer>
      <InputFieldsContainer>
        { emailField }
        { !userModel?.isLoggedIn && confirmEmailField }
      </InputFieldsContainer>
      {
        checkboxItems.map((item) => (
          <CheckboxContainer key={ item.id }>
            <input
              className='agree-checkbox'
              type='checkbox'
              name='agree-checkbox'
              id={ item.id }
              onChange={ item.id === 'donation-checkbox' ? handleDonationCheckboxToggle : handleCheckboxToggle }
            />
            <label htmlFor={ item.id }>{ item.label }</label>
          </CheckboxContainer>
        ))
      }
      <CTAContainer>
        <Button
          className='continue-cta'
          disabled={ isDisabled }
          kind={ ButtonKind.SecondaryGreen }
          onClick={ onContinueClick }
        >
          Continue
          <div className='arrow-icon-container'>
            <ArrowIcon
              stroke={ isDisabled ? theme.colors.disabled : theme.colors.darkGray1 }
              direction={ ArrowDirection.Right } 
            />
          </div>
        </Button>
        { loginSection }
      </CTAContainer>
    </PageOneContainer>
  );
};

const PageOneObserver = observer(PageOneBase);
export const PageOne = withTheme(PageOneObserver);
