import React from 'react';
import { PillProgressBarContainer } from './styles';

interface ProgressItem {
  label?: string;
  complete: boolean;
  active: boolean;
}

interface IProps {
  className?: string;
  items: ProgressItem[];
}

export const PillProgressBar: React.FC<IProps> = ({
  className = '',
  items,
}) => {
  const renderPills = () => items.map((item, index) => {
    const itemLabel = item.label ? item.label.split(' ') : [];
    return (
      <li className={ item.complete ? 'complete item-wrapper' : item.active ? 'active item-wrapper' : 'item-wrapper' } key={ index }>
        {
          !!item.label && 
            <div>
              <span className='item-label'>{ itemLabel[0] }</span>
              <span className='item-label'>{ itemLabel[1] }</span>
            </div>
        }
        <span className='pill' />
      </li>
    );
  });

  return (
    <PillProgressBarContainer className={ className } items={ items.length }>
      { renderPills() }
    </PillProgressBarContainer>
  );
};
