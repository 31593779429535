import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextMDMedium, TextSMMedium } from '../../styles/components/text';

export const YouShopWeDonateSectionContainer = styled(Section)`
  ${FlexColCenter}
  padding: 60px 15px;
`;

export const KarmaCardSection = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  gap: 20px;
  max-width: 100%;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-around;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 1400px;
  }
`;

export const TextContainer = styled.div`
  ${FlexCol}
  align-items: center;
  max-width: 100%;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: flex-start;
  }

  h2 {
    font-size: 32px;
    line-height: 34px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 40px;
      line-height: 42px;
    }
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 23px;
  }
`;

export const KarmaAppNotificationsImgContainer = styled.div`
  .app-with-alerts {
    margin: auto;
    max-width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    max-width: 480px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 40%;
  }
`;

export const DonationsContainer = styled.div`
  ${FlexCol}
  gap: 23px;
  max-width: 480px;
`;

export const DonationContainer = styled.div`
  ${FlexHorizontalCenter}
  background: ${({ theme }) => theme.colors.primary}4D;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  gap: 12px;
  min-height: 106px;
  padding: 16px 24px;
  position: relative;
  text-align: left;

  p {
    ${TextMDMedium}
    margin-bottom: 28px;
  }

  img {
    max-height: 62px;
    width: 80px;
  }

  .learn-more {
    ${TextSMMedium}
    bottom: 16px;
    color: #243F50;
    position: absolute;
    right: 24px;
  }
`;

export const PartnershipsContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;
  gap: 42px 96px;
  justify-content: center;
  margin-top: 15px;

  .b-corp {
    height: 89px;
  }

  .one-percent {
    height: 74px;
    width: auto;
  }

  .one-tree {
    height: 74px;
  }

  .feeding-america {
    height: 54px;
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    padding: 0 23px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 96px;
    margin-top: 37px;
  }
`;
