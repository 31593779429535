import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { UserMonthlyImpactReportModel } from '../../models/userMonthlyImpactReport';
import { ImpactReportTransactionsContainer, ImpactReportTransactionsHeader, ImpactReportTransactionsHeaderContainer } from './styles';
import { ImpactReportTransactionItem } from '../ImpactReportTransactionItem';

interface IProps extends IThemeProps {
  className?: string;
  activeMonthReport: UserMonthlyImpactReportModel;
}

const ImpactReportTransactionsBase: React.FC<IProps> = ({
  className,
  activeMonthReport,
}) => {
  const renderTransactions = useCallback(() => {
    let content: JSX.Element[] = [];

    if (activeMonthReport.transactions === null) return <p className='no-transactions-text'>No transactions to show</p>;

    content = activeMonthReport.transactions.map( transaction => (
      <ImpactReportTransactionItem transaction={ transaction } key={ transaction._id } />
    ));

    return content;
  }, [activeMonthReport]);

  return (
    <ImpactReportTransactionsContainer className={ className }>
      <ImpactReportTransactionsHeaderContainer>
        <ImpactReportTransactionsHeader>
          Transactions
        </ImpactReportTransactionsHeader>
      </ImpactReportTransactionsHeaderContainer>
      { renderTransactions() }
    </ImpactReportTransactionsContainer>
  );
};

const ImpactReportTransactionsObserver = observer(ImpactReportTransactionsBase);
export const ImpactReportTransactions = withTheme(ImpactReportTransactionsObserver);
