import React from 'react';
import { AppLandingBenefitsInnerWrapper, AppLandingBenefitsSectionContainer } from './styles';
import { H2MobileMarketing } from '../../styles/components/header';
import KarmaCardImage from '../../../public/assets/images/empty-kw-card.png';
import { MembershipCard } from '../MembershipCard';
import { ApplyButton } from '../ApplyButton';
import { MembershipBenefits } from '../MembershipBenefits';

interface IProps {
  className?: string;
}

export const AppLandingBenefitsSection: React.FC<IProps> = ({
  className = '',
}) => (
  <AppLandingBenefitsSectionContainer className={ className }>
    <AppLandingBenefitsInnerWrapper>
      <img alt='Karma Wallet App and Card' className='karma-card' src={ KarmaCardImage } />
      <H2MobileMarketing>Karma Wallet Card: prepaid reloadable debit card.</H2MobileMarketing>
      <p className='good-for-you-text'>Good for your wallet, good for the planet.</p>
      <MembershipBenefits location='App' className='membership-benefits' />
      <MembershipCard className='membership-card'>
        <ApplyButton locationOfButton='AppLanding' />
      </MembershipCard>
    </AppLandingBenefitsInnerWrapper>
  </AppLandingBenefitsSectionContainer>
);
