import styled from 'styled-components';
import { Modal } from '../Modal';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const AutomaticRewardsEnrollModalContainer = styled(Modal)`
  ${FlexColCenter}
  max-width: 760px;
  max-height: 605px;
  text-align: center;
  width: 90vw;
  padding: 20px;

  .terms-of-service-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    max-width: 250px;
    margin-top: 20px;
  }

  .modal-body {
    ${FlexCol}
    align-items: center;
    justify-content: center;
  }

  .earn-more-cashback-title {
    color: ${ props => props.theme.colors.primary };
  }

  .card-number-info span {
    font-weight: 600;
  }

  .cta-container {
    ${FlexCol}
    gap: 5px;
    justify-content: center;
    margin-top: 10px;

    button {
      min-width: 150px;
    }

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexRow}
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
    }
  }

  .toggle-button-container {
    ${FlexRow}
    gap: 10px;
    justify-content: center;
  }

  .card-toggle-button {
    background-color: ${ props => props.theme.colors.midGray };
    border-radius: 50%;
    height: 6px;
    cursor: pointer;
    width: 6px;
  }

  .card-toggle-button.selected {
    background-color: ${ props => props.theme.colors.primary };
  }
`;
