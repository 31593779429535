import styled from 'styled-components';
import { BasicContainer, FlexCol, FlexColCenter, FocusVisible } from '../../styles/styles';

export const PlaidDisclaimerContainer = styled.div`
  ${FlexCol}
  text-align: left;

  @media ( ${({ theme }) => theme.breakpoints.mdMin}) {
    align-items: center;

    .graphic-container {
      margin: auto;
    }
  }

  .graphic-container {
    margin-bottom: 22px;
    text-align: center;

    img {
      display: block;
      height: 62px;
      margin: auto;
      margin-bottom: 8px;
      width: 119px;
    }
  }

  h5 {
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 22px;
    text-align: center;
  }

  p {
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 10px;
    text-align: center;
  }

  a {
      ${FocusVisible}
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
`;

export const PlaidDisclaimerWidgetContainer = styled.div`
  ${BasicContainer}
  ${FlexColCenter}
  align-items: center;
  border-radius: 16px;
  padding: 48px 60px;
  text-align: center;
  width: 100%;

  img {
      height: 83px;
      margin-bottom: 22px;
    }
    
  .header {
    font-size: 18px;
    line-height: 26px;
  }

  .description {
    font-weight: 500;
    margin-top: 22px;
    margin-bottom: 41px;
  }

  .learn-more {
    border-width: 1px;
    font-weight: 600;
    padding: 6px 24px;
    line-height: 19px;
  }
`;
