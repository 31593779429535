import React, { useCallback, useMemo } from 'react';
import { ButtonWrapper, ConnectedAccountsInnerContainer, ConnectedAccountsWidgetContainer, LoadingSpinnerContainer } from './styles';
import { AccountHubH2 } from '../../styles/components/header';
import { useAnalytics } from '../../contexts/analytics-store';
import { DashboardBankCards } from '../DashboardBankCards';
import { useUserSession } from '../../contexts/user';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { ButtonLink } from '../Button';
import { NoLinkedCards } from './NoLinkedCards';
import { ButtonKind } from '../Button/styles';

interface IProps {
  className?: string;
}

export const ConnectedAccountsWidgetBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const hasKarmaWalletCard = user.hasKarmaWalletCard && user.cardsLoaded;
  
  const onManageClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_ConnectedAccounts_Manage_Click');
  }, []);

  const accountsContent = useMemo(() => {
    if (user.loadingCards || !user.cardsLoaded) { 
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );
    }
  
    if (user.hasCards) {
      return (
        <>
          <DashboardBankCards className={ hasKarmaWalletCard ? 'has-karma-card' : 'bank-cards' } />
          <ButtonWrapper>
            <ButtonLink
              href='/account/linked-cards'
              onClick={ onManageClick }
              className='manage-accounts-text'
              kind={ ButtonKind.PrimaryGhost }
            >
              Manage Linked Accounts
            </ButtonLink>
          </ButtonWrapper>
        </>
      );
    }

    return <NoLinkedCards />;
  }, [user.loadingCards, user.cardsLoaded, user.hasCards, hasKarmaWalletCard]);

  return (
    <ConnectedAccountsWidgetContainer className={ className } hasKarmaCard={ hasKarmaWalletCard }>
      <AccountHubH2 className='header'>Linked Accounts</AccountHubH2>
      <ConnectedAccountsInnerContainer hasKarmaCard={ hasKarmaWalletCard }>
        { accountsContent }
      </ConnectedAccountsInnerContainer>
    </ConnectedAccountsWidgetContainer>
  );
};

export const ConnectedAccountsWidget = observer(ConnectedAccountsWidgetBase);
