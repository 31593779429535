import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonContainer, TransactionsItemSkeletonContainer, TransactionsPrimaryInfoSkeletonWrapper } from './styles';

interface IProps {
  className?: string;
}

export const TransactionsItemSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <TransactionsItemSkeletonContainer className={ className }>
    <SkeletonContainer>
      <Skeleton className='company-logo-skele' />
      <TransactionsPrimaryInfoSkeletonWrapper>
        <Skeleton className='company-name-skele' />
        <Skeleton className='amount-date-skele' />
      </TransactionsPrimaryInfoSkeletonWrapper>
    </SkeletonContainer>
  </TransactionsItemSkeletonContainer>
);
