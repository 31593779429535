import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexRow } from '../../styles/styles';

export const ManageKarmaWalletCardContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  gap: 20px;
  padding: 16px 20px;
  position: relative;
  text-align: center;
  width: 100%;

  .dismiss-icon {
    ${FlexRow}
    color: ${({ theme }) => theme.colors.grey3};
    height: 10px;
    position: absolute;
    right: 15px;
    top: 15px;

    svg {
      width: 10px;
    }
  }

  img {
    height: auto;
    margin-bottom: 10px;
    max-width: 148px;
  }

  h5 {
    line-height: 1.375rem;
    margin-bottom: 10px;

    @media ( ${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 18px;
    }
  }

  @media ( ${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    text-align: left;

    img {
      margin: 0;
    }
`;

export const AppStoreButtonsContainer = styled.div`
  ${FlexRow}
  gap: 12px;
  justify-content: center;
  margin-top: 10px;

  .app-store-link {
    all: unset;
    height: 40px;

    img {
      height: 40px;
      margin: 0;
    }
  }

  @media ( ${({ theme }) => theme.breakpoints.smMin}) {
    justify-content: flex-start;
  }
`;

export const InfoContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 20px;

  p {
    margin: 0;
  }

  @media ( ${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    text-align: left;
  }
`;
