import React, { useCallback, useEffect } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { buildBaseKarmaUrl } from '../../lib/urlBuilders';
import { H1 } from '../../styles/components/header';
import { AccordionItem, AssessingImpactAccordion, CarbonOffsetsAccordion, FAQsContainer, FAQSectionWrapper, GeneralAccordion, KarmaScoreToolAccordion, KarmaWalletCardAccordion, KarmaWalletCardCashbackAccordion, KarmaWalletCardDonationsAccordion, KarmaWalletMarketplaceAccordion, KarmaWalletMembershipAccordion, PartnershipsAccordion, QuestionGroupTitle, SecurityAndPrivacyAccordion } from './styles';
import { useSearchParams } from 'react-router-dom';
import { ChevronDirection, ChevronIcon } from '../../components/svgs/icons/ChevronIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  className?: string;
}

export enum FAQSections {
  KWMembership = 'kw-membership',
  KWCard = 'kw-card',
  KWCardCashback = 'kw-card-cashback',
  KWCardDonations = 'kw-card-donations',
  KWMarketplace = 'kw-marketplace',
  KWScoreTool = 'kw-score-tool',
  SecurityAndPrivacy = 'security-and-privacy',
  AssessingImpact = 'assessing-impact',
  CarbonOffsets = 'carbon-offsets',
  Partnerships = 'partnerships',
  General = 'general',
}

export enum FAQAnswers {
  MembershipCost = 'membership-cost',
  MembershipCancelation = 'membership-cancelation',
  MembershipRefund = 'membership-refund',
  KWCardDescription = 'kw-card-description',
  KWCardBenefits = 'kw-card-benefits',
  PendingTransaction = 'pending-transaction',
  TransactionDate = 'transaction-date',
  KWCardDifference = 'kw-card-difference',
  BankingPartner = 'banking-partner',
  CardAvailability = 'card-availability',
  CardDeposits = 'card-deposits',
  ACHTransferTime = 'ach-transfers',
  CardUtilization = 'card-utilization',
  CardLostOrStolen = 'card-lost-or-stolen',
  CardCloseAccount = 'card-close-account',
  ApplicationPending = 'application-pending',
  EarnCashback = 'earn-cashback',
  CashbackTransactionTime = 'cashback-transaction-time',
  CashbackNotReceived = 'cashback-not-received',
  CashbackTransactionVisibility = 'cashback-transaction-visibility',
  CashbackLimit = 'cashback-limit',
  CashbackTransactionPending = 'cashback-transaction-pending',
  DonationAutomatic = 'donation-automatic',
  DonationPercentage = 'donation-percentage',
  CompanyScore = 'company-score',
  DoneGoodDescription = 'donegood-description',
  DoneGoodBenefits = 'donegood-benefits',
  KarmaScoreUtilization = 'karma-score-utilization',
  RemoveCard = 'remove-card',
  ResetPassword = 'reset-password',
  SecurityPrivacyApproach = 'security-privacy-approach',
  InformationCollected = 'information-collected',
  LinkingBankCards = 'linking-bank-cards',
  PrivacyPolicy = 'privacy-policy',
  CompanyRating = 'company-rating',
  UNSDGs = 'unsdgs',
  KarmaScoreCalculation = 'karma-score-calculation',
  MissingImpactData = 'missing-impact-data',
  CarbonEmissionsCalculation = 'carbon-emissions-calculation',
  CarbonOffsets = 'carbon-offsets',
  ThirdPartyCertifications = 'third-party-certifications',
  BrandPartnerships = 'brand-partnerships',
  ReporterPartnerships = 'reporter-partnerships',
  InfluencerPartnerships = 'influencer-partnerships',
  CompanyTeam = 'company-team',
  NonProfitPartnership = 'non-profit-partnership',
  SocialMedia = 'social-media',
  QuestionNotAnswered = 'question-not-answered',
}

const FAQsBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const [searchParams] = useSearchParams();

  const scrollToArea = (scrollToId: string) => {
    const element = document.getElementById(scrollToId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isInitialEntered = (section?: FAQSections | FAQAnswers) => searchParams.get('scrollTo') === section;

  const noScrollTo = () => !searchParams.get('scrollTo');

  useEffect(() => {
    if (searchParams.get('scrollTo')) {
      const scrollToSection = searchParams.get('scrollTo');
      scrollToArea(scrollToSection);
    }
  }, [searchParams.get('scrollTo')]);

  const onLinkClick = useCallback((linkName: string) => () => {
    analytics.fireEvent(`FAQs_${linkName}Click`);
  }, []);

  const renderQuestionText = (question: string) => (
    <>
      <span className='question-text'>{ question }</span>
      <ChevronIcon className='chevron-down' direction={ ChevronDirection.Down } stroke={ theme.colors.dark } />
    </>
  );

  return (
    <FAQsContainer className={ className } title='FAQs'>
      <FAQSectionWrapper>
        <div className='page-header-section'>
          <H1 className='center'>Frequently Asked Questions</H1>
          <div className='center'>Date of last update August 16, 2024</div>
        </div>
        { /* -------- Karma Wallet Membership Accordion -------- */ }
        <KarmaWalletMembershipAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWMembership }
        >
          <QuestionGroupTitle>Karma Wallet Membership</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What is the cost to become a Karma Wallet member?') }
            initialEntered={ isInitialEntered(FAQAnswers.MembershipCost) || noScrollTo() }
            id={ FAQAnswers.MembershipCost }
          >
            <p className='answer-text'>To become a Karma Wallet member, the cost is $40 yearly. This unlocks a suite of rewards, benefits, and perks. But, if you aren't quite ready for the Karma Wallet membership, you can still check your Karma Score with a free account!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Can I cancel my membership?') }
            initialEntered={ isInitialEntered(FAQAnswers.MembershipCancelation) }
            id={ FAQAnswers.MembershipCancelation }
          >
            <p className='answer-text'>You can cancel your membership at any time. You must cancel your membership at least 30 days' prior to your membership renewal date to prevent being charged. After membership cancellation, you will still have access to your card and benefits until your membership renewal date. </p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Can I get a refund if I cancel?') }
            initialEntered={ isInitialEntered(FAQAnswers.MembershipRefund) }
            id={ FAQAnswers.MembershipRefund }
          >
            <p className='answer-text'>No. All membership sales are final.</p>
          </AccordionItem>
        </KarmaWalletMembershipAccordion>

        { /* -------- Karma Wallet Card Accordion -------- */ }
        <KarmaWalletCardAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWCard }
        >
          <QuestionGroupTitle>Karma Wallet Card</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What is the Karma Wallet Card?') }
            initialEntered={ isInitialEntered(FAQAnswers.KWCardDescription) }
            id={ FAQAnswers.KWCardDescription }
          >
            <p className='answer-text'>The Karma Wallet Visa® Card is a prepaid reloadable debit card that offers up to 20% cashback* on sustainable spending. On select purchases, we automatically donate to our nonprofit partners. It's that simple.</p>
            <p className='answer-text'> You can sign up in seconds - with no credit check needed.</p>
            <p className='answer-text'>*This optional offer is not a Pathward product or service nor does Pathward endorse this offer. Up to 20%* cashback with eligible brands.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What are the membership benefits of the Karma Wallet Card?') }
            initialEntered={ isInitialEntered(FAQAnswers.KWCardBenefits) }
            id={ FAQAnswers.KWCardBenefits }
          >
            <p className='answer-text'>Good for your wallet, good for the planet. You help change the world with your Karma Wallet Card.</p>
            <ul>
              <li>You buy gas, we donate to reforestation.</li>
              <li>You dine out, we donate to hunger alleviation.</li>
              <li>Up to 20% cashback on thousands of merchants.</li>
              <li>Social and environmental ratings for 18,000+ companies.</li>
              <li>Earn up to 10% cashback on every DoneGood order.</li>
              <li>Earn free shipping on every DoneGood order.</li>
            </ul>
            <p className='answer-text'>Learn more <a onClick={ onLinkClick('App') } href='http://karmawallet.io/app' target='_blank' rel='noreferrer'>here</a>.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What does a pending transaction indicate?') }
            initialEntered={ isInitialEntered(FAQAnswers.PendingTransaction) }
            id={ FAQAnswers.PendingTransaction }
          >
            <p className='answer-text'>A transaction in a pending state is not yet complete with the merchant. When the transaction is completed, funds will debit from your Karma Wallet Card, pending state will be removed from the transaction, and the transaction date will be updated to reflect the completion date.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Why is the date different on my transaction than the day I spent?') }
            initialEntered={ isInitialEntered(FAQAnswers.TransactionDate) }
            id={ FAQAnswers.TransactionDate }
          >
            <p className='answer-text'>The date reflected on a transaction corresponds to when the money was actually taken off the card. This can sometimes be a day or two after the transaction occurred. The date on your transaction will reflect the completion date of the transaction once it is in a completion state. This practice is quite common and is consistent with other financial institutions.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What\'s the difference between my virtual and physical card?') }
            initialEntered={ isInitialEntered(FAQAnswers.KWCardDifference) }
            id={ FAQAnswers.KWCardDifference }
          >
            <p className='answer-text'>Your virtual card is what you can add to your Apple, Google, or Samsung Wallet, and is available for immediate use once you are approved for a Karma Wallet Card. Your physical card arrives in the mail within 5-7 business days, and can be used in person at places that may not take Apple or Google Pay. For extra security purposes, the card numbers are different, allowing you to lock one within our app as needed while still using the other.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Who is your banking partner?') }
            initialEntered={ isInitialEntered(FAQAnswers.BankingPartner) }
            id={ FAQAnswers.BankingPartner }
          >
            <p className='answer-text'>Our banking partner is Pathward®, Member FDIC. Click <a onClick={ onLinkClick('PathwardESGCommitments') } href='https://www.pathward.com/about-us/esg/' target='_blank' rel='noreferrer'>here</a> to learn more about Pathward's ESG commitments!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Where is Karma Wallet available?') }
            initialEntered={ isInitialEntered(FAQAnswers.CardAvailability) }
            id={ FAQAnswers.CardAvailability }
          >
            <p className='answer-text'>Both the Karma Wallet Card and Karma Score tool are available in the USA only right now. Stay tuned as we expand!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Are my deposits insured?') }
            initialEntered={ isInitialEntered(FAQAnswers.CardDeposits) }
            id={ FAQAnswers.CardDeposits }
          >
            <p className='answer-text'>Yes, all deposits in your Karma Wallet account are FDIC insured up to $250,000. Activate your card to ensure the card is registered for FDIC insurance eligibility and other protections.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How long does an ACH funds transfer from an external bank to my Karma Wallet card take?') }
            initialEntered={ isInitialEntered(FAQAnswers.ACHTransferTime) }
            id={ FAQAnswers.ACHTransferTime }
          >
            <p className='answer-text'>It typically takes about 3-5 business days for transfers to be completed. This time frame is due to the way transfers are processed by the banking system. Here is how it works:</p>
            <ol>
              <li>Initiation: Transfers of funds between financial institutions are conducted through the Automated Clearing House (ACH) network. When you initiate a transfer, it is sent to your bank's ACH operator who then schedules it to take place.</li>
              <li>Batching: ACH transactions are processed in batches by the ACH network operators, rather than individually. This process happens 3x per day during business hours.</li>
              <li>Clearing: The batched transactions are sent to the receiving bank's ACH operator for processing. The receiving bank applies the transactions to the respective accounts.</li>
              <li>Settlement: Funds are settled and the transaction is completed. This is when funds become available to you in your account.</li>
            </ol>
            <p className='answer-text'>Note that weekends and bank holidays may extend the processing time since ACH transfers are only processed on business days.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Do I have to get a Karma Wallet Card to utilize Karma Wallet?') }
            initialEntered={ isInitialEntered(FAQAnswers.CardUtilization) }
            id={ FAQAnswers.CardUtilization }
          >
            <p className='answer-text'>You do not need a Karma Wallet Card to benefit from Karma Wallet. You can access our 18,000+ Company Profiles without an account or membership <a href='/browse-and-compare-companies' target='_blank' rel='noreferrer'>here</a>. You can also create a free Karma Wallet account and link your existing banking cards to receive your Karma Score, transaction insights, and offset your shopping carbon footprint.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('My Karma Wallet Card was lost or stolen. What can I do?') }
            initialEntered={ isInitialEntered(FAQAnswers.CardLostOrStolen) }
            id={ FAQAnswers.CardLostOrStolen }
          >
            <p className='answer-text'>If your physical Karma Wallet card was lost or stolen, log in to the app and immediately lock your Physical card by going to the Account Screen and selecting Manage Card. You will then need to call the customer service number to cancel your card and order a replacement. In the meantime, you can continue to use your Virtual Card for online purposes.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How can I close my Karma Wallet account?') }
            initialEntered={ isInitialEntered(FAQAnswers.CardCloseAccount) }
            id={ FAQAnswers.CardCloseAccount }
          >
            <p className='answer-text'>To close your account, please send an email from the email address associated with your Karma Wallet account to support@karmawallet.io and an associate from our team will assist you in closing your account within 2-3 business days. Please note if you have a Karma Wallet Visa Card, closing your Karma Wallet account will also close your card.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('My application was denied and additional documentation was requested. What does this mean?') }
            initialEntered={ isInitialEntered(FAQAnswers.ApplicationPending) }
            id={ FAQAnswers.ApplicationPending }
          >
            <p className='answer-text'>Your application may be temporarily denied as part of our standard KYC (Know Your Customer) compliance process, which requires us to verify the identity of all our customers thoroughly. It's a routine step to ensure customer security and meet regulatory standards. Providing the requested documents helps us proceed with your application.</p>
            <p className='answer-text'>If you have questions or need help with the documentation, don't hesitate to reach out to <a onClick={ onLinkClick('MailSupport') } href='mailto:support@karmawallet.io'>support@karmawallet.io</a>.</p>
          </AccordionItem>
        </KarmaWalletCardAccordion>
        
        { /* -------- Karma Wallet Card Cashback Accordion -------- */ }
        <KarmaWalletCardCashbackAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWCardCashback }
        >
          <QuestionGroupTitle>Karma Wallet Card Cashback</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('How do I earn cashback?') }
            initialEntered={ isInitialEntered(FAQAnswers.EarnCashback) }
            id={ FAQAnswers.EarnCashback }
          >
            <p className='answer-text'>With the Karma Wallet Card, you can earn up to 20% cashback* on qualifying purchases. Brands that have a neutral or positive rating can be eligible for cashback.</p>
            <p className='answer-text'>As a paid Karma Wallet Card member, you can explore your cashback offers in-app. When you shop at any companies offering cashback with your Karma Wallet Card, your cashback rewards will automatically accrue in your app dashboard (no extra effort needed).</p>
            <p className='answer-text'>Earned cashback is deposited to your Karma Wallet Card every month by the 15th, as long as the cashback is no longer in a pending state. A pending state means Karma Wallet is waiting on the transaction to process from the merchant you shopped with. No need to worry! Your cashback will be in your account soon.</p>
            <p className='answer-text'>*This optional offer is not a Pathward product or service nor does Pathward endorse this offer. Up to 20%* cashback with eligible brands.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How long does it take for a cashback transaction to show in my account?') }
            initialEntered={ isInitialEntered(FAQAnswers.CashbackTransactionTime) }
            id={ FAQAnswers.CashbackTransactionTime }
          >
            <p className='answer-text'>After an eligible purchase, cashback transactions can appear in your dashboard in as little as one or two days, as soon as the sale is reported by the merchant. Some merchants take longer than others to report sales. For example, some merchants will not report the sale until the product ships.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Why didn\'t I get cashback on my purchase?') }
            initialEntered={ isInitialEntered(FAQAnswers.CashbackNotReceived) }
            id={ FAQAnswers.CashbackNotReceived }
          >
            <p className='answer-text'>There are a few reasons why you may not have received cashback on your purchase. Some cashback offers are only valid for certain categories or promotions. Check the "Offer Details" modal on the company's Karma Wallet page to confirm.</p>
            <p className='answer-text'>You might not have received cashback if you did not use your Karma Wallet Card to place the order. You are only able to earn cashback if you use your Karma Wallet Card at checkout.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Where can I see my cashback transactions?') }
            initialEntered={ isInitialEntered(FAQAnswers.CashbackTransactionVisibility) }
            id={ FAQAnswers.CashbackTransactionVisibility }
          >
            <p className='answer-text'>On your account page in-app or on our website, in your Cashback dashboard you can keep track of all current and past transactions, as well as see the next estimated payout date.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How many rewards can I earn from each merchant?') }
            initialEntered={ isInitialEntered(FAQAnswers.CashbackLimit) }
            id={ FAQAnswers.CashbackLimit }
          >
            <p className='answer-text'>There is no limit to the number of rewards you can earn from a merchant.</p>
            <p className='answer-text'>In order to protect your safety and prevent fraud and violations of our Terms of Service, we may contact you if you have multiple pending rewards from the same merchant.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What does it mean if a transaction is pending?') }
            initialEntered={ isInitialEntered(FAQAnswers.CashbackTransactionPending) }
            id={ FAQAnswers.CashbackTransactionPending }
          >
            <p className='answer-text'>After a purchase is completed with a valid cashback offer, the money will appear in your dashboard as pending. It will stay in a pending state until the merchant notifies us of a completed sale. This process can take 30+ days.</p>
            <p className='answer-text'>Your cashback may also be marked as pending if the merchant places a temporary hold on the sale. In this case, merchants typically place a hold of 30+ days to ensure the order is not canceled or returned. This hold period may be longer depending on the merchant (e.g., the hold period for hotel bookings can extend until after your stay ends).</p>
            <p className='answer-text'>Unfortunately, this is out of our control. After a merchant's holding period is over, your cashback will be released.</p>
          </AccordionItem>
        </KarmaWalletCardCashbackAccordion>

        { /* -------- Karma Wallet Card Donations Accordion -------- */ }
        <KarmaWalletCardDonationsAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWCardDonations }
        >
          <QuestionGroupTitle>Karma Wallet Card Donations</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('How do automatic donations on the Karma Wallet Card work?') }
            initialEntered={ isInitialEntered(FAQAnswers.DonationAutomatic) }
            id={ FAQAnswers.DonationAutomatic }
          >
            <p className='answer-text'>When you buy gas or take rideshare, we automatically donate to reforestation projects through our non-profit partner <a onClick={ onLinkClick('BlogOneTreePlanted') } href='https://karmawallet.io/blog/2024/01/impact-partner-one-tree-planted/' target='_blank' rel='noreferrer'>One Tree Planted</a>.</p>
            <p className='answer-text'>When you dine out, order takeout, or order food delivery, we automatically donate to hunger alleviation through our non-profit partner <a onClick={ onLinkClick('BlogFeedingAmerica') } href='https://karmawallet.io/blog/2024/01/impact-partner-feeding-america/' target='_blank' rel='noreferrer'>Feeding America</a>.</p>
            <p className='answer-text'>These donations are done automatically, once a quarter, at no additional cost to you!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What percentage of my purchases goes towards a donation?') }
            initialEntered={ isInitialEntered(FAQAnswers.DonationPercentage) }
            id={ FAQAnswers.DonationPercentage }
          >
            <p className='answer-text'>For each time you dine out, order takeout, or order food delivery, we donate 10c to Feeding America. This allows Feeding America to donate 1 meal to someone in need. Every time you use your Karma Wallet Card at a restaurant - you donate 1 meal to someone in need.</p>
            <p className='answer-text'>For every $200 you spend on gas or rideshare services, we plant 1 tree with a donation of $1 through One Tree Planted. This money is allocated across various reforestation projects in the USA and abroad - you can learn more <a onClick={ onLinkClick('BlogOneTreePlanted') } href='https://karmawallet.io/blog/2024/01/impact-partner-one-tree-planted/' target='_blank' rel='noreferrer'>here</a>.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('My company is on Karma Wallet, and we are confused or unhappy with our score. Can we work with your team to provide more information?') }
            initialEntered={ isInitialEntered(FAQAnswers.CompanyScore) }
            id={ FAQAnswers.CompanyScore }
          >
            <p className='answer-text'>Absolutely! While our primary data comes from partners and publicly available sources, we understand that this may not be representative of all of your company practices. Please reach out to <a onClick={ onLinkClick('MailData') } href='mailto:data@karmawallet.io'>data@karmawallet.io</a> and we'll work with you to process the necessary documentation in order to update your profile!</p>
          </AccordionItem>
        </KarmaWalletCardDonationsAccordion>

        { /* -------- Karma Wallet Marketplace DoneGood Accordion -------- */ }
        <KarmaWalletMarketplaceAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWMarketplace }
        >
          <QuestionGroupTitle>Karma Wallet Marketplace (DoneGood)</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What is DoneGood?') }
            initialEntered={ isInitialEntered(FAQAnswers.DoneGoodDescription) }
            id={ FAQAnswers.DoneGoodDescription }
          >
            <p className='answer-text'><a onClick={ onLinkClick('DoneGood') } href='http://donegood.co' target='_blank' rel='noreferrer'>DoneGood</a> is a sustainable marketplace with over 100+ brands and thousands of ethically made products. In April 2024, Karma Wallet acquired DoneGood - expanding our ecosystem of tools to empower conscious consumers like you.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How do I benefit as a Karma Wallet Member?') }
            initialEntered={ isInitialEntered(FAQAnswers.DoneGoodBenefits) }
            id={ FAQAnswers.DoneGoodBenefits }
          >
            <p className='answer-text'>As a Karma Wallet Member, you get free shipping and up to 10% cashback on DoneGood purchases when you use your <a onClick={ onLinkClick('App') } href='http://karmawallet.io/app' target='_blank' rel='noreferrer'>Karma Wallet Card</a>.</p>
          </AccordionItem>
        </KarmaWalletMarketplaceAccordion>

        { /* -------- Karma Score Tool Accordion -------- */ }
        <KarmaScoreToolAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.KWScoreTool }
        >
          <QuestionGroupTitle>Karma Score Tool</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('Do I have to get a Karma Wallet Card to utilize Karma Wallet?') }
            initialEntered={ isInitialEntered(FAQAnswers.KarmaScoreUtilization) }
            id={ FAQAnswers.KarmaScoreUtilization }
          >
            <p className='answer-text'>You do not need a Karma Wallet Card to benefit from Karma Wallet. You can access our 18,000+ Company Profiles without an account or membership <a onClick={ onLinkClick('BrowseAndCompare') } href='https://karmawallet.io/browse-and-compare-companies' target='_blank' rel='noreferrer'>here</a>. You can also create a free Karma Wallet account and link your existing banking cards to receive your Karma Score, transaction insights, and offset your shopping carbon footprint.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How do I remove a card from my Karma Wallet account?') }
            initialEntered={ isInitialEntered(FAQAnswers.RemoveCard) }
            id={ FAQAnswers.RemoveCard }
          >
            <p className='answer-text'>To remove a card from your account, go to your profile page and click the three dots in the top right corner of your connected card. This will provide you with options for card removal.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How can I reset my Karma Wallet password?') }
            initialEntered={ isInitialEntered(FAQAnswers.ResetPassword) }
            id={ FAQAnswers.ResetPassword }
          >
            <p className='answer-text'>You can reset your password by going to <a onClick={ onLinkClick('KWResetPassword') } href={ `${buildBaseKarmaUrl('karma')}?resetpassword=true` } target='_blank' rel='noreferrer'>this page</a>.</p>
          </AccordionItem>
        </KarmaScoreToolAccordion>

        { /* -------- Security & Privacy Accordion -------- */ }
        <SecurityAndPrivacyAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.SecurityAndPrivacy }
        >
          <QuestionGroupTitle>Security & Privacy</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What is Karma Wallet\'s approach to security and privacy?') }
            initialEntered={ isInitialEntered(FAQAnswers.SecurityPrivacyApproach) }
            id={ FAQAnswers.SecurityPrivacyApproach }
          >
            <p className='answer-text'>We value your privacy and work hard to protect it. Each member's personal information is kept strictly confidential, and we don't share individual user information with anyone.</p>
            <p className='answer-text'>Occasionally we are required to share select information with service providers, business partners, or other third parties. In these situations, your data is aggregated, anonymized, or de-identified to remove any details that could identify you personally.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What information do you collect?') }
            initialEntered={ isInitialEntered(FAQAnswers.InformationCollected) }
            id={ FAQAnswers.InformationCollected }
          >
            <p className='answer-text'>We collect routine information when you register for a Karma Wallet account, such as your name and email address. This information, along with your username, passwords, and transactions, are safely stored within Karma Wallet. We never sell your personal data. Learn more about our rigorous privacy and security policies here: <a onClick={ onLinkClick('AWSDataPrivacy') } href='http://aws.amazon.com/compliance/data-privacy-faq' target='_blank' rel='noreferrer'>Data Privacy FAQ</a>.
            </p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How does linking my existing bank cards or bank account work?') }
            initialEntered={ isInitialEntered(FAQAnswers.LinkingBankCards) }
            id={ FAQAnswers.LinkingBankCards }
          >
            <p className='answer-text'>We receive your transaction data via <a onClick={ onLinkClick('WhatIsPlaid') } href='https://plaid.com/what-is-plaid/' target='_blank' rel='noreferrer'>Plaid</a>, which uses bank-grade security to protect your data, including your debit and credit card details. Thousands of financial companies trust Plaid, including companies like Venmo and American Express.</p>
            <p className='answer-text'>None of your personal credit card or bank information is stored with us.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What is your privacy policy?') }
            initialEntered={ isInitialEntered(FAQAnswers.PrivacyPolicy) }
            id={ FAQAnswers.PrivacyPolicy }
          >
            <p className='answer-text'>We use cookies to optimize site features and analytics. You can find all the details spelled out in <a onClick={ onLinkClick('KWPrivacy') } target='_blank' href='/privacy-policy' rel='noreferrer'>our privacy policy</a>.</p>
          </AccordionItem>
        </SecurityAndPrivacyAccordion>

        { /* -------- Assessing Impact Accordion -------- */ }
        <AssessingImpactAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.AssessingImpact }
        >
          <QuestionGroupTitle>Assessing Impact</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('How do you rate companies?') }
            initialEntered={ isInitialEntered(FAQAnswers.CompanyRating) }
            id={ FAQAnswers.CompanyRating }
          >
            <p className='answer-text'>Karma Wallet's data primarily comes from 40+ sources, including public and private third-party rating agencies, certifications, and relevant associations. We map over 500 data points to a standard benchmark of <a onClick={ onLinkClick('UNSDGs') } href='https://sdgs.un.org/goals' target='_blank' rel='noreferrer'>United Nations Sustainable Development Goals (UN SDGs)</a>, making it easy for consumers to understand the overall impact of brands even when their data comes from different sources.</p>
            <p className='answer-text'>Each of our 18,000+ companies gets a score out of 16, and a comprehensive Report Card so you can analyze their social and environmental performance.</p>
            <p className='answer-text'>Check out our <a onClick={ onLinkClick('KWHowItWorks') } href={ `${buildBaseKarmaUrl('karma')}/rating-system` } target='_blank' rel='noreferrer'>Rating System</a> page to learn more.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What are the United Nations Sustainable Development Goals?') }
            initialEntered={ isInitialEntered(FAQAnswers.UNSDGs) }
            id={ FAQAnswers.UNSDGs }
          >
            <p className='answer-text'>The United Nations Sustainable Development Goals (UN SDGs) are a set of 17 global goals put forward in 2015 by the United Nations General Assembly.</p>
            <p className='answer-text'>These goals are widely accepted as being necessary measures for humanity to make strides toward creating a better world, and they address many global challenges in areas such as poverty, inequality, climate change, environmental degradation, peace, and justice.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How do you calculate my personalized Karma Score?') }
            initialEntered={ isInitialEntered(FAQAnswers.KarmaScoreCalculation) }
            id={ FAQAnswers.KarmaScoreCalculation }
          >
            <p className='answer-text'>Your personal Karma Score is the dollar-weighted average of the Ratings of the companies you shop with.</p>
            <p className='answer-text'>0-50: Negative</p>
            <p className='answer-text'>50-75: Neutral</p>
            <p className='answer-text'>75+: Positive</p>
            <p className='answer-text'>When you support better brands, your Karma Score goes up!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('Why do some of my purchases not display impact data?') }
            initialEntered={ isInitialEntered(FAQAnswers.MissingImpactData) }
            id={ FAQAnswers.MissingImpactData }
          >
            <p className='answer-text'>This depends on which company you make the purchase from. While we constantly add new sources, some companies you do business with may not be rated or certified by the data providers Karma Wallet uses. If we don't have data on a company, it doesn't mean they are doing poorly, only that we aren't able to create a report card for them. If that happens, their data will not show up in your transactions, or be factored into your personal Karma Score.</p>
          </AccordionItem>
        </AssessingImpactAccordion>

        { /* -------- Carbon Offsets Accordion -------- */ }
        <CarbonOffsetsAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.CarbonOffsets }
        >
          <QuestionGroupTitle>Carbon Offsets</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('How do you calculate carbon emissions?') }
            initialEntered={ isInitialEntered(FAQAnswers.CarbonEmissionsCalculation) }
            id={ FAQAnswers.CarbonEmissionsCalculation }
          >
            <p className='answer-text'>The carbon footprint of each purchase is calculated by integrating three unique data sources into our estimation algorithm. This includes spending data, economic-environmental data, and company-reported data. Then we calculate this for the spending you do each month!</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('How do carbon offsets work?') }
            initialEntered={ isInitialEntered(FAQAnswers.CarbonOffsets) }
            id={ FAQAnswers.CarbonOffsets }
          >
            <p className='answer-text'>We offer carbon offsetting through Rare.org.</p>
            <p className='answer-text'>Rare's carbon credit projects are high-quality initiatives that protect forests, oceans, and grasslands - all of which are verified carbon removal or avoidance mechanisms for carbon emissions. These carbon projects have been certified by one of the major international carbon credit standards, such as The Gold Standard, Verra, or the Climate Action Reserve.</p>
            <p className='answer-text'>To read more about Rare.org, click <a onClick={ onLinkClick('RarePDF') } href='https://cdn.karmawallet.io/rare/Rare_CarbonOffsetDonations_FAQ.pdf' target='_blank' rel='noreferrer'>here</a>.</p>
          </AccordionItem>
        </CarbonOffsetsAccordion>

        { /* -------- Partnerships Accordion -------- */ }
        <PartnershipsAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.Partnerships }
        >
          <QuestionGroupTitle>Partnerships</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What third-party certifications or partnerships does Karma Wallet have?') }
            initialEntered={ isInitialEntered(FAQAnswers.ThirdPartyCertifications) }
            id={ FAQAnswers.ThirdPartyCertifications }
          >
            <p className='answer-text'>We don't just talk about sustainable companies. We are one. The Karma Wallet mission is to create a better world for us all, and our partners help us do it.</p>
            <p className='answer-text'><strong>1% for the Planet:</strong> We're a proud member of 1% for the Planet, joining a global network of brands that are putting people and the planet over profit.</p>
            <p className='answer-text'><strong>B Corp Certification:</strong> As a Certified B Corp, we are part of a network of companies using business as a force for good. We passed strict third-party standards for social and environmental impact and were awarded a score of 100.3. We also donate 5% of our revenue to non-profit organizations. You can view the details of our B Corp Assessment <a onClick={ onLinkClick('ImpactKarmaBcorpAssessment') } href='https://www.bcorporation.net/en-us/find-a-b-corp/company/impact-karma-inc/' target='_blank' rel='noreferrer'>here</a>.</p>
            <p className='answer-text'><strong>One Tree Planted:</strong> When you buy gas or take rideshare on your Karma Wallet Card, we donate to reforestation projects through OTP. If you sign up as a free member, we plant a tree on your behalf.</p>
            <p className='answer-text'><strong>Rare:</strong> We partner with Rare to offset the carbon footprint of your spending.</p>
            <p className='answer-text'><strong>Feeding America:</strong> When you dine out or order food delivery, we donate a meal to someone in need through Feeding America.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('I am a brand. How can I work with Karma Wallet?') }
            initialEntered={ isInitialEntered(FAQAnswers.BrandPartnerships) }
            id={ FAQAnswers.BrandPartnerships }
          >
            <p className='answer-text'>With our brand partners, we encourage them to join the Karma Collective.</p>
            <p className='answer-text'>The Karma Collective is a community of mission-driven brands that offer exclusive deals and offers to our community of conscious consumers.</p>
            <p className='answer-text'>Sustainable brands on Karma Wallet see higher average order values (AOV) by 1.3x, purchase frequency by 2.2x, and annual customer value (ACV) by 2.8x.</p>
            <p className='answer-text'>To learn more about the Karma Collective, contact us at <a onClick={ onLinkClick('MailMarketing') } href='mailto:marketing@karmawallet.io'>marketing@karmawallet.io</a>.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('I am a reporter or publication. How can I work with Karma Wallet?') }
            initialEntered={ isInitialEntered(FAQAnswers.ReporterPartnerships) }
            id={ FAQAnswers.ReporterPartnerships }
          >
            <p className='answer-text'>Help us share our story. If you're a reporter, publication, blogger, or podcaster, we'd love to speak with you. Contact us at <a onClick={ onLinkClick('MailMarketing') } href='mailto:marketing@karmawallet.io'>marketing@karmawallet.io</a>. Click <a onClick={ onLinkClick('Press') } href='https://blog.karmawallet.io/press/' target='_blank' rel='noreferrer'>here</a> to see recent Press from Karma Wallet.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('I am an influencer. How can I work with Karma Wallet?') }
            initialEntered={ isInitialEntered(FAQAnswers.InfluencerPartnerships) }
            id={ FAQAnswers.InfluencerPartnerships }
          >
            <p className='answer-text'>We welcome influencers, bloggers, podcasters, affiliates, and publications to help us grow our community!</p><p className='answer-text'>We offer a flat commission fee for each new Karma Wallet Card member you help us acquire. We are also open to select paid placements. Learn more <a onClick={ onLinkClick('Press') } href='https://karmawallet.io/partnerships' target='_blank' rel='noreferrer'>here</a>.</p><p className='answer-text'>Contact us at <a onClick={ onLinkClick('MailMarketing') } href='mailto:marketing@karmawallet.io'>marketing@karmawallet.io</a>.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('I am a company. How can I bring Karma Wallet to my team?') }
            initialEntered={ isInitialEntered(FAQAnswers.CompanyTeam) }
            id={ FAQAnswers.CompanyTeam }
          >
            <p className='answer-text'>We have several unique ways that Karma Wallet can work with your business or organization. Reach out to our B2B team to learn more at <a onClick={ onLinkClick('MailMarketing') } href='mailto:marketing@karmawallet.io'>marketing@karmawallet.io</a>.</p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('I am a non-profit. How can we work together?') }
            initialEntered={ isInitialEntered(FAQAnswers.NonProfitPartnership) }
            id={ FAQAnswers.NonProfitPartnership }
          >
            <p className='answer-text'>As a public benefit corporation (and member of 1% for the Planet), we donate 5% of our revenue to non-profits each year. Each non-profit partnership is unique. To chat about a potential non-profit partnership, contact us at <a onClick={ onLinkClick('MailSupport') } href='mailto:support@karmawallet.io'>support@karmawallet.io</a>.</p>
          </AccordionItem>
        </PartnershipsAccordion>
         
        { /* -------- General Accordion -------- */ }
        <GeneralAccordion
          transition
          transitionTimeout={ 750 }
          id={ FAQSections.General }
        >
          <QuestionGroupTitle>General</QuestionGroupTitle>
          <AccordionItem
            header={ renderQuestionText('What are your social media profiles?') }
            initialEntered={ isInitialEntered(FAQAnswers.SocialMedia) }
            id={ FAQAnswers.SocialMedia }
          >
            <p className='answer-text'>Join the Karma Wallet community! Follow us on social media for insights, news, and updates.</p>
            <p className='answer-text'><a onClick={ onLinkClick('KarmaWalletLinkedInProfile') } href='https://www.linkedin.com/company/karmawallet/' target='_blank' rel='noreferrer'>LinkedIn</a></p>
            <p className='answer-text'><a onClick={ onLinkClick('KarmaWalletInstagramProfile') } href='https://www.instagram.com/karmawallet/' target='_blank' rel='noreferrer'>Instagram</a></p>
            <p className='answer-text'><a onClick={ onLinkClick('KarmaWalletFacebookProfile') } href='https://www.facebook.com/karmawallet1' target='_blank' rel='noreferrer'>Facebook</a></p>
            <p className='answer-text'><a onClick={ onLinkClick('KarmaWalletXProfile') } href='https://x.com/KarmaWallet' target='_blank' rel='noreferrer'>X</a></p>
            <p className='answer-text'><a onClick={ onLinkClick('KarmaWalletTikTokProfile') } href='https://www.tiktok.com/@karma.wallet' target='_blank' rel='noreferrer'>TikTok</a></p>
          </AccordionItem>
          <AccordionItem
            header={ renderQuestionText('What if I was not able to find the answer to my question?') }
            initialEntered={ isInitialEntered(FAQAnswers.QuestionNotAnswered) }
            id={ FAQAnswers.QuestionNotAnswered }
          >
            <p className='answer-text'>If your question has not been answered here, please contact us! We are available Monday-Friday, 9-5pm EST, via email or social media. We look forward to hearing from you!</p>
            <p className='answer-text'>Please email <a onClick={ onLinkClick('MailSupport') } href='mailto:support@karmawallet.io'>support@karmawallet.io</a>.</p>
          </AccordionItem>
        </GeneralAccordion>

        <div className='pathward-disclaimer-text'>The Karma Wallet Visa® Prepaid Card is issued by Pathward®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Karma Wallet Visa® Prepaid Card is powered by Marqeta. This card can be used everywhere Visa debit cards are accepted.</div>
      </FAQSectionWrapper>
    </FAQsContainer>
  );
};

export const FAQs = withTheme(FAQsBase);
