import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';
import { H5 } from '../../../styles/components/header';
import { TextSMMedium } from '../../../styles/components/text';

export const KarmaCardAppSectionContainer = styled(Section)`
  overflow: hidden;
  padding: 65px 15px;
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 120px 15px 100px;
  }
`;

export const BackgroundContainer = styled.div`
  background: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  .svg-icon {
    position: absolute;
  }

  .coin-b-1 {
    left: -3%;
    top: -12%;
    transform: scale(0.6) rotate(-10deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 6%;
      top: -10%;
      transform: unset;
    }
  }

  .coin-b-3 {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: 15%;
      top: 85%;
      transform: scale(0.9) rotate(35deg);
    }
  }

  .coin-b-4 {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: -8%;
      top: 44%;
      transform: scale(0.9) rotate(-75deg);
    }
  }

  .coin-b-5 {
    left: -12%;
    top: 90%;
    transform: scale(0.8) rotate(0deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 31%;
      top: 97%;
      transform: scale(1.2) rotate(0deg);
    }
  }

  .earth-clouds {
    right: 0%;
    top: 88%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: 2%;
      top: 87%;
    }
  }

  .leaf {
    left: 4%;
    top: 56%;

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      left: 64%;
      top: -14%;
    }
  }

  .light {
    display: none;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      display: block;
      left: 28%;
      top: 20%;
      transform: scale(0.20);
    }
  }

  .food {
    display: none;

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      display: block;
      left: -2%;
      bottom: -25%;
    }
  }

  .recycling {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      right: 0%;
      top: 3%;
      transform: scale(0.9);
    }

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      right: 1%;
      top: -13%;
    }
  }
`;

export const KarmaCardSection = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  gap: 55px;
  position: relative;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    ${FlexRow}
    gap: 0;
    justify-content: space-between;
    min-height: 450px;
  }

  .people-with-cards {
    max-width: 462px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      max-width: 556px;
    }

    @media (${({ theme }) => theme.breakpoints.xxlMin}) {
      max-width: 717px;
    }
  }
`;

export const GenericTagContainer = styled.a`
  display: flex;
  text-decoration: none;

  .tag {
    margin-bottom: 2px;
  }
`;

export const SubheaderText = styled(H5)`
  color: ${({ theme }) => theme.colors.white};
  max-width: 90%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: 558px;
  }
`;

export const DebitCardTextContainer = styled.div`
  ${FlexCol} 
  max-width: 400px;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: unset;
  }

  .debit-card-text {
    color: ${({ theme }) => theme.colors.offWhite};
    margin-top: 10px;
  }

  h2 {
    font-size: 40px;
    line-height: 2.5rem;
    margin-bottom: 5px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
      line-height: 4.5rem;
      margin-bottom: 5px;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    max-width: 500px;
    margin-top: 20px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: 85%;
    }
  }

  .cta-button {
    @media (${({ theme }) => theme.breakpoints.smMax}) {
      width: 100%;
    }
  }
`;

export const ApplyContainer = styled.div`
  ${FlexCol}
  gap: 32px;
  margin-top: 32px;

  .cta-button {
    margin: 0;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexHorizontalCenter}
  }
`;

export const SupportedByContainer = styled.div`
  ${FlexHorizontalCenter}
  justify-content: center;
  gap: 7px;

  .supported {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey3};
    margin: unset;
  }
`;

export const AppButtonsWrapper = styled.div`
  ${FlexRow}
  align-items: flex-start;
  gap: 12px;
  justify-content: space-evenly;
  margin-top: 30px;

  img {
    height: 42px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin-top: 60px;
    justify-content: flex-start;

    img {
      height: 50px;
    }
  }
`;
