import React, { SelectHTMLAttributes } from 'react';
import { SelectChevronIcon, SelectField, SelectFieldContainer, USStatesDropdownContainer } from './styles';
import { states } from '../../constants/usStates';
import { Label } from '../../styles/components/label';
import { ChevronDirection } from '../svgs/icons/ChevronIcon';

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  errorText?: string;
  labelHidden?: boolean;
  labelAlignment?: string;
  label: string;
  id: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  placeholder?: string;
}

export const USStatesDropdown: React.FC<IProps> = ({
  className = '',
  errorText,
  label,
  labelHidden,
  labelAlignment,
  id,
  onChange,
}) => (
  <USStatesDropdownContainer className={ className } labelAlignment={ labelAlignment }>
    <Label className={ `${labelHidden ? 'sr-only' : ''}` } htmlFor={ id }>{ label }</Label>
    <SelectFieldContainer>
      <SelectChevronIcon direction={ ChevronDirection.Down } />
      <SelectField
        id={ id }
        onChange={ onChange }
        required={ true }
        defaultValue={ 'Select a State' }
      >
        <option className='placeholder' key='placeholder-state' disabled value='Select a State'>Select a State</option>
        {
          states.map((state) => (
            <option key={ state.abbreviation } value={ state.name }>{ state.abbreviation }</option>
          ))
        }
      </SelectField>
    </SelectFieldContainer>
    { !!errorText && <p className='field-error'>{ errorText }</p> }
  </USStatesDropdownContainer>
);
