import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { ApplyHeaderContainer, HeaderText, SubheaderText } from './styles';
import { ApplyForCardModel, CurrentPage } from '../../../../models/karmaCard';
import { useAppData } from '../../../../contexts/app';

interface IProps {
  applyModel: ApplyForCardModel;
}

interface IContent {
  headerText?: string;
  subtext?: string | JSX.Element;
}

const ApplyHeaderBase: React.FC<IProps> = ({ applyModel }) => {
  const app = useAppData();
  const thisComponentRef = useRef(null);
  const isPageOne = applyModel.currentPage === CurrentPage.PageOne;

  useEffect(() => {
    if (app.isDesktop || applyModel.currentPage === CurrentPage.PageOne || applyModel.currentPage === CurrentPage.Success || applyModel.currentPage === CurrentPage.Payment) return;

    if (!!thisComponentRef.current) {
      thisComponentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [thisComponentRef, applyModel.currentPage]);

  const contentObject = useMemo(() => {
    let content: IContent = {
      subtext: <span>No credit check required.{ app.isDesktop && <span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span> } Available to US residents only.</span>,
    };

    if (applyModel.currentPage === CurrentPage.Payment) {
      content = {
        headerText: 'You\'ve been approved.',
        subtext: 'To continue with the application and receive your card, please pay for your membership fee.',
      };
    }

    if (applyModel.currentPage === CurrentPage.Pending) {
      content = {
        headerText: 'Application is Pending',
      };
    }

    if (applyModel.currentPage === CurrentPage.PageTwo_CollectDocs) {
      content = {
        headerText: 'You\'re almost there! We just need a little more info.',
        subtext: 'Please press continue to proceed with your application.',
      };
    }

    return content;
  }, [applyModel.currentPage, app.isDesktop]);

  if (applyModel.currentPage === CurrentPage.Success
    || applyModel.currentPage === CurrentPage.Pending
    || applyModel.currentPage === CurrentPage.Payment
    || applyModel.currentPage === CurrentPage.Declined
  ) return null;

  return (
    <ApplyHeaderContainer ref={ thisComponentRef } className={ `header-section ${isPageOne ? 'page-one' : ''}` }>
      <div className={ `header-container ${isPageOne ? 'page-one' : ''}` }>
        <div>
          <HeaderText>{ contentObject.headerText }</HeaderText>
        </div>
      </div>
      { 
        contentObject.subtext ?
          <SubheaderText className={ isPageOne ? 'page-one' : '' }>
            { contentObject.subtext }
          </SubheaderText>
          : null
      }
    </ApplyHeaderContainer>
  );
};

export const ApplyHeader = observer(ApplyHeaderBase);
