import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../styles/styles';

export const MembershipHeadingContainer = styled.div``;

export const KarmaCardAppSectionContainer = styled(Section)`
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 250px 15px 0;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 100vh;
    max-height: 920px;
  }

  &#karma-card-section {
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      margin-bottom: unset;
      padding: 180px 15px 20px !important;
    }
  
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 150px 15px 20px !important;
    }
  }
`;

export const BackgroundContainer = styled.div`
  background: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    top: -100px;
  }

  .svg-icon {
    position: absolute;
  }

  .coin-b-1 {
    left: -3%;
    top: -8%;
    transform: scale(0.6) rotate(-10deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 7%;
      top: 8%;
      transform: unset;
    }
  }

  .coin-b-2 {
    left: 80%;
    top: 10%;
    transform: scale(0.9) rotate(35deg);
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 22%;
      top: -10%;
      transform: scale(1.2) rotate(50deg);
    }
  }

  .coin-b-6 {
    left: -12%;
    top: 45%;
    transform: scale(.8) rotate(-11deg);
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: -15%;
      top: 11%;
      transform: scale(0.9) rotate(45deg);
    }
  }

  .coin-b-3 {
    left: 17%;
    top: 95%;
    transform: scale(0.9) rotate(-65deg);
  }

  .coin-b-4 {
    right: -7%;
    top: 75%;
    transform: scale(1.5) rotate(-75deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 1%;
      top: 85%;
      transform: scale(0.9) rotate(35deg);
    }
  }

  .coin-b-5 {
    left: 38%;
    top: 85%;
    transform: scale(1.2) rotate(15deg);
  }

  .bottle {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      right: 1%;
      top: 46%;
      transform: scale(1.1);
    }
  }

  .earth-clouds {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: 5%;
      top: 65%;
      display: block;
    }
  }

  .leaf-a {
    left: -5%;
    top: 52%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 58%;
      top: -10%;
    }
  }

  .light {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: 42%;
      top: 3%;
      transform: scale(0.23);
    }
  }

  .food {
    display: none;
    left: 52%;
    top: 70%;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      display: block;
    }
  }

  .recycling {
    left: 75%;
    top: 43%;
    transform: scale(0.7);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 98%;
      top: 85%;
      transform: scale(0.9);
    }
  }
`;

export const KarmaCardSection = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  height: 100%;
  padding-top: 50px;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    justify-content: space-between;
    padding-top: 0;
    top: -20px;
  }
`;

export const DebitCardTextContainer = styled.div`
  ${FlexCol}
  max-width: 400px;
  width: 100%;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: unset;
    width: 50%;
  }

  .inverted-text {
    display: inline;
  }

  .debit-card-text.block {
    display: inline;
    margin-right: 15px;
  }

  .debit-card-text {
    color: ${({ theme }) => theme.colors.offWhite};
    margin-top: 10px;
  }

  h2 {
    font-size: 40px;
    line-height: 2.25rem;
    margin-bottom: 5px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
      line-height: 3.75rem;
      margin-bottom: 5px;
    }
  }

  h5 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    max-width: 500px;
    line-height: 26px;
    margin-top: 20px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: 85%;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.grey4};
    font-style: italic;
    max-width: 500px;
    margin-top: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  ${FlexColCenter}
  justify-content: flex-start;
  margin-top: 20px;
  gap: 20px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }

  .learn-more-button {
    width: 100%;
    text-align: center;
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: unset;
    }
  }
`;

export const KarmaCardAndAppImgContainer = styled.div`
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .recycled-plastic-text {
    display: none;
  }

  .app-mockup {
    display: block;
    filter: drop-shadow(5px 7px 7px rgba(0, 0, 0, 0.5));
    height: auto;
    margin-top: 50px;
    max-width: 300px;
  }

  .app-and-card {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    .app-mockup {
      display: none;
    }
    
    .recycled-plastic-text {
      display: block;
      position: absolute;
      right: -15px;
      top: 32%;
      transform: scale(0.75);
    }

    .app-and-card {
      display: block;
      height: 100%;
      max-height: 550px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-right: 80px;

    .recycled-plastic-text {
      right: 50px;
      transform: scale(1);
    }

    .app-and-card {
      display: block;
      height: 100%;
      max-height: 650px;
    }
  }
`;
