import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow, TruncateText } from '../../styles/styles';
import { CompanyLogo } from '../CompanyLogo';
import { _H5 } from '../../styles/components/header';

export const CompanyTopSectionContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.light};
  padding: 18px 15px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 32px 15px;
  }
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 72px 15px;
  }
`;

export const CompanyTopSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  gap: 48px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 34px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .data-sources-mobile {
      display: none;
    }
  } 
`;

export const TopButtonsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-evenly;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    justify-content: flex-end;
  }
  
  Button {
    border-width: 1px;

    &:hover {
      border-width: 1px;
    }
  }
`;

export const DataContainer = styled.div`
  ${FlexCol}
  align-items: flex-start;
  gap: 48px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;

export const CompanyCard = styled.div`
  ${FlexCol}
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 2px 8px 25px 0px #0000000D;
  justify-content: space-evenly;
  min-height: 400px;
  padding: 32px 48px;
  width: 100%;

  .company-name {
    color: ${({ theme }) => theme.colors.black};
    margin-top: 8px;
    text-align: center;
  }

  svg {
    margin-top: -2px;
  }

  .cashback {
    font-size: 12px;
    padding: 6px 16px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: 462px;
    padding: 32px 48px;
    width: 34%;

    .company-name {
      margin: 8px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    height: 491px;
    padding: 48px;

    .company-name {
      margin-top: 23px;
    }
  }
`;

export const CompanyLinkContainer = styled.a`
  text-decoration: none;
`;

export const CompanyCardLogo = styled(CompanyLogo)`
  height: 145px;
  width: 145px;

  img {
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    height: 200px;
    width: 200px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 220px;
    width: 220px;
  }
`;

export const OwnedBy = styled.a`
  ${TruncateText}
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  margin: 10px 0 17px;
  max-width: 100%;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin: 14px 0 23px;
  }
`;

export const CompanyDetailsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  .data-sources-desktop {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 12px;
    width: 66%;

    .data-sources-desktop {
      display: unset;
     }
  }
`;

export const CompanyRatingBreakdown = styled.div`
  ${FlexCol}
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 2px 8px 25px 0px #0000000D;
  gap: 24px;
  padding: 24px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    gap: 20px;
  }

  .company-score {
    ${FlexRow}
    align-items: center;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
    gap: 16px;
    justify-content: center;
    padding: 16px 8px;
    text-align: center;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexCol}
      height: 155px;
      justify-content: space-between;
      width: 26%;
    }
  }

  .score {
    color: ${({ theme }) => theme.colors.white};
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    margin-bottom: -12px;
    margin-top: 7px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      line-height: 60px;
    }
  }

  .score-text {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    line-height: 26px;
    max-width: 93px;
  }

  .details {
    ${FlexCol}
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightGray2};
    border-radius: 8px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexRow}
      height: 155px;
      width: 74%;
    }
  }
  
  .left-details {
    ${FlexCol}
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray2};
    height: 154px;
    justify-content: space-evenly;
    padding: 0 30px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      border: unset;
      border-right: 1px solid ${({ theme }) => theme.colors.lightGray2};
      width: 50%;
    }
  }
  
  .right-details{
    border: unset;
  }

  .detail-label {
    color: ${({ theme }) => theme.colors.midGray};
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  .rating {
    p {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .value {
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }
`;

export const CompanyValuesContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 24px;
  margin: auto;
  margin-bottom: 30px;
  width: 100%;
  
  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 27px;
    margin-bottom: 0px;
    margin-top: 10px;
    max-width: 1012px;

    h2 {
      ${_H5}
    }
  }
`;

export const CompanyValuesInnerContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  .error-text, .no-values {
    color: ${({ theme }) => theme.colors.darkGray3};
    padding-bottom: 40px;
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    gap: 24px;
    justify-content: center;
  }
`;

export const ValueItem = styled.div`
  ${FlexHorizontalCenter}
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
  gap: 12px;
  max-height: 50px;
  padding: 12px 24px;

  .value-name {
    font-size: 14px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 18px;
    }
  }

  img {
    height: 24px;
    width: 24px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      height: 36px;
      width: 36px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-height: 60px;
  }
`;

export const ScoreGraphicContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 2px 8px 25px 0px #0000000D;
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

export const DataSourcesContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 12px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    h2 {
      ${_H5}
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: flex-start;
  }
`;

export const DataSourcesInnerContainer = styled.div`
  ${FlexRow}
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .data-source {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
    max-height: 123px;
    max-width: 132px;
    padding: 24px;

    img {
      height: 75px;
      width: 75px;
      object-fit: scale-down;
    }
  }
`;
