import styled from 'styled-components';
import { AccountHubBasicContainer, FlexCenter, FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';
import { Checkbox } from '../../../components/Checkbox';

export const TransactionsPageContainer = styled.div`
  margin: 0 auto;
  padding-bottom: 40px;
  width: 100%;

  .load-more-button {
    margin: 40px auto 0;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  min-height: 50px;
  position: relative;
`;

export const TransactionsPageInnerWrapper = styled.div`
  margin: 0 auto;

  .transaction-not-logged-button {
    color: ${({ theme }) => theme.colors.grey3};
    font-weight: 400;
    margin-bottom: 5px;
    margin: 0 5px 8px auto;
    text-decoration: underline;
  }
`;

export const TransactionsContainer = styled.div`
  ${FlexColCenter}
  background-color: ${({ theme }) => theme.colors.white};

  .cashback-skeleton {
    height: 900px;
  }
`;

export const TransactionsPageHeader = styled.div`
  ${FlexCol}
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;

  h2 {
    font-size: 22px;
    font-weight: 500;
  }

  p {
    max-width: 475px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    justify-content: space-between;
  }
`;

export const ErrorMessageContainer = styled.div`
  ${AccountHubBasicContainer}
  ${FlexRow}
  align-items: center;
  color: ${({ theme }) => theme.colors.grey2};
  height: 500px;
  justify-content: center;
  width: 100%;
`;

export const ConnectedAccountsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  margin-top: 10px;

  p {
    margin: unset;
  }
`;

export const ConnectedAccountLogosContainer = styled.div`
  ${FlexRow}
  gap: 7px;
  margin-left: 8px;

  .connected-account-icon {
    border: 1px solid ${({ theme }) => theme.colors.grey2};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 2px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const FilterContainer = styled.div`
  ${FlexCol}
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;

  p {
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    min-width: 60px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      margin: 0;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    gap: 15px;
    margin: unset;
    width: unset;
  }
`;

export const NoTransactions = styled.div`
  ${FlexCenter}
  min-height: 100px;
  color: ${({ theme }) => theme.colors.lightGray1};
`;

export const FilterOptionsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 999px;
  gap: 25px;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    padding: 13px 30px;
  }
`;

export const TransactionFilterCheckbox = styled(Checkbox)`
  height: 20px;

  .checkbox, square {
    border-radius: 3px;
    border-width: 1px;
    max-height: 15px;
    max-width: 15px;
    min-height: 15px;
    min-width: 15px;

    svg {
      width: 10px;
    }
  }

  .label {
    color: ${({ theme }) => theme.colors.grey5};
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      font-size: 14px;
    }
  }
`;
